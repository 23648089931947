let API_POINTS = {
  url: "http://localhost:8080/v1"
};

const SERVER_POINT = process.env.REACT_APP_BUILD_TO || "LOCAL"; // QA, UAT, PROD, LOCAL

switch (SERVER_POINT) {
  case "PROD":
    API_POINTS = {
      url: "https://apply-server.alliance2020.com/v1"
    };
    break;
  case "UAT":
    API_POINTS = {
      url: "https://alliance-uat.qaserverix.co/v1"
    };
    break;
  case "QA":
    API_POINTS = {
      url: "https://alliance-qa.qaserverix.co/v1"
    };
    break;
   case "DEV":
    API_POINTS = {
      url: "http://192.168.1.88:4054/v1"
    };
    break;

  default:
    API_POINTS = {
      url: "http://localhost:8080/v1"
    };
    break;
}

export const _api = API_POINTS;

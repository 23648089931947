import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import { withStyles, FormControl, Grid, Paper, IconButton } from '@material-ui/core';
import classNames from "classnames"

//Styles
import DirectoriesStyles from "../DirectoriesStyles"
import { AllianceAMaskIcon, CloseIcon, DeleteIcon } from "../../../assets/svg"

// Import Components
import { Button, TextField, Typography, CheckBox, Select, DatePickr } from "../../../components";

// Import Helpers
import validations from "../../../utils/validations";

// Import Actions
import { directoryAction, alertAction } from "../../../redux/actions";
import history from "../../../config/history";


const AddEditDirectory = (props) => {
    // Define Dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, match } = props;

    //get reducerData
    const { directory } = useSelector(state => state.directory);

    const associateOptions = [{ value: "WSTCSA", label: "WSTCSA" }, { value: "PSLA", label: "PSLA" }, { value: "SLA", label: "SLA" }, { value: "STLA", label: "STLA" }]

    //set edit flag true for new user
    const isEdit = match.params && match.params.id ? true : false;

    let defaultFormVal = {
        first_name: "",
        last_name: "",
        hologram: "",
        company: "",
        card_id: "",
    }
    //edit user
    if (isEdit && Object.keys(directory).length > 0) {
        defaultFormVal = {
            first_name: directory.first_name ? directory.first_name : '',
            last_name: directory.last_name ? directory.last_name : "",
            hologram: directory.hologram ? directory.hologram : "",
            company: directory.company ? directory.company : "",
            card_id: directory.card_id ? directory.card_id : "",
        }
    }

    // Set Form default values
    const { register, handleSubmit, errors, control, reset } = useForm({
        mode: 'all',
        reValidateMode: 'all',
        defaultValues: defaultFormVal
    });

    //set state for dropdowns
    const [doNotUse, setDoNotUse] = useState(isEdit && Number(directory.useLogo) > 0 ? true : false);
    const [userImg, setUserImg] = useState(directory.userImg ? directory.userImg : "");
    // const [associate, setAssociate] = useState(directory.category ? directory.category : "")
    const [showValidate, setShowValidate] = useState(false);
    const [expiryDate, setExpiryData] = useState(directory.expiry_date ? directory.expiry_date : "")
    const [isActive, setIsActive] = useState(directory.is_active === 0 ? false : true)

    // Check / Uncheck Send Password Checkbox
    const handleChangeDoNotUse = (event) => {
        setDoNotUse(event.target.checked);
    };

    //update ddn
    const handleChangeAssociate = (e) => {
        // setAssociate(e.value)
    }

    // Submit Form
    const onSubmit = (data) => {
        if (data && expiryDate && new Date(expiryDate) != "Invalid Date") {
            setShowValidate(false)

            // data["category"] = associate;
            data["category"] = "";
            data["userImg"] = userImg;
            data["useLogo"] = doNotUse;
            data["expiry_date"] = expiryDate
            data["is_active"] = isActive;
            if (data["first_name"] && data["first_name"].length > 50) {
                dispatch(alertAction.error("First Name length should be less than 50"));
            } else if (data["last_name"] && data["last_name"].length > 50) {
                dispatch(alertAction.error("Last Name length should be less than 50"));
            } else if (data["company"] && data["company"].length > 250) {
                dispatch(alertAction.error("Company length should be less than 250"));
            } else if (data["card_id"] && data["card_id"].length > 250) {
                dispatch(alertAction.error("Card id length should be less than 250"));
            } else if (data["hologram"] && data["hologram"].length > 200) {
                dispatch(alertAction.error("Hologram length should be less than 200"));

            } else if (isEdit === true) {
                //edit users
                dispatch(directoryAction.editDirectories({ data: data, id: match.params.id, oldData: directory || {} }, () => {
                    history.push({ pathname: `/directory` });
                }));
            } else {
                //add new users
                dispatch(directoryAction.addDirectories(data, () => {
                    history.push({ pathname: `/directory` });
                }));
            }
        } else {
            setShowValidate(true)
        }
    }

    const onError = () => {
        if (expiryDate && new Date(expiryDate) != "Invalid Date") {
            setShowValidate(false)
        } else {
            setShowValidate(true)
        }
    }

    /**
   *Handle Cancel Navigage to Tab based on Roles
   */
    const handleCancel = () => {
        props.history.push('/directory');
    };

    //handle image upload
    const imageHandler = (e) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setUserImg(reader.result)
                }
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    //change date
    const handleChange = (e) => {
        setExpiryData(e["value"])
        if (!e["value"] || new Date(e["value"]) == "Invalid Date") {
            setShowValidate(true)
        }
    }

    //prevent form submit on enter click
    const checkKeyDown = (e) => {
        if (e.code === 'Enter') e.preventDefault();
    };

    return (
        <React.Fragment>
            <Grid item xs={12} >
                <Grid container direction="row" justify="space-between" alignItems="center" className={classes.titlerow}>
                    <Grid item>
                        <Typography variant="h3" component="h3">{isEdit ? "Edit Listing" : 'ADD Listing'} </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.addDirectoryContainer} container alignItems="flex-start" spacing={3} wrap="nowrap">
                <Grid item>
                    <Paper elevation={3} className={`${"relative"} ${classes.cardSectionProfileImg}`} >
                        <Grid className={classes.profileImgContainer}>
                            {userImg ? <IconButton className={'closeIconBtn'} onClick={() => { setUserImg("") }}><DeleteIcon /></IconButton> : ''}
                            <label htmlFor="file-input">
                                {userImg ? <img src={userImg} className={classes.uploadImgPopup} /> : <AllianceAMaskIcon />}
                                <input className={`${classes.uploadImgPopup} ${classes.uploadImgOpacity}`} id="file-input" onChange={(e) => { imageHandler(e) }} type="file" accept=".png,.jpeg,.jpg" style={{ display: "none" }} value='' />
                                {!userImg ? <Grid className={classes.uploadText} >Upload image</Grid> : ''}
                            </label>
                        </Grid>
                    </Paper>
                    {userImg ? <Grid className={classes.userProfileDoNot}>
                        <CheckBox
                            label="Use current"
                            checked={doNotUse}
                            color="secondary"
                            handleChange={(w) => { handleChangeDoNotUse(w) }}
                        />
                    </Grid> : ''}
                </Grid>
                <Grid item className={classes.grow}>
                    <Paper elevation={3} className={`${"pt-3 pb-3"} ${classes.usersCardSection}`} >
                        <form onSubmit={handleSubmit(onSubmit, onError)} onKeyDown={(e) => checkKeyDown(e)} noValidate>
                            <Grid container spacing={3}>

                                <Grid item xs={12} className={classes.titleContainer}>
                                    <Typography variant="h6" className={classes.groupTitle}>{"Generic"}</Typography>
                                </Grid>

                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{"First Name"}<span className={"reqStar"}>*</span></Typography>
                                        <TextField
                                            id={"first_name"}
                                            name={"first_name"}
                                            size={"small"}
                                            margin={"none"}
                                            error={errors.first_name ? true : false}
                                            helperText={errors.first_name?.message}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired(),
                                                }
                                            })}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{"Last Name"}<span className={"reqStar"}>*</span></Typography>
                                        <TextField
                                            id={"last_name"}
                                            name={"last_name"}
                                            size={"small"}
                                            margin={"none"}
                                            error={errors.last_name ? true : false}
                                            helperText={errors.last_name?.message}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired(),
                                                }
                                            })}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{"Company"}<span className={"reqStar"}>*</span></Typography>
                                        <TextField
                                            id={"company"}
                                            name={"company"}
                                            size={"small"}
                                            margin={"none"}
                                            error={errors.company ? true : false}
                                            helperText={errors.company?.message}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired(),
                                                }
                                            })}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{"Hologram"}<span className={"reqStar"}>*</span></Typography>
                                        <TextField
                                            id={"hologram"}
                                            name={"hologram"}
                                            size={"small"}
                                            margin={"none"}
                                            error={errors.hologram ? true : false}
                                            helperText={errors.hologram?.message}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired(),
                                                }
                                            })}
                                        />
                                    </Grid>
                                </Grid>

                                {/* <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel "}>{"Associate"}<span className={"reqStar"}>*</span></Typography>
                                        <FormControl variant="outlined" className={classNames(classes.formControl, classes.selectBox)}>
                                            <Select
                                                options={associateOptions}
                                                handleChange={(e) => handleChangeAssociate(e)}
                                                value={associate}
                                                error={showValidate && !associate ? true : false}
                                                errorText={"This field is required"}
                                            >
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid> */}

                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{"Card ID"}<span className={"reqStar"}>*</span></Typography>
                                        <TextField
                                            id={"card_id"}
                                            name={"card_id"}
                                            size={"small"}
                                            margin={"none"}
                                            error={errors.card_id ? true : false}
                                            helperText={errors.card_id?.message}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired(),
                                                }
                                            })}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{"Expiration Date *"}</Typography>
                                        <DatePickr
                                            id={"expiry_date"}
                                            name={"expiry_date"}
                                            value={expiryDate ? expiryDate : ''}
                                            className={classes.textField}
                                            format={"YYYY/MM/DD"}
                                            mask={"____/__/__"}
                                            placeholder={"____/__/__"}
                                            handleChange={(e) => { handleChange(e) }}
                                            inputVariant="outlined"
                                            margin="dense"
                                            size="small"
                                            error={showValidate && !expiryDate ? true : false}
                                            fullWidth={true}
                                            disabled={false}
                                        />
                                        {showValidate ? (!expiryDate) ? <p style={{ color: "#f35b5b" }} className={classes.hintText}>{`This field is required`}</p> : new Date(expiryDate) == "Invalid Date" ? <p style={{ color: "#f35b5b" }} className={classes.hintText}>{`Please enter valid date`}</p> : "" : ""}
                                    </Grid>
                                </Grid>

                                <Grid item xs={4} align="left" className={classes.activeCheckBox}>
                                    <Grid item xs={10} className="mt-2">
                                        <CheckBox
                                            label="Active"
                                            checked={isActive}
                                            color="secondary"
                                            handleChange={(w) => { setIsActive(w.target.checked) }}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid container spacing={5} className={`${classes.addUSerAction} ${"mt-1"}`}>
                                <Grid item xs={12} className="">
                                    <Grid item xs={4} align="left">
                                        <Button color="primary" size="small" title={isEdit ? "Update" : "Create"} className="mr-1" type="submit" ></Button>
                                        <Button color="secondary" size="small" title={"CANCEl"} onClick={handleCancel} ></Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

// default props
AddEditDirectory.defaultProps = {
    classes: {}
};

// prop types
AddEditDirectory.propTypes = {
    classes: PropTypes.object
};

export default withStyles(DirectoriesStyles)(AddEditDirectory);

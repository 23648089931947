import palette from '../../assets/theme/palette';
const SubmissionsStyles = () => ({

    padding5: { padding: 5 },
    padding6: { padding: 6 },
    paddingBottom5: { paddingBottom: 5 },
    titlerow: {
        height: '34px',
        padding: '0 16px',

        // backgroundColor: 'blue',
        // padding: '10px 0',
        '& .MuiToggleButton-root': {
            padding: 7
        }
    },
    allianceDrawer: {
        '& .MuiDrawer-paper': {
            width: 600
        }
    },
    grow: {
        flexGrow: 1
    },
    settingsTile: {
        padding: '8px 13px 8px 7px',
        border: `1px solid ${palette.greyshades.light2}`,
        borderRadius: 4,
        backgroundColor: palette.greyshades.light3,
        marginBottom: 15,
        height: 42,
        '& .MuiFormControlLabel-root': {
            marginRight: 0
        },
        '& .dragIcon': {
            cursor: "move"
        }
    },
    dragIcon: {
        marginRight: 5,
        cursor: 'pointer',
        fill: palette.greyshades.light1
    },
    checkIcon: {
        fill: palette.primary.dary
    },
    backDrop: {
        zIndex: 3
    },
    SubmissionTable: {
        paddingLeft: 16,
        paddingRight: 16
    },
    cardSection: {
        height: '100%',
        width: "100%",
        borderRadius: '4px',
        boxShadow: '0px 0px 8px #d4d1d1',
        padding: '8px 0px 8px 16px',
    },
    textArea: {
        height: 'auto',
        minHeight: 34,
        '& .MuiOutlinedInput-multiline': {
            height: 'auto',
            borderRadius: '4px !important'
        }
    },
    SubmissionsTable: {
        paddingRight: 16,
        paddingLeft: 16
    },
    subMissionListDropDown: {
        '& .MuiPaper-root': {
            right: '16px !important',
            left: 'auto !important'
        }
    },
    // errorContainer: {
    //     '& .MuiTypography-root': {
    //         background: 'red',
    //         color: '#fff',
    //         padding: '8px'
    //     }
    // }
    columnSttingsBody: {
        maxHeight: 'calc(100vh - 138px)',
        overflowY: 'auto'
    }
})

export default SubmissionsStyles
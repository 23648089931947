import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames"
import { Grid, FormControl, TextareaAutosize } from '@material-ui/core';

//Styles and Images
import ApplicationStyles from "../ApplicationStyles"

//import actions
import { applicationAction, updateReducerAction } from '../../../redux/actions';

// Import Components
import { Button, TextField, Typography, Select } from "../../../components";
import QuestionList from "./QuestionList";

// Import Cofig Constants
import { application_page_constants } from "../../../config/constants";
import { applicationConstant } from "../../../redux/constants";

const QuestionSection = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, register, handleClose } = props;

    //get reducer data
    const { getApp: { appData, question } } = useSelector(s => (s.application))

    //set state
    const [text, setText] = useState('');
    const [quesType, setQuesType] = useState('');
    const [choice, setChoice] = useState('');
    const [addQValidation, setAddQValidation] = useState(false)

    //clear question state
    const clearData = () => {
        setAddQValidation(false)
        setText('')
        setQuesType('')
        setChoice('')
    }

    ///add new question
    const addQuestion = () => {
        if (text && quesType && text.trim() && quesType.trim()) {
            if ((quesType !== 'single' && quesType !== 'multiple') || ((quesType === 'single' || quesType === 'multiple') && choice && choice.trim())) {
                const updatedData = [...question, { id: null, text: text, type: quesType, choices: choice, position: question.length + 1 }]
                dispatch(updateReducerAction.update(applicationConstant.UPDATE_QUESTION_REDUCER, updatedData, () => {
                    clearData()
                }))
            } else {
                setAddQValidation(true)
            }
        } else {
            setAddQValidation(true)
        }
    }

    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <QuestionList classes={classes} />
                </Grid>
                <Grid item xs={12} className={classes.greyContainer}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Typography variant={"body1"}>{application_page_constants.TEXT}</Typography>
                                <TextField
                                    id={`name`}
                                    name={`name`}
                                    margin="none"
                                    placeholder={'text'}
                                    value={text ? text : ''}
                                    onChange={(e) => { setText(e.target.value) }}
                                    error={addQValidation && !text.trim() ? true : false}
                                    helperText={addQValidation && !text.trim() ? "this field is required" : ""}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant={"body1"}>{application_page_constants.TYPE}</Typography>
                                <FormControl variant="outlined" className={classNames(classes.formControl, classes.selectBox)}>
                                    <Select
                                        options={application_page_constants.QuestionTypes}
                                        handleChange={(e) => setQuesType(e.value)}
                                        value={quesType}
                                        error={addQValidation && !quesType ? true : false}
                                        errorText={addQValidation && !quesType ? "this field is required" : ""}
                                    >
                                    </Select>
                                </FormControl>
                            </Grid>

                            {(quesType === 'single' || quesType === 'multiple') &&
                                <Grid item xs={12}>
                                    <TextareaAutosize className={classes.allianceTextarea} aria-label="minimum height" rowsMin={8} onChange={(e) => { setChoice(e.target.value) }} />
                                    {(addQValidation && !choice.trim()) && <p style={{ color: "red" }}>this field is required</p>}
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <Grid>
                                    <Button color="primary" size="small" title={application_page_constants.ADD_QUESTION} onClick={() => { addQuestion() }} className="mr-1"></Button>
                                    <Button color="secondary" size="small" title={application_page_constants.CANCEL} onClick={() => { clearData() }}></Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )

}

// default props
QuestionSection.defaultProps = {
    classes: {},
    editAnchor: {},
    handelEditClose: () => { },
};

// prop types
QuestionSection.propTypes = {
    classes: PropTypes.object,
    editAnchor: PropTypes.object,
    handelEditClose: PropTypes.func,
};

export default withStyles(ApplicationStyles)(QuestionSection);

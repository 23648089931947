import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles, Paper } from '@material-ui/core';

//Images

//Styles
import UsersStyles from "../../UsersStyles"

// Import Components
import { Table, AlertDialog, Typography, Button as Btn, TextField } from "../../../../components";
import NoRecordsFound from '../NoRecordsFound'

import { getTableHeight, getAuditTableRowSize } from '../../../../config/layout'

// Import Actions
import { auditAction, userAction } from "../../../../redux/actions";

//Constants
import { audit_page_constants } from "../../../../config/constants"

// Import Config Layout
import { getChildContainerTabsHeight, getTabsAuditTableHeight, } from "../../../../config/layout";
import { TextareaAutosize } from "@material-ui/core";



const AuditLogs = (props) => {

    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes } = props;

    //get page height
    const height = getTabsAuditTableHeight();
    const tableRowSize = getAuditTableRowSize();

    //get reducer data
    const { getUser: { id: currentUID } } = useSelector(state => state.user);
    const { search: { page, data } } = useSelector(state => state.audit);
    const { loading } = useSelector(s => s.UI)
    const [openAlert, setOpenAlert] = useState(null)

    //get selected user fields
    const selectHeaders = audit_page_constants.HEADER_DEFAULT
    const sortHeaders = audit_page_constants.HEADER_DEFAULT
    let selectedField = [];
    let selectedHeaders = [];
    if (selectHeaders && sortHeaders) {
        sortHeaders.map(i => {
            const isFound = selectHeaders.filter(h => (i === h))
            if (isFound.length) {
                selectedHeaders.push(i)
                selectedField.push(audit_page_constants.HEADER_MAP_CONSTANT[i])
            }
        })
    }

    useEffect(() => {
        dispatch(auditAction.search(1, tableRowSize, "id", "desc", [], [], currentUID, "users"));
    }, [dispatch]);

    //Handle Table pagination Event
    const onChangePageTable = (skip, limit) => {
        const { sFields, sValues, sortBy, sort } = page;
        dispatch(auditAction.search(skip, limit, sortBy, sort, sFields, sValues, currentUID, "users"));
    };

    //Handle Table Sort Request Event
    const onSortRequest = (sortBy, sort) => {
        dispatch(auditAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues, currentUID, "users"));
    };

    //Button actions
    const handelActionButtonClick = (field, itemId, item) => {
        switch (field) {
            case "SEARCH":
                if (item.action.toLowerCase() !== "delete")
                    setOpenAlert({ field, itemId, item })
                break;
            default:
                break;
        }
    }

    //Handle Table Search Input Change Event
    const oninputChangeRequest = (searchField, searchKey) => {
        let { sFields, sValues } = page;
        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === "") {
                const index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        dispatch(auditAction.search(1, page.limit, page.sortBy, page.sort, page.sFields, page.sValues, currentUID, "users"));
    };

    return (
        <React.Fragment>
            {page.totalCount > 0 &&
                <Grid item xs={12} className={`${classes.AuditLogTable}`}>
                    <Paper elevation={3} className={classes.cardSection} >
                        <Table
                            height={height}
                            classes={classes}
                            data={data}
                            header={selectedHeaders}
                            field={selectedField}
                            search={selectedField}
                            sortBy={page.sortBy}
                            sort={page.sort}
                            onInputChangeRequest={oninputChangeRequest}
                            onChangePagination={onChangePageTable}
                            onSortRequest={onSortRequest}
                            handelActionButtonClick={handelActionButtonClick}
                            page={Number(page.skip)}
                            count={page.count}
                            actions={['SEARCHAUDIT']}
                            sFields={page.sFields}
                            sValues={page.sValues}
                            rowsPerPage={page.limit}
                            reset={page.sFields && page.sFields.length > 0 ? false : true}
                            screenSizeRowPerPage={tableRowSize}
                        >
                        </Table>
                    </Paper>
                </Grid>
            }
            {(page.totalCount === 0 && !loading) && <NoRecordsFound />}
            {openAlert &&
                <Grid className={"alertDialog"}>
                    <AlertDialog
                        fullScreen={false}
                        open={true}

                    >
                        <Grid className={'p-4'} style={{ minWidth: '600px' }}>
                            <Typography variant="h4" component="h3" className="mb-1"> {"Old value"} </Typography>
                            {/* <Typography variant="h6" color="TextSecondary"> {`${openAlert.item.old_value}`} </Typography> */}
                            <TextareaAutosize rowsMin="2" rowsMax="6" className={`${"mb-2"} ${classes.allianceTextarea}`} value={openAlert.item.old_value} readOnly />
                            <Typography variant="h4" component="h3" className="mb-1"> {"New value"} </Typography>
                            <TextareaAutosize rowsMin="2" rowsMax="6" className={classes.allianceTextarea} value={openAlert.item.new_value} readOnly />
                            {/* <Typography variant="h6" color="TextSecondary"> {`${openAlert.item.new_value}`} </Typography> */}
                            <Grid className={'pt-2'} align={"center"}>
                                <Btn
                                    color={"secondary"}
                                    onClick={() => { setOpenAlert(null) }}
                                    title={"Close"}
                                />
                            </Grid>
                        </Grid>
                    </AlertDialog>
                </Grid>
            }
        </React.Fragment>
    );
}

// default props
AuditLogs.defaultProps = {
    classes: {}
};

// prop types
AuditLogs.propTypes = {
    classes: PropTypes.object
};

export default withStyles(UsersStyles)(AuditLogs);

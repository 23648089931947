import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import arrayMove from "array-move";
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";


//Styles
import ServicesStyles from "../ServicesStyles"

//Images
import { DragIcon, CheckSelectedIcon, DeleteIcon, BellIcon } from '../../../assets/svg'

// Import Components
import { Button, TextFieldWithoutRef, Typography, CheckBox, Link } from "../../../components";
import AddedSuccessfully from "../Components/AddedSuccessfully"

// Import Helpers
import validations from "../../../utils/validations";

// Import Constants
import { serviceConstant } from "../../../redux/constants";
import { user_page_constants } from "../../../config/constants";

// Import Actions
import { serviceAction, updateReducerAction } from "../../../redux/actions";

//Sortable Item
const SortableItem = SortableElement(props => {
    return (
        props.children
    );
});

//Drag Handle
const DragHandle = SortableHandle((props) => {
    const { classes } = props;

    return (<DragIcon className={"cla"} draggable={false}></DragIcon>);
})

//create sortable list for drag and drop
const SortableList = SortableContainer(props => {

    //get props
    const { data, classes, checkedData, updateCheckedData } = props;

    return (
        <React.Fragment>
            <Grid container spacing={2} justify={"space-between"}>
                {data && data.map((item, index) => (
                    <SortableItem
                        key={`sort-item-${index}`}
                        index={index}
                    >
                        <Grid item sm={6} className={classes.serviceSettingTileWidth}>

                            <Grid container alignItems="center" className={`${classes.servicesSettingsTile}`}>
                                <Grid className={`${"mr5"} ${classes.dragContainer}`}><DragHandle /></Grid>
                                <Grid className={"grow"}>
                                    <Grid container>
                                        <Typography variant={"body2"} title={item} className={`${"oneLineEllip"} ${classes.grow}`}></Typography>
                                        <CheckBox
                                            className={"settingSTileCheck"}
                                            checked={checkedData.indexOf(item) > -1}
                                            handleChange={() => { updateCheckedData(item, index) }}
                                            icon
                                            checkedIcon
                                        />
                                    </Grid>
                                    {
                                        item === "Introduction" && checkedData.indexOf(item) > -1 &&
                                        (
                                            <>
                                                <Grid container>
                                                    <Typography variant={"body2"} title={"Introduction signature"} className={classes.grow}></Typography>
                                                    <CheckBox
                                                        className={"settingSTileCheck"}
                                                        checked={checkedData.indexOf("Introduction signature") > -1}
                                                        handleChange={() => { updateCheckedData("Introduction signature") }}
                                                        icon
                                                        checkedIcon
                                                    />
                                                </Grid>
                                                <Grid container>
                                                    <Typography variant={"body2"} title={"Send Attachment To Taz"} className={classes.grow}></Typography>
                                                    <CheckBox
                                                        className={"settingSTileCheck"}
                                                        checked={checkedData.indexOf("Introduction Attach") > -1}
                                                        handleChange={() => { updateCheckedData("Introduction Attach") }}
                                                        icon
                                                        checkedIcon
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    }
                                    {
                                        item === "Criteria" && checkedData.indexOf(item) > -1 &&
                                        <Grid container>
                                            <Typography variant={"body2"} title={"Criteria signature"} className={classes.grow}></Typography>
                                            <CheckBox
                                                className={"settingSTileCheck"}
                                                checked={checkedData.indexOf("Criteria signature") > -1}
                                                handleChange={() => { updateCheckedData("Criteria signature") }}
                                                icon
                                                checkedIcon
                                            />
                                        </Grid>
                                    }
                                    {
                                        item === "Application Signature" &&
                                        <Grid container>
                                            <Typography variant={"body2"} title={"Application Review"} className={classes.grow}></Typography>
                                            <CheckBox
                                                className={"settingSTileCheck"}
                                                checked={checkedData.indexOf("Application Review") > -1}
                                                handleChange={() => { updateCheckedData("Application Review") }}
                                                icon
                                                checkedIcon
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>

                    </SortableItem>
                ))}
            </Grid>
        </React.Fragment>
    )
})

const AddService = (props) => {

    //define dispatch
    const dispatch = useDispatch();

    const { classes, handleGoToService, addedSuccess } = props;

    const { createFromScratch, createFromScratchErrorFlags: errorFlags } = useSelector((state) => state.service);

    const [data, setData] = useState(createFromScratch.serviceData);
    const [checkedData, setCheckedData] = useState(createFromScratch.serviceCheckedData);

    // Set Form Values
    const { register, handleSubmit, errors } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {}
    });

    // Submit Form
    const onSubmit = (data) => {
        console.log(data)
    }

    const handleChange = (e) => {
        createFromScratch[e.target.name] = e.target.value
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
    }

    //chakne row index on sort
    const onSortEnd = (e) => {
        const { oldIndex, newIndex } = e;
        const resultData = arrayMove(data, oldIndex, newIndex);
        setData(resultData)
        createFromScratch.serviceData = resultData
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
    }

    //update checked data
    const updateCheckedData = (com, ind) => {
        const compIndex = checkedData.indexOf(com);
        let updateData = []
        if (compIndex === -1) {
            updateData = [...checkedData, com]
        } else {
            updateData = [...checkedData]
            updateData.splice(compIndex, 1)
        }
        setCheckedData(updateData)
        createFromScratch.serviceCheckedData = updateData
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
    }

    return (

        <Grid item xs={12} className={classes.contentcontainer} >
            {!addedSuccess ? <Typography variant="h4" className="mt-3 mb-3" align="center">{user_page_constants.ADDSERVICE}</Typography> : <Grid className={"mt-6"}></Grid>}
            <Paper elevation={3} className={classes.cardSectionDialogAddTemplate} >
                {!addedSuccess &&
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Grid item sm={12}>
                            <Grid container spacing={3}>
                                <Grid item sm={7} align="left">
                                    <Grid container spacing={3}>
                                        <Grid item sm={6} className="mb-1">
                                            <Typography className="textFieldLabel" title={user_page_constants.ENTER_PRODUCT} variant={"body1"}><span className={"reqStar"}>*</span></Typography>
                                            <TextFieldWithoutRef
                                                name={"product"}
                                                margin="none"
                                                size={"small"}
                                                value={createFromScratch["product"] ? createFromScratch["product"] : ""}
                                                handleChange={(e) => handleChange(e)}
                                                error={errorFlags.product ? true : false}
                                                helperText={errorFlags.product ? "This field is required" : ""}
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Typography className="textFieldLabel" title={user_page_constants.ENTER_FEE} variant={"body1"}></Typography>
                                            <TextFieldWithoutRef
                                                name={"fees"}
                                                margin="none"
                                                size={"small"}
                                                value={createFromScratch["fees"] ? createFromScratch["fees"] : ""}
                                                handleChange={(e) => handleChange(e)}
                                                error={errorFlags.fees ? true : false}
                                                helperText={errorFlags.fees ? "This field is required" : ""}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={12} className="mt-2">
                                        <SortableList
                                            useDragHandle
                                            axis="xy"
                                            enableUserSelectHack={false}
                                            helperClass="sortable-list-tab"
                                            data={data}
                                            onSortEnd={onSortEnd}
                                            checkedData={[...checkedData]}
                                            updateCheckedData={updateCheckedData}
                                            classes={classes}
                                        ></SortableList>
                                    </Grid>
                                </Grid>
                                <Grid item sm={5} align="right">
                                    <Grid item sm={12}>
                                        <Grid className={classes.selectTemplate}>
                                            <Typography variant={"h6"} title={"Selected Template"} className="mb-2" align="center"></Typography>
                                            <Grid container alignItems="center" className={classes.settingsTileBgnone}>
                                                <Typography variant={"body2"} title={createFromScratch.template_name} className={`${"oneLineEllip"} ${classes.grow}`}></Typography>
                                                <CheckSelectedIcon className={classes.checkIcon} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                }
                {
                    addedSuccess &&
                    <AddedSuccessfully
                        handleGoToService={handleGoToService}
                        title={user_page_constants.SERVICE_LIST}
                    />
                }
            </Paper>
        </Grid>

    );
}

// default props
AddService.defaultProps = {
    classes: {}
};

// prop types
AddService.propTypes = {
    classes: PropTypes.object
};

export default withStyles(ServicesStyles)(AddService);

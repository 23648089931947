import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles, Paper } from '@material-ui/core';

//Styles
import ServicesStyles from "../ServicesStyles"

// Import Components
import { Button as Btn, Table, Typography, AlertDialog, PageContainer } from "../../../components";
import NoRecordsFound from "./NoRecordsFound"

// Import Cofig Constants
import { serviceConstant } from "../../../redux/constants";

import { getTableHeight, getTableRowSize } from "../../../config/layout"

// Import Actions
import { serviceAction, updateReducerAction, userAction } from "../../../redux/actions";

const ServicesList = (props) => {

  //define dispatch
  const dispatch = useDispatch();

  const { classes, editOpenDrawer } = props;

  //set state
  const [deleteAlert, setDeleteAlert] = useState(null)
  const [selectAll, setSelectAll] = useState(false)

  const height = getTableHeight();
  const tableRowSize = getTableRowSize();

  const serviceList = {
    "Client Code":"code", "Client": "full_name", "Company Name":"company_name", "Template": "temp_name", "Product": "product", "Fee": "fee"
  }

  //get reducer data
  const { search: { page, data }, applications } = useSelector(state => state.service);
  let { checkBoxSelect } = useSelector(state => state.service);
  const { currentUser: { preference, id: currentUID } } = useSelector(state => state.user);

  let sortHeaders = preference && preference.service && preference.service.sort ? preference.service.sort : ["Client", "Company Name", "Template", "Product", "Fee"];
  if(!sortHeaders.includes("Client Code")){
    sortHeaders.unshift("Client Code")
  }
  if(!sortHeaders.includes("Company Name")){
    sortHeaders.push("Company Name")
  }
  const { loading } = useSelector(s => s.UI)

  let selectedField1 = [];
  let selectedHeaders1 = [];
  if (sortHeaders) {
    sortHeaders.map(i => {
      selectedHeaders1.push(i)
      selectedField1.push(serviceList[i])
    })
  }

  useEffect(() => {
    if (page.count === Object.keys(checkBoxSelect).length && page.count !== 0) {
      setSelectAll(true)
    }
    let { sFields, sValues } = page;
    if (typeof sFields === 'undefined')
      sFields = []
    if (typeof sValues === 'undefined')
      sValues = []
    dispatch(serviceAction.search(page.skip, tableRowSize, "service_name", "asc", sFields, sValues));
  }, [dispatch]);

    //update select all checkbox
    useEffect(()=>{
      if(checkBoxSelect && Object.keys(checkBoxSelect).length===0 && selectAll){
        setSelectAll(false)
      }
    },[checkBoxSelect,dispatch])

  //Handle Table pagination Event
  const onChangePageTable = (skip, limit) => {
    const { sFields, sValues, sortBy, sort } = page;
    dispatch(serviceAction.search(skip, limit, sortBy, sort, sFields, sValues));
  };

  //Handle Table Sort Request Event
  const onSortRequest = (sortBy, sort) => {
    dispatch(serviceAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues));
  };

  //handle detlete user on yes click in alert
  const deleteAction = () => {
    const { field, itemId, item } = deleteAlert
    dispatch(serviceAction.remove({ id: item.id }, () => {
      dispatch(serviceAction.search(1, page.limit, "create_time", "desc", page.sFields, page.sValues));
      setDeleteAlert(null)
    }));
  }

  //Button actions
  const handelActionButtonClick = (field, itemId, item) => {
    switch (field) {
      case "EDIT":
        const selectedservice = data.filter(obj => (obj.id === itemId))
        dispatch(updateReducerAction.update(serviceConstant.ADD_EDIT_SERVICE_REDUCER_UPDATE, selectedservice[0], () => {
          editOpenDrawer(itemId)
        }));
        break;
      case "DELETE":
        dispatch(serviceAction.getApplicationCount({ id: itemId }, () => {
          setDeleteAlert({ field: field, itemId: itemId, item: item })
        }))
        break;
      default:
        break;
    }
  }

  //update preference on submit click
  const updatePreference = (sort) => {
    let updatedData = [...sort]
    const allHeaders = preference && preference.service && preference.service.sort ? preference.service.sort : [];
    allHeaders.map(i => {
      if (updatedData.indexOf(i) === -1) {
        updatedData.push(i)
      }
    })
    dispatch(userAction.updatePreference({ id: currentUID, preference: { ...preference, "service": { "sort": updatedData } } }, () => { }));
  }

  //Handle Table Search Input Change Event
  const oninputChangeRequest = (searchField, searchKey) => {
    let { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    dispatch(serviceAction.search(1, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
  };

  // handle checkbox change
  const handleCheckboxChange = (name, value) => {
    if (name === "checkAll" && value === true) {
      page.allServiceIds.map((key) => {
        checkBoxSelect[key] = true
      })
    } else if (name === "checkAll" && value === false) {
      checkBoxSelect = {}
    }
    else if (value === false) {
      delete checkBoxSelect[name]
    }
    else {
      checkBoxSelect[name] = value
    }

    if (page.count === Object.keys(checkBoxSelect).length && page.count !== 0) {
      setSelectAll(true)
    }
    else {
      setSelectAll(false)
    }
    dispatch(updateReducerAction.update(serviceConstant.CHECKBOX_REDUCER_UPDATE, checkBoxSelect, () => { }));
  }

  return (
    <React.Fragment>
      {/* <PageContainer height={height} paddingRight={12}> */}
      {(page.totalcount > 0) &&
        <Grid item xs={12} className={`${"mt-15"} ${classes.servicesTable}`}>
          <Paper elevation={3} className={classes.cardSection} >
            <Table
              height={height}
              classes={classes}
              data={data}
              header={selectedHeaders1}
              field={selectedField1}
              search={selectedField1}
              sortBy={page.sortBy}
              sort={page.sort}
              onInputChangeRequest={oninputChangeRequest}
              onChangePagination={onChangePageTable}
              onSortRequest={onSortRequest}
              handelActionButtonClick={handelActionButtonClick}
              updatePreference={updatePreference}
              page={Number(page.skip)}
              count={page.count}
              actions={['EDIT', 'DELETE']}
              sFields={page.sFields}
              sValues={page.sValues}
              rowsPerPage={page.limit}
              checkBox={true}
              checkBoxSelect={checkBoxSelect}
              handleCheckboxChange={handleCheckboxChange}
              selectAll={selectAll}
              screenSizeRowPerPage={tableRowSize}
              loading={loading}
            >
            </Table>
          </Paper>
        </Grid>
      }
      {(!page.totalcount && !loading) && <NoRecordsFound />}
      {/* </PageContainer>  */}
      {deleteAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            {applications > 0 ?
              <Typography variant="h6">{`An application is mapped to this service, You cannot delete it`}</Typography> :
              <Typography variant="h6">{`Are you sure you want to delete this service`}</Typography>
            }
            <Grid className={'pt-2'} align={"center"}>
              {applications === 0 &&
                <Btn
                  color={"primary"}
                  className={"mr-1"}
                  onClick={() => { deleteAction() }}
                  title={"Yes"}
                />
              }
              <Btn
                color={"secondary"}
                onClick={() => { setDeleteAlert(null) }}
                title={applications > 0 ? "Close" : "No"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
    </React.Fragment>
  );
}

// default props
ServicesList.defaultProps = {
  classes: {}
};

// prop types
ServicesList.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ServicesStyles)(ServicesList);

const HeaderComponentStyle = theme => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1,
  },
  menuItem: {
    borderRadius: '0px',
    padding: '13px 20px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#C3BFC1',
    fontWeight: '400',
  },
  avatarMenu: {
    width: '30px',
    height: '30px',
  },
  menuItemactive: {
    backgroundColor: '#AA224D',
    borderRadius: '0px',
    padding: '13px 20px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#FFFFFF',
    fontWeight: '500',
  },
  avatarMenubutton: {
    width: '30px',
    height: '30px',
    padding: '0',
    minWidth: 'auto',
    '& .AllianceAMaskIcon': {
      borderRadius:30,
      width: '30px',
    height: '30px',
      backgroundColor: '#fff'
    }
  },
  headerLogo: {
    padding: '0px',
    marginRight: 30
  },
  // menuIcon: {
  //   maxWidth: 13,
  //   marginRight: 12
  // },
  backToLogin: {
    color: '#fff',
    fontSize: 13,
    fontWeight: 400,
    marginRight: 10,
    '& .chevLeft': {
      backgroundColor: '#AA224D',
      color: '#fff',
      borderRadius: "30px",
      marginRight: 10
    }
  },
  menuList: {
    '& .MuiPaper-root': {
      width: '112px',
      '& .link:hover': {
        '& .menuIcon': {
          fill: '#12A8DE',
          marginRight: 12
        }
      },
      '& .menuIcon': {
        marginRight: 12,
        fill: '#676767'
      }
    }
  },
  uploadImgPopup: {
    height: 30,
    width: 30,
    borderRadius: 30,
    position: "relative",
    marginRight: 15,
    '& img': {
      height: 30,
      width: 30,
      borderRadius: 30,
      objectFit: 'cover',
      // padding: 10
    }
  },

});

export default HeaderComponentStyle;

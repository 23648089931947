import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import classNames from "classnames"
import arrayMove from "array-move";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { Backdrop, IconButton } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

//Styles
import UsersStyles from "../../UsersStyles"

//Images
import { EditTableIcon, DragIcon, CheckSelectedIcon, CloseIcon } from '../../../../assets/svg'

// Import Helpers
import validations from "../../../../utils/validations";

// Import Components
import { Button, TextField, Typography, CheckBox, Link, AutoComplete } from "../../../../components";

// Import Cofig Constants
import { user_page_constants } from "../../../../config/constants";

// Import Actions
import { serviceAction, updateReducerAction } from "../../../../redux/actions";

// Import Constants
import { serviceConstant } from "../../../../redux/constants";

//Sortable Item
const SortableItem = SortableElement(props => {
    return (
        props.children
    );
});

//Drag Handle
const DragHandle = SortableHandle((props) => {
    const { classes } = props;

    return (<DragIcon className={"cla"} draggable={false}></DragIcon>);
})

//create sortable list for drag and drop
const SortableList = SortableContainer(props => {

    //get props
    const { data, classes, checkedData, updateCheckedData } = props;

    return (
        <React.Fragment>
            <Grid container spacing={2} justify={"space-between"}>
                {data && data.map((item, index) => (
                    <SortableItem
                        key={`sort-item-${index}`}
                        index={index}
                    >
                        <Grid item sm={6} className={classes.serviceSettingTileWidth}>

                            <Grid container alignItems="center" className={`${classes.servicesSettingsTile}`}>
                                <Grid className={`${"pr-1"} ${classes.dragContainer}`}><DragHandle /></Grid>
                                <Grid className={"grow"}>
                                    <Grid container>
                                        <Typography variant={"body2"} title={item} className={` ${classes.grow}`}></Typography>
                                        <CheckBox
                                            className={"settingSTileCheck"}
                                            checked={checkedData.indexOf(item) > -1}
                                            handleChange={() => { updateCheckedData(item, index) }}
                                            icon
                                            checkedIcon
                                        />
                                    </Grid>
                                    {
                                        item === "Introduction" && checkedData.indexOf(item) > -1 &&

                                        <>
                                            <Grid container>
                                                <Typography variant={"body2"} title={"Introduction signature"} className={classes.grow}></Typography>
                                                <CheckBox
                                                    className={"settingSTileCheck p-0"}
                                                    checked={checkedData.indexOf("Introduction signature") > -1}
                                                    handleChange={() => { updateCheckedData("Introduction signature") }}
                                                    icon
                                                    checkedIcon
                                                />
                                            </Grid>
                                            <Grid container>
                                                <Typography variant={"body2"} title={"Send Attachment To Taz"} className={classes.grow}></Typography>
                                                <CheckBox
                                                    className={"settingSTileCheck p-0"}
                                                    checked={checkedData.indexOf("Introduction Attach") > -1}
                                                    handleChange={() => { updateCheckedData("Introduction Attach") }}
                                                    icon
                                                    checkedIcon
                                                />
                                            </Grid>
                                        </>
                                    }
                                    {
                                        item === "Criteria" && checkedData.indexOf(item) > -1 &&
                                        <Grid container>
                                            <Typography variant={"body2"} title={"Criteria signature"} className={classes.grow}></Typography>
                                            <CheckBox
                                                className={"settingSTileCheck"}
                                                checked={checkedData.indexOf("Criteria signature") > -1}
                                                handleChange={() => { updateCheckedData("Criteria signature") }}
                                                icon
                                                checkedIcon
                                            />
                                        </Grid>
                                    }
                                    {
                                        item === "Application Signature" &&
                                        <Grid container>
                                            <Typography variant={"body2"} title={"Application Review"} className={classes.grow}></Typography>
                                            <CheckBox
                                                className={"settingSTileCheck"}
                                                checked={checkedData.indexOf("Application Review") > -1}
                                                handleChange={() => { updateCheckedData("Application Review") }}
                                                icon
                                                checkedIcon
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                    </SortableItem>
                ))}
            </Grid>
        </React.Fragment>
    )
})

const AddEditService = (props) => {
    //define dispatch
    const dispatch = useDispatch();
    const { classes, toggleDrawer, drawer, serviceId } = props;
    //get reducer data
    const { applicationLists, addEditService, usersSearch: { page } } = useSelector(state => state.service);
    const rowData = ["Introduction", "Criteria", "Application Signature", "Document", "Application timestamp", "Queue Submission", "Payment PDF"]
    const editServiceData = JSON.parse(JSON.stringify(addEditService))
    const { getUser: currentuser } = useSelector(state => state.user);
    let clientId = ""
    if (Number(currentuser.ownership_id) === 1 || Number(currentuser.role) === 1) {
        clientId = currentuser.owner_id
    }
    else if (Number(currentuser.ownership_id) === 2 || Number(currentuser.role) === 2) {
        clientId = currentuser.client_id
    }

    const [errorFlags, setErrorFlags] = useState({
        template: false
    })

    //set edit flag true for new user
    const isEdit = (serviceId !== null) ? true : false;

    // set state
    const [template, setTemplate] = useState("");
    const [data, setData] = useState([]);
    const [checkedData, setCheckedData] = useState(["Application Review"]);

    useEffect(() => {
        dispatch(serviceAction.getTemplateList({ client_id: clientId, template_id: isEdit ? editServiceData.template_id : null }))
        setTemplate(isEdit ? editServiceData.template_id : "")
        setCheckedData(isEdit ? editServiceData.selectedItem : ["Application Review"])
        setData(isEdit ? editServiceData.selectOrder : rowData)
    }, [dispatch]);

    // Set Form Values
    const { register, handleSubmit, errors, reset } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            product: isEdit ? editServiceData.product : "",
            fees: isEdit ? editServiceData.fees : 0
        }
    });

    // change compant Drop DN
    const handleChangeTemplate = (value) => {
        setTemplate(value && value.value ? value.value : value);
    };

    //set company selected Dropown
    const getSelectedValue = () => {
        const valuee = applicationLists.filter(i => (i.value === Number(template)))
        return (valuee[0] ? valuee[0] : { value: "", label: "" })
    }

    //chakne row index on sort
    const onSortEnd = (e) => {
        const { oldIndex, newIndex } = e;
        const resultData = arrayMove(data, oldIndex, newIndex);
        setData(resultData)
    }

    //update checked data
    const updateCheckedData = (com, ind) => {
        const compIndex = checkedData.indexOf(com);
        if (compIndex === -1) {
            const updateData = [...checkedData, com]
            setCheckedData(updateData)
        } else {
            const updateData = [...checkedData]
            updateData.splice(compIndex, 1)
            setCheckedData(updateData)
        }
    }

    // Submit Form
    const onSubmit = (finaldata) => {
        let updateFlag = true
        if (!template) {
            errorFlags.template = true
            updateFlag = false
        }

        if (Number(finaldata.fees) && Number(finaldata.fees) < 0) {
            finaldata.fees = 0
        } else if (!Number(finaldata.fees)) {
            finaldata.fees = 0
        }

        finaldata["client_id"] = clientId
        finaldata["template_id"] = template
        finaldata["selectedItem"] = checkedData
        finaldata["selectOrder"] = data
        if (updateFlag) {
            if (isEdit === true) {
                //edit users
                dispatch(serviceAction.updateService({ data: finaldata, id: serviceId, oldData: editServiceData }, () => {
                    reset()
                    setTemplate("")
                    setCheckedData([])
                    dispatch(toggleDrawer(false))
                    dispatch(serviceAction.userServiceSearch(page.skip, page.limit, "service_name", "asc", page.sFields, page.sValues, clientId));
                }));
            } else {
                //add new users
                dispatch(serviceAction.createService(finaldata, () => {
                    reset()
                    setTemplate("")
                    setCheckedData([])
                    dispatch(toggleDrawer(false))
                    dispatch(serviceAction.userServiceSearch(page.skip, page.limit, "service_name", "asc", page.sFields, page.sValues, clientId));
                }));
            }
        } else {
            setErrorFlags(errorFlags)
        }
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backDrop} onClick={toggleDrawer(false)} open={drawer} />
            <Drawer anchor='right' role="presentation" variant="persistent" open={drawer} onClose={toggleDrawer(false)} className={classes.allianceDrawer600}>
                <Grid container alignItems="center" justify="space-between" className={"p-2"}>
                    <Typography variant="h4" title={isEdit ? user_page_constants.EDITA_SERVICE : user_page_constants.ADDA_SERVICE}></Typography>
                    <IconButton onClick={toggleDrawer(false)}><CloseIcon /></IconButton>
                </Grid>
                <Grid container className="pl-2 pr-2 pb-2">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Grid container justify="space-between" spacing={3}>
                            <Grid item sm={6} align="left">
                                <Grid item sm={11}>
                                    <Typography variant="body1">{user_page_constants.SELECT_TEMPLATE}</Typography>
                                    <FormControl variant="outlined" className={`${"textFieldLabel"} ${classNames(classes.formControl, classes.selectBox)}`}>
                                        <AutoComplete
                                            name={'template-sel'}
                                            suggestions={applicationLists}
                                            handleAutoComplete={(newValue, name) => { handleChangeTemplate(newValue) }}
                                            value={getSelectedValue()}
                                            showValidate={errorFlags.template ? true : false}
                                            validationMsg={errorFlags.template ? "This field is required" : ""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} align="right">
                                <Grid item sm={11}>
                                    <Typography variant="body1" className="textFieldLabel">{user_page_constants.ENTER_PRODUCT}<span className={"reqStar"}>*</span></Typography>
                                    <TextField
                                        size={"small"}
                                        margin={"none"}
                                        id={"product"}
                                        name={"product"}
                                        error={errors.product ? true : false}
                                        helperText={errors.product?.message}
                                        inputRef={register({
                                            validate: {
                                                ...validations.isRequired('product cannot be blank')
                                            }
                                        })}
                                    >
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} align="left">
                                <Grid item sm={11}>
                                    <Typography variant="body1" className="textFieldLabel">{user_page_constants.ENTER_FEE}</Typography>
                                    <TextField
                                        size={"small"}
                                        margin={"none"}
                                        id={"fees"}
                                        name={"fees"}
                                        inputRef={
                                            register()
                                        }
                                    >
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container className={"mt-4"}>
                            <SortableList
                                useDragHandle
                                axis="xy"
                                enableUserSelectHack={false}
                                helperClass="sortable-list-tab"
                                data={data}
                                onSortEnd={onSortEnd}
                                checkedData={[...checkedData]}
                                updateCheckedData={updateCheckedData}
                                classes={classes}
                            ></SortableList>
                        </Grid>
                        <Grid container className="pt-4">
                            <Button color="primary" size="small" title={user_page_constants.APPLY} className="mr-1" type="submit"></Button>
                            <Button color="secondary" size="small" onClick={toggleDrawer(false)} title={user_page_constants.CANCEL}></Button>
                        </Grid>
                    </form>
                </Grid>

            </Drawer>
        </React.Fragment>
    )
}
// default props
AddEditService.defaultProps = {
    classes: {},
    handleAddServiceOpen: () => { },
    toggleDrawer: () => { }
};

// prop types
AddEditService.propTypes = {
    classes: PropTypes.object,
    handleAddServiceOpen: PropTypes.func,
    toggleDrawer: PropTypes.func
};

export default withStyles(UsersStyles)(AddEditService);

import React from 'react'

// export const SearchIcon = (props) => (
//     <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ fill: "red" }}>
//         <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
//         </path>
//     </svg>
// )

export const BellIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.934" height="16.345" viewBox="0 0 12.934 16.345">
        <g id="bell_2_" data-name="bell (2)" transform="translate(0)">
            <path id="bell_2_2" data-name="bell (2)" d="M66.1,13.008a2.54,2.54,0,0,1-.772-1.051,12.16,12.16,0,0,1-.8-4.8c0-.006,0-.012,0-.018a4.638,4.638,0,0,0-2.73-4.206V1.821A1.821,1.821,0,0,0,59.976,0h-.151a1.821,1.821,0,0,0-1.818,1.821V2.927a4.638,4.638,0,0,0-2.73,4.225,12.16,12.16,0,0,1-.8,4.8,2.539,2.539,0,0,1-.772,1.051.466.466,0,0,0-.256.529.487.487,0,0,0,.48.374h3.51a2.466,2.466,0,0,0,4.931,0h3.51a.487.487,0,0,0,.48-.374A.466.466,0,0,0,66.1,13.008ZM58.966,1.821a.862.862,0,0,1,.86-.862h.151a.862.862,0,0,1,.86.862v.8a4.638,4.638,0,0,0-1.871,0v-.8ZM59.9,15.386a1.511,1.511,0,0,1-1.508-1.475h3.015A1.511,1.511,0,0,1,59.9,15.386Zm1.928-2.434H55.017a5.019,5.019,0,0,0,.251-.44,12.124,12.124,0,0,0,.967-5.36,3.666,3.666,0,1,1,7.331,0c0,.006,0,.012,0,.018a12.1,12.1,0,0,0,.967,5.341,5.023,5.023,0,0,0,.251.44Z" transform="translate(-53.434 0)" fill="#c3bfc1" />
        </g>
    </svg>
)

export const CheckSelectedIcon = (props) => (
    <svg id="check_circle-24px_1_" data-name="check_circle-24px (1)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_21" data-name="Path 21" d="M0,0H20V20H0Z" fill="none" />
        <path id="Path_22" data-name="Path 22" d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2ZM8.4,14l-4-4L5.528,8.872,8.4,11.736l6.072-6.072L15.6,6.8Z" fill="#12a8de" />
    </svg>
)

export const ClockIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.593" height="14.593" viewBox="0 0 14.593 14.593">
        <g id="clock" transform="translate(-1 -1)">
            <path id="Path_808" data-name="Path 808" d="M8.3,1a7.3,7.3,0,1,0,7.3,7.3A7.3,7.3,0,0,0,8.3,1Zm0,13.266a5.97,5.97,0,1,1,5.97-5.97A5.97,5.97,0,0,1,8.3,14.266Z" fill="#12a8de" />
            <path id="Path_809" data-name="Path 809" d="M12.327,9.369V5.663a.663.663,0,1,0-1.327,0v3.98a.663.663,0,0,0,.194.469l1.99,1.99a.663.663,0,1,0,.938-.938Z" transform="translate(-3.367 -1.347)" fill="#12a8de" />
        </g>
    </svg>
)

export const DeleteIcon = (props) => (
    <svg id="Component_19_1" data-name="Component 19 – 1" xmlns="http://www.w3.org/2000/svg" width="14" height="17.231" viewBox="0 0 14 17.231">
        <g id="trash" transform="translate(-48)">
            <path id="Path_11" data-name="Path 11" d="M60.654,2.154H57.692V1.615A1.617,1.617,0,0,0,56.077,0H53.923a1.617,1.617,0,0,0-1.615,1.615v.538H49.346A1.348,1.348,0,0,0,48,3.5V5.385a.538.538,0,0,0,.538.538h.294l.465,9.769a1.613,1.613,0,0,0,1.614,1.539h8.177A1.613,1.613,0,0,0,60.7,15.692l.465-9.769h.294A.538.538,0,0,0,62,5.385V3.5A1.348,1.348,0,0,0,60.654,2.154Zm-7.269-.538a.539.539,0,0,1,.538-.538h2.154a.539.539,0,0,1,.538.538v.538H53.385ZM49.077,3.5a.27.27,0,0,1,.269-.269H60.654a.27.27,0,0,1,.269.269V4.846H49.077ZM59.626,15.641a.538.538,0,0,1-.538.513H50.911a.538.538,0,0,1-.538-.513l-.463-9.718H60.089Z" fill="#fc626c" />
            <path id="Path_12" data-name="Path 12" d="M240.538,216.077a.538.538,0,0,0,.538-.538v-7a.538.538,0,0,0-1.077,0v7A.538.538,0,0,0,240.538,216.077Z" transform="translate(-185.538 -201)" fill="#fc626c" />
            <path id="Path_13" data-name="Path 13" d="M320.538,216.077a.538.538,0,0,0,.538-.538v-7a.538.538,0,1,0-1.077,0v7A.538.538,0,0,0,320.538,216.077Z" transform="translate(-262.846 -201)" fill="#fc626c" />
            <path id="Path_14" data-name="Path 14" d="M160.538,216.077a.538.538,0,0,0,.538-.538v-7a.538.538,0,1,0-1.077,0v7A.538.538,0,0,0,160.538,216.077Z" transform="translate(-108.231 -201)" fill="#fc626c" />
        </g>
    </svg>
)

export const DownloadIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.279" height="14.164" viewBox="0 0 13.279 14.164">
        <g id="download" transform="translate(-16)">
            <g id="Group_62" data-name="Group 62" transform="translate(19.098)">
                <g id="Group_61" data-name="Group 61">
                    <path id="Path_26" data-name="Path 26" d="M135.04,6.456a.442.442,0,0,0-.4-.259h-1.77V.443A.443.443,0,0,0,132.424,0h-1.77a.443.443,0,0,0-.443.443V6.2h-1.77a.442.442,0,0,0-.333.734l3.1,3.541a.442.442,0,0,0,.666,0l3.1-3.541A.441.441,0,0,0,135.04,6.456Z" transform="translate(-127.998)" fill="#12a8de" />
                </g>
            </g>
            <g id="Group_64" data-name="Group 64" transform="translate(16 9.738)">
                <g id="Group_63" data-name="Group 63">
                    <path id="Path_27" data-name="Path 27" d="M27.508,352v2.656H17.77V352H16v3.541a.885.885,0,0,0,.885.885H28.393a.884.884,0,0,0,.885-.885V352Z" transform="translate(-16 -352)" fill="#12a8de" />
                </g>
            </g>
        </g>
    </svg>
)

export const DragIcon = (props) => (
    <svg id="drag_indicator-24px" className={"dragIcon"} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="Path_19" data-name="Path 19" d="M0,0H24V24H0Z" fill="none" />
        <path id="Path_20" data-name="Path 20" d="M11,18a2,2,0,1,1-2-2A2.006,2.006,0,0,1,11,18ZM9,10a2,2,0,1,0,2,2A2.006,2.006,0,0,0,9,10ZM9,4a2,2,0,1,0,2,2A2.006,2.006,0,0,0,9,4Zm6,4a2,2,0,1,0-2-2A2.006,2.006,0,0,0,15,8Zm0,2a2,2,0,1,0,2,2A2.006,2.006,0,0,0,15,10Zm0,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,15,16Z" fill="#c3bfc1" />
    </svg>
)

export const EditTableIcon = (props) => (
    <svg id="edit-table" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <path id="Path_861" data-name="Path 861" d="M168.937,182.368a.634.634,0,0,1-.617-.779l.921-3.909a.625.625,0,0,1,.169-.3l8.067-8.067a1.946,1.946,0,0,1,2.689,0l1.195,1.195a1.9,1.9,0,0,1,0,2.688l-8.067,8.067a.634.634,0,0,1-.3.169l-3.909.921a.58.58,0,0,1-.146.018Zm1.5-4.222-.645,2.736,2.737-.645,7.94-7.94a.633.633,0,0,0,0-.9l-1.2-1.2a.635.635,0,0,0-.9,0Zm2.413,2.667h0Zm0,0" transform="translate(-159.919 -160.368)" fill="#c3bfc1" />
        <path id="Path_862" data-name="Path 862" d="M346.028,234.705a.63.63,0,0,1-.448-.186l-2.988-2.988a.634.634,0,1,1,.9-.9l2.988,2.988a.634.634,0,0,1-.449,1.083Zm0,0" transform="translate(-327.125 -220.341)" fill="#c3bfc1" />
        <path id="Path_863" data-name="Path 863" d="M5.781,19.465H1.9A2,2,0,0,1,0,17.379V2.086A2,2,0,0,1,1.9,0H14.156a2,2,0,0,1,1.9,2.086V6.377a.637.637,0,1,1-1.268,0V2.086a.667.667,0,0,0-.634-.7H1.9a.667.667,0,0,0-.634.7V17.379a.667.667,0,0,0,.634.7H5.781a.7.7,0,0,1,0,1.39Zm0,0" transform="translate(0)" fill="#c3bfc1" />
        <path id="Path_864" data-name="Path 864" d="M15.423,107.936H.634a.634.634,0,0,1,0-1.268H15.423a.634.634,0,0,1,0,1.268Zm0,0" transform="translate(0 -102.061)" fill="#c3bfc1" />
        <path id="Path_865" data-name="Path 865" d="M12.313,214.6H.634a.634.634,0,1,1,0-1.268h11.68a.634.634,0,1,1,0,1.268Zm0,0" transform="translate(0 -204.118)" fill="#c3bfc1" />
        <path id="Path_866" data-name="Path 866" d="M8.029,321.268H.634a.634.634,0,0,1,0-1.268H8.029a.634.634,0,0,1,0,1.268Zm0,0" transform="translate(0 -306.178)" fill="#c3bfc1" />
        <path id="Path_867" data-name="Path 867" d="M187.3,116.387a.634.634,0,0,1-.634-.634V107.3a.634.634,0,1,1,1.268,0v8.451A.634.634,0,0,1,187.3,116.387Zm0,0" transform="translate(-178.605 -101.755)" fill="#c3bfc1" />
    </svg>
)

export const TileIcon = (props) => (
    <svg id="four" xmlns="http://www.w3.org/2000/svg" width="11.772" height="11.772" viewBox="0 0 11.772 11.772" fill="#c3bfc1">
        <g id="Group_1860" data-name="Group 1860">
            <path id="Path_757" data-name="Path 757" d="M4.82,0H.247A.247.247,0,0,0,0,.247V4.82a.247.247,0,0,0,.247.247H4.82a.247.247,0,0,0,.247-.247V.247A.247.247,0,0,0,4.82,0Z" />
            <path id="Path_758" data-name="Path 758" d="M59.218,0H54.645A.247.247,0,0,0,54.4.247V4.82a.247.247,0,0,0,.247.247h4.573a.247.247,0,0,0,.247-.247V.247A.247.247,0,0,0,59.218,0Z" transform="translate(-47.692)" />
            <path id="Path_759" data-name="Path 759" d="M4.82,54.4H.247A.247.247,0,0,0,0,54.645v4.573a.247.247,0,0,0,.247.247H4.82a.247.247,0,0,0,.247-.247V54.645A.247.247,0,0,0,4.82,54.4Z" transform="translate(0 -47.692)" />
            <path id="Path_760" data-name="Path 760" d="M59.218,54.4H54.645a.247.247,0,0,0-.247.247v4.573a.247.247,0,0,0,.247.247h4.573a.247.247,0,0,0,.247-.247V54.645A.247.247,0,0,0,59.218,54.4Z" transform="translate(-47.692 -47.692)" />
        </g>
    </svg>
)

export const LogoIcon = (props) => (
    <svg id="Group_7" data-name="Group 7" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="57.961" height="26" viewBox="0 0 57.961 26">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_15" data-name="Rectangle 15" width="57.961" height="26" fill="#fff" />
            </clipPath>
        </defs>
        <g id="Group_6" data-name="Group 6" clip-path="url(#clip-path)">
            <path id="Path_39" data-name="Path 39" d="M44.908,39.443a121.486,121.486,0,0,1-26.079,5.4A141.766,141.766,0,0,1,0,45.681a159.505,159.505,0,0,0,18.445-.025,140.071,140.071,0,0,0,26.478-3.8" transform="translate(0 -23.103)" fill="#aa224d" />
            <path id="Path_40" data-name="Path 40" d="M82.509,26,89.666,0h7.125l7.016,26H98.118L92.852,5,87.847,26Z" transform="translate(-48.328 0)" fill="#fff" />
            <path id="Path_41" data-name="Path 41" d="M82.509,26,89.666,0h7.125l7.016,26H98.118L92.852,5,87.847,26Z" transform="translate(-48.328 0)" fill="#fff" stroke="#fff" stroke-width="1" />
            <path id="Path_42" data-name="Path 42" d="M108.283,27.838c8.171-2.136,13.245-4.86,13.09-7.57-.105-1.911-2.8-3.535-7.305-4.751,2.242.824,3.562,1.867,3.7,3.1.233,2.222-3.4,4.651-9.5,6.813" transform="translate(-63.416 -9.089)" fill="#aa224d" />
        </g>
    </svg>
)

export const AllianceAIcon = (props) => (
    <svg id="Group_2191" data-name="Group 2191" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="140.554" height="63.049" viewBox="0 0 140.554 63.049">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_15" data-name="Rectangle 15" width="140.554" height="63.049" fill="#878083" />
            </clipPath>
        </defs>
        <g id="Group_6" data-name="Group 6" opacity="0.31" clip-path="url(#clip-path)">
            <path id="Path_39" data-name="Path 39" d="M108.9,39.443C93.2,44.993,70.786,49.81,45.66,52.526A343.78,343.78,0,0,1,0,54.569a386.8,386.8,0,0,0,44.73-.061c24.756-1.4,47.245-4.789,64.208-9.225" transform="translate(0 0.181)" fill="#878083" />
            <path id="Path_40" data-name="Path 40" d="M82.509,63.049,99.865,0h17.278l17.015,63.049h-13.8l-12.77-50.922L95.454,63.049Z" transform="translate(0.379 0)" fill="#878083" />
            <path id="Path_41" data-name="Path 41" d="M82.509,63.049,99.865,0h17.278l17.015,63.049h-13.8l-12.77-50.922L95.454,63.049Z" transform="translate(0.379 0)" fill="#878083" />
            <path id="Path_42" data-name="Path 42" d="M108.305,45.395c19.816-5.179,32.118-11.786,31.742-18.356-.255-4.635-6.78-8.572-17.715-11.522,5.437,2,8.638,4.527,8.967,7.514.565,5.388-8.237,11.28-23.031,16.522" transform="translate(0.498 0.071)" fill="#878083" />
        </g>
    </svg>
)

export const HomeIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.986" height="16" viewBox="0 0 15.986 16">
        <g id="home_4_" data-name="home (4)" transform="translate(-0.208 0)">
            <g id="Group_2194" data-name="Group 2194" transform="translate(0.208 0)">
                <path id="Path_994" data-name="Path 994" d="M16.033,7.6,14.891,6.453,8.605.167a.571.571,0,0,0-.808,0L1.511,6.453.368,7.6a.571.571,0,0,0,.808.808l.167-.168v7.192A.571.571,0,0,0,1.915,16H14.486a.571.571,0,0,0,.571-.571V8.236l.167.167a.571.571,0,0,0,.808-.808ZM9.915,14.857H6.486V10.285H9.915Zm4,0H11.058V9.714a.571.571,0,0,0-.571-.571H5.915a.571.571,0,0,0-.571.571v5.143H2.486V7.093L8.2,1.379l5.714,5.714Z" transform="translate(-0.208 0)" fill="#aa224d" />
            </g>
        </g>
    </svg>
)

export const ListIcon = (props) => (
    <svg id="list_4_" data-name="list (4)" xmlns="http://www.w3.org/2000/svg" width="14.005" height="11.355" viewBox="0 0 14.005 11.355">
        <g id="Group_1862" data-name="Group 1862" transform="translate(0 0)">
            <g id="Group_1861" data-name="Group 1861">
                <path id="Path_761" data-name="Path 761" d="M1.136,37.333a1.136,1.136,0,1,0,1.136,1.136A1.134,1.134,0,0,0,1.136,37.333Z" transform="translate(0 -37.333)" fill="#c3bfc1" />
                <path id="Path_762" data-name="Path 762" d="M1.136,165.333a1.136,1.136,0,1,0,1.136,1.136A1.134,1.134,0,0,0,1.136,165.333Z" transform="translate(0 -160.791)" fill="#c3bfc1" />
                <path id="Path_763" data-name="Path 763" d="M1.136,293.333a1.136,1.136,0,1,0,1.136,1.135A1.134,1.134,0,0,0,1.136,293.333Z" transform="translate(0 -284.249)" fill="#c3bfc1" />
                <rect id="Rectangle_1257" data-name="Rectangle 1257" width="10.598" height="1.514" transform="translate(3.407 9.463)" fill="#c3bfc1" />
                <rect id="Rectangle_1258" data-name="Rectangle 1258" width="10.598" height="1.514" transform="translate(3.407 0.379)" fill="#c3bfc1" />
                <rect id="Rectangle_1259" data-name="Rectangle 1259" width="10.598" height="1.514" transform="translate(3.407 4.921)" fill="#c3bfc1" />
            </g>
        </g>
    </svg>
)

export const SearchIcon = (props) => (
    <svg id="loupe_1_" data-name="loupe (1)" className="searchIcon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_5" data-name="Group 5">
            <path id="Path_7" data-name="Path 7" d="M19.756,18.58l-5.687-5.687a7.932,7.932,0,1,0-1.178,1.178l5.687,5.687a.833.833,0,1,0,1.178-1.178ZM7.917,14.169a6.25,6.25,0,1,1,6.25-6.25A6.256,6.256,0,0,1,7.917,14.169Z" transform="translate(0 -0.003)" fill="#878083" />
        </g>
    </svg>
)

export const MailIcon = (props) => (
    <svg id="mail_outline-24px_1_" data-name="mail_outline-24px (1)" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <path id="Path_845" data-name="Path 845" d="M0,0H22V22H0Z" fill="none" />
        <path id="Path_846" data-name="Path 846" d="M18.2,4H3.8A1.773,1.773,0,0,0,2.009,5.75L2,16.25A1.781,1.781,0,0,0,3.8,18H18.2A1.781,1.781,0,0,0,20,16.25V5.75A1.781,1.781,0,0,0,18.2,4Zm0,12.25H3.8V7.5L11,11.875,18.2,7.5ZM11,10.125,3.8,5.75H18.2Z" fill="#aeaeae" />
    </svg>
)

export const AllianceAMaskIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="50" height="50" viewBox="0 0 50 50">
        <defs>
            <clipPath id="clip-path">
                <circle id="Ellipse_13" data-name="Ellipse 13" cx="25" cy="25" r="25" transform="translate(265 137)" fill="#ededed" />
            </clipPath>
            <clipPath id="clip-path-2">
                <rect id="Rectangle_15" data-name="Rectangle 15" width="47.446" height="21.283" fill="#878083" />
            </clipPath>
        </defs>
        <g id="Mask_Group_2" data-name="Mask Group 2" transform="translate(-265 -137)" clip-path="url(#clip-path)">
            <g id="Group_2033" data-name="Group 2033" transform="translate(253.554 151)">
                <g id="Group_6" data-name="Group 6" opacity="0.31" clip-path="url(#clip-path-2)">
                    <path id="Path_39" data-name="Path 39" d="M36.761,39.443a99.449,99.449,0,0,1-21.348,4.416A116.05,116.05,0,0,1,0,44.549a130.572,130.572,0,0,0,15.1-.021,114.661,114.661,0,0,0,21.674-3.114" transform="translate(0 -26.067)" fill="#878083" />
                    <path id="Path_40" data-name="Path 40" d="M82.509,21.283,88.368,0H94.2l5.744,21.283H95.287L90.976,4.093l-4.1,17.19Z" transform="translate(-54.528 0)" fill="#878083" />
                    <path id="Path_41" data-name="Path 41" d="M82.509,21.283,88.368,0H94.2l5.744,21.283H95.287L90.976,4.093l-4.1,17.19Z" transform="translate(-54.528 0)" fill="#878083" />
                    <path id="Path_42" data-name="Path 42" d="M108.281,25.6c6.689-1.748,10.842-3.979,10.715-6.2-.086-1.565-2.289-2.894-5.98-3.889,1.835.675,2.916,1.528,3.027,2.537.191,1.819-2.78,3.808-7.775,5.577" transform="translate(-71.552 -10.255)" fill="#878083" />
                </g>
            </g>
        </g>
    </svg>
)

export const StepperActiveIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g id="Path_871" data-name="Path 871" fill="#fff">
            <path d="M 9 16 C 5.140190124511719 16 2 12.85980987548828 2 9 C 2 5.140190124511719 5.140190124511719 2 9 2 C 12.85980987548828 2 16 5.140190124511719 16 9 C 16 12.85980987548828 12.85980987548828 16 9 16 Z" stroke="none" />
            <path d="M 9 4 C 6.242989540100098 4 4 6.242989540100098 4 9 C 4 11.75700950622559 6.242989540100098 14 9 14 C 11.75700950622559 14 14 11.75700950622559 14 9 C 14 6.242989540100098 11.75700950622559 4 9 4 M 9 0 C 13.97056007385254 0 18 4.029439926147461 18 9 C 18 13.97056007385254 13.97056007385254 18 9 18 C 4.029439926147461 18 0 13.97056007385254 0 9 C 0 4.029439926147461 4.029439926147461 0 9 0 Z" stroke="none" fill="#12a8de" />
        </g>
    </svg>
)

export const TelephoneIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.484" height="14.484" viewBox="0 0 14.484 14.484">
        <g id="phone_6_" data-name="phone (6)" transform="translate(0.25 0.25)">
            <path id="Path_995" data-name="Path 995" d="M10.051,13.984a3.9,3.9,0,0,1-1.337-.239,14.253,14.253,0,0,1-5.176-3.3A14.252,14.252,0,0,1,.239,5.27a3.868,3.868,0,0,1-.2-1.9A3.966,3.966,0,0,1,2.08.466,3.894,3.894,0,0,1,3.945,0a.437.437,0,0,1,.427.345l.686,3.2a.437.437,0,0,1-.118.4L3.768,5.119a11.5,11.5,0,0,0,5.1,5.1l1.172-1.172a.437.437,0,0,1,.4-.118l3.2.686a.437.437,0,0,1,.345.427,3.894,3.894,0,0,1-.466,1.864,3.966,3.966,0,0,1-2.9,2.039,3.887,3.887,0,0,1-.564.041ZM3.6.893A3.062,3.062,0,0,0,1.061,4.972a13.254,13.254,0,0,0,7.952,7.952,3.062,3.062,0,0,0,4.079-2.535l-2.6-.558L9.263,11.055a.437.437,0,0,1-.495.087A12.374,12.374,0,0,1,2.843,5.216a.437.437,0,0,1,.087-.495L4.154,3.5Z" transform="translate(0 0)" fill="#aa224d" stroke="#aa224d" stroke-width="0.5" />
        </g>
    </svg>
)

export const LocationIcon = (props) => (
    <svg id="place-24px" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
        <path id="Path_997" data-name="Path 997" d="M0,0H19V19H0Z" fill="none" />
        <path id="Path_998" data-name="Path 998" d="M9.5,9.5A1.446,1.446,0,0,1,8.125,8,1.446,1.446,0,0,1,9.5,6.5,1.446,1.446,0,0,1,10.875,8,1.446,1.446,0,0,1,9.5,9.5Zm4.125-1.35C13.625,5.428,11.8,3.5,9.5,3.5S5.375,5.427,5.375,8.15c0,1.755,1.341,4.08,4.125,6.855C12.284,12.23,13.625,9.905,13.625,8.15ZM9.5,2C12.388,2,15,4.415,15,8.15Q15,11.885,9.5,17,4,11.881,4,8.15C4,4.415,6.613,2,9.5,2Z" fill="#aa224d" />
    </svg>
)

export const RefundIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.569" height="16.552" viewBox="0 0 14.569 16.552">
        <g id="Refund" transform="translate(-1249.986 -406.217)">
            <g id="refund-2" data-name="refund" transform="translate(1238.378 406.217)">
                <g id="Group_60" data-name="Group 60" transform="translate(11.608)">
                    <g id="Group_59" data-name="Group 59" transform="translate(0)">
                        <path id="Path_24" data-name="Path 24" d="M23.361,3.511l-.8,1.026A5.989,5.989,0,1,1,19.221,3.29L18.1,4.469l.834.8L20.7,3.4h0l.795-.834-.833-.795h0L18.8,0l-.8.834L19.222,1.99A7.284,7.284,0,0,0,17.76,16.462a7.362,7.362,0,0,0,1.148.09A7.287,7.287,0,0,0,23.361,3.511Z" transform="translate(-11.608)" fill="#12a8de" />
                        <path id="Path_25" data-name="Path 25" d="M71.725,76.922h0v-.748a1.78,1.78,0,0,0,1.75-1.723c0-1.445-1.344-1.611-2.325-1.732-.628-.075-1.108-.162-1.108-.542,0-.529.745-.586,1.065-.586a1.446,1.446,0,0,1,1.155.509l.05.083.986-.456-.049-.1a1.946,1.946,0,0,0-1.525-1.056v-.659H70.571v.656c-1.075.159-1.713.755-1.713,1.608,0,1.391,1.263,1.533,2.185,1.636.821.1,1.2.3,1.2.638,0,.646-.895.7-1.169.7a1.5,1.5,0,0,1-1.369-.709l-.042-.1-1.071.454.043.1a2.259,2.259,0,0,0,1.933,1.318v.712Z" transform="translate(-63.727 -63.941)" fill="#12a8de" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export const ReloadIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.925" height="16.005" viewBox="0 0 13.925 16.005">
        <g id="reload" transform="translate(-31.817 0)">
            <path id="Path_224" data-name="Path 224" d="M39.545,15.961a7,7,0,0,0-.723-13.954V.123A.135.135,0,0,0,38.6.029L34.726,2.876a.119.119,0,0,0,0,.193L38.6,5.917a.136.136,0,0,0,.219-.095V3.942a5.07,5.07,0,1,1-5.087,5.879.964.964,0,0,0-1.906.291A7.01,7.01,0,0,0,39.545,15.961Z" transform="translate(0 0)" fill="#12a8de" />
        </g>
    </svg>
)

export const ScrollIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="37.012" viewBox="0 0 24 37.012">
        <path id="scroll" d="M12.017,31.1l-6.489-6.86,2.1-1.987,4.383,4.634,4.357-4.631,2.106,1.981ZM24,28.338V8.675A8.685,8.685,0,0,0,15.325,0H8.675A8.685,8.685,0,0,0,0,8.675V28.338a8.685,8.685,0,0,0,8.675,8.675h6.651A8.685,8.685,0,0,0,24,28.338ZM15.325,2.892a5.79,5.79,0,0,1,5.783,5.783V28.338a5.79,5.79,0,0,1-5.783,5.783H8.675a5.79,5.79,0,0,1-5.783-5.783V8.675A5.79,5.79,0,0,1,8.675,2.892ZM12,5.856A1.446,1.446,0,1,0,13.446,7.3,1.446,1.446,0,0,0,12,5.856Zm0,5.783a1.446,1.446,0,1,0,1.446,1.446A1.446,1.446,0,0,0,12,11.639Zm0,5.783a1.446,1.446,0,1,0,1.446,1.446A1.446,1.446,0,0,0,12,17.422Zm0,0" transform="translate(0 0)" fill="#c3bfc1" />
    </svg>
)

export const SortIcon = (props) => (
    <svg id="sort-24px_1_" data-name="sort-24px (1)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="Path_222" data-name="Path 222" d="M0,0H24V24H0Z" fill="none" />
        <path id="Path_223" data-name="Path 223" d="M3,18H9V16H3ZM3,6V8H21V6Zm0,7H15V11H3Z" fill="#878083" />
    </svg>
)

export const VpnIcon = (props) => (
    <svg id="vpn_key-24px_1_" data-name="vpn_key-24px (1)" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <path id="Path_849" data-name="Path 849" d="M0,0H22V22H0Z" fill="none" />
        <path id="Path_850" data-name="Path 850" d="M20.167,17h-5.5V13.571H12.21A6.459,6.459,0,0,1,6.417,17,6.226,6.226,0,0,1,0,11,6.226,6.226,0,0,1,6.417,5,6.469,6.469,0,0,1,12.21,8.429H22v5.143H20.167ZM16.5,15.286h1.833V11.857h1.833V10.143H10.945l-.211-.574A4.58,4.58,0,0,0,6.417,6.714,4.444,4.444,0,0,0,1.833,11a4.444,4.444,0,0,0,4.583,4.286,4.58,4.58,0,0,0,4.318-2.854l.211-.574H16.5ZM6.417,13.571A2.673,2.673,0,0,1,3.667,11a2.673,2.673,0,0,1,2.75-2.571A2.673,2.673,0,0,1,9.167,11,2.673,2.673,0,0,1,6.417,13.571Zm0-3.429A.859.859,0,1,0,7.333,11,.891.891,0,0,0,6.417,10.143Z" fill="#aeaeae" />
    </svg>
)

export const MoreIcon = (props) => (
<svg fill="#C3BFC1" width="22" height="22" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>
)

export const ChevronLeftIcon = (props) => (
<svg class="chevLeft" fill="#D2D3D4" height="24" width="24" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg>
)

export const ChevronTopIcon = (props) => (
<svg class="chevTop" fill="#D2D3D4" height="24" width="24" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>
)

export const CloseIcon = (props) => (
<svg class="closeIcon" width="24" height="24" fill="#878083" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
)

export const ChipCloseIcon = (props) => (
    <svg class="chipclose" width="14" height="14" fill="#878083" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
)

export const CopIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="24" height="24" fill="#878083"><g id="Policeman"><path d="M48.461,40.6,37,36.307V32.786A11,11,0,0,0,43,23V20.281l1.243-.311A1,1,0,0,0,45,19V16a1,1,0,0,0-1-1H43V13.265L46.868,6.5A1,1,0,0,0,46.6,5.2l-4-3A1,1,0,0,0,42,2H22a1,1,0,0,0-.6.2l-4,3a1,1,0,0,0-.268,1.3L21,13.265V15H20a1,1,0,0,0-1,1v3a1,1,0,0,0,.757.97L21,20.281V23a10.992,10.992,0,0,0,6,9.794v3.513L15.538,40.6A7.038,7.038,0,0,0,11,47.16V61a1,1,0,0,0,1,1H52a1,1,0,0,0,1-1V47.16A7.037,7.037,0,0,0,48.461,40.6Zm-9.907-1.578-2.581,4.131L33.46,39.388l2.6-1.3Zm-4.6,14.6L32,55.586l-1.958-1.958L30.905,45H33.1ZM32,40.8,33.465,43h-2.93ZM22.333,4H41.667l3.027,2.271L41.42,12H22.58L19.306,6.271ZM23,14H41v1H23Zm-2,3H43v1.219L39.877,19H24.123L21,18.219Zm2,6V20.781l.757.189A1,1,0,0,0,24,21H40a1,1,0,0,0,.243-.03L41,20.781V23a9,9,0,0,1-18,0Zm9,11a10.966,10.966,0,0,0,3-.426v2.808l-3,1.5-3-1.5v-2.8A11.04,11.04,0,0,0,32,34Zm-4.057,4.09,2.6,1.3-2.513,3.769-2.581-4.131ZM51,60H45V51H43v9H21V51H19v9H13V47.16a5.025,5.025,0,0,1,3.241-4.683l7.294-2.735,3.617,5.788a1,1,0,0,0,1.68.025l.009-.013L28.005,53.9a1,1,0,0,0,.288.807l3,3a1,1,0,0,0,1.414,0l3-3a1,1,0,0,0,.288-.807l-.836-8.358.009.013A1,1,0,0,0,36,46h.015a1,1,0,0,0,.833-.47l3.617-5.788,7.293,2.734A5.027,5.027,0,0,1,51,47.16Z"/><path d="M37,25H35a3,3,0,0,1-6,0H27a5,5,0,0,0,10,0Z"/><path d="M29.553,9.9l2,1a1,1,0,0,0,.894,0l2-1A1,1,0,0,0,35,9V6a1,1,0,0,0-1-1H30a1,1,0,0,0-1,1V9A1,1,0,0,0,29.553,9.9ZM31,7h2V8.382l-1,.5-1-.5Z"/></g></svg>
)

// export const SortIcon = (props) => (
// <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z"></path></svg>
// )

export const Information = ({color}) =>
(
    <svg className="infoIcon" xmlns="http://www.w3.org/2000/svg" width="15.097" height="15.097" viewBox="0 0 15.097 15.097">
        <path id="information_1_" data-name="information (1)" d="M7.549,0A7.549,7.549,0,1,0,15.1,7.549,7.557,7.557,0,0,0,7.549,0ZM8.04,12.028c-.359.06-1.072.209-1.435.239a.883.883,0,0,1-.773-.4A.943.943,0,0,1,5.718,11L7.145,7.077H5.662a1.773,1.773,0,0,1,1.4-1.649A5.463,5.463,0,0,1,8.492,5.19a1.138,1.138,0,0,1,.773.4.943.943,0,0,1,.114.864L7.952,10.38H9.435a1.67,1.67,0,0,1-1.4,1.648Zm.453-7.31a.944.944,0,1,1,.944-.944.944.944,0,0,1-.944.944Z" fill={color ? color : "#54c0fd"} />
    </svg>
)
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { IconButton, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import ReactPageScroller from 'react-page-scroller';

//Styles
import ServicesStyles from "../ServicesStyles"
import { ScrollIcon } from '../../../assets/svg'

// Import Components
import { Typography } from "../../../components";
import Generic from './Documents/Generic';
import Layout from './Documents/Layout';
import Content from './Documents/Content';
import Choices from './Documents/Choices';
import ReactPageScroller from "../../../components/react-page-scroller"

// Import Constants
import { serviceConstant } from "../../../redux/constants";
import { user_page_constants } from "../../../config/constants";

// Import Actions
import { updateReducerAction } from "../../../redux/actions";

const AddDocuments = (props) => {

    //define dispatch
    const dispatch = useDispatch();

    const { classes, handleRichTextChange } = props;

    let { createFromScratch, createFromScratchErrorFlags: errorFlags } = useSelector((state) => state.service);

    const [page, setPage] = useState(createFromScratch.page)
    const [data, setData] = useState(createFromScratch.documentData[page])
    let defaultValues = {}

    // Set Form Values
    const { register, handleSubmit, getValues, errors, control, watch } = useForm({
        mode: 'all',
        reValidateMode: 'all',
        defaultValues,
        shouldUnregister: true,
    });

    // Submit Form
    const onSubmit = (data) => {
        console.log(data)
    }

    //move next page
    const moveNextPage = () => {
        createFromScratch.page = page + 1
        setPage(page + 1)
    }

    //handle page change
    const handlePageChange = (number) => {
        createFromScratch.page = number
        setPage(number)
    }

    //handle tab change
    const handleTabChange = (event, newValue) => {
        let updateFlag = true
        errorFlags.errorTab0 = false
        errorFlags.errorTab1 = false
        errorFlags.errorTab3 = false
        if (createFromScratch.tabValue === 0) {
            createFromScratch.documentData[page]["title"] = createFromScratch.documentData[page]["title"] ? createFromScratch.documentData[page]["title"].trim() : ""
            if (!(createFromScratch.documentData[page]["title"].trim())) {
                updateFlag = false
                errorFlags.documentData[page].title = true
            }
            else {
                errorFlags.documentData[page].title = false
            }
        } else if (createFromScratch.tabValue === 1) {
            if (!createFromScratch.documentData[page]["richText"]) {
                updateFlag = false
                errorFlags.documentData[page].richText = true
            }
            else {
                let str = createFromScratch.documentData[page]["richText"]
                str = str.replace(/(<([^>]+)>)/ig, "")
                str = str.trim()
                if (str) {
                    errorFlags.documentData[page].richText = false
                } else {
                    updateFlag = false
                    errorFlags.documentData[page].richText = true
                    errorFlags.errorTab1 = true
                }
            }
        } else if (createFromScratch.tabValue === 3 && (createFromScratch.documentData[page]).hasOwnProperty("choices") && createFromScratch.documentData[page].choices.length > 0) {
            createFromScratch.documentData[page].choices.map((items, indx) => {
                items.title = items.title ? items.title.trim() : ""
                items.position = items.position ? items.position.trim() : ""
                if (!(items.title.trim())) {
                    errorFlags.documentData[page].choices[indx].title = true
                    updateFlag = false
                } else {
                    errorFlags.documentData[page].choices[indx].title = false
                }
                if (!(items.position.trim())) {
                    errorFlags.documentData[page].choices[indx].position = true
                    updateFlag = false
                } else {
                    errorFlags.documentData[page].choices[indx].position = false
                }
                createFromScratch.documentData[page].choices[indx].variation.map((item1, indx1) => {
                    item1.text = item1.text ? item1.text.trim() : ""
                    if (!(item1.text.trim())) {
                        errorFlags.documentData[page].choices[indx].variation[indx1].text = true
                        updateFlag = false
                    } else {
                        errorFlags.documentData[page].choices[indx].variation[indx1].text = false
                    }
                })
            })
        }

        dispatch(updateReducerAction.update(serviceConstant.UPDATE_ERROR_FLAG_DOCUMENT, errorFlags, () => { }));
        if (updateFlag) {
            createFromScratch.tabValue = newValue
            //createFromScratch.documentData[page].richText = richText
            dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
        }
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const addNewDocument = () => {
        let updateFlag = true
        errorFlags.errorTab0 = false
        errorFlags.errorTab1 = false
        errorFlags.errorTab3 = false
        createFromScratch.documentData[page]["title"] = createFromScratch.documentData[page]["title"] ? createFromScratch.documentData[page]["title"].trim() : ""
        if (!(createFromScratch.documentData[page]["title"].trim())) {
            updateFlag = false
            errorFlags.documentData[page].title = true
            errorFlags.errorTab0 = true
        } else {
            errorFlags.documentData[page].title = false
        }
        if (!createFromScratch.documentData[page]["richText"]) {
            updateFlag = false
            errorFlags.documentData[page].richText = true
            errorFlags.errorTab1 = true
        } else {
            let str = createFromScratch.documentData[page]["richText"]
            str = str.replace(/(<([^>]+)>)/ig, "")
            str = str.trim()
            if (str) {
                errorFlags.documentData[page].richText = false
            } else {
                updateFlag = false
                errorFlags.documentData[page].richText = true
                errorFlags.errorTab1 = true
            }

        }

        if ((createFromScratch.documentData[page]).hasOwnProperty("choices") && createFromScratch.documentData[page].choices.length > 0) {
            createFromScratch.documentData[page].choices.map((items, indx) => {
                items.title = items.title ? items.title.trim() : ""
                items.position = items.position ? items.position.trim() : ""
                if (!(items.title.trim())) {
                    errorFlags.documentData[page].choices[indx].title = true
                    errorFlags.errorTab3 = true
                    updateFlag = false
                } else {
                    errorFlags.documentData[page].choices[indx].title = false
                }
                if (!(items.position.trim())) {
                    errorFlags.documentData[page].choices[indx].position = true
                    errorFlags.errorTab3 = true
                    updateFlag = false
                } else {
                    errorFlags.documentData[page].choices[indx].position = false
                }
                createFromScratch.documentData[page].choices[indx].variation.map((item1, indx1) => {
                    item1.text = item1.text ? item1.text.trim() : ""
                    if (!(item1.text.trim())) {
                        errorFlags.documentData[page].choices[indx].variation[indx1].text = true
                        errorFlags.errorTab3 = true
                        updateFlag = false
                    } else {
                        errorFlags.documentData[page].choices[indx].variation[indx1].text = false
                    }
                })
            })
        }

        errorFlags.documentData[Object.keys(createFromScratch.documentData).length] = {}
        dispatch(updateReducerAction.update(serviceConstant.UPDATE_ERROR_FLAG_DOCUMENT, errorFlags, () => { }));
        if (updateFlag) {
            let cfsData = JSON.parse(JSON.stringify(createFromScratch))
            cfsData.page = cfsData.totalPage.length
            setPage(cfsData.totalPage.length)
            cfsData.tabValue = 0
            cfsData.totalPage.push(cfsData.totalPage.length)
            cfsData.documentData[Object.keys(cfsData.documentData).length] = {}
            errorFlags.documentData[Object.keys(cfsData.documentData).length] = {}
            dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, cfsData, () => { }));
        }
    }

    //handle Delete Document
    const handleDeleteDocument = () => {
        let newPage = 0
        if (page !== 0) {
            newPage = page - 1
        }
                
        let cfsData = JSON.parse(JSON.stringify(createFromScratch))
        delete cfsData.documentData[page]
        cfsData.totalPage.pop()
        let cfsData1 = JSON.parse(JSON.stringify(cfsData))
        cfsData.documentData = {}
        Object.keys(cfsData1.documentData).map((key, index) => {
            cfsData.documentData[index] = cfsData1.documentData[key]
        })
       
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, {...cfsData, page:newPage}, () => {
            console.log("suceess")
            createFromScratch.page = newPage
            setPage(newPage)
         }));
    }

    return (
        <Grid item xs={12} className={classes.contentcontainer} >
            <Typography variant="h4" className="mt-3 mb-3" align="center">{user_page_constants.ADD_DOCUMENTS}</Typography>
            <Paper elevation={3} className={classes.cardSectionDialog} style={{ overflowY: "auto" }} >
                <form onSubmit={handleSubmit(onSubmit)} noValidate className={"pageScrollVerticle"}>
                    <React.Fragment>

                        <AppBar position="static" color="default" className={classes.appBarCls}>
                            <Tabs
                                id={`${createFromScratch.tabValue}`}
                                value={createFromScratch.tabValue}
                                onChange={(e, n) => { handleTabChange(e, n) }}
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="full width tabs example"
                                classes="ncvjkxn"
                                className={"cursorPointer relative"}
                            >
                                <Tab label={<Typography variant="h6" color={errorFlags.errorTab0 ? "error" : "TextSecondary"}>{"Generic"}</Typography>}  {...a11yProps(0)} />
                                <Tab label={<Typography variant="h6" color={errorFlags.errorTab1 ? "error" : "TextSecondary"}>{"Content"}</Typography>} {...a11yProps(1)} />
                                <Tab label={<Typography variant="h6" color="TextSecondary" >{"Layout"}</Typography>} {...a11yProps(2)} />
                                <Tab label={<Typography variant="h6" color={errorFlags.errorTab3 ? "error" : "TextSecondary"}>{"Choices"}</Typography>} {...a11yProps(3)} />
                                {/* {Object.keys(createFromScratch.documentData).length > 1 && <IconButton className={classes.documentDelete} onClick={() => handleDeleteDocument()}><DeleteIcon /></IconButton>} */}
                            </Tabs>
                        </AppBar>
                        {createFromScratch.totalPage.length === 1 &&
                            <Grid item xs={12}>
                                {createFromScratch.tabValue === 0 && (
                                    <Generic
                                        register={register}
                                        errors={errors}
                                        control={control}
                                        watch={watch}
                                        createFromScratch={createFromScratch}
                                        page={page}
                                        errorFlags={errorFlags}
                                    />
                                )}
                                {createFromScratch.tabValue === 1 && (
                                    <Content
                                        register={register}
                                        errors={errors}
                                        control={control}
                                        watch={watch}
                                        handleRichTextChange={handleRichTextChange}
                                        createFromScratch={createFromScratch}
                                        page={page}
                                        errorFlags={errorFlags}
                                    />
                                )}
                                {createFromScratch.tabValue === 2 && (
                                    <Layout
                                        register={register}
                                        errors={errors}
                                        control={control}
                                        watch={watch}
                                        createFromScratch={createFromScratch}
                                        page={page}
                                        errorFlags={errorFlags}
                                    />
                                )}
                                {createFromScratch.tabValue === 3 && (
                                    <Choices
                                        register={register}
                                        errors={errors}
                                        control={control}
                                        watch={watch}
                                        createFromScratch={createFromScratch}
                                        page={page}
                                        errorFlags={errorFlags}
                                    />
                                )}
                            </Grid>
                        }
                        {createFromScratch.totalPage.length > 1 &&
                            <ReactPageScroller
                                pageOnChange={(e) => handlePageChange(e)}
                                containerWidth="100%"
                                containerHeight={350}
                                customPageNumber={page}

                            >
                                {createFromScratch.totalPage.map((currentPage) => (
                                    <Grid item xs={12} id={currentPage}>
                                        {createFromScratch.tabValue === 0 && (
                                            <Generic
                                                register={register}
                                                errors={errors}
                                                control={control}
                                                watch={watch}
                                                createFromScratch={createFromScratch}
                                                page={page}
                                                errorFlags={errorFlags}
                                            />
                                        )}
                                        {createFromScratch.tabValue === 1 && (
                                            <Content
                                                register={register}
                                                errors={errors}
                                                control={control}
                                                watch={watch}
                                                handleRichTextChange={handleRichTextChange}
                                                createFromScratch={createFromScratch}
                                                page={page}
                                                errorFlags={errorFlags}
                                            />
                                        )}
                                        {createFromScratch.tabValue === 2 && (
                                            <Layout
                                                register={register}
                                                errors={errors}
                                                control={control}
                                                watch={watch}
                                                createFromScratch={createFromScratch}
                                                page={page}
                                                errorFlags={errorFlags}
                                            />
                                        )}
                                        {createFromScratch.tabValue === 3 && (
                                            <Choices
                                                register={register}
                                                errors={errors}
                                                control={control}
                                                watch={watch}
                                                createFromScratch={createFromScratch}
                                                page={page}
                                                errorFlags={errorFlags}
                                            />
                                        )}
                                    </Grid>
                                ))}
                            </ReactPageScroller>
                        }
                    </React.Fragment>

                </form>
            </Paper>
            { (page + 1) === createFromScratch.totalPage.length &&
                <Grid item xs={12} align="left" className="mt-2">
                    <Typography color="primary" variant={"body2"} title={user_page_constants.ADD_ANOTHER_DOCUMENT} className={classes.grow, "cursorPointer inlineBlock link"} onClick={() => addNewDocument()}></Typography>
                </Grid>
            }
            { (page + 1) !== createFromScratch.totalPage.length &&
                <React.Fragment>
                    <IconButton onClick={()=>{moveNextPage()}}>
                        <ScrollIcon />
                    </IconButton>
                </React.Fragment>
            }
        </Grid>
    );
}

// default props
AddDocuments.defaultProps = {
    classes: {}
};

// prop types
AddDocuments.propTypes = {
    classes: PropTypes.object
};

export default withStyles(ServicesStyles)(AddDocuments);

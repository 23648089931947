import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Chip,IconButton, Drawer, Grid, withStyles } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';


//Styles and Images
import ApplicationStyles from "../ApplicationStyles"
import { CloseIcon } from '../../../assets/svg'

//import helpers
import { isEmptyHTML, IsEmail } from '../../../utils'

// Import Actions
import { applicationAction, alertAction } from "../../../redux/actions";

// Import Components
import { Button, Typography, AutoComplete, TextField, RichTextEdit, ToolTip } from "../../../components";
import { Fragment } from 'react';

const SendInvite = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, invitePopup, setInvitePopup, appId } = props;

    //get reducer data
    const { common: { is_common, email, fee: fees, managerList }, common, managerName } = useSelector(s => (s.application))

    useEffect(() => {
        dispatch(applicationAction.getClientCommon({ 'id': appId }, () => {
            dispatch(applicationAction.getManagerName());
        }))
    }, [dispatch])

    //text select manager for common =0
    const [manager, setManager] = useState('')
    const [fee, setFee] = useState(fees ? fees : '')
    const [emailId, setEmailID] = useState([])
    const [subject, setSubject] = useState(email[0] && email[0]['subject'] ? email[0]['subject'] : '')
    //rich text
    const [message, setMessage] = useState(email[0] && email[0]['content'] ? email[0]['content'] : '')
    const [messageC, setMessageC] = useState(email[0] && email[0]['content'] ? email[0]['content'] : '')
    //manager dropdown for common =0
    const [managerDDN, setManagerDDN] = useState('')
    //validation message
    const [showValidation, setShowValidation] = useState(false)

    const manSelList = managerList ? managerList : [];

    //change values on reducer update
    useEffect(() => {
        setFee(fees ? fees : '');
        setSubject(email[0] && email[0]['subject'] ? email[0]['subject'] : '');
        setMessage(email[0] && email[0]['content'] ? email[0]['content'] : '')
        setMessageC(email[0] && email[0]['content'] ? email[0]['content'] : '')
    }, [common])

    //open close drawer
    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setInvitePopup(open)
    };

    //set company selected Dropown
    const getSelectedValue = (arr, val) => {
        const valuee = arr.filter(i => (i.value === val))
        return (valuee[0] ? valuee[0] : { value: "", label: "" })
    }

    // change manager Drop DN
    const handleChangeManager = (value) => {
        setManagerDDN(value && value.value ? value.value : value);
    };

    // change manager Drop DN
    const handleChangeManager1 = (value) => {
        setManager(value && value.value ? value.value : value);
    };

    //filter options data
    const onFilterChange = (arr, val) => {
        const { inputValue: value } = val
        if (value) {
            const eValue = value.toLowerCase()
            let tempdata = managerName.filter(f => ((f["label"] && f["label"].toLowerCase().indexOf(eValue) > -1) || (f['email'] && f['email'].toLowerCase().indexOf(eValue) > -1) || (f['phone'] && f['phone'].toLowerCase().indexOf(eValue) > -1)))

            if (manSelList.length > 0) {
                tempdata = tempdata.filter(m => (manSelList.indexOf(m["label"]) > -1))
            }
            return tempdata
        } else {
            return manSelList && manSelList.length ? managerName.filter(ml => (manSelList.indexOf(ml["label"]) > -1)) : managerName
        }
    }

    //send invitaion
    const sendInvitation = () => {
        const notiArray=getEmailChips(emailId);
        const emailFail=notiArray.filter(val => val.isValid === false)

        if ( !fee || (!emailId || !emailId.length) || !subject || isEmptyHTML(messageC) === true) {
            setShowValidation(true)
            dispatch(alertAction.error("All fields  are required"));
        }else if(emailFail.length>0){
            setShowValidation(true)
            dispatch(alertAction.error("Please enter a valid emails"));
        } else {
            setShowValidation(false)
            const data = {
                appId: appId,
                manager: is_common ? manager : managerDDN,
                fee: fee,
                feeUpdated: Number(fee)=== Number(fees) ? false : true ,
                email: emailId ? emailId : [],
                mamagerData: is_common ? managerName.filter(m => (m['value'] === manager)) : managerName.filter(m => (m['value'] === managerDDN)),
                subject: subject,
                content: messageC
            }
            dispatch(applicationAction.sendInvitation(data, () => {
                setInvitePopup(false)
            }));
        }
    }

    //add emailIDs
    const handleAddChip = (chip) => {
        let tempArr = [...emailId, chip]
        tempArr = tempArr.filter(i => (i != ''))
        setEmailID(tempArr)
    }

    const getEmailChips = (emailArr) => {
        let resultEmails = [];
        emailArr.map((i) => {
            if (i) {
                if (IsEmail(i.trim())) {
                    resultEmails.push({ email: i.trim().toLowerCase(), isValid: true });
                } else {
                    resultEmails.push({ email: i.trim().toLowerCase(), isValid: false });
                }
            }
        });
        return resultEmails;
    }

    //delete email Ids
    const handleDeleteChip = (chip, index) => {
        let tempArr = [...emailId]
        tempArr = tempArr.filter(i => (i != ''))
        tempArr.splice(index, 1)
        setEmailID(tempArr)
    }

    return (
        <React.Fragment>
            <Drawer anchor='right' role="presentation" variant="persistent" open={invitePopup} onClose={toggleDrawer(false)} className={classes.allianceDrawer600}>
                <Grid container alignItems="center" justify="space-between" className={"pl-2 pt-1 pr-2 pb-2"}>
                    <Typography variant="h4" title={`SEND INVITATION`}></Typography>
                    <IconButton onClick={toggleDrawer(false)}><CloseIcon /></IconButton>
                </Grid>
                <Grid container className="pl-2 pr-2 pb-2">
                    <Grid container className={classes.inviteContainer} justify="space-between" spacing={3}>
                        <Grid item sm={12} align="left">
                            <Typography variant="body1">{"Manager"}</Typography>
                            {is_common ?
                                <Fragment>
                                    <ToolTip disableHoverListener={true} title={`Type name or phone or email`} placement={'top'}>
                                        <AutoComplete
                                            name={'mamager1-sel'}
                                            // showValidate={showValidation && !manager ? true : false}
                                            // validationMsg={"This Field is Required"}
                                            suggestions={[]}
                                            disabled={false}
                                            handleAutoComplete={(newValue, name) => { handleChangeManager1(newValue) }}
                                            filterOptions={(arr, eValue) => { return onFilterChange(arr, eValue) }}
                                            value={getSelectedValue(managerName, manager)}
                                        />
                                    </ToolTip>
                                </Fragment>
                                :
                                <Fragment>
                                    <AutoComplete
                                        name={'mamager-sel'}
                                        // showValidate={showValidation && !managerDDN ? true : false}
                                        // validationMsg={"This Field is Required"}
                                        suggestions={manSelList && manSelList.length ? managerName.filter(ml => (manSelList.indexOf(ml["label"]) > -1)) : managerName}
                                        disabled={false}
                                        handleAutoComplete={(newValue, name) => { handleChangeManager(newValue) }}
                                        value={getSelectedValue(managerName, managerDDN)}
                                    />
                                </Fragment>
                            }

                        </Grid>
                        <Grid item sm={12} align="left">
                            <Typography variant="body1">{"Fee"}</Typography>
                            <TextField
                                id={`fee`}
                                name={`fee`}
                                margin="none"
                                placeholder={'Fee'}
                                value={fee ? fee : ''}
                                onChange={(e) => { setFee(e.target.value) }}
                                error={showValidation && !fee ? true : false}
                                helperText={showValidation && !fee ? "Fee is required" : ""}
                            />
                        </Grid>
                        <Grid item sm={12} align="left">
                            <Typography variant="body1">{"Email"}</Typography>
                            {/* <TextField
                                id={`email`}
                                name={`email`}
                                margin="none"
                                placeholder={'Email'}
                                value={emailId ? emailId : ''}
                                onChange={(e) => { setEmailID(e.target.value) }}
                                error={showValidation && (!emailId || !IsEmail(emailId)) ? true : false}
                                helperText={showValidation ? !emailId ? "Email is required" : !IsEmail(emailId) ? "Invalid Email" : "" : ""}
                            /> */}
                            <ChipInput
                                className={`${classes.chipInput} ${classes.frequencyWidth} chipboderRadius10`}
                                variant="outlined"
                                color="primary"
                                value={getEmailChips(emailId) || []}
                                onBlur={(chip) => handleAddChip(chip.target.value)}
                                onAdd={(chip) => handleAddChip(chip)}
                                onDelete={(chip, index) => handleDeleteChip(chip, index)}
                                dataSource={getEmailChips(emailId) || []}
                                chipRenderer={({ value, handleDelete, className }, key) => {
                                    return (
                                        <Chip
                                            key={key}
                                            label={value.email}
                                            onClick={(e) => e.stopPropagation()}
                                            onDelete={handleDelete}
                                            className={value.isValid ? "" : `${classes.errorChipReport}`}
                                        />
                                    )
                                }}
                            />

                        </Grid>
                        <Grid item sm={12} align="left">
                            <Typography variant="body1">{"Subject"}</Typography>
                            <TextField
                                id={`subject`}
                                name={`subject`}
                                margin="none"
                                placeholder={'subject'}
                                value={subject ? subject : ''}
                                onChange={(e) => { setSubject(e.target.value) }}
                                error={showValidation && !subject ? true : false}
                                helperText={showValidation && !subject ? "Subject is required" : ""}
                            />

                        </Grid>
                        <Grid item sm={12} align="left">
                            <Typography variant="body1">{"Message"}</Typography>
                            <RichTextEdit
                                value={message ? message : ""}
                                onChange={(v) => { setMessageC(v) }}
                            />
                            {(showValidation && isEmptyHTML(messageC) === true) && <p>This field is required</p>}
                        </Grid>
                    </Grid>
                    <Grid container className="pt-3">
                        <Button color="primary" size="small" onClick={() => { sendInvitation() }} title={"SEND"} className="mr-1"></Button>
                        <Button color="secondary" size="small" onClick={toggleDrawer(false)} title={"Cancel"}></Button>
                    </Grid>
                </Grid>
            </Drawer>
        </React.Fragment>
    )
}
// default props
SendInvite.defaultProps = {
    classes: {},
    invitePopup: false,
    setInvitePopup: () => { },
    appId: null
};

// prop types
SendInvite.propTypes = {
    classes: PropTypes.object,
    invitePopup: PropTypes.bool,
    appId: PropTypes.number,
    setInvitePopup: PropTypes.func
};

export default withStyles(ApplicationStyles)(SendInvite);

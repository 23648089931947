import React from "react";
import PropTypes from "prop-types";
import { Grid, withStyles } from '@material-ui/core';
import Lottie from 'react-lottie'

//images and Styles
import TenantApplicationStyles from "./TenantApplicationStyles"

// Import Components
import { Typography } from "../../components";

//Images
import animationData from '../../assets/images/mailsentAnimation.json'

const SubmitSuccess = (props) => {

  // location
  const { location: { search } } = props

  // check for duplicate submission
  const isDuplicate = search && search.includes("duplicate") ? true : false

  const animateProps = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Grid container alignItems={"center"} alignContent="baseline" justify="center" className={"h-100 "} style={{ backgroundColor: "white" }}>
      <Grid item xs={12} align="center">
        <Lottie options={animateProps} height={280} width={280} />
        <Typography variant="h4" align="center" className={"pb15 pt5 animate__animated animate__fadeInUp animate__delay-0.8s"}>{"Completed"}</Typography>
        <Typography variant="h6" align="center" className={"pb-1 animate__animated animate__fadeInUp animate__delay-0.8s"}>{isDuplicate ? "Your application is already submitted" :"Your information has been successfully submitted"}</Typography>
        <Typography variant="h6" align="center" className={"animate__animated animate__fadeInUp animate__delay-0.8s"}>{"A confirmation email has been sent to the address provided"}</Typography>
      </Grid>
    </Grid>
  );
}

// default props
SubmitSuccess.defaultProps = {
  classes: {}
};

// prop types
SubmitSuccess.propTypes = {
  classes: PropTypes.object
};

export default withStyles(TenantApplicationStyles)(SubmitSuccess);
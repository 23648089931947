import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Tooltip, withStyles } from '@material-ui/core';
import moment from "moment";
//Styles
import DirectoryStyles from "./DirectoriesStyles"

// Import Components
import { Button, TextField, Typography, Select } from "../../components";

// Import Actions
import { directoryAction, updateReducerAction } from "../../redux/actions";
import { directoryConstant } from "../../redux/constants";

const DirectoryFrame = (props) => {
  //define dispatch
  const dispatch = useDispatch();

  const { classes } = props;

  //get reducer data
  let { directoryData: { data, page } } = useSelector((state) => state.directory);

  //hadle search to get data
  const handleSearch = () => {
    dispatch(directoryAction.getDirectoryFrame(page));
  }

  const handleChange = (e) => {
    let updatePage = page;
    updatePage[e["name"]] = e["value"]
    dispatch(updateReducerAction.update(directoryConstant.UPDATE_FRAME_STATE, updatePage));

  }

  const handleClear = () => {
    let updatePage = {
      ...page,
      skip: 0,
      first_name: "",
      last_name: "",
      hologram: "",
      card_id: "",
    }
    dispatch(updateReducerAction.update(directoryConstant.UPDATE_FRAME_STATE, updatePage,()=>{
      dispatch(updateReducerAction.update(directoryConstant.UPDATE_FRAME_DATA, []))
    }));
  }

  const handleReset = () => {
    let updatePage = {
      ...page,
      skip: 0,
      sort: "",
      sortField: "",
    }
    dispatch(updateReducerAction.update(directoryConstant.UPDATE_FRAME_STATE, updatePage, () => {
      dispatch(directoryAction.getDirectoryFrame(updatePage));
    }));
  }

  const nextClick = ()=>{
    dispatch(directoryAction.getDirectoryFrame({...page,skip:page.skip+1}));
  }

  const PrevClick = ()=>{
    dispatch(directoryAction.getDirectoryFrame({...page,skip:page.skip-1}));
  }

  return (
    <React.Fragment >
      <Grid item xs={12} className={classes.DirectoryListDiv}>
        <Grid container spacing={3} className={classes.DirectoryListContainer}>

          <Grid item xs={12} >
            <Typography variant="h6" className={classes.groupTitle}>{"Find a listing"}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant={"body1"} className={"textFieldLabel"}>{"First Name"}</Typography>
            <TextField
              id={"first_name"}
              margin="none"
              name={"first_name"}
              value={page && page["first_name"] ? page["first_name"] : ""}
              onChange={(e) => { handleChange({ name: "first_name", value: e.target.value }) }}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant={"body1"} className={"textFieldLabel"}>{"Last Name"}</Typography>
            <TextField
              id={"last_name"}
              margin="none"
              name={"last_name"}
              value={page && page["last_name"] ? page["last_name"] : ""}
              onChange={(e) => { handleChange({ name: "last_name", value: e.target.value }) }}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant={"body1"} className={"textFieldLabel"}>{"Hologram"}</Typography>
            <TextField
              id={"hologram"}
              margin="none"
              name={"hologram"}
              value={page && page["hologram"] ? page["hologram"] : ""}
              onChange={(e) => { handleChange({ name: "hologram", value: e.target.value }) }}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant={"body1"} className={"textFieldLabel"}>{"Card ID"}</Typography>
            <TextField
              id={"card_id"}
              margin="none"
              name={"card_id"}
              value={page && page["card_id"] ? page["card_id"] : ""}
              onChange={(e) => { handleChange({ name: "card_id", value: e.target.value }) }}
            />
          </Grid>

          <Grid item xs={6}>
            <Button color="primary" size="small" title={"Clear"} onClick={() => { handleClear() }} className="mr-1" type="submit" ></Button>
            <Button color="primary" size="small" title={"Search"} onClick={() => { handleSearch() }}></Button>
          </Grid>

          {data && data.length>0 &&
            <React.Fragment>
              <Grid item xs={12} className={`pt-2`}>
                <Typography variant="h6" className={classes.groupTitle}>{"Search Results"}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant={"body1"} className={"textFieldLabel"}>{"Sort By"}</Typography>
                <Select
                  options={[{ label: "", value: "" }, { label: "First Name", value: "first_name" }, { label: "Last Name", value: "last_name" }, { label: "Hologram Number", value: "hologram" }, { label: "Company", value: "company" }, { label: "Card ID", value: "card_id" }, { label: "User", value: "id" }]}
                  handleChange={(e) => handleChange({ name: "sortField", value: e.value })}
                  value={page && page["sortField"] ? page["sortField"] : ""}
                >
                </Select>
              </Grid>

              <Grid item xs={6}>
                <Typography variant={"body1"} className={"textFieldLabel"}>{"Sort Order"}</Typography>
                <Select
                  options={[{ label: "", value: "" }, { label: "Ascending", value: "asc" }, { label: "Descending", value: "desc" }]}
                  handleChange={(e) => handleChange({ name: "sort", value: e.value })}
                  value={page && page["sort"] ? page["sort"] : ""}
                >
                </Select>
              </Grid>

              <Grid item xs={6}>
                <Button color="primary" size="small" title={"Reset"} onClick={() => { handleReset() }} className="mr-1" type="submit" ></Button>
                <Button color="primary" size="small" title={"Sort"} onClick={() => { handleSearch() }}></Button>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    {data && data.map((itm, indx) => (
                      <React.Fragment>
                        <Grid container alignItems="center" className={classes.searchListContainer}>
                          <Grid item xs={9}>

                            <Grid container>
                              <Grid item xs={3}>
                                <Typography variant={"body1"} className={"resultLabel"}>{"First Name:"}</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant={"body1"} className={"resultValue"}>{itm["first_name"] ? itm["first_name"] : ""}</Typography>
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={3}>
                                <Typography variant={"body1"} className={"resultLabel"}>{"Last Name:"}</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant={"body1"} className={"resultValue"}>{itm["last_name"] ? itm["last_name"] : ""}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container>

                              <Grid item xs={3}>
                                <Typography variant={"body1"} className={"resultLabel"}>{"Hologram Number:"}</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant={"body1"} className={"resultValue"}>{itm["hologram"] ? itm["hologram"] : ""}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container>

                              <Grid item xs={3}>
                                <Typography variant={"body1"} className={"resultLabel"}>{"Company:"}</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant={"body1"} className={"resultValue"}>{itm["company"] ? itm["company"] : ""}</Typography>
                              </Grid>
                            </Grid>
                            {/* <Grid container>
                              <Grid item xs={3}>
                                <Typography variant={"body1"} className={"resultLabel"}>{"Associate:"}</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant={"body1"} className={"resultValue"}>{itm["category"] ? itm["category"] : ""}</Typography>
                              </Grid>
                            </Grid> */}

                            <Grid container>
                              <Grid item xs={3}>
                                <Typography variant={"body1"} className={"resultLabel"}>{"Card ID Number:"}</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant={"body1"} className={"resultValue"}>{itm["card_id"] ? itm["card_id"] : ""}</Typography>
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={3}>
                                <Typography variant={"body1"} className={"resultLabel"}>{"Expiry Date:"}</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant={"body1"} className={"resultValue"}>{itm["expiry_date"] ? moment(new Date(itm["expiry_date"])).format("YYYY-MM-DD") : ""}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={3} align="right">
                            <Grid item className={classes.userImg}>
                              {itm.image !== '' ? <img className={classes.userImage} src={itm.image} alt={"img"} /> : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {page.skip>0 &&<Button color="primary" size="small" title={"Previous"} onClick={() => { PrevClick() }} className="mr-1" type="submit" ></Button>}
                {(page.skip>=0 && page.limit && page.count && page.count>page.limit && (page.skip*page.limit)<page.count) && <Button color="primary" size="small" title={"Next"} onClick={() => { nextClick() }}></Button>}
              </Grid>
            </React.Fragment>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

// default props
DirectoryFrame.defaultProps = {
  classes: {}
};

// prop types
DirectoryFrame.propTypes = {
  classes: PropTypes.object
};

export default withStyles(DirectoryStyles)(DirectoryFrame);
import React from "react";
import PropTypes from "prop-types";
import { Dialog as Core } from "@material-ui/core";

/**
 *Alert component
 */
function AlertDialogComponent(props) {
  const { children, open, fullScreen, onEntered, maxWidth } = props;

  let widthProps = {}
  if (maxWidth) {
    widthProps = { 'maxWidth': maxWidth }
  }

  /**
   * Handle Close
   */
  const handleClose = () => {
    handleClose(!open);
  };

  return (
    <React.Fragment>
      <Core
        data-test="dialog-test"
        onClose={handleClose}
        onEntered={onEntered}
        fullScreen={fullScreen}
        aria-labelledby="dialog"
        open={open}
        disableBackdropClick={true}
        {...widthProps}
      >
        {children}
      </Core>
    </React.Fragment>
  );
}

// default props
AlertDialogComponent.defaultProps = {
  className: "",
  children: {},
  fullScreen: false,
  open: false,
  handleClose: () => { },
  disableBackdropClick: false,
  onEntered: () => { },
};

// prop types
AlertDialogComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  fullScreen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  disableBackdropClick: PropTypes.bool,
  onEntered: PropTypes.func.isRequired,
};

// export component
export const AlertDialog = AlertDialogComponent;

import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from 'react-hook-form'
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames"
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextareaAutosize } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

//Styles and Images
import ApplicationStyles from "../ApplicationStyles"

// Import Components
import { Button, TextField, Typography, CheckBox, AutoComplete, MultiSelect } from "../../../components";
import QuestionSection from "./QuestionSection";

// Import Helpers
import validations from "../../../utils/validations";

// Import Cofig Constants
import { application_page_constants } from "../../../config/constants";

//import actions
import { applicationAction } from "../../../redux/actions";

const ApplicationTabAdd = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    const screenSuggestions = [{label:"",value:""},{label:"Tenant",value:"resident"},{label:"Employment",value:"employment"}]

    //get props
    const { classes, register, isEdit,setScreenType,screenType, handleClose, selService, setSelService, selCriteria, setSelCriteria, errors, control, selManager, setSelManager, showValidate } = props;

    //get reducer data
    const { serviceName, criteriaName, managerName } = useSelector(s => (s.application))

    //set autocomplate Dropown
    const getSelectedValue = (arr, val) => {
        const valuee = arr.filter(i => (i.value === val))
        return (valuee[0] ? valuee[0] : { value: "", label: "" })
    }

    //change maaner data
    const handleManagerChange = (val, e) => {
        let { name, value } = val;
        if (value && Array.isArray(value)) {
            if (value.indexOf("Select All") > -1 && selManager.indexOf("Select All") === -1) {
                setSelManager(["Select All", ...managerName.map(i => { return i['label'] })])
            } else if (value.indexOf("Select All") === -1 && selManager.indexOf("Select All") > -1) {
                setSelManager([])
            } else if (value.indexOf("Select All") === -1 && selManager.indexOf("Select All") === -1 && (value.length === managerName.length)) {
                setSelManager(["Select All", ...managerName.map(i => { return i['label'] })])
            } else if (selManager.indexOf("Select All") > -1 && (value.indexOf("Select All") > -1 && (managerName.length > value.length - 1))) {
                value = value.filter(v => (v !== "Select All"))
                setSelManager(value)
            } else {
                setSelManager(value)
            }
        }
    }

    //Accordion state and changes
    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => { setExpanded(newExpanded ? panel : false) };


    return (
        <Fragment>
            <Grid container>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                        aria-label="Expand"
                        aria-controls="additional-actions2-content"
                        id="additional-actions2-header"
                    >
                        <Typography variant={"h6"} className={"mb-1"}>{application_page_constants.GENERIC}</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item xs={12} className={classes.greyContainer}>
                            <Grid container spacing={3}>

                                <Grid item xs={4}>
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"}>{application_page_constants.SERVICE}</Typography>
                                        <AutoComplete
                                            name={'selService'}
                                            suggestions={serviceName}
                                            disabled={isEdit ? false : false}
                                            handleAutoComplete={(newValue, name) => { setSelService(newValue && newValue.value ? newValue.value : newValue) }}
                                            value={getSelectedValue(serviceName, selService)}
                                            showValidate={showValidate && !selService ? true : false}
                                            validationMsg={"this Field is Required"}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={10} align="center">
                                        <Typography variant={"body1"}>{application_page_constants.CRITERIA}</Typography>
                                        <AutoComplete
                                            name={'selCriteria'}
                                            showValidate={showValidate && !selCriteria ? true : false}
                                            validationMsg={"This Field is Required"}
                                            suggestions={criteriaName}
                                            disabled={isEdit ? false : false}
                                            handleAutoComplete={(newValue, name) => { setSelCriteria(newValue && newValue.value ? newValue.value : newValue) }}
                                            value={getSelectedValue(criteriaName, selCriteria)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={10} align="right">
                                        <Typography variant={"body1"}>{application_page_constants.NAME}<span className={"reqStar"}>*</span></Typography>
                                        <TextField
                                            id={"name"}
                                            name={"name"}
                                            size={"small"}
                                            margin={"none"}
                                            error={errors.name ? true : false}
                                            helperText={errors.name?.message}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired('Name cannot be blank')
                                                }
                                            })}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12} align="right">
                                        <Typography variant="body1" title={"Description"}></Typography>
                                        <TextareaAutosize name={"descp"} ref={(e) => { register(e) }} className={classes.allianceTextarea} aria-label="minimum height" rowsMin={8} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={10} className={classes.multiSelect}>
                                        <Typography variant={"body1"}>{"Manager"}<span className={"reqStar"}>*</span></Typography>
                                        <MultiSelect
                                            name={"selectClient"}
                                            suggestions={managerName.length ? ["Select All", ...managerName.map(i => { return i['label'] })] : []}
                                            handleAutoCompolete={(name, value, e) => { handleManagerChange({ name, value }, e); }}
                                            value={selManager}
                                            error={showValidate && (!selManager || selManager.length === 0) ? true : false}
                                            errorText={showValidate && (!selManager || selManager.length === 0) ? "this field is required" : ""}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={10} align="center">
                                        <Typography variant={"body1"}>{"Screening Type"}</Typography>
                                        <AutoComplete
                                            name={'screenType'}
                                            // showValidate={showValidate && !screenType ? true : false}
                                            // validationMsg={"This Field is Required"}
                                            suggestions={screenSuggestions}
                                            disabled={false}
                                            handleAutoComplete={(newValue, name) => { setScreenType(newValue && newValue.value ? newValue.value : newValue) }}
                                            value={getSelectedValue(screenSuggestions, screenType)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={10} ></Grid>
                                    </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={10}>
                                        <Controller
                                            name="show_application_name"
                                            control={control}
                                            render={props =>
                                                <CheckBox
                                                    label={application_page_constants.SHOW_APPLICATION_NAME}
                                                    checked={props.value}
                                                    color="secondary"
                                                    handleChange={(e) => { props.onChange(e.target.checked); }}
                                                    inputRef={register()}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={10} align="left" >
                                        <Controller
                                            name="is_instant"
                                            control={control}
                                            render={props =>
                                                <CheckBox
                                                    label={application_page_constants.INSTANT}
                                                    checked={props.value}
                                                    color="secondary"
                                                    handleChange={(e) => { props.onChange(e.target.checked); }}
                                                    inputRef={register()}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={10} align="left" >
                                        <Controller
                                            name="show_confirmation"
                                            control={control}
                                            render={props =>
                                                <CheckBox
                                                    label={application_page_constants.SHOW_CONFIRMATION}
                                                    checked={props.value}
                                                    color="secondary"
                                                    handleChange={(e) => { props.onChange(e.target.checked); }}
                                                    inputRef={register()}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12} align="right">
                                        <Typography variant="body1" title={"Review Confirmation Message"}></Typography>
                                        <TextareaAutosize name={"confirmation_msg"} ref={(e) => { register(e) }} className={classes.allianceTextarea} aria-label="minimum height" rowsMin={8} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                        aria-label="Expand"
                        aria-controls="additional-actions2-content"
                        id="additional-actions2-header"
                    >
                        <Typography variant={"h6"} className={"mb-1"}>{application_page_constants.MAPS}</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item xs={12} className={classes.greyContainer}>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>

                                    <Grid item xs={4}>
                                        <Grid item xs={10}>
                                            <Typography variant={"body1"}>{application_page_constants.INTRODUCTION_MAP}</Typography>
                                            <TextField
                                                id={"intro_map"}
                                                name={"intro_map"}
                                                size={"small"}
                                                margin={"none"}
                                                inputRef={register()}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Grid item xs={10}>
                                            <Typography variant={"body1"}>{application_page_constants.CRITERIA_MAP}</Typography>
                                            <TextField
                                                id={"criteria_map"}
                                                name={"criteria_map"}
                                                size={"small"}
                                                margin={"none"}
                                                inputRef={register()}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Grid item xs={10}>
                                            <Typography variant={"body1"}>{application_page_constants.APPLICATION_MAP}</Typography>
                                            <TextField
                                                id={"application_map"}
                                                name={"application_map"}
                                                size={"small"}
                                                margin={"none"}
                                                inputRef={register()}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Grid item xs={10}>
                                            <Typography variant={"body1"}>{application_page_constants.DISCLOSURE_MAP}</Typography>
                                            <TextField
                                                id={"disclosure_map"}
                                                name={"disclosure_map"}
                                                size={"small"}
                                                margin={"none"}
                                                inputRef={register()}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Grid item xs={10}>
                                            <Typography variant={"body1"}>{application_page_constants.PAYMENT_MAP}</Typography>
                                            <TextField
                                                id={"payment_map"}
                                                name={"payment_map"}
                                                size={"small"}
                                                margin={"none"}
                                                inputRef={register()}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Grid item xs={10}>
                                            <Typography variant={"body1"}>{application_page_constants.APPLICATION_REVIEW_MAP}</Typography>
                                            <TextField
                                                id={"application_review_map"}
                                                name={"application_review_map"}
                                                size={"small"}
                                                margin={"none"}
                                                inputRef={register()}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                        aria-label="Expand"
                        aria-controls="additional-actions2-content"
                        id="additional-actions2-header"
                    >
                        <Typography variant={"h6"} className={"mb-1"}>{application_page_constants.QUESTIONS}</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <QuestionSection classes={classes} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Fragment>
    )

}

// default props
ApplicationTabAdd.defaultProps = {
    classes: {},
    editAnchor: {},
    handelEditClose: () => { },
    selService: '',
    setSelService: () => { },
    selCriteria: '',
    setSelCriteria: () => { },
    screenType: '',
    setScreenType: () => { },
    isEdit: false
};

// prop types
ApplicationTabAdd.propTypes = {
    classes: PropTypes.object,
    editAnchor: PropTypes.object,
    handelEditClose: PropTypes.func,
    selService: PropTypes.string,
    setSelService: PropTypes.func,
    selCriteria: PropTypes.string,
    setSelCriteria: PropTypes.func,
    isEdit: PropTypes.bool,
    screenType: PropTypes.string,
    setScreenType: PropTypes.func,

};

export default withStyles(ApplicationStyles)(ApplicationTabAdd);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

// Layout Style
import PublicLayoutStyle from "./PublicLayoutStyle.jsx";

const PublicLayout = (props) => {
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}

// Define Props Types
PublicLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element
};

// Default props
PublicLayout.defaultProps = {
  classes: {},
  children: {}
};

export default withStyles(PublicLayoutStyle)(PublicLayout);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { HomeNIcon, EmpIcons } from "../../assets/svg";

import s1 from '../../assets/images/step_obtain.png'
import s2 from '../../assets/images/step_enter.png'
import s3 from '../../assets/images/step_fill.png'
import s4 from '../../assets/images/step_result.png'
import s5 from '../../assets/images/step_pay.png'

// // Import Styles
import BannerLayoutStyles from "./BannerLayoutStyles";

// Import Components
import { Typography } from "../../components";

// Import History
import history from "../../config/history";

function BannerLayout(props) {

  //get props
  const { classes } = props;

  const path = history.location && history.location.pathname ? history.location.pathname : ""

  return (

    /* Authentication container */
    <Grid container className={"h-100 bg-white"} alignItems="stretch">
      <Grid item xs={6} className={"pl-4 pr-4"}>
        <Grid container justify="space-between" className={classes.container}>
          <Grid item xs={12} className={classes.loginContainer}>
            <Grid item xs={12} align="center" className="pb-2"><Grid className={classes.imageContOuter}><Grid className={classes.imageCont}>{path.indexOf("employment") > -1 ? <HomeNIcon/> : <EmpIcons/> }</Grid></Grid></Grid>

            {/* login page */}
            <Grid className={"w-100"}>{props.children}</Grid>

          </Grid>
          <Grid item sm={12} className={classes.bottomContainer}>
            <Typography className={"flex pb-1"} variant={"subtitle1"} color="textSecondary"><span className={"pr-2"}><img src={s1} alt="Alliance 2020" /></span>{"1. Obtain passcode from requestor"}</Typography>
            <Typography className={"flex pb-1"} variant={"subtitle1"} color="textSecondary"><span className={"pr-2"}><img src={s2} alt="Alliance 2020" /></span>{"2. Enter passcode"}</Typography>
            <Typography className={"flex pb-1"} variant={"subtitle1"} color="textSecondary"><span className={"pr-2"}><img src={s3} alt="Alliance 2020" /></span>{"3. Fill out application"}</Typography>
            {path.indexOf("employment") > -1 && <Typography className={"flex pb-1"} variant={"subtitle1"} color="textSecondary"><span className={"pr-2"}><img src={s4} alt="Alliance 2020" /></span>{"4. Results in 2-3 business days"}</Typography>}
            {path.indexOf("employment") === -1 && <Typography className={"flex pb-1"} variant={"subtitle1"} color="textSecondary"><span className={"pr-2"}><img src={s5} alt="Alliance 2020" /></span>{"4. Pay screening fee"}</Typography>}
            {path.indexOf("employment") === -1 && <Typography className={"flex pb-1"} variant={"subtitle1"} color="textSecondary"><span className={"pr-2"}><img src={s4} alt="Alliance 2020" /></span>{"5. Results in 2-3 business days"}</Typography>}            
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} className={classes.bannerImg}>
      </Grid>
    </Grid>
  );
}

// prop types
BannerLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element.isRequired
};

export default withStyles(BannerLayoutStyles)(BannerLayout);

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import classNames from "classnames"
import { Grid, Dialog, withStyles, Accordion, AccordionSummary, AccordionDetails, TextareaAutosize } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

//Styles and Images
import ApplicationStyles from "../ApplicationStyles"

// Import Components
import { Button, Typography, RichTextEdit, Info } from "../../../components";
import ApplicationTab from './ApplicationTab';
import ApplicationTabAdd from "./ApplicationTabAdd"

// Import Cofig Constants
import { application_page_constants, templates_page_constants } from "../../../config/constants";

//import actions
import { alertAction, applicationAction } from '../../../redux/actions';

const AddEditApplication = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, openApp, setOpenApp, isEdit } = props;

    //get reducer data
    const { getApp: { appData, question }, appOldData, search: { page }, getAll: { page: cardPage }, managerName, viewType } = useSelector(s => (s.application))

    useEffect(() => {
        // get service and criteria name
        dispatch(applicationAction.getServiceName())
        dispatch(applicationAction.getCriteriaName())
        //get all managers of client
        dispatch(applicationAction.getManagerName());
    }, [dispatch])

    //set state for rich text value
    const [richText, setRichText] = useState(appData && appData.criteria ? appData.criteria : '')
    const [criteriaText, setCriteriaText] = useState(appData && appData.criteria ? appData.criteria : '')

    const [richText1, setRichText1] = useState(appData && appData.introduction ? appData.introduction : '')
    const [introText, setIntroText] = useState(appData && appData.introduction ? appData.introduction : '')

    const [richText2, setRichText2] = useState(appData && appData.disclaimer ? appData.disclaimer : '')
    const [disclaimerText, setDisclaimerText] = useState(appData && appData.disclaimer ? appData.disclaimer : '')

    //manager ddn
    const [selManager, setSelManager] = useState(appData && appData.managerList ? appData.managerList : [])

    useEffect(() => {
        let managerLis = appData && appData.managerList ? appData.managerList : []
        if (managerLis.length > 0 && managerName.length === managerLis.length) {
            setSelManager(["Select All", ...managerLis])
        }
    }, [dispatch, managerName, appData])

    let defaultValue = {
        payment: appData && appData.payment ? appData.payment : '',
        name: appData && appData.name ? appData.name : '',
        show_application_name: appData && (appData.show_application_name === 0 || appData.show_application_name === 1) ? appData.show_application_name === 1 ? true : false : true,
        is_instant: appData && (appData.is_instant === 0 || appData.is_instant === 1) ? appData.is_instant === 1 ? true : false : true,
        show_confirmation: appData && (appData.show_confirmation === 0 || appData.show_confirmation === false) ? false : true,
        intro_map: appData && appData.intro_map ? appData.intro_map : '',
        criteria_map: appData && appData.criteria_map ? appData.criteria_map : '',
        application_map: appData && appData.application_map ? appData.application_map : '',
        application_review_map: appData && appData.application_review_map ? appData.application_review_map : '',
        indentification_map: appData && appData.indentification_map ? appData.indentification_map : '',
        disclosure_map: appData && appData.disclosure_map ? appData.disclosure_map : '',
        payment_map: appData && appData.payment_map ? appData.payment_map : '',
        descp: appData && appData.descp ? appData.descp : '',
        confirmation_msg : appData && appData.confirmation_msg ? appData.confirmation_msg : 'I acknowledge the information entered is correct.',
    }

    const [selService, setSelService] = useState(appData && appData.service_id ? appData.service_id + '' : '')
    const [selCriteria, setSelCriteria] = useState(appData && appData.criteria_id ? appData.criteria_id + '' : '1')
    const [screenType, setScreenType] = useState(appData && appData.screenType ? appData.screenType : '')
    const [showValidate, setFormValidate] = useState(false)

    // Set Form Values
    const { register, handleSubmit, errors, control } = useForm({
        mode: 'all',
        reValidateMode: 'all',
        defaultValues: defaultValue
    });

    // Submit Form
    const onSubmit = (data) => {
        if (data) {
            let editQues = question.filter(q => (q.isEdit === true))

            //check for map length greater than the DB characters length
            let mapData = ["intro_map", "criteria_map", "application_map", "disclosure_map", "payment_map"]
            let mapDispData = ["Introduction Map", "Criteria Map", "Application Map", "Document Map", "Payment Map"]
            let errStr = ""
            for (let m = 0; m < mapData.length; m++) {
                if (mapData[m] === "disclosure_map" && (data[mapData[m]] && data[mapData[m]].length > 50)) {
                    errStr = `${mapDispData[m]} length should be less than 50 characters`;
                } else if (mapData[m] !== "disclosure_map" && (data[mapData[m]] && data[mapData[m]].length > 60)) {
                    errStr = `${mapDispData[m]} length should be less than 60 characters`;
                }
            }
            
            if (editQues.length > 0) {
                dispatch(alertAction.error("Please save the edited question"))
            } else if (selManager.length === 0) {
                dispatch(alertAction.error("Please Select managers"))
                setFormValidate(true)
            } else if (!selService) {
                dispatch(alertAction.error("Please Select Service"))
                setFormValidate(true)
            } else if (!selCriteria) {
                dispatch(alertAction.error("Please Select criteria"))
                setFormValidate(true)
            } else if (errStr) {
                dispatch(alertAction.error(errStr))
            } else if (data["name"] && data["name"].length>255) {
                dispatch(alertAction.error(`Name length should be less than 255 characters`))
            }else if(!data.confirmation_msg){
                dispatch(alertAction.error(`Review confirmation message cannot be blank.`))
            } else {
                setFormValidate(false)
                data['managerList'] = selManager.filter(m => (m !== "Select All"))
                data['criteria'] = richText
                data['introduction'] = richText1
                data['disclaimer'] = richText2
                data['question'] = question
                data['service_id'] = selService
                data['criteria_id'] = selCriteria
                data['screenType'] = screenType ? screenType : ""

                //edit application
                if (isEdit) {
                    data['id'] = appData && appData.id ? appData.id : null
                    dispatch(applicationAction.updateApp({ ...data, oldData: appOldData }, () => {
                        handleClose()
                        if (viewType === "table") {
                            dispatch(applicationAction.search(1, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
                        } else {
                            dispatch(applicationAction.getAll({ limit: 20, skip: 0, search: cardPage.search, sort: cardPage.sort }));
                        }
                    }))
                } else {
                    //add application
                    dispatch(applicationAction.createApp(data, () => {
                        handleClose()
                        if (viewType === "table") {
                            dispatch(applicationAction.search(1, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
                        } else {
                            dispatch(applicationAction.getAll({ limit: 20, skip: 0, search: cardPage.search, sort: cardPage.sort }));
                        }
                    }))
                }
            }
        }
    }

    //Accordion state and changes
    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => { setExpanded(newExpanded ? panel : false) };

    //close popup
    const handleClose = () => {
        setOpenApp(false);
    };

    const onError =(err,e)=>{
        if(err && err["name"] && err["name"]["message"]){
            dispatch(alertAction.error(err["name"]["message"]))
        }
    }

    return (
        <React.Fragment>
            <Dialog className={"paperBgGray"} fullScreen open={openApp} onClose={handleClose} disableEnforceFocus={true}>
                <form onSubmit={handleSubmit(onSubmit,onError)} noValidate>
                    <Grid container className={`${classes.appTemplateDialog}`}>
                        <Grid container justify="space-between" className={`${classes.dialogHeader} ${"p-2"}`} alignItems="center">
                            <Typography variant="h4" title={isEdit ? "Edit Application" : "Add Application"}></Typography>
                            <Grid>
                                <Button color="primary" type='submit' size="small" title={isEdit ? application_page_constants.UPDATE : "Create"} className="mr-1"></Button>
                                <Button color="secondary" size="small" onClick={handleClose} title={application_page_constants.CANCEL}></Button>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" className={`${classes.accordionContainer} ${"pl-6 pr-6 pt-2"}`}>
                            <Grid className={classes.accordionConDiv}>

                                {!isEdit &&
                                    <ApplicationTabAdd
                                        register={register}
                                        errors={errors}
                                        control={control}
                                        handleClose={handleClose}
                                        selService={selService}
                                        setSelService={setSelService}
                                        selCriteria={selCriteria}
                                        setSelCriteria={setSelCriteria}
                                        selManager={selManager}
                                        setSelManager={setSelManager}
                                        showValidate={showValidate}
                                        screenType={screenType}
                                        setScreenType={setScreenType}
                                        isEdit={isEdit}
                                    />}

                                {isEdit && <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions2-content"
                                        id="additional-actions2-header"
                                    >
                                        <Typography variant="h6">{application_page_constants.APPLICATIONlOW}</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ApplicationTab
                                            register={register}
                                            errors={errors}
                                            control={control}
                                            handleClose={handleClose}
                                            selService={selService}
                                            setSelService={setSelService}
                                            selCriteria={selCriteria}
                                            setSelCriteria={setSelCriteria}
                                            selManager={selManager}
                                            setSelManager={setSelManager}
                                            showValidate={showValidate}
                                            screenType={screenType}
                                            setScreenType={setScreenType}
                                            isEdit={isEdit}
                                        />
                                    </AccordionDetails>
                                </Accordion>}

                                {(isEdit && (appData && appData.is_introduction === 1)) && <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions1-content"
                                        id="additional-actions1-header"
                                    >
                                        <Typography variant="h6" title={application_page_constants.INTRODUCTION}></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12}>
                                            <RichTextEdit
                                                value={introText ? introText : ""}
                                                onChange={(v) => { setRichText1(v) }}
                                            />
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>}

                                {isEdit && <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions2-content"
                                        id="additional-actions2-header"
                                    >
                                        <Typography variant="h6" title={application_page_constants.DISCLAIMER}>
                                            <Info color={expanded === 'panel5' ? "#fff" : ""}>
                                                <p dangerouslySetInnerHTML={{ __html: `${templates_page_constants.DISCLAIMER_TEXT}` }} className={`${classes.borderline} pb-1`}></p>
                                            </Info>
                                        </Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12}>
                                            <RichTextEdit
                                                value={disclaimerText ? disclaimerText : ""}
                                                onChange={(v) => { setRichText2(v) }}
                                            />
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>}

                                {isEdit && <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions3-content"
                                        id="additional-actions3-header"
                                    >
                                        <Typography variant="h6" title={application_page_constants.PAYMENT}></Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" title={application_page_constants.PAYMENT}></Typography>
                                            <TextareaAutosize name={"payment"} ref={(e) => { register(e) }} className={classes.allianceTextarea} aria-label="minimum height" rowsMin={8} />
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>}

                                {(isEdit && (appData && appData.is_criteria === 1)) && <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions2-content"
                                        id="additional-actions2-header"
                                    >
                                        <Typography variant="h6" title={application_page_constants.CRITERIA}></Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12}>
                                            <RichTextEdit
                                                value={criteriaText ? criteriaText : ""}
                                                onChange={(v) => { setRichText(v) }}
                                            />
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>}

                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>
        </React.Fragment>
    )
}
// default props
AddEditApplication.defaultProps = {
    classes: {},
    openApp: true,
    setOpenApp: () => { },
    isEdit: false
};

// prop types
AddEditApplication.propTypes = {
    classes: PropTypes.object,
    openApp: PropTypes.bool,
    setOpenApp: PropTypes.func,
    isEdit: PropTypes.bool,
};

export default withStyles(ApplicationStyles)(AddEditApplication);

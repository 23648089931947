const styles = (theme) => ({


    autoComplete: {
        '& .MuiOutlinedInput-root': {
            padding: '0 9px',
            height: 34,
            minHeight: 34
            
        }
    }


})

export default styles
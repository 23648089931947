import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, Grid, IconButton } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

//Styles & Images
import ApplicationStyles from "./ApplicationStyles"
import { TileIcon, ListIcon, SortIcon } from '../../assets/svg'

// Import Components
import { Button, Typography, SearchBar } from "../../components";
import ApplicationList from './ApplicationList/ApplicationList';
import ColumnSettings from './ApplicationList/ColumnSettings'
import ApplicationTile from "./ApplicationCord/ApplicationTile"

// Import Cofig Constants
import { application_page_constants } from "../../config/constants";

// Import History
import history from "../../config/history";

// Import Actions
import { applicationAction,updateReducerAction } from "../../redux/actions";

//import constants
import { applicationConstant } from "../../redux/constants";
import AddEditApplication from "./AddEditApplication/AddEditApplication";

const Application = (props) => {
  //define dispatch
  const dispatch = useDispatch();

  //get props
  const { classes, match} = props;

  //get reducer data
  let { getAll: { page },viewType } = useSelector((state) => state.application);
  const { loading } = useSelector(s=>s.UI)

  //set state
  const [openApp, setOpenApp ] =useState(false)

  //udate view type in reducer
  const setviewType=(type)=>{
    if(type){
      dispatch(updateReducerAction.update(applicationConstant.UPDATE_VIEW_TYPE,type));
      if(type === "table"){
        history.push(`application?page=true`)
      }else{
        history.push(`/application`)
      }
    }
  }

  //reset and route
  const resetAndOpenApp=()=>{
    dispatch(applicationAction.resetAppData())
    setOpenApp(true)
  }

  //get sort data
  const getSortedData = (sorting) => {
    dispatch(applicationAction.getAll({ limit: 20, skip: 0, search: page.search, sort:sorting }));
  }

  //get search data
  const intiSearch = (value) => {
    dispatch(applicationAction.getAll({ limit: 20, skip: 0, search: value, sort: page.sort }))
  }

  return (
    <React.Fragment>
      <Grid item xs={12} >
        <Grid container direction="row" justify="space-between" alignItems="center" className={`${classes.titlerow}`}>
          <Grid item>
            <Typography variant="h3" component="h3"> {`${application_page_constants.APPLICATION} ${viewType !== "table" &&  page && page.totalCount>=0 ? `(${page.totalCount})` : ""}`} </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              {viewType !== "table" &&
                <Grid className={classes.searchBoxGrid}>
                <SearchBar className={"mr-1"} loading={loading} value={page.search} intiSearch={(v) => { intiSearch(v) }} />
              </Grid>
              }
              {viewType === "table" && <ColumnSettings /> }
              {viewType !== "table" && 
                <Grid>
                  {page && page.sort && page.sort === "asc" ?
                      <IconButton className={classes.searchIcon} onClick={() => { getSortedData('desc') }}><SortIcon/></IconButton>
                      :
                      <IconButton className={classes.searchIcon} onClick={() => { getSortedData('asc') }}><SortIcon /></IconButton>
                  }
                </Grid> 
              }
              <ToggleButtonGroup
                value={viewType}
                exclusive
                onChange={(event, newAlignment) => { setviewType(newAlignment) }}
                aria-label="text alignment"
                className={`${"ml-1"} ${classes.toggleButton}`}
              >
                <ToggleButton value="left" aria-label="left aligned">
                  <TileIcon />
                </ToggleButton>
                <ToggleButton value="table" aria-label="centered">
                  <ListIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              <Button variant="contained" size="small" color="primary" className={"ml-1"} onClick={()=>{resetAndOpenApp()}} title={application_page_constants.ADD_APPLICATION_BTN} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      { viewType === "table" ? <ApplicationList {...props} /> : <ApplicationTile {...props}/>}
      {openApp && <AddEditApplication openApp ={openApp} isEdit={false} setOpenApp={setOpenApp}/>}
    </React.Fragment>
  );
}

// default props
Application.defaultProps = {
  classes: {}
};

// prop types
Application.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ApplicationStyles)(Application);

import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from '@material-ui/core/Drawer';
import { Backdrop, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';

//Styles
import UsersStyles from '../../UsersStyles'

//Images
import { EditTableIcon, DragIcon, CheckSelectedIcon, CloseIcon } from '../../../../assets/svg'

//Components
import { Button, TextField, Typography, CheckBox, Link } from "../../../../components";

//Constants
import { user_page_constants } from "../../../../config/constants"

// Import Actions
import { serviceAction } from "../../../../redux/actions";
import { Alert } from '@material-ui/lab';

function UpdateFee(props) {
    const dispatch = useDispatch();
    const { classes, clientId, page } = props;

    const [drawer, setDrawer] = useState(false);

    let { checkBoxSelect } = useSelector(state => state.service);

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawer(open);
    };

    // Set Form Values
    const { register, handleSubmit, errors, reset } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            fee: null
        }
    });

    // Submit Form
    const onSubmit = (finaldata) => {
        if(Number(finaldata.fee) && Number(finaldata.fee)<0){
            finaldata.fee = 0
        } else if(!Number(finaldata.fee)){
            finaldata.fee = 0
        }
        dispatch(serviceAction.updateFee(finaldata, () => {
            setDrawer(false)
            reset()
            dispatch(serviceAction.userServiceSearch(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues, clientId));
        }))
    }


    //UpdateFee Alert
    const [alertMsg, openAlertMsg] = useState(false);

    const alertBox = () => {
        openAlertMsg(true);
    };

    const handleClose = () => {
        openAlertMsg(false);
    };

    return (
        <React.Fragment>
            <Backdrop className={classes.backDrop} onClick={toggleDrawer(false)} open={drawer} />
            {/* <IconButton onClick={toggleDrawer(true)} className={classes.padding5}><EditTableIcon /></IconButton> */}
            <Button color="secondary" onClick={(Object.keys(checkBoxSelect).length > 0) ? toggleDrawer(true) : alertBox} title={user_page_constants.UPDATEFEE} className={`${classes.btnBlue} ${"mr-1"}`}></Button>
            <Drawer anchor='right' role="presentation" variant="persistent" open={drawer} onClose={toggleDrawer(false)} className={classes.allianceDrawer}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container alignItems="center" justify="space-between" className={"p-2"}>
                        <Typography variant="h4" title={user_page_constants.UPDATEFEE}></Typography>
                        <IconButton onClick={toggleDrawer(false)} className={classes.padding5}><CloseIcon /></IconButton>
                    </Grid>
                    <Grid container className="pl-2 pr-2 pb-2">
                        <Typography variant={"body1"}>{user_page_constants.FEE}</Typography>
                        <TextField
                            id={"fee"}
                            name={"fee"}
                            size={"small"}
                            margin={"none"}
                            inputRef={
                                register()
                            }
                        />
                    </Grid>
                    <Grid container className="p-2">
                        <Button color="primary" size="small" title={user_page_constants.APPLY} className="mr-1" type="submit"></Button>
                        <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={toggleDrawer(false)}></Button>
                    </Grid>
                </form>
            </Drawer>
            <Snackbar
             className={classes.updateFeeAlert}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={alertMsg}
                autoHideDuration={5000}
                onClose={handleClose}
                message="Note archived">
                <Alert severity="error">Please select the template <IconButton className={"p5"} onClick={handleClose}><CloseIcon /></IconButton></Alert>
            </Snackbar>
        </React.Fragment>

    )
}

// default props
UpdateFee.defaultProps = {
    classes: {},
    drawer: false,
    toggleDrawer: () => { },
};

// prop types
UpdateFee.propTypes = {
    classes: PropTypes.object,
    drawer: PropTypes.bool,
    toggleDrawer: PropTypes.func
};

// export default UserList;
export default withStyles(UsersStyles)(UpdateFee);
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import propTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

//Import Styles and images
import UsersStyles from "../../UsersStyles";

// Import Components
import { PageContainer } from "../../../../components";
import UserFilters from './UserFilters'
import UserChords from './UserChords'

// Import Actions
import { userAction } from "../../../../redux/actions";

// Import Config Layout
import { getChildContainerHeight, usersCardLimit } from "../../../../config/layout";
import NoRecordsFound from "../NoRecordsFound";

function UsersTile(props) {
  //define dispatch
  const dispatch = useDispatch();

  //get props
  const { classes } = props;
  const cardLimit = usersCardLimit();

  //get page height
  const height = getChildContainerHeight();

  //get reducer data
  const { chordFilter:filter,currentUser,getAll: { data,page }, } = useSelector((state) => state.user);
  const { loading } = useSelector(s=>s.UI)
  
  //get user data
  useEffect(() => {
    dispatch(userAction.getCompaniesAndRole(filter,(chordData)=>{
      dispatch(userAction.getAll({limit:cardLimit,skip:1,rolesSel:chordData.rolesSel,companySel:chordData.companySel,search:page.search,sort:'desc'}));
    }));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <PageContainer height={height} paddingRight={12}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            className={"h-100"}
            spacing={2}
            wrap="nowrap"
          >
            {currentUser.role!=='client' &&
              <UserFilters classes={classes} filter={filter} />
            }
            {data && data.length>0 ? <UserChords classes={classes} /> :  !loading ? <NoRecordsFound/> : null}
          </Grid>
        </Grid>
      </PageContainer>
    </Grid>
  );
}

// default props
UsersTile.defaultProps = {
  classes: {},
};

// prop types
UsersTile.propTypes = {
  classes: propTypes.object,
};

// export default UserList;
export default withStyles(UsersStyles)(UsersTile);

import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from 'react-hook-form'
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames"
import { Grid, FormControl, IconButton, TextareaAutosize } from '@material-ui/core';
import arrayMove from "array-move";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

//Styles and Images
import ApplicationStyles from "../ApplicationStyles"
import { DragIcon, DeleteIcon, EditIcon } from '../../../assets/svg'

//import actions
import { updateReducerAction } from '../../../redux/actions';

// Import Components
import { Button, TextField, Typography, AlertDialog, Select } from "../../../components";

// Import Cofig Constants
import { application_page_constants } from "../../../config/constants";
import { applicationConstant } from "../../../redux/constants";

//Sortable Item
const SortableItem = SortableElement(props => {
    return (
        props.children
    );
});

//Drag Handle
const DragHandle = SortableHandle((props) => {
    const { classes } = props;

    return (<DragIcon className={"cla"} draggable={false}></DragIcon>);
})

//create sortable list for drag and drop
const SortableList = SortableContainer(props => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { data, classes, setDeleteAlert } = props;

    //set state
    const [text, setText] = useState('');
    const [quesType, setQuesType] = useState('');
    const [choice, setChoice] = useState('');
    const [updateQuesVal, setUpdateQuesVal] = useState(false)

    //clear temp question state
    const clearData = (index) => {
        setText('')
        setQuesType('')
        setChoice('')
        setUpdateQuesVal(false)
        let updatedData = data
        for (let i = 0; i < updatedData.length; i++) {
            if (index === i) {
                delete updatedData[i]['isEdit']
            }
        }
        dispatch(updateReducerAction.update(applicationConstant.UPDATE_QUESTION_REDUCER, updatedData))
    }

    //set question to edit mode
    const openEditQuestion = (item, index) => {
        setText(item.text ? item.text : '')
        setQuesType(item.type ? item.type : '')
        setChoice(item.choices ? item.choices : '')
        let updatedData = data
        for (let i = 0; i < updatedData.length; i++) {
            if (index === i) {
                updatedData[i]['isEdit'] = true
            } else {
                delete updatedData[i]['isEdit']
            }
        }
        dispatch(updateReducerAction.update(applicationConstant.UPDATE_QUESTION_REDUCER, updatedData))
    }

    //update question
    const updateQuestion = (index) => {
        if (text && quesType && text.trim() && quesType.trim()) {
            if ((quesType !== 'single' && quesType !== 'multiple') || ((quesType === 'single' || quesType === 'multiple') && choice && choice.trim())) {
                let updatedData = data
                for (let i = 0; i < updatedData.length; i++) {
                    if (index === i) {
                        updatedData[i]['text'] = text
                        updatedData[i]['type'] = quesType
                        updatedData[i]['choices'] = choice
                        delete updatedData[i]['isEdit']
                    }
                }
                dispatch(updateReducerAction.update(applicationConstant.UPDATE_QUESTION_REDUCER, updatedData, () => {
                    clearData()
                }))
            } else {
                setUpdateQuesVal(true)
            }
        } else {
            setUpdateQuesVal(true)
        }
    }

    return (
        <React.Fragment>
            <Grid container>
                {data && data.map((item, index) => (
                    <SortableItem
                        key={`sort-item-${index}`}
                        index={index}
                    >
                        <Grid item xs={12}>
                            <Grid container alignItems="center" className={classNames(classes.settingsTileBgnoneAddDoc, classes.widthAuto)}>
                                <DragHandle />
                                {item.isEdit ?
                                    <Fragment>
                                        <Grid className={classes.QuestEditDeleteIcon}>
                                            <IconButton align="right" onClick={(e) => { setDeleteAlert({ "delIndex": index }) }}><DeleteIcon /></IconButton>
                                        </Grid>

                                        <Grid container spacing={3} className="pt5">
                                            <Grid item xs={6}>
                                                <Typography variant={"body1"}>{application_page_constants.TEXT}</Typography>
                                                <TextField
                                                    id={`name`}
                                                    name={`name`}
                                                    margin="none"
                                                    placeholder={'text'}
                                                    value={text ? text : ''}
                                                    onChange={(e) => { setText(e.target.value) }}
                                                    error={updateQuesVal && !text.trim() ? true : false}
                                                    helperText={updateQuesVal && !text.trim() ? "this field is required" : ""}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant={"body1"}>{application_page_constants.TYPE}</Typography>
                                                <FormControl variant="outlined" className={classNames(classes.formControl, classes.selectBox)}>
                                                    <Select
                                                        options={application_page_constants.QuestionTypes}
                                                        handleChange={(e) => setQuesType(e.value)}
                                                        value={quesType}
                                                        error={updateQuesVal && !quesType ? true : false}
                                                        errorText={updateQuesVal && !quesType ? "this field is required" : ""}
                                                    >
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {(quesType === 'single' || quesType === 'multiple') &&
                                                <Grid item xs={12}>
                                                    <TextareaAutosize className={classes.allianceTextarea} aria-label="minimum height" rowsMin={8} value={choice} onChange={(e) => { setChoice(e.target.value) }} />
                                                    {(updateQuesVal && !choice.trim()) && <p style={{ color: "red" }}>this field is required</p>}
                                                </Grid>
                                            }

                                            <Grid item xs={12}>
                                                <Grid>
                                                    <Button color="primary" size="small" title={"Update Question"} onClick={() => { updateQuestion(index) }} className="mr-1"></Button>
                                                    <Button color="secondary" size="small" title={"Cancel"} onClick={() => { clearData(index) }}></Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <Typography variant={"body2"} title={item.text ? item.text : ''} className={classes.grow}></Typography>
                                        <IconButton onClick={() => { openEditQuestion(item, index) }} className={`${"mr-1 ml-1 p-0"}`}><EditIcon /></IconButton>
                                        <IconButton onClick={(e) => { setDeleteAlert({ "delIndex": index }) }}><DeleteIcon /></IconButton>
                                    </Fragment>
                                }
                            </Grid>
                        </Grid>

                    </SortableItem>
                ))}
            </Grid>
        </React.Fragment>
    )
})

const QuestionList = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes } = props;

    //get reducer data
    const { getApp: { question } } = useSelector(s => (s.application))

    //set state
    const [deleteAlert, setDeleteAlert] = useState(null);

    //check row index on sort
    const onSortEnd = (e) => {
        const { oldIndex, newIndex } = e;
        let resultData = arrayMove(question, oldIndex, newIndex);
        for (let i = 1; i <= resultData.length; i++) {
            resultData[i - 1]["position"] = i;
        }
        dispatch(updateReducerAction.update(applicationConstant.UPDATE_QUESTION_REDUCER, resultData))
    }

    //handle delete
    const handleDelete = (indx) => {
        let resultData = [...question];
        resultData.splice(indx, 1);
        for (let i = 1; i <= resultData.length; i++) {
            resultData[i - 1]["position"] = i;
        }
        dispatch(updateReducerAction.update(applicationConstant.UPDATE_QUESTION_REDUCER, resultData, () => {
            setDeleteAlert(null)
        }))
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <SortableList
                    useDragHandle
                    axis="xy"
                    enableUserSelectHack={false}
                    helperClass="sortable-list-tab"
                    data={question}
                    onSortEnd={onSortEnd}
                    classes={classes}
                    setDeleteAlert={setDeleteAlert}
                ></SortableList>
            </Grid>
            {deleteAlert &&
                <AlertDialog
                    fullScreen={false}
                    open={true}
                >
                    <Grid className={'p-4'}>
                        <Typography variant="h6">{`Are you sure do you want to remove the Question?`}</Typography>
                        <Grid className={'pt-2'} align={"center"}>
                            <Button
                                color={"primary"}
                                className={"mr-1"}
                                onClick={() => { handleDelete(deleteAlert["delIndex"]) }}
                                title={"Continue"}
                            />
                            <Button
                                color={"secondary"}
                                onClick={() => { setDeleteAlert(null) }}
                                title={"Cancel"}
                            />
                        </Grid>
                    </Grid>
                </AlertDialog>
            }
        </Fragment>
    )
}

// default props
QuestionList.defaultProps = {
    classes: {},
};

// prop types
QuestionList.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(ApplicationStyles)(QuestionList);

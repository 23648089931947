import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox as Core, withStyles } from '@material-ui/core';

import { CheckSelectedIcon, CheckIcon } from '../../assets/svg'

import style from './style'


/**
 * Checkbox Component
 * @param {*} props
 */

function CheckboxComponent({ id, icon, checkedIcon, className, classes, color, name, label, disabled, checked, handleChange, ...rest }) {
    // console.log(icon, checkedIcon, "icon")
    const extraProps = {}
    if(icon) extraProps.icon = <CheckIcon />
    if(checkedIcon) extraProps.checkedIcon = <CheckSelectedIcon />

    return (
        <FormControlLabel className={classes.checkBoxCls}
            control={
                <Core
                    {...extraProps}
                    id={id}
                    name={name}
                    color={color}
                    className={className}
                    disabled={disabled}
                    checked={checked}
                    onChange={(e) => { handleChange(e) }}
                />
            }
            label={label}
            {...rest}
        />
    )
}

// default props
CheckboxComponent.defaultProps = {
    id: "",
    name: "",
    icon: false,
    checkedIcon: false,
    label: "",
    className: "",
    color: "secondary",
    disabled: false,
    checked: false,
    handleChange: () => { }
};

// prop types
CheckboxComponent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    classes: PropTypes.object,
    label: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    icon: PropTypes.bool,
    checkedIcon: PropTypes.bool,
    handleChange: PropTypes.func,
};

export const CheckBox = withStyles(style)(CheckboxComponent)
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PriorityHigh from '@material-ui/icons/PriorityHigh';

// Import Component
import { Button } from "../../components";

// Custom Style
const useStyles = makeStyles({
  root: {
    position: "relative",
    textAlign: "center",
    padding: "100px",
    height: "100%"
  },
  icon: {
    width: "100px",
    height: "100px",
    color: "#54C0FD"
  }
});

function PageNotHaveAccess(props) {
  console.log(props)
  const classes = useStyles();

  const goBack = () => {
    // history.goBack()
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="height100"
      >
        <Grid item xs>
          <PriorityHigh className={classes.icon}></PriorityHigh>
          <Typography variant="h2" className="mb-2 txtBold">
            Access Denied.
          </Typography>

          <Typography variant="h4">
            You dont have access for this page. Please contact alliance admin.
          </Typography>

          <div className={"pt-2"}>
            <Button
              title={'Go back'}
              color={"primary"}
              size={"large"}
              handleClick={() => goBack()}
            ></Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default PageNotHaveAccess;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import PropTypes from "prop-types";
import { withStyles, Grid, Dialog, Accordion, AccordionSummary, AccordionDetails, TextareaAutosize } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

//Styles and Images
import TemplatesStyles from "../TemplatesStyles"

// Import Components
import { Button, TextField, Typography, Info, CheckBox, RichTextEdit, TextAreaWithLines } from "../../../components";
import AddChoices from "./AddChoices";

// Import Helpers
import validations from "../../../utils/validations";
import { validateData } from './validation'
import { richTextTester } from '../../../utils'

// Import Cofig Constants
import { templates_page_constants } from "../../../config/constants";

// Import Actions
import { updateReducerAction, templateAction, alertAction } from "../../../redux/actions";

//import constant
import { templateConstant } from '../../../redux/constants'

const AddEditDocuments = (props) => {

    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, templateID, setTemplateID } = props;

    //get reducer data
    const { documentDrawer: open, documentOld, document: { disclosure, choices, variation }, documentTemplate: { page } } = useSelector(s => (s.template));

    //set state for rich text value
    const [richText, setRichText] = useState(disclosure && disclosure.content ? disclosure.content : '')
    const [contentText, setContentText] = useState(disclosure && disclosure.content ? disclosure.content : '')

    // Set Form Values
    const { register, handleSubmit, errors, control, setValue, watch, clearErrors } = useForm({
        mode: 'all',
        reValidateMode: 'all',
        defaultValues: {
            title: disclosure && disclosure.title ? disclosure.title : "",
            description: disclosure && disclosure.description ? disclosure.description : "",
            is_signature: disclosure && disclosure.is_signature ? true : false,
            is_authorization: disclosure && disclosure.is_authorization ? true : false,
            conditions: disclosure && disclosure.conditions ? disclosure.conditions : "",
            is_visible: disclosure && disclosure.is_visible ? true : false,
            layout: disclosure && disclosure.layout ? disclosure.layout : "",
        }
    });

    //update form data on change
    useEffect(() => {
        //set form values
        setValue('title', disclosure && disclosure.title ? disclosure.title : '')
        setValue("description", disclosure && disclosure.description ? disclosure.description : '')
        setValue("is_signature", disclosure && disclosure.is_signature ? true : false)
        setValue("is_authorization", disclosure && disclosure.is_authorization ? true : false)
        setValue("conditions", disclosure && disclosure.conditions ? disclosure.conditions : '')
        setValue("is_visible", disclosure && disclosure.is_visible ? true : false)
        setValue("layout", disclosure && disclosure.layout ? disclosure.layout : '')

        setContentText(disclosure && disclosure.content ? disclosure.content : '')
        setRichText(disclosure && disclosure.content ? disclosure.content : '')
    }, [disclosure]);

    // Submit Form
    const onSubmit = (data) => {
        if (data) {
            if (richTextTester(richText) === true) {
                dispatch(alertAction.error("Please enter Content"));
            } else if(data["title"] && data["title"].length>64){
                dispatch(alertAction.error("Title length should be less than 64"));
            } else if(data["description"] && data["description"].length>249){
                dispatch(alertAction.error("Description length should be less than 250"));
            }else {
                const { choiceValidation, variationValidation } = validateData(variation, choices)

                if (choiceValidation === false && variationValidation === false) {
                    data['content'] = richText;
                    data["id"] = disclosure["id"];
                    data['variation'] = variation;
                    data['choices'] = choices;

                    dispatch(templateAction.addOrUpdateDocument({ ...data, oldData: documentOld }, () => {
                        dispatch(templateAction.getAllDocument({ limit: 20, skip: 0, search: page.search, sort: page.sort }))
                        setTemplateID(null)
                    }))
                } else {
                    dispatch(alertAction.error("Please fill required fields"));
                }
            }
        }
    }

    //Accordion
    //Accordion state and changes
    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => { setExpanded(newExpanded ? panel : false) };


    //close add edit popup
    const handleClose = () => {
        setTemplateID(null)
        dispatch(updateReducerAction.update(templateConstant.UPDATE_DOCUMENT_DRAWER, false))
    };

    const onError =(err,e)=>{
        if(err && err["title"] && err["title"]["message"]){
            dispatch(alertAction.error(err["title"]["message"]))
        }
    }

    return (
        <React.Fragment>
            <Dialog className="paperBgGray" fullScreen open={open} onClose={handleClose} disableEnforceFocus={true}>
                <form onSubmit={handleSubmit(onSubmit,onError)} >
                    <Grid container className={`${classes.appTemplateDialog}`}>
                        <Grid container justify="space-between" className={`${classes.dialogHeader} ${"p-2 mb-2"}`} alignItems="center">
                            <Typography variant="h4" title={`${disclosure && disclosure.id ? 'EDIT' : 'ADD'} DOCUMENT`}></Typography>
                            <Grid>
                                <Button color="primary" type='submit' size="small" title={`${disclosure && disclosure.id ? 'Update' : 'Create'}`} className="mr-1"></Button>
                                <Button color="secondary" size="small" onClick={handleClose} title={templates_page_constants.CANCEL}></Button>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" className={`${classes.accordionContainer}`}>
                            <Grid className={classes.accordionConDiv}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions1-content"
                                        id="additional-actions1-header"
                                    >
                                        <Typography variant="h6">{templates_page_constants.GENERIC}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={8}>
                                                        <Typography variant={"body1"}>{templates_page_constants.TITLE}<span className={"reqStar"}>*</span></Typography>
                                                        <TextField
                                                            id={'title'}
                                                            name="title"
                                                            margin="none"
                                                            placeholder={'Title'}
                                                            error={errors.title ? true : false}
                                                            helperText={errors.title?.message}
                                                            inputRef={register({
                                                                validate: {
                                                                    ...validations.isRequired('Title cannot be blank.')
                                                                }
                                                            })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={"bgGray"}>
                                                        <Typography variant={"body1"}>{templates_page_constants.DOC_DESCRIPTION}</Typography>
                                                        <TextareaAutosize name={"description"} ref={(e) => { register(e) }} className={classes.allianceTextarea} aria-label="minimum height" rowsMin={5} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Controller
                                                            name="is_signature"
                                                            control={control}
                                                            render={props =>
                                                                <CheckBox
                                                                    label={"Signature"}
                                                                    checked={props.value}
                                                                    color="secondary"
                                                                    handleChange={(e) => { props.onChange(e.target.checked); }}
                                                                    inputRef={register()}
                                                                    {...props}
                                                                />
                                                            }
                                                        />
                                                        <Controller
                                                            name="is_authorization"
                                                            control={control}
                                                            render={props =>
                                                                <CheckBox
                                                                    label={templates_page_constants.DOC_AUTHORISATION}
                                                                    checked={props.value}
                                                                    color="secondary"
                                                                    handleChange={(e) => { props.onChange(e.target.checked); }}
                                                                    inputRef={register()}
                                                                    {...props}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={"bgGray"}>
                                                        <Typography variant={"body1"}>{templates_page_constants.DOC_CONDITION}</Typography>
                                                        {/* <TextareaAutosize name={"conditions"} ref={(e) => { register(e) }} className={classes.allianceTextarea} aria-label="minimum height" rowsMin={5} /> */}
                                                        <Controller
                                                            name={"conditions"}
                                                            control={control}
                                                            render={props =>
                                                                <TextAreaWithLines
                                                                    minRow="5"
                                                                    onChange={e => props.onChange(e.target.value)}
                                                                    value={props.value}

                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions2-content"
                                        id="additional-actions2-header"
                                    >
                                        <Typography variant="h6" title={templates_page_constants.CONTENT}></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12} className={"bgGray"}>
                                            <RichTextEdit
                                                value={contentText ? contentText : ""}
                                                onChange={(v) => { setRichText(v) }}
                                            />
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions3-content"
                                        id="additional-actions3-header"
                                    >
                                        <Typography variant="h6" title={templates_page_constants.LAYOUT}></Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12} className={"bgGray"}>
                                            <Controller
                                                name="is_visible"
                                                control={control}
                                                render={props =>
                                                    <CheckBox
                                                        label={'Show on document page'}
                                                        checked={props.value}
                                                        color="secondary"
                                                        handleChange={(e) => { props.onChange(e.target.checked); }}
                                                        inputRef={register()}
                                                        {...props}
                                                    />
                                                }
                                            />
                                            <Typography variant={"body1"} className="pb5">{"Layout"}<Info>
                                                <p dangerouslySetInnerHTML={{ __html: `${templates_page_constants.LAYOUT_TEXT}` }} className={`${classes.borderline} pb-1`}></p>
                                            </Info></Typography>

                                            {/* Textarea with Line Number */}
                                            <Controller
                                                name="layout"
                                                control={control}
                                                render={props =>
                                                    <TextAreaWithLines
                                                        onChange={e => props.onChange(e.target.value)}
                                                        value={props.value}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions3-content"
                                        id="additional-actions3-header"
                                    >
                                        <Typography variant="h6" title={templates_page_constants.CHOICES}></Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <AddChoices
                                            register={register}
                                            errors={errors}
                                            control={control}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>
        </React.Fragment>
    )
}
// default props
AddEditDocuments.defaultProps = {
    classes: {},
    setTemplateID: () => { },
    templateID: null
};

// prop types
AddEditDocuments.propTypes = {
    classes: PropTypes.object,
    setTemplateID: PropTypes.func,
    templateID: PropTypes.number
};

export default withStyles(TemplatesStyles)(AddEditDocuments);

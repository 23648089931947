import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames"
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';

//Styles
import ServicesStyles from "../ServicesStyles"

//images
import selectclientImg from '../../../assets/images/svg/selectclient.svg'

// Import Components
import { Typography, AutoComplete, Signature } from "../../../components";

// Import Cofig Constants
import { serviceConstant } from "../../../redux/constants";
import { user_page_constants } from "../../../config/constants";

// Import Actions
import { userAction, updateReducerAction } from "../../../redux/actions";

const AddClients = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    const { classes, clientId } = props;

    let { createFromScratch, createFromScratchErrorFlags: errorFlags } = useSelector((state) => state.service);

    const [client, setClient] = useState(createFromScratch.client_id);

    const { company: clientLists } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(userAction.getCompanies());

        //clear default client ID
        if(clientId === false && createFromScratch){
            createFromScratch.client_id = null
            setClient(null);
            dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));    
        }
    }, [dispatch]);

    // Set Form Values
    const { register, handleSubmit, errors, getValues } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
        }
    });

    // Submit Form
    const onSubmit = (data) => {
        data["client"] = client
    }

    // change compant Drop DN
    const handleChangeClient = (value) => {
        createFromScratch.client_id = (value && value.value) ? value.value : value
        setClient(value && value.value ? value.value : value);
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
    };

    //set client selected Dropown
    const getSelectedClientValue = () => {
        const valuee = clientLists.filter(i => (Number(i.value) === Number(client)))
        return (valuee[0] ? valuee[0] : { value: "", label: "" })
    }

    // const handleSignChange = (sign, bs64) => {
    //     createFromScratch.sign = sign
    //     createFromScratch.signImg = bs64
    //     dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
    // }

    return (

        <Grid item xs={12} className={classes.contentcontainer} >
            <Typography variant="h4" className="mt-3 mb-3" align="center">{user_page_constants.ADDCLIENT}</Typography>
            <Paper elevation={3} className={classes.cardSectionDialog} >
                <form onSubmit={handleSubmit(onSubmit)} noValidate id="clientForm" className={classes.addClientContainer}>
                    <Grid item sm={12} className="mb-2">
                        <Grid item sm={12} align="center">
                            <img src={selectclientImg} alt="client" className={"marginAuto"} />
                        </Grid>
                        <Grid item sm={12}>
                            <Grid item sm={6}>
                                <Typography variant="h4" align="center" className={"mt-1 mb-1"}>{user_page_constants.SEARCH_SELECT_CLIENT}</Typography>
                                <FormControl variant="outlined" className={classNames(classes.formControl, classes.selectBox)}>
                                    <AutoComplete
                                        name={'client-sel'}
                                        suggestions={clientLists}
                                        handleAutoComplete={(newValue, name) => { handleChangeClient(newValue) }}
                                        value={getSelectedClientValue()}
                                        showValidate={errorFlags.client_id ? true : false}
                                        validationMsg={errorFlags.client_id ? "This field is required" : ""}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {/* <Grid item sm={6} align="left">
                            <Grid item sm={11}>
                                <Typography variant="body1">{user_page_constants.SEARCH_SELECT_CLIENT}</Typography>
                                    <Signature 
                                        name = {"sign"}
                                        classes= {classes}
                                        sigPad = {createFromScratch.sign? createFromScratch.sign: {}}
                                        signImg = {createFromScratch.signImg? createFromScratch.signImg:null}
                                        handleSignChange={handleSignChange}
                                    />
                            </Grid>
                        </Grid> */}
                    </Grid>
                </form>
            </Paper>
        </Grid>

    );
}

// default props
AddClients.defaultProps = {
    classes: {}
};

// prop types
AddClients.propTypes = {
    classes: PropTypes.object
};

export default withStyles(ServicesStyles)(AddClients);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import classNames from "classnames"
import { Chip, FormControl, Grid, withStyles, Paper, IconButton } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';

//Import styles and Images
import { AllianceAMaskIcon, DeleteIcon } from "../../../../assets/svg"
import UsersStyles from "../../UsersStyles"

// Import Components
import { Button, TextField, Typography, CheckBox, Select, AutoComplete, ToolTip } from "../../../../components";

// Import Helpers
import validations from "../../../../utils/validations";
import { isEmail } from '../../../../utils'
import { changeDetection } from "./validation"

// Import Actions
import { userAction, alertAction, authAction, updateReducerAction } from "../../../../redux/actions";

// Import Cofig Constants
import { user_page_constants } from "../../../../config/constants";
import { serviceConstant } from "../../../../redux/constants";

const GeneralTab = (props) => {
    // Define Dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, match } = props;

    //get reducerData
    const { currentUser, company: clientCompanies, getUser: userData, region: stateOptions } = useSelector(state => state.user);

    //set edit flag true for new user
    const isEdit = match.params && match.params.id ? true : false;

    //default values initialisation 
    let roleOptions = [
        { "value": "3", "label": "Admin" },
        { "value": "1", "label": "Client" },
        { "value": "2", "label": "Manager" }
    ];
    //remove admin option for clients
    if (currentUser.role && (currentUser.role === "Client" || currentUser.role === "client")) {
        roleOptions = [
            { "value": "2", "label": "Manager" }
        ];
    }

    let statusOptions = [
        { "value": "1", "label": "Active" },
        { "value": "0", "label": "In-Active" }
    ];
    let commonOptions = [
        { "value": "1", "label": "Yes" },
        { "value": "0", "label": "No" }
    ];

    let defaultFormVal = {
        full_name: "",
        company: "",
        email: "",
        phone: "",
        code: "",
        salesrate: "",
        address: "",
        zipcode: "",
        role: "",
        state: "",
        city: "",
        sendPwd: false,
        username: "",
        password: "",
        groupings: "",
        notification: "",
    }
    //edit user
    if (isEdit && Object.keys(userData).length > 0) {
        defaultFormVal = {
            full_name: userData.full_name ? userData.full_name : '',
            company: userData.company ? userData.company : '',
            email: userData.email ? userData.email : '',
            phone: userData.phone ? userData.phone : '',
            code: userData.code ? userData.code : '',
            salesrate: userData.salesrate ? userData.salesrate : '',
            address: userData.street ? userData.street : '',
            zipcode: userData.post_code ? userData.post_code : '',
            role: userData.ownership_id ? userData.ownership_id.toString() : '',
            state: userData.region_id ? userData.region_id.toString() : '',
            city: userData.city ? userData.city : '',
            sendPwd: userData.sendPwd === true ? true : false,
            username: userData.username ? userData.username : "",
            password: userData.password ? userData.password : "",
            groupings: userData.groupings ? userData.groupings : "",
            notification: userData.notification ? userData.notification : "",
        }
    }
    // Set Form default values
    const { register, handleSubmit, errors, clearErrors, getValues, reset, setValue, watch } = useForm({
        mode: 'all',
        reValidateMode: 'all',
        defaultValues: defaultFormVal
    });

    useEffect(() => {
        //get client companies and regions
        dispatch(userAction.getCompanies());
        dispatch(userAction.getRegion());
        dispatch(updateReducerAction.update(serviceConstant.CHECKBOX_REDUCER_UPDATE, {}, () => { }));
        //get user seta
        if (isEdit && match.params.id && Object.keys(userData).length === 0) {
            dispatch(userAction.getUser({ id: match.params.id }))
        }
    }, [dispatch]);

    //set state for dropdowns
    const [role, setRole] = useState(isEdit && userData.ownership_id ? userData.ownership_id.toString() : "0");
    const [activeStatus, setStatus] = useState(isEdit && userData.is_active >= 0 ? userData.is_active.toString() : "1");
    const [activeCommon, setCommon] = useState(isEdit && userData.is_common >= 0 ? userData.is_common.toString() : "0");
    const [activeStateField, setActiveState] = useState(isEdit && userData.region_id ? userData.region_id.toString() : "0");
    const [companySel, setCompanySel] = useState(isEdit && userData.client_id ? userData.client_id.toString() : "");
    const [sendPwd, setSendPwd] = useState(false);
    const [doNotUse, setDoNotUse] = useState(isEdit && Number(userData.useLogo) > 0 ? true : false);
    const [userImg, setUserImg] = useState(userData.userImg ? userData.userImg : "");
    const [showEmail, setShowEmail] = useState(isEdit && userData.ownership_id === 2 ? userData.email === '' ? false : true : false);
    let [notiArr, setNotiArr] = useState(isEdit && userData.notification ? userData.notification.split('\n') : [])
    const [showValidate, setShowValidate] = useState(false);
    const [showValidate1, setShowValidate1] = useState(false);
    const [fName, setFname] = useState(userData.first_name ? userData.first_name : "");
    const [lName, setLname] = useState(userData.last_name ? userData.last_name : "");

    //update role
    useEffect(() => {
        if (role !== "2" && currentUser.role && (currentUser.role === "Client" || currentUser.role === "client")) {
            setRole("2")
            setCompanySel((currentUser.role && (currentUser.role === "Client" || currentUser.role === "client")) && clientCompanies && clientCompanies.length === 1 ? clientCompanies[0]['value'] : '')
        }
    }, [currentUser, clientCompanies])

    useEffect(() => {
        if (clientCompanies && clientCompanies.length === 1) {
            setCompanySel(clientCompanies[0]['value'])
        }
    }, [clientCompanies])

    // Handle Role function
    const handleChangeRole = (event) => {
        setRole(event.value);
        setStatus('1');
        setCommon('0');
        setActiveState('0');
        setCompanySel((currentUser.role && (currentUser.role === "Client" || currentUser.role === "client")) && clientCompanies && clientCompanies.length === 1 ? clientCompanies[0]['value'] : '')
        setSendPwd(false)
        setShowValidate(false)
        setShowValidate1(false)
        setNotiArr([])
        // reset()
        if (event.value === "3") {
            setTimeout(() => {
                setValue("company", "Alliance");
                clearErrors('company')
            }, 100);
        } else {
            let cmny = getValues("company")
            if (cmny === "Alliance") {
                setValue("company", "")
            }
        }
    };

    // Handle Status function
    const handleChangeStatus = (event) => {
        setStatus(event.value);
    };

    // Handle Common function
    const handleChangeCommon = (event) => {
        setCommon(event.value);
    };

    // Handle State function
    const handleChangeState = (value) => {
        setActiveState(value && value.value ? value.value : value);
    };

    // Check / Uncheck Send Password Checkbox
    const handleChangeCheckbox = (event) => {
        setSendPwd(event.target.checked);
    };

    // change compant Drop DN
    const handleChangeCompany = (value) => {
        setCompanySel(value && value.value ? value.value : value);
        setShowValidate(value && value.value ? false : true)
    };

    // Check / Uncheck Send Password Checkbox
    const handleChangeDoNotUse = (event) => {
        setDoNotUse(event.target.checked);
    };

    //add emailIDs
    const handleAddChip = (chip) => {
        let tempArr = [...notiArr, chip]
        tempArr = tempArr.filter(i => (i !== ''))
        setNotiArr(tempArr)
    }

    const getEmailChips = (emailArr) => {
        let resultEmails = [];
        emailArr.map((i) => {
            if (i) {
                if (isEmail(i.trim())) {
                    resultEmails.push({ email: i.trim().toLowerCase(), isValid: true });
                } else {
                    resultEmails.push({ email: i.trim().toLowerCase(), isValid: false });
                }
            }
        });
        return resultEmails;
    }

    //delete email Ids
    const handleDeleteChip = (chip, index) => {
        let tempArr = [...notiArr]
        tempArr = tempArr.filter(i => (i !== ''))
        tempArr.splice(index, 1)
        setNotiArr(tempArr)
    }

    // Submit Form
    const onSubmit = (data) => {

        data['role'] = role;
        data['is_common'] = activeCommon;
        data['region_id'] = activeStateField;
        data['is_active'] = activeStatus;
        data['client'] = companySel;
        data['userImg'] = userImg;
        data['sendPwd'] = sendPwd;
        data['useLogo'] = doNotUse ? 1 : 0;
        data['showEmail'] = showEmail;
        data['last_name'] = lName;
        data['first_name'] = fName;

        ///check invalid email
        const notiArray = getEmailChips(notiArr);
        const emailFail = notiArray.filter(val => val.isValid === false)

        if (role === '0') {
            dispatch(alertAction.error("Please select a Role"));
        } else if (role === '2' && (companySel === '' || fName === '')) {
            if (fName === '') {
                setShowValidate1(true)
            } else {
                setShowValidate(true)
            }
        } else if (role === '2' && notiArr && notiArr.length > 0 && emailFail.length > 0) {
            dispatch(alertAction.error("Invalid notification email"));

        } else if (data["phone"] && data["phone"].length > 25) {
            dispatch(alertAction.error("Phone length should be less than 25"));
        } else if (data["code"] && data["code"].length > 50) {
            dispatch(alertAction.error("Code length should be less than 50"));
        } else if (data["salesrate"] && data["salesrate"].length > 50) {
            dispatch(alertAction.error("Sales rate length should be less than 50"));
        } else if (data["company"] && data["company"].length > 150) {
            dispatch(alertAction.error("Company length should be less than 150"));
        } else if (data["full_name"] && data["full_name"].length > 150) {
            dispatch(alertAction.error("Full name length should be less than 150"));
        } else if ((data["first_name"] && data["first_name"].length > 50) || (data["last_name"] && data["last_name"].length > 50)) {
            dispatch(alertAction.error("First name and Last name length should be less than 50"));
        } else if (data["username"] && data["username"].length > 50) {
            dispatch(alertAction.error("User name length should be less than 50"));
        } else if (data["password"] && data["password"].length > 50) {
            dispatch(alertAction.error("Password length should be less than 50"));
        } else if (data["groupings"] && data["groupings"].length > 100) {
            dispatch(alertAction.error("Groupings length should be less than 100"));
        } else if (data["email"] && data["email"].length > 255) {
            dispatch(alertAction.error("Email length should be less than 255"));
        } else if (data["address"] && data["address"].length > 50) {
            dispatch(alertAction.error("Street length should be less than 50"));
        } else if (data["city"] && data["city"].length > 50) {
            dispatch(alertAction.error("City length should be less than 50"));
        } else if (data["zipcode"] && data["zipcode"].length > 15) {
            dispatch(alertAction.error("Postal Code length should be less than 15"));

        } else {

            if (role === '2') {
                data['notification'] = notiArr && notiArr.length ? notiArr.join('\n') : ''
            }
            if (isEdit === true) {
                //edit users
                dispatch(userAction.updateUser({ data: data, id: match.params.id, oldData: userData || {} }, () => {
                    setUserImg("")
                    dispatch(userAction.getUser({ id: match.params.id }))
                    dispatch(authAction.getProfileLogo())
                }));
            } else {
                //add new users
                if (role === '1') {
                    dispatch(userAction.createUser(data, (response) => {
                        if (response && response.data && response.data.id) {
                            props.history.push({ pathname: `/users/edit/${response.data.id}` });
                        }
                        dispatch(userAction.getCompanies());
                    }));
                } else {
                    dispatch(userAction.createUser(data, (response) => {
                        if (response && response.data && response.data.id) {
                            props.history.push({ pathname: `/users/edit/${response.data.id}` });
                        }
                    }))
                }
            }
        }
    }

    /**
   *Handle Cancel Navigage to Tab based on Roles
   */
    const handleCancel = () => {
        props.history.push('/users?page=true');
    };

    //handle image upload
    const imageHandler = (e) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    // console.log(reader.result)
                    setUserImg(reader.result)
                }
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    //set company selected Dropown
    const getSelectedValue = (arr, val) => {
        const valuee = arr.filter(i => (i.value === val))
        return (valuee[0] ? valuee[0] : { value: "", label: "" })
    }

    //prevent form submit on enter click
    const checkKeyDown = (e) => {
        if (e.code === 'Enter') e.preventDefault();
    };

    let editedData = true;
    if (isEdit) {
        let newData = watch()
        newData = { ...newData, is_common: activeCommon, first_name: fName, last_name: lName, showEmail: showEmail, notification: notiArr, is_active: activeStatus, region_id: activeStateField, useLogo: doNotUse, userImg: userImg }
        editedData = changeDetection(userData, newData);
    }

    return (
        <Grid container alignItems="flex-start" spacing={3} wrap="nowrap">
            <Grid item>
                <Paper elevation={3} className={`${"relative"} ${classes.cardSectionProfileImg}`} >
                    <Grid className={classes.profileImgContainer}>
                        {userImg ? <IconButton className={'closeIconBtn'} onClick={() => { setUserImg("") }}><DeleteIcon /></IconButton> : ''}
                        <label htmlFor="file-input">
                            {userImg ? <img src={userImg} className={classes.uploadImgPopup} alt={"img"} /> : <AllianceAMaskIcon />}
                            <input className={`${classes.uploadImgPopup} ${classes.uploadImgOpacity}`} id="file-input" onChange={(e) => { imageHandler(e) }} type="file" accept=".png,.jpeg,.jpg" style={{ display: "none" }} value='' />
                            {!userImg ? <Grid className={classes.uploadText} >Upload image</Grid> : ''}
                        </label>
                    </Grid>
                </Paper>
                {userImg ? <Grid className={classes.userProfileDoNot}>
                    <CheckBox
                        label="Use current"
                        checked={doNotUse}
                        color="secondary"
                        handleChange={(w) => { handleChangeDoNotUse(w) }} />
                </Grid> : ''}
            </Grid>
            <Grid item className={classes.grow}>
                <Paper elevation={3} className={`${"pt-3 pb-3"} ${classes.usersCardSection}`} >
                    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)} noValidate>
                        <Grid container spacing={3}>

                            <Grid item xs={12} className={classes.titleContainer}>
                                <Typography variant="h6" className={classes.groupTitle}>{"Generic"}</Typography>
                            </Grid>


                            <Grid item xs={4} align="center">
                                <Grid item xs={10}>
                                    <Typography variant={"body1"} className={"textFieldLabel "}>{user_page_constants.ROLE}</Typography>
                                    <FormControl variant="outlined" className={classNames(classes.formControl, classes.selectBox)}>
                                        <Select
                                            options={roleOptions}
                                            handleChange={(e) => handleChangeRole(e)}
                                            value={role}
                                            disabled={isEdit ? true : false}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired(),
                                                }
                                            })}
                                        >
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {role === "2" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.CLIENT_NAME}<span className={"reqStar"}>*</span></Typography>
                                        <AutoComplete
                                            name={'cliet-sel'}
                                            showValidate={showValidate}
                                            validationMsg={"This Field is Required"}
                                            suggestions={clientCompanies}
                                            disabled={isEdit || (currentUser.role && (currentUser.role === "Clijent" || currentUser.role === "cliednt")) ? true : false}
                                            handleAutoComplete={(newValue, name) => { handleChangeCompany(newValue) }}
                                            value={getSelectedValue(clientCompanies, companySel)}
                                            className={isEdit || (currentUser.role && (currentUser.role === "Client" || currentUser.role === "client")) ? "displayNone" : ""}
                                        />
                                    </Grid>
                                </Grid>
                            }

                            {(role === "0" || role === "1" || role === "3") &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.COMPANY_NAME}<span className={"reqStar"}>*</span></Typography>
                                        <TextField
                                            id={'company'}
                                            name="company"
                                            size={"small"}
                                            margin={"none"}
                                            disabled={role === "3" ? true : false}
                                            error={errors.company ? true : false}
                                            helperText={errors.company?.message}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired(),
                                                }
                                            })}
                                        />
                                    </Grid>
                                </Grid>
                            }

                            {(role === '1' || role === '3') &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.FULLNAME}<span className={"reqStar"}>*</span></Typography>
                                        <TextField
                                            id={"full_name"}
                                            name={"full_name"}
                                            size={"small"}
                                            margin={"none"}
                                            error={errors.full_name ? true : false}
                                            helperText={errors.full_name?.message}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired(),
                                                }
                                            })}
                                        />
                                    </Grid>
                                </Grid>
                            }

                            {role === "2" &&
                                <React.Fragment>
                                    <Grid item xs={4} align="center">
                                        <Grid item xs={10}>
                                            <Typography variant={"body1"} className={"textFieldLabel"}>{"First Name"}<span className={"reqStar"}>*</span></Typography>
                                            <TextField
                                                id={"first_name"}
                                                name={"first_name"}
                                                size={"small"}
                                                margin={"none"}
                                                value={fName}
                                                onChange={(e) => { setFname(e.target.value); setShowValidate1(false) }}
                                                error={showValidate1}
                                                helperText={showValidate1 ? "This Field is Required" : ""}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} align="center">
                                        <Grid item xs={10}>
                                            <Typography variant={"body1"} className={"textFieldLabel"}>{"Last Name"}</Typography>
                                            <TextField
                                                id={"last_name"}
                                                name={"last_name"}
                                                size={"small"}
                                                margin={"none"}
                                                value={lName}
                                                onChange={(e) => { setLname(e.target.value) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }

                            {(role === '1' || role === '3') &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.EMAIL}<span className={"reqStar"}>*</span></Typography>
                                        <ToolTip title={`${isEdit ? '' : 'Password link will be sent to specified email'}`} placement={'top'}>
                                            <TextField
                                                id={'email'}
                                                name="email"
                                                size={"small"}
                                                margin={"none"}
                                                error={errors.email ? true : false}
                                                helperText={errors.email?.message}
                                                inputRef={register({
                                                    validate: {
                                                        ...validations.isRequired(),
                                                        ...validations.isEmail()
                                                    }
                                                })}
                                            />
                                        </ToolTip>
                                    </Grid>
                                </Grid>
                            }

                            <Grid item xs={4} align="center">
                                <Grid item xs={10}>
                                    <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.PHONE}<span className={"reqStar"}>*</span></Typography>
                                    <TextField
                                        id={"phone"}
                                        name={"phone"}
                                        size={"small"}
                                        margin={"none"}
                                        error={errors.phone ? true : false}
                                        helperText={errors.phone?.message}
                                        inputRef={
                                            role === '2' ?
                                                register() :
                                                register({
                                                    validate: {
                                                        ...validations.isRequired(),
                                                    }
                                                }
                                                )}
                                    ></TextField>
                                </Grid>
                            </Grid>

                            {role === "1" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.CODE}<span className={"reqStar"}>*</span></Typography>
                                        <TextField
                                            id={"code"}
                                            name={"code"}
                                            size={"small"}
                                            margin={"none"}
                                            error={errors.code ? true : false}
                                            helperText={errors.code?.message}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired(),
                                                }
                                            })}
                                        ></TextField>
                                    </Grid>
                                </Grid>
                            }

                            {role === "1" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.SALESRATE}</Typography>
                                        <TextField
                                            id={"salesrate"}
                                            name={"salesrate"}
                                            size={"small"}
                                            margin={"none"}
                                            inputRef={register()}
                                        ></TextField>
                                    </Grid>
                                </Grid>
                            }

                            {role !== "0" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.STATUS}</Typography>
                                        <FormControl variant="outlined" className={classNames(classes.formControl, classes.selectBox)}>
                                            <Select
                                                options={statusOptions}
                                                handleChange={(e) => handleChangeStatus(e)}
                                                value={activeStatus}
                                                inputRef={register()}
                                            >
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }


                            {role === "1" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.COMMON}</Typography>
                                        <FormControl variant="outlined" className={classNames(classes.formControl, classes.selectBox)}>
                                            <Select
                                                options={commonOptions}
                                                inputRef={register()}
                                                handleChange={(e) => handleChangeCommon(e)}
                                                value={activeCommon}
                                            >
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }
                            {(role === "1" && !isEdit) &&
                                <Grid item xs={4} align="center" >
                                    <Grid item xs={10}>
                                        <Grid container justify="flex-start">
                                            <CheckBox
                                                id={'sendPwd'}
                                                name={"sendPwd"}
                                                color="secondary"
                                                label={user_page_constants.SENDPASSWORD}
                                                checked={sendPwd}
                                                inputRef={register()}
                                                handleChange={(e) => handleChangeCheckbox(e)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {/* {role === '2' && <Grid item xs={4} align="center"></Grid>} */}
                            {role === "2" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10} className="mt-2">
                                        <Grid container justify="flex-start">
                                            <CheckBox
                                                id={'showEmail'}
                                                name={"showEmail"}
                                                color="secondary"
                                                label={"Account Login"}
                                                checked={showEmail}
                                                inputRef={register()}
                                                handleChange={(e) => setShowEmail(!showEmail)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                            {(role === '2' && showEmail) &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.EMAIL}<span className={"reqStar"}>*</span></Typography>
                                        <ToolTip title={`${isEdit ? '' : 'Password link will be sent to specified email'}`} placement={'top'}>
                                            <TextField
                                                id={'email'}
                                                name="email"
                                                size={"small"}
                                                margin={"none"}
                                                error={errors.email ? true : false}
                                                helperText={errors.email?.message}
                                                inputRef={register({
                                                    validate: {
                                                        ...validations.isRequired(),
                                                        ...validations.isEmail()
                                                    }
                                                })}
                                            />
                                        </ToolTip>
                                    </Grid>
                                </Grid>
                            }

                            {role === "2" &&
                                <Grid item xs={12} className={classes.titleContainer}>
                                    <Typography variant="h6" className={classes.groupTitle}>{"Screening"}</Typography>
                                </Grid>
                            }

                            {role === "2" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.USER_NAME}<span className={"reqStar"}>*</span></Typography>
                                        <TextField
                                            id={"username"}
                                            error={errors.username ? true : false}
                                            helperText={errors.username?.message}
                                            name={"username"}
                                            size={"small"}
                                            margin={"none"}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired(),
                                                }
                                            })}
                                        ></TextField>
                                    </Grid>
                                </Grid>
                            }
                            {role === "2" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.PWWD}<span className={"reqStar"}>*</span></Typography>
                                        <TextField
                                            id={"password"}
                                            name={"password"}
                                            error={errors.password ? true : false}
                                            helperText={errors.password?.message}
                                            size={"small"}
                                            margin={"none"}
                                            inputRef={register({
                                                validate: {
                                                    ...validations.isRequired(),
                                                }
                                            })}
                                        ></TextField>
                                    </Grid>
                                </Grid>
                            }

                            {role === "2" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.GRPING}</Typography>
                                        <TextField
                                            id={"groupings"}
                                            name={"groupings"}
                                            size={"small"}
                                            margin={"none"}
                                            inputRef={register()}
                                        ></TextField>
                                    </Grid>
                                </Grid>
                            }

                            {role === "2" &&
                                <Grid item xs={12} style={{ paddingLeft: '3.3%', paddingRight: '3.3%' }}>
                                    <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.NOTIFI}</Typography>
                                    <ChipInput
                                        className={`${classes.chipInput} ${classes.frequencyWidth} chipboderRadius10`}
                                        variant="outlined"
                                        color="primary"
                                        value={getEmailChips(notiArr) || []}
                                        onBlur={(chip) => handleAddChip(chip.target.value)}
                                        onAdd={(chip) => handleAddChip(chip)}
                                        onDelete={(chip, index) => handleDeleteChip(chip, index)}
                                        dataSource={getEmailChips(notiArr) || []}
                                        chipRenderer={({ value, handleDelete, className }, key) => {
                                            return (
                                                <Chip
                                                    key={key}
                                                    label={value.email}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onDelete={handleDelete}
                                                    className={value.isValid ? "" : `${classes.errorChipReport}`}
                                                />
                                            )
                                        }}
                                    />
                                </Grid>
                            }


                            {role === "1" &&
                                <Grid item xs={12} className={classes.titleContainer}>
                                    <Typography variant="h6" className={classes.groupTitle}>{"Address"}</Typography>
                                </Grid>
                            }


                            {role === "1" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.STREET}</Typography>
                                        <TextField
                                            id={"address"}
                                            name={"address"}
                                            size={"small"}
                                            margin={"none"}
                                            inputRef={register()}
                                        ></TextField>
                                    </Grid>
                                </Grid>
                            }
                            {role === "1" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.CITY}</Typography>
                                        <TextField
                                            id={"city"}
                                            name={"city"}
                                            size={"small"}
                                            margin={"none"}
                                            inputRef={register()}
                                        ></TextField>
                                    </Grid>
                                </Grid>
                            }
                            {role === "1" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.STATE}</Typography>
                                        <AutoComplete
                                            name={'selState'}
                                            suggestions={stateOptions}
                                            disabled={false}
                                            handleAutoComplete={(newValue, name) => { handleChangeState(newValue) }}
                                            value={getSelectedValue(stateOptions, activeStateField)}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {role === "1" &&
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.ZIPCODE}</Typography>
                                        <TextField
                                            id={"zipcode"}
                                            name={"zipcode"}
                                            size={"small"}
                                            margin={"none"}
                                            inputRef={register()}
                                        ></TextField>
                                    </Grid>
                                </Grid>
                            }


                        </Grid>
                        <Grid container spacing={5} className={`${classes.addUSerAction} ${"mt-1"}`}>
                            <Grid item xs={12} className="">
                                <Grid item xs={4} align="left">
                                    <Button color="primary" disabled={(!isEdit || (isEdit && editedData)) ? false : true} size="small" title={isEdit ? "Update" : "Create"} className="mr-1" type="submit" ></Button>
                                    <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={handleCancel} ></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

// default props
GeneralTab.defaultProps = {
    classes: {}
};

// prop types
GeneralTab.propTypes = {
    classes: PropTypes.object
};

export default withStyles(UsersStyles)(GeneralTab);

import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment'
import { useDispatch } from "react-redux";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PasscodeIcon } from "../../assets/svg";

import { Button, TextField } from "../../components";
// Import History
import history from "../../config/history";

import { alertAction, tenantAppAction } from "../../redux/actions";

function Tenant() {
    //define dispatch
    const dispatch = useDispatch();

    const path = history.location && history.location.pathname ? history.location.pathname : ""
    //set state
    const [passCode, setPassCode] = useState("");

    //update  state
    const updatePassCode = (val) => {
        val = val ? val.trim() : "";
        setPassCode(val);
    }

    useEffect(()=>{
        setPassCode("")
    },[path])

    //get page form
    const getPage = () => {
        if (passCode) {
            dispatch(tenantAppAction.checkPasscodeData({ passCode: passCode }, (resp) => {
                if (resp && resp.data && resp.data.isValid === true) {
                    history.push(`/a/${passCode}`)
                } else {
                    dispatch(alertAction.error("Please Enter Valid Passcode"))
                }
            }))
        } else {
            dispatch(alertAction.error("Please Enter Passcode"))
        }
    }

    return (
        <Grid container justify={"center"}>
            <Typography variant="h2" className="mb-2 txtBold">
                {path.indexOf("employment") > -1 ? "Background Questionnaire" : "Tenant Application"}
            </Typography>
            <Grid container alignItems="center" style={{ padding: "40px 40px 34px 40px",justifyContent: "center" }}>
                <Typography variant={"subtitle1"} className="txtBold">
                    {"Submit the passcode provided to start an application."}
                </Typography>
                <TextField
                    style={{width:"70%"}}
                    value={passCode || ""}
                    onChange={(e) => { updatePassCode(e.target.value) }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><PasscodeIcon/></InputAdornment>,
                    }}
                />
            </Grid>
            <Grid style={{ textAlign: "center", width: "100%" }}>
                <Button color={"primary"} onClick={() => { getPage() }}>Submit</Button>
            </Grid>
        </Grid>
    );
}

// default props
Tenant.defaultProps = {
    classes: {},
    match: {},
    user: {},
};

// prop types
Tenant.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
};


export default Tenant;
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { SmoothProvider } from 'react-smooth-scrolling'


// Import Material Theme
import theme from "../assets/theme";

// Import Routes
import Routes from "../routes";

// Import Components
import { SnackBar, NetworkDetector, PageLoader } from "../components";

function App() {
  return (
    <React.StrictMode>
      <CssBaseline />
      <ThemeProvider theme={theme}>
          <Routes />
      </ThemeProvider>
      <SnackBar />
      <PageLoader />
    </React.StrictMode>
  );
}

export default App;

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker as DatePicker } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Icon } from "@material-ui/core";

import Styles from './Styles'

// Import Images
import CalendarIcon from "../../assets/images/svg/calendar.svg";

/**
 *DatePicker Component
 *
 * @class DatePicker
 * @extends {Component}
 */
function DatePickerComponent (props) {
  /**
     *render html
     *
     * @returns
     * @memberof DatePicker
     */
  const { classes, value,error, name,mask,placeholder,disableFuture,disablePast, fullWidth, label, disabled, size, className, validators, errorMessages, validations, onBlur, onOpen, onClose, format, style, helperText, notRequired, panel } = props;
  const customClass = validators && validators.length > 0 && !notRequired ? className + " required" : className;
  // const warningClass = hasWarnings(fieldErrors, warningFieldName);

    // Handle TextField Events
    const handleChange = (event, value) => {
      if (event) {
        let d = event._d;

        if(d != "Invalid Date"){
          if(format==="MM/YY" || format==="MM/YYYY"){
            d = new Date(new Date(d).setDate(15))
          }else{
            d = new Date(new Date(d).setHours(13,0,0,0))
          }
        }
        
        // let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        props.handleChange({ name: props.name, value: d });
      } else {
        props.handleChange({ name: props.name, value: null });
      }
    };

  return (
    <DatePicker
      label={label}
      name={name}
      value={value || null}
      onChange={handleChange}
      fullWidth={fullWidth}
      format={format}
      inputVariant="outlined"
      placeholder={placeholder}
      mask={mask}
      disableFuture={disableFuture}
      disablePast={disablePast}
      maskChar="_"
      className={`${customClass} date-picker-custom importField ${error ? classes.borderColorRed : ""}`}
      disabled={disabled}
      InputProps={
        {
          endAdornment: (
            <InputAdornment position="end" style={{ cursor: "pointer" }}>
              <Icon position="end" style={{padding: "5px"}}>
                <img src={CalendarIcon} alt="calender" style={{ height: "16px", weight: "16px", position: "relative", top: "3px", left: "5px" }} />
              </Icon>
            </InputAdornment>
          )
        }
      }
      invalidDateMessage={errorMessages}
      validators={validators}
      errorMessages={errorMessages}
      onBlur={onBlur}
      onOpen={onOpen}
      onClose={onClose}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          ev.preventDefault();
        }
      }}
      style={style}
      size={size}
      {...validations}
      panel={panel}
      helperText={helperText}
      KeyboardButtonProps={{
        "aria-label": "change date"
      }}
      autoComplete= "off"
    />
  );
}

// default props
DatePickerComponent.defaultProps = {
  name: "",
  value: "",
  label: "",
  fullWidth: "",
  disabled: false,
  className: "cursor-pointer",
  errorMessages: [],
  validators: [],
  onOpen: ()=>{},
  onClose: ()=>{},
  handleChange: () => { },
  validations: {},
  onBlur: () => { },
  format: "MM/DD/YYYY",
  fieldErrors: [],
  warningFieldName: "",
  style: {},
  helperText: "",
  notRequired: false,
  inputVariant: "outlined",
  size: "small",
  placeholder:"__/__/____",
  mask:"__/__/____",
  disableFuture:false,
  disablePast:false,
  error:false,
  classes:{}
};

// prop types
DatePickerComponent.propTypes = {
  name: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  // margin: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.array,
  validators: PropTypes.array,
  helperText: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  placeholder:PropTypes.string,
  mask:PropTypes.string,
  disableFuture:PropTypes.bool,
  disablePast:PropTypes.bool,
  error:PropTypes.bool,
  classes:PropTypes.object,
};

// Export Component
export const DatePickr = withStyles(Styles)(DatePickerComponent);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import { Grid,Backdrop, IconButton, Drawer, withStyles } from '@material-ui/core';

//Styles and Images
import TemplatesStyles from "../TemplatesStyles"
import { CloseIcon } from '../../../assets/svg'

// Import Components
import { Button, TextField, Typography, AutoComplete } from "../../../components";
import AgreementContainer from "./AgreementContainer";

// Import Cofig Constants
import { templates_page_constants } from "../../../config/constants";

//import constant
import { templateConstant } from '../../../redux/constants'

// Import Helpers
import validations from "../../../utils/validations";

// Import Actions
import { updateReducerAction,templateAction, alertAction } from "../../../redux/actions";

const AddEditAgreement = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes } = props;

    //get reduer data
    const { agreementDrawer:drawer,agreementOld,agreement:{result,nameData},agreementTemplate:{page}, disclosureName } = useSelector(s=>(s.template));

    //set state
    const [updateData, setUpdateData] = useState("");

    //open close drawer
    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        dispatch(updateReducerAction.update(templateConstant.UPDATE_AGREEMENT_DRAWER,open))
    };

    //update form data on change
    useEffect(() => {
        setValue("name", nameData && nameData.name ? nameData.name : '')
        clearErrors("name");
    }, [nameData]);

    //get disclosure names
    useEffect(() => {
        dispatch(templateAction.getDisclosureName())
    }, [dispatch]);

    // Set Form Values
    const {  register, handleSubmit, errors, setValue, clearErrors,watch } = useForm({
        mode: 'all',
        reValidateMode: 'all',
        defaultValues: {
            name: nameData.name ? nameData.name : '',
        }
    });

    //update name fields
    const watchName = watch("name");
    useEffect(()=>{
        dispatch(updateReducerAction.update(templateConstant.UPDATE_AGREEMENT_NAME, watchName))
    },[watchName])

    // Submit Form
    const onSubmit = (data) => {
        if(!nameData.name){
            dispatch(alertAction.error("Name cannot be blank."));
        }else if(result.length===0){
            dispatch(alertAction.error("Document cannot be blank."));
        } else if (nameData.name && nameData.name.length > 250) {
            dispatch(alertAction.error("Name length should be less than 250"));
        }else{
            data['nameData']=nameData
            data['result']=result
            dispatch(templateAction.addOrUpdateAgreement({data : data,oldData:agreementOld},()=>{
                dispatch(templateAction.getAllAgreement({ limit: 20, skip: 0, search: page.search, sort: page.sort }))
            }))
        }
    }

    //set company selected Dropown
    const getSelectedValue = (arr,val) => {
        const valuee = arr.filter(i => (i.value === val))
        return (valuee[0] ? valuee[0] : { value: "", label: "" })
    }

    // change aggrement template Drop DN
    const documentMap = (val) => {
        const { value,label }= val
        if(value && label){
            const isAlreadyIn=result.filter(v=>(Number(v.disclosure_id)===Number(value)))
            if(isAlreadyIn.length>0){
                dispatch(alertAction.error("Duplicate document configured using same document"));
                setUpdateData('')
            }else{
                const name =nameData && nameData['name'] ? nameData['name'] : null
                const agId =nameData && nameData['agreement_id'] ? nameData['agreement_id'] : null
                const position=result && result.length ? (result.length)+1 :1
                
                const updateeData=[...result,{id:agId?Number(agId):null,name:name,agreement_id:agId?Number(agId):null,disclosure_id:Number(value),position:position,title:label}]
                dispatch(updateReducerAction.update(templateConstant.UPDATE_AGREEMENT_ARR_DATA, updateeData))
            }
        }
    };

    //disclosureIds
    const getSuggestions = () =>{
        const dataId = result.map(i=>{return i["disclosure_id"]+""})
        const resultDocs = disclosureName.filter(d=>(dataId.indexOf(d["value"])===-1))
        return resultDocs
    }


    return (
        <React.Fragment>
            <Backdrop className={classes.backDrop} onClick={toggleDrawer(false)} open={drawer} />
            <Button color="primary" title={"+ ADD AGREEMENT"} onClick={toggleDrawer(true)}></Button>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Drawer anchor='right' role="presentation" variant="persistent" open={drawer} onClose={toggleDrawer(false)} className={classes.allianceDrawer600}>
                <Grid container alignItems="center" justify="space-between" className={"pl-2 pt-1 pr-2 pb-2"}>
                    <Typography variant="h4" title={nameData && nameData['agreement_id'] ? 'EDIT AGREEMENT' :'ADD AGREEMENT'}></Typography>
                    <IconButton onClick={toggleDrawer(false)}><CloseIcon /></IconButton>
                </Grid>
                <Grid container className={`${classes.rightPanelContainer} ${"pl-2 pr-2"}`}>
                        <Grid container className={classes.addAgreementForm} justify="space-between" spacing={3}>
                            <Grid item sm={12}>
                                <Typography variant="body1">{templates_page_constants.NAME}<span className={"reqStar"}>*</span></Typography>
                                <TextField
                                    id={'name'}
                                    name="name"
                                    margin="none"
                                    placeholder={'Name'}
                                    error={errors.name ? true : false}
                                    helperText={errors.name?.message}
                                    inputRef={register({
                                        validate: {
                                            ...validations.isRequired('Name cannot be blank.')
                                        }
                                    })}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Typography variant="body1">{templates_page_constants.SEARCH_ADD_CONTENT}</Typography>
                                <AutoComplete
                                    name={'selEmail'}
                                    suggestions={getSuggestions()}
                                    disabled={false}
                                    handleAutoComplete={(newValue, name) => { documentMap(newValue) }}
                                    value={{value:"",label:""}}
                                    hasValue={false}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                {result && result.length>0 && <AgreementContainer/>}
                            </Grid>
                        </Grid>
                </Grid>
                <Grid container className="pl-2 pb-2 pt-2 pr-2">
                    <Button color="primary"type="submit" size="small" title={nameData && nameData['agreement_id'] ? 'Update' :'Create'} className="mr-1"></Button>
                    <Button color="secondary" size="small" onClick={toggleDrawer(false)} title={templates_page_constants.CANCEL}></Button>
                </Grid>
            </Drawer>
            </form> 
        </React.Fragment>
    )
}
// default props
AddEditAgreement.defaultProps = {
    classes: {},
};

// prop types
AddEditAgreement.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(TemplatesStyles)(AddEditAgreement);

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { Provider } from "react-redux";

// To Suport IE 11
import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";

// Import Date Pickers Library
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// Import Css Files
import "./assets/css/index.css";
import "./assets/css/mediaquery.css";

// Import App File
import App from "./containers/App";
import reportWebVitals from './reportWebVitals';

// Import History
import history from "./config/history";

// Define Redux Store
import configureStore from "./redux/store";
export const store = configureStore();

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Provider store={store}>
      <Router history={history}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Router>
    </Provider>
  </MuiPickersUtilsProvider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

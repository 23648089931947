import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { IconButton, Drawer, Grid, withStyles } from '@material-ui/core';

//Styles and Images
import ApplicationStyles from "../ApplicationStyles"
import { CloseIcon } from '../../../assets/svg'

// Import Actions
import { applicationAction, alertAction } from "../../../redux/actions";

// Import Components
import { Button, Typography, AutoComplete, ToolTip, Link } from "../../../components";

const GetLink = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, getLinkPopup, setgetLinkPopup, appId } = props;

    //get reducer data
    const { common: { is_common, managerList }, appLink, managerName } = useSelector(s => (s.application))

    useEffect(() => {
        dispatch(applicationAction.getClientCommon({ 'id': appId }, () => {
            dispatch(applicationAction.getManagerName());
        }))
    }, [dispatch])

    //text select manager for common =0
    const [manager, setManager] = useState('')
    //manager dropdown for common =0
    const [managerDDN, setManagerDDN] = useState('')
    //validation message
    const [showValidation, setShowValidation] = useState(false)

    //mamager list
    const manSelList = managerList ? managerList : [];

    //open close drawer
    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setgetLinkPopup(open)
    };

    //set company selected Dropown
    const getSelectedValue = (arr, val) => {
        const valuee = arr.filter(i => (i.value === val))
        return (valuee[0] ? valuee[0] : { value: "", label: "" })
    }

    // change manager Drop DN
    const handleChangeManager = (value) => {
        setManagerDDN(value && value.value ? value.value : value);
    };

    // change manager Drop DN
    const handleChangeManager1 = (value) => {
        setManager(value && value.value ? value.value : value);
    };

    //filter options data
    const onFilterChange = (arr, val) => {
        const { inputValue: value } = val
        if (value) {
            const eValue = value.toLowerCase()
            let tempdata = managerName.filter(f => ((f["label"] && f["label"].toLowerCase().indexOf(eValue) > -1) || (f['email'] && f['email'].toLowerCase().indexOf(eValue) > -1) || (f['phone'] && f['phone'].toLowerCase().indexOf(eValue) > -1)))
            
            if (manSelList.length > 0) {
                tempdata = tempdata.filter(m => (manSelList.indexOf(m["label"]) > -1))
            }
            return tempdata
        } else {
            return manSelList && manSelList.length ? managerName.filter(ml => (manSelList.indexOf(ml["label"]) > -1)) : managerName
        }
    }

    //send invitaion
    const getLinks = () => {
        if ((!manager && !managerDDN)) {
            setShowValidation(true)
            dispatch(alertAction.error("Please select manager"));
        } else {
            setShowValidation(false)
            const data = {
                id: appId,
                manager: is_common ? manager : managerDDN,
            }
            dispatch(applicationAction.getLink(data));
        }
    }

    return (
        <React.Fragment>
            <Drawer anchor='right' role="presentation" variant="persistent" open={getLinkPopup} onClose={toggleDrawer(false)} className={classes.allianceDrawer600}>
                <Grid container alignItems="center" justify="space-between" className={"pl-2 pt-1 pr-2 pb-2"}>
                    <Typography variant="h4" title={`Get Link`}></Typography>
                    <IconButton onClick={toggleDrawer(false)}><CloseIcon /></IconButton>
                </Grid>
                <Grid container className="pl-2 pr-2 pb-2">
                    <Grid container justify="space-between" spacing={3}>
                        <Grid item sm={12} align="left">
                            <Typography variant="body1">{"Manager"}</Typography>
                            {is_common ?
                                <Fragment>
                                    <ToolTip disableHoverListener={true} title={`Type name or phone or email`} placement={'top'}>
                                    <AutoComplete
                                        name={'mamager1-sel'}
                                        showValidate={showValidation && !manager ? true : false}
                                        validationMsg={"This Field is Required"}
                                        suggestions={[]}
                                        disabled={false}
                                        handleAutoComplete={(newValue, name) => { handleChangeManager1(newValue) }}
                                        filterOptions={(arr, eValue) => { return onFilterChange(arr, eValue) }}
                                        value={getSelectedValue(managerName, manager)}
                                    />
                                    </ToolTip>
                                </Fragment>
                                :
                                <Fragment>
                                    <AutoComplete
                                        name={'mamager-sel'}
                                        showValidate={showValidation && !managerDDN ? true : false}
                                        validationMsg={"This Field is Required"}
                                        // suggestions={managerName}
                                        suggestions={manSelList && manSelList.length ? managerName.filter(ml => (manSelList.indexOf(ml["label"]) > -1)) : managerName}
                                        disabled={false}
                                        handleAutoComplete={(newValue, name) => { handleChangeManager(newValue) }}
                                        value={getSelectedValue(managerName, managerDDN)}
                                    />
                                </Fragment>
                            }

                        </Grid>
                    </Grid>
                    <Grid container className="pt-3">
                        <Button color="primary" size="small" onClick={() => { getLinks() }} title={"Get Link"} className="mr-1"></Button>
                        <Button color="secondary" size="small" onClick={toggleDrawer(false)} title={"Cancel"}></Button>
                    </Grid>
                    {appLink && 
                    <Grid container alignItems="center" className={"mt-2"} >
                        <Typography variant="body1">{`Link  : `}</Typography>
                        <a className={classes.getLink} href={`${appLink}`} target={"_blank"}>
                            {`${appLink}`}
                        </a>
                  </Grid>
                    }
                </Grid>
            </Drawer>
        </React.Fragment >
    )
}
// default props
GetLink.defaultProps = {
    classes: {},
    getLinkPopup: false,
    setgetLinkPopup: () => { },
    appId: null
};

// prop types
GetLink.propTypes = {
    classes: PropTypes.object,
    getLinkPopup: PropTypes.bool,
    appId: PropTypes.number,
    setgetLinkPopup: PropTypes.func
};

export default withStyles(ApplicationStyles)(GetLink);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles, Paper } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//Styles
import SubmissionsStyles from "../SubmissionsStyles"

import RetrySubmission from "./RetrySubmission";

// Import History
import history from "../../../config/history";

// Import Components
import { Button as Btn, Table, Typography, AlertDialog, PageContainer } from "../../../components";

//Constants
import { submissions_page_constants } from "../../../config/constants"

import { getTableHeight, getTableRowSize } from "../../../config/layout"

// Import Actions
import { submissionAction, userAction } from "../../../redux/actions";

import NoRecordsFound from "./NoRecordsFound";

const SubmissionsList = (props) => {

  //define dispatch
  const dispatch = useDispatch();

  const { classes, location } = props;

  //set state
  const [deleteAlert, setDeleteAlert] = useState(null)
  const [refundAlert, setRefundAlert] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorl, setAnchorl] = React.useState(null);

  const height = getTableHeight();
  const tableRowSize = getTableRowSize();
  // let selectedHeaders = ["Application", "Manager", "Property", "Applicant","Phone","Email","Transaction","Response","Card","Created","Status","Payment","Report","Refund","Refund time"]
  // let selectedField = ["application_name", "manager_name", "property", "applicant","phone","email","transaction", "response","card_number","created_date_formatted","status","payment_status","report_status","refund","refund_date_formatted"]

  //get reducer data
  const { search: { page, data }, applications,attachmentLen } = useSelector(state => state.submission);
  const { loading } = useSelector(s => s.UI)

  const { currentUser: { preference, id: currentUID } } = useSelector(state => state.user);

  //is same page
  const isSamePage = location && location.search === "?page=true" ? true : false;

  //get selected user fields
  const selectHeaders = preference && preference.submission && preference.submission.select ? preference.submission.select : submissions_page_constants.HEADER_DEFAULT;
  const sortHeaders = preference && preference.submission && preference.submission.sort ? preference.submission.sort : submissions_page_constants.HEADER_DEFAULT_SELECTED;
  let selectedField = [];
  let selectedHeaders = [];
  if (selectHeaders && sortHeaders) {
    sortHeaders.map(i => {
      const isFound = selectHeaders.filter(h => (i === h))
      if (isFound.length) {
        selectedHeaders.push(i)
        selectedField.push(submissions_page_constants.SUBMISSION_LIST_MAP[i])
      }
    })
  }

  useEffect(() => {
    if (!isSamePage) {
      dispatch(submissionAction.search(1, tableRowSize, "create_time", "desc", [], []));
    }
  }, [dispatch]);

  useEffect(() => {
    let { sFields, sValues } = page;
    if (typeof sFields === 'undefined')
      sFields = []
    if (typeof sValues === 'undefined')
      sValues = []
    if (isSamePage) {

      dispatch(submissionAction.search(1, tableRowSize, "create_time", "desc", sFields, sValues));
      history.replace(location.pathname);
    }
  }, [isSamePage]);

  //Handle Table pagination Event
  const onChangePageTable = (skip, limit) => {
    const { sFields, sValues, sortBy, sort } = page;
    dispatch(submissionAction.search(skip, limit, sortBy, sort, sFields, sValues));
  };

  //Handle Table Sort Request Event
  const onSortRequest = (sortBy, sort) => {
    dispatch(submissionAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues));
  };

  //handle delete submission on yes click in alert
  const deleteAction = () => {
    const { field, itemId, item } = deleteAlert
    dispatch(submissionAction.remove({ id: item.id }, () => {
      dispatch(submissionAction.search(page.skip, page.limit, "create_time", "desc", page.sFields, page.sValues));
      setDeleteAlert(null)
    }));
  }

  //handle refund payment on yes click in alert
  const refundPaymentAction = () => {
    const { field, itemId, item } = refundAlert
    dispatch(submissionAction.refundPayment({ id: item.id }, () => {
      dispatch(submissionAction.search(page.skip, page.limit, "create_time", "desc", page.sFields, page.sValues));
      setRefundAlert(null)
    }));
  }

  //handle Download attachment
  const downloadAttachmentAction = (attachment_field) => {
    const { field, itemId, item } = anchorEl
    dispatch(submissionAction.downloadAttachment({ id: itemId, attachment_field: attachment_field }));
    setAnchorEl(null);
  }

  const handelDownloadButtonClick = (event, itemId, item) => {
    setAnchorEl({ event: event.currentTarget, itemId: itemId, item: item });
    dispatch(submissionAction.getAttachmentValues({ id: itemId }));
  };

  const handelDownloadClose = () => {
    setAnchorEl(null);
  };

  //update preference on submit click
  const updatePreference = (sort) => {
    let updatedData = [...sort]
    const allHeaders = preference && preference.submission && preference.submission.sort ? preference.submission.sort : [];
    allHeaders.map(i => {
      if (updatedData.indexOf(i) === -1) {
        updatedData.push(i)
      }
    })
    dispatch(userAction.updatePreference({ id: currentUID, preference: { ...preference, "submission": { ...preference.submission, "sort": updatedData } } }, () => { }));
  }

  //Button actions
  const handelActionButtonClick = (field, itemId, item, event = '') => {
    switch (field) {
      case 'REFUND':
        setRefundAlert({ field: field, itemId: itemId, item: item })
        break;
      case "DELETE":
        setDeleteAlert({ field: field, itemId: itemId, item: item })
        break;
      case "RETRY":
        dispatch(submissionAction.getRetrySubmission({ id: itemId }));
        setAnchorl({ field: field, itemId: itemId, item: item, page: page })
        break;
      default:
        break;
    }
  }

  //Handle Table Search Input Change Event
  const oninputChangeRequest = (searchField, searchKey) => {
    if (!loading) {
      let { sFields, sValues } = page;
      if (sFields.indexOf(searchField) >= 0) {
        if (searchKey === "") {
          const index = sFields.indexOf(searchField);
          sValues.splice(index, 1);
          sFields.splice(index, 1);
        } else {
          sValues[sFields.indexOf(searchField)] = searchKey;
        }
      } else {
        sFields.push(searchField);
        sValues.push(searchKey);
      }
      dispatch(submissionAction.search(1, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
    }
  };

  return (
    <React.Fragment>
      {/* <PageContainer height={height} paddingRight={12}> */}
      <Grid item xs={12} className={`${"mt-15"} ${classes.SubmissionsTable}`}>
        {page.totalCount > 0 &&
          <Paper elevation={3} className={classes.cardSection} >
            <Table
              tableId={"submission"}
              height={height}
              classes={classes}
              data={data}
              header={selectedHeaders}
              field={selectedField}
              search={selectedField}
              sortBy={page.sortBy}
              sort={page.sort}
              onInputChangeRequest={oninputChangeRequest}
              onChangePagination={onChangePageTable}
              onSortRequest={onSortRequest}
              handelActionButtonClick={handelActionButtonClick}
              handelDownloadButtonClick={handelDownloadButtonClick}
              updatePreference={updatePreference}
              page={Number(page.skip)}
              count={page.count}
              actions={['REFUND', 'DOWNLOAD', 'RETRY', 'DELETE']}
              sFields={page.sFields}
              sValues={page.sValues}
              rowsPerPage={page.limit}
              reset={page.sFields && page.sFields.length > 0 ? false : true}
              screenSizeRowPerPage={tableRowSize}
              loading={loading}
            >
            </Table>
          </Paper>
        /* }{!(page.totalCount) && <NoRecordsFound/>} */}
        {(page.totalCount === 0 && !loading) && <NoRecordsFound />}
      </Grid>
      {/* </PageContainer>  */}
      {anchorl && <RetrySubmission setAnchorl={setAnchorl} anchorl={anchorl} />}
      {deleteAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            {applications > 0 ?
              <Typography variant="h6">{`An application is mapped to this submission, are you sure you want to delete the submission`}</Typography> :
              <Typography variant="h6">{`Are you sure you want to delete this submission`}</Typography>
            }
            <Grid className={'pt-2'} align={"center"}>
              <Btn
                color={"primary"}
                className={"mr-1"}
                onClick={() => { deleteAction() }}
                title={"Yes"}
              />
              <Btn
                color={"secondary"}
                onClick={() => { setDeleteAlert(null) }}
                title={"No"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
      {refundAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            {applications > 0 ?
              <Typography variant="h6">{`An application is mapped to this submission, are you sure you want to perform this action?`}</Typography> :
              <Typography variant="h6">{`Are you sure you want to perform this action?`}</Typography>
            }
            <Grid className={'pt-2'} align={"center"}>
              <Btn
                color={"primary"}
                className={"mr-1"}
                onClick={() => { refundPaymentAction() }}
                title={"Yes"}
              />
              <Btn
                color={"secondary"}
                onClick={() => { setRefundAlert(null) }}
                title={"No"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
      {anchorEl &&
        <Menu
          id="simple-menu"
          anchorEl={anchorEl.event}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handelDownloadClose}
        >
          {anchorEl && anchorEl.item && attachmentLen && attachmentLen.criteria_length == 1 && <MenuItem onClick={() => downloadAttachmentAction('criteria')}>{submissions_page_constants.DOWNLOAD_CRITERIA}</MenuItem>}
          {anchorEl && anchorEl.item && attachmentLen && attachmentLen.disclosure_length == 1 && <MenuItem onClick={() => downloadAttachmentAction('disclosure')}>{submissions_page_constants.DOWNLOAD_DOCUMENT}</MenuItem>}
          <MenuItem onClick={() => downloadAttachmentAction('information')}>{submissions_page_constants.DOWNLOAD_APPLICATION}</MenuItem>
          {anchorEl && anchorEl.item && attachmentLen && attachmentLen.attachment_length == 1 && <MenuItem onClick={() => downloadAttachmentAction('attachment')}>{submissions_page_constants.DOWNLOAD_ATTACHMENT}</MenuItem>}
        </Menu>
      }
    </React.Fragment>
  );
}

// default props
SubmissionsList.defaultProps = {
  classes: {}
};

// prop types
SubmissionsList.propTypes = {
  classes: PropTypes.object
};

export default withStyles(SubmissionsStyles)(SubmissionsList);

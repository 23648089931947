import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"

//import components
import { Typography, AlertDialog, Button as Btn } from "../../../components";
import FormFieldsGenerator from "../FieldHelpers/FormFieldsGenerator"

//import actions
import { alertAction, updateReducerAction } from "../../../redux/actions";

//import constants
import { tenantAppConstant } from "../../../redux/constants";

const ApplicationValidation = (props) => {
    const dispatch = useDispatch()

    const { closePopup } = props;

    //set state
    const [isUpdate, setIsUpdate] = useState(false)

    //get reducer data
    const { appData: { application, appType, settings, application: { applicationForm } } } = useSelector(s => (s.tenantApp))

    //set state
    const userApplicationSection = (applicationForm || []).filter(f => (f.title === (appType === "resident" ? "Applicant" : "Applicant Information")))
    const [appSection, setAppSection] = useState(JSON.parse(JSON.stringify(userApplicationSection)))

    const verificationFields = ["FirstName", "LastName", "MiddleName", "Generation", "Email", "Phone", "SSN", "DateOfBirth"]

    //change payment form
    const handleChange = (e, indx0, indx, maxLen, checkIndex, fieldType = "") => {
        const regX = /[^a-zA-Z0-9! @ # $ % ^ * ( ) _ \n + = . ,-]/
        if (e["value"] && typeof e["value"] === "string" && !e["val"] && regX.test(e["value"]) === true) {
            dispatch(alertAction.error("Please enter only these accepted characters A-Z 0-9 ! @ # $ % ^ * ( ) _ - + = . ,"))
        } else {
            if (!maxLen || (maxLen && typeof e["value"] === "string" && e["value"].length <= Number(maxLen)) || (typeof e["value"] !== "string")) {
                let applicationData = JSON.parse(JSON.stringify(appSection[0]));
                applicationData = { ...applicationData, "elements": { ...applicationData["elements"], [e["name"]]: { ...applicationData["elements"][e["name"]], "value": e["value"] } } }
                setAppSection([applicationData])
            } else {
                dispatch(alertAction.error("Maximum length reached"))
            }
        }
        setIsUpdate(true)
    }

    //update reducer data
    const updateData = () => {
        const applicationIndex = (applicationForm || []).findIndex(f => (f.title === (appType === "resident" ? "Applicant" : "Applicant Information")))
        let updateData = application;
        updateData.applicationForm[applicationIndex] = appSection[0];

        const elementsArr = Object.keys(appSection[0].elements)
        for (let i = 0; i < elementsArr.length; i++) {
            if (verificationFields.includes(elementsArr[i])) {
                //update settings
                if (settings && settings["valueData"] && settings["valueData"].hasOwnProperty(elementsArr[i])) {
                    let updateSettings = settings;
                    updateSettings["valueData"][elementsArr[i]] = appSection[0].elements[elementsArr[i]].value;
                    dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_SETTINGS, updateSettings))
                }

                //DOB UPDATE
                if (elementsArr[i] == "DateOfBirth") {
                    dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_DOB, appSection[0].elements[elementsArr[i]].value))
                }
            }
        }
        dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_APPLICATION, updateData))
        setIsUpdate(false)
    }

    return (
        <AlertDialog
            fullScreen={false}
            open={true}
            maxWidth={'md'}
        >
            <Grid className={'p-4'}>
                <Typography color={"primary"} variant="h5">{`Confirm the following information is correct before proceeding`}</Typography>
                
                <Typography className="pt-1" variant="subtitle2">{`Note: Update the necessary details by entering the correct information in the fields and select the Update option to save the changes made`}</Typography>
                <Grid className={'pt-2'} align={"center"}>
                    <Grid container spacing={3}>
                        {verificationFields && verificationFields.map((item, index) => {
                            if (appSection && appSection[0] && appSection[0].elements && appSection[0].elements[item]) {
                                const currentElement = appSection[0].elements[item];

                                return (
                                    <Grid item xs={12} sm={6} key={`verify_app_${index}`}>
                                        <Typography variant="body1">{currentElement.label}</Typography>
                                        <FormFieldsGenerator
                                            item={item}
                                            indx={index}
                                            indx0={index}
                                            contents={currentElement || {}}
                                            handleChange={handleChange}
                                            data={appSection[0].elements || {}}
                                            validataForm={false}
                                        />
                                    </Grid>
                                )
                            } else {
                                return null
                            }
                        })}
                    </Grid>
                    <Grid className="mt-15">
                        <Btn
                            color={"primary"}
                            disabled={userApplicationSection.length===0 ? true : !isUpdate}
                            onClick={() => { updateData() }}
                            title={"Update"}
                        />
                        <Btn
                            className="ml-1"
                            color={"secondary"}
                            onClick={() => { closePopup() }}
                            title={"Continue"}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </AlertDialog>
    )
}

// default props
ApplicationValidation.defaultProps = {
    classes: {},
    closePopup: () => { }
};

// prop types
ApplicationValidation.propTypes = {
    classes: PropTypes.object,
    closePopup: PropTypes.func
};

export default ApplicationValidation;
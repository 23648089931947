import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames"
import { IconButton, withStyles, Grid } from '@material-ui/core';
import arrayMove from "array-move";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

//Styles and Images
import ServicesStyles from "../../ServicesStyles"
import { DragIcon, BellIcon, DeleteIcon, BellIconWithoutFill } from '../../../../assets/svg'

// Import Components
import { Button, TextField, Typography, AlertDialog } from "../../../../components";

// Import Cofig Constants
import { templates_page_constants } from "../../../../config/constants";

// Import Actions
import { updateReducerAction } from "../../../../redux/actions";

//import constant
import { serviceConstant } from '../../../../redux/constants'

//Sortable Item
const SortableItem = SortableElement(props => {
    return (
        props.children
    );
});

//Drag Handle
const DragHandle = SortableHandle((props) => {
    const { classes } = props;

    return (<DragIcon className={"cla"} draggable={false}></DragIcon>);
})

//create sortable list for drag and drop
const SortableList = SortableContainer(props => {

    //get props
    const { data, classes, setDeleteAlert, updateVariationField, errorFlags } = props;

    return (
        <React.Fragment>
            <Grid container>
                {data && data.map((item, index) => (
                    <SortableItem
                        key={`sort-item-${index}`}
                        index={index}
                    >
                        <Grid item sm={12}>

                            <Grid container alignItems="center" className={`${"mb-1"} ${classNames(classes.servicesSettingsTile, classes.widthAuto)}`}>
                                {/* <Grid className={`${"pr-1"} ${classes.dragContainer}`}><DragHandle /></Grid> */}
                                <DragHandle />
                                {/* <Grid className={`${classes.createTempChoiceText} ${"grow"}`}> */}
                                    <TextField
                                        className={classes.textFieldChoices}
                                        id={`text-${index}`}
                                        name={`text-${index}`}
                                        margin="none"
                                        placeholder={'Text'}
                                        value={item.text ? item.text : ''}
                                        onChange={(e) => { updateVariationField({ name: 'text', value: e.target.value }, index) }}
                                        error={errorFlags[index].text ? true : false}
                                        helperText={errorFlags[index].text ? "Variations cannot be blank" : ""}
                                    />
                                    <IconButton
                                        className={`${classes.notifyIcon} ${"mr-1 ml-1"}`}
                                        onClick={(e) => { updateVariationField({ name: 'is_notification', value: (Number(item.is_notification) === 1 ? 0 : 1) }, index) }}
                                    >
                                        {item.is_notification === 0 ? <BellIcon /> : <BellIconWithoutFill />}
                                    </IconButton>
                                    <IconButton onClick={(e) => { setDeleteAlert(index) }}><DeleteIcon /></IconButton>
                                {/* </Grid> */}
                            </Grid>
                        </Grid>
                    </SortableItem>
                ))}
            </Grid>
        </React.Fragment>
    )
})

const VariationContainer = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, parentIndex, createFromScratch, page, choiceVariation, errorFlagsvar:errorFlags, errorFlagsAll} = props;

    //add new variation to choices
    const addVariation = () => {
        if (!createFromScratch.documentData[page].choices[parentIndex].variation) {
            createFromScratch.documentData[page].choices[parentIndex].variation = []
            errorFlagsAll.documentData[page].choices[parentIndex].variation = []
        }
        createFromScratch.documentData[page].choices[parentIndex].variation = [...createFromScratch.documentData[page].choices[parentIndex].variation, { text: "", is_notification: 0, position: choiceVariation.length + 1 }]
        errorFlagsAll.documentData[page].choices[parentIndex].variation = [...errorFlagsAll.documentData[page].choices[parentIndex].variation, { text: false }]
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
        dispatch(updateReducerAction.update(serviceConstant.UPDATE_ERROR_FLAG_DOCUMENT, errorFlagsAll, () => { }));
    }

    //check row index on sort
    const onSortEnd = (e) => {
        const { oldIndex, newIndex } = e;

        let resultData = arrayMove(choiceVariation, oldIndex, newIndex);
        for (let i = 1; i <= resultData.length; i++) {
            resultData[i - 1]["position"] = i;
        }
        let resultFlagData = arrayMove(errorFlags, oldIndex, newIndex);
        for (let j = 1; j <= resultFlagData.length; j++) {
            resultFlagData[j - 1]["position"] = j;
        }

        createFromScratch.documentData[page].choices[parentIndex].variation = resultData
        errorFlagsAll.documentData[page].choices[parentIndex].variation = resultFlagData
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
        dispatch(updateReducerAction.update(serviceConstant.UPDATE_ERROR_FLAG_DOCUMENT, errorFlagsAll, () => { }));
    }

    //update variation
    const updateVariationField = (e, index) => {
        let choiceTemp = choiceVariation
        const { name, value } = e;
        for (let i = 0; i < choiceTemp.length; i++) {
            if (i === index) {
                choiceTemp[i][name] = value;
            }
        }

        createFromScratch.documentData[page].choices[parentIndex].variation = choiceTemp
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
    }

    //handle delete
    const handleDelete = (index) => {
        let resultData = JSON.parse(JSON.stringify(choiceVariation));
        resultData.splice(index, 1);
        let resultFlagData = JSON.parse(JSON.stringify(errorFlags));
        resultFlagData.splice(index, 1);

        for (let i = 1; i <= resultData.length; i++) {
            resultData[i - 1]["position"] = i;
        }

        createFromScratch.documentData[page].choices[parentIndex].variation = resultData
        errorFlagsAll.documentData[page].choices[parentIndex].variation = resultFlagData
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
        dispatch(updateReducerAction.update(serviceConstant.UPDATE_ERROR_FLAG_DOCUMENT, errorFlagsAll, () => { }));
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <SortableList
                    useDragHandle
                    axis="xy"
                    enableUserSelectHack={false}
                    helperClass="sortable-list-tab"
                    data={choiceVariation}
                    onSortEnd={onSortEnd}
                    classes={classes}
                    setDeleteAlert={handleDelete}
                    updateVariationField={updateVariationField}
                    errorFlags={errorFlags}
                ></SortableList>
            </Grid>
            <Grid item xs={12} align="left">
                <Typography color="primary" variant={"body2"} title={"+ Add Another Variation"} className={`${"cursorPointer link inlineBlock"} ${classes.grow}`} onClick={() => { addVariation() }}></Typography>
            </Grid>
        </React.Fragment>
    )
}
// default props
VariationContainer.defaultProps = {
    classes: {},
    index: -1,
    choiceData: {}
};

// prop types
VariationContainer.propTypes = {
    classes: PropTypes.object,
    index: PropTypes.number,
    choiceData: PropTypes.object
};

export default withStyles(ServicesStyles)(VariationContainer);

import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core';

//Styles
import SettingsStyles from "../SettingsStyles"

// Import History
import history from "../../../config/history";

// Import Components
import { Button, TextFieldWithoutRef, Typography } from "../../../components";

// Import Cofig Constants
import { settings_page_constants, user_page_constants } from "../../../config/constants";
import { settingsConstant } from "../../../redux/constants";

// Import Actions
import { settingsAction, updateReducerAction } from "../../../redux/actions";

//helpers
import { isEmail } from "../../../utils"

const CompanyInformation = (props) => {
    const dispatch = useDispatch()

    const { configuration: { company } } = useSelector(state => state.settings);
    const [errors, setErrors] = useState({});
    const [updateButton, setUpdateButton] = useState(false)

    /**
  *Handle Cancel Navigage to Tab based on Roles
  */
    const handleCancel = () => {
        history.go(-1)
    };

    const handleChange = (e) => {
        setUpdateButton(true)
        company[e.target.name] = e.target.value
        dispatch(updateReducerAction.update(settingsConstant.UPDATE_COMPANY_REQUEST, company, () => { }));
    }

    const handleSubmit = () => {
        let errors = {}
        if (!(company["name"].trim())) {
            errors["name"] = true
        }
        if (!(company["address"].trim())) {
            errors["address"] = true
        }
        if (!(company["phone"].trim())) {
            errors["phone"] = true
        }
        if (!(company["fax"].trim())) {
            errors["fax"] = true
        }
        if (!(company["email"].trim()) || !(isEmail(company["email"]))) {
            errors["email"] = true
        }
        setErrors(errors)
        if (Object.keys(errors).length === 0) {
            dispatch(settingsAction.saveConfiguration({ key: "company", value: company }, () => { 
                setUpdateButton(false)
            }));
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={6} align="left">
                        <Grid item xs={10}>
                            <Typography variant={"body1"}>{settings_page_constants.NAME}</Typography>
                            <TextFieldWithoutRef
                                name={"name"}
                                size={"small"}
                                margin={"none"}
                                value={company.name ? company.name : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.name ? true : false}
                                helperText={errors.name ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={10}>
                            <Typography variant={"body1"}>{"Email ID"}</Typography>
                            <TextFieldWithoutRef
                                name={"email"}
                                size={"small"}
                                margin={"none"}
                                value={company.email ? company.email : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.email ? true : false}
                                helperText={errors.email ? company.email && !isEmail(company.email) ? "Enter valid email" : !company.email ? "This is required" : "" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={10}>
                            <Typography variant={"body1"}>{settings_page_constants.PHONE}</Typography>
                            <TextFieldWithoutRef
                                name={"phone"}
                                size={"small"}
                                margin={"none"}
                                value={company.phone ? company.phone : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.phone ? true : false}
                                helperText={errors.phone ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={10}>
                            <Typography variant={"body1"}>{settings_page_constants.FAX}</Typography>
                            <TextFieldWithoutRef
                                name={"fax"}
                                size={"small"}
                                margin={"none"}
                                value={company.fax ? company.fax : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.fax ? true : false}
                                helperText={errors.fax ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Grid item xs={12} className={"multiLineText"}>
                            <Typography variant={"body1"}>{settings_page_constants.ADDRESS}</Typography>
                            <TextFieldWithoutRef
                                name={"address"}
                                size={"small"}
                                margin={"none"}
                                rows={3}
                                multiline={true}
                                value={company.address ? company.address : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.address ? true : false}
                                helperText={errors.address ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <Grid item xs={4} align="left">
                            <Button color="primary" size="small" title={"UPDATE"} className="mr-1" onClick={handleSubmit} disabled={updateButton?false:true}></Button>
                            <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={handleCancel}></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

// default props
CompanyInformation.defaultProps = {
    classes: {},
};

// prop types
CompanyInformation.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(SettingsStyles)(CompanyInformation);
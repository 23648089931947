import React from "react";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core';

//Images
import { NoRecordIcon } from "../../assets/svg"


// Import Components
import { Button, TextField, Typography, CheckBox, Link } from "../../components";




const NoRecordsFound = (props) => {

    const { classes } = props;

    const whiteBgContainer = {
        height: "calc(100vh - 124px)",
        background: '#fff',
        borderRadius: 4,
        alignContent: 'center',
        marginLeft: '10px',
        marginRight: '10px',
        boxShadow: '0px 0px 16px #50576614'
    };

    return (

        <Grid container alignItems={"center"} alignContent="baseline" justify="center" style={whiteBgContainer}>
            <Grid item xs={12} align="center">
                <NoRecordIcon />
                <Typography variant="h4" align="center" className={"pt-2 pb-1"}>{"NO RECORDS FOUND"}</Typography>
            </Grid>
        </Grid>
    );
}

// default props
NoRecordsFound.defaultProps = {
    classes: {}
};

// prop types
NoRecordsFound.propTypes = {
    classes: PropTypes.object
};

export default NoRecordsFound

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TextField as Core } from '@material-ui/core';
import InputMask from "react-input-mask";

// Imports Styles
import style from "./style";
/**
 * MaskInput Component
 */
function MaskInputComponent(props) {

  const { id, name, value, maskChar, placeholder, onChange, mask, fullWidth, multiline,error,helperText, autoComplete, margin, rows, variant } = props;

  return (
    <InputMask
      id={id}
      name={name}
      mask={mask}
      value={value ? value : ''}
      disabled={false}
      maskChar={maskChar}
      onChange={onChange}
    >
      {() =>
        <Core
          id={id}
          name={name}
          margin="none"
          className={"importField"}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          variant={variant}
          fullWidth={fullWidth}
          autoComplete= "new-password"
        />
      }
    </InputMask>
  );
}

// default props
MaskInputComponent.defaultProps = {
  id: "",
  name: "",
  value: "",
  type: "text",
  autoComplete: "off",
  rows: "0",
  margin: "normal",
  variant: "outlined",
  mask: "",
  fullWidth: true,
  maskChar: "_",
  error:false,
  helperText:"",
};

// prop types
MaskInputComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  autoComplete: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  error:PropTypes.bool,
  helperText:PropTypes.string,
};

// export component
export const InputMaskField = withStyles(style)(MaskInputComponent);

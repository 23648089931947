import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { IconButton, withStyles, Grid } from '@material-ui/core';

//Styles and Images
import TemplatesStyles from "../TemplatesStyles"
import { DeleteIcon } from '../../../assets/svg'

// Import Components
import { TextField, Typography, CheckBox, AlertDialog, Button } from "../../../components";

// Import Cofig Constants
import { templates_page_constants } from "../../../config/constants";

// Import Actions
import { alertAction, updateReducerAction } from "../../../redux/actions";

//import constant
import { templateConstant } from '../../../redux/constants'
import VariationContainer from "./VariationContainer";

const AddChoices = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes,control,register, errors } = props;

    //get reducer data
    const { document:{ choices, variation,disclosure } } = useSelector(s=>(s.template));

    //set state
    const [deleteAlert, setDeleteAlert] = useState(null);

    //add new choices
    const addChoices =()=>{
        const updatedChoices=[...choices,{index:choices.length,id:null,disclosure_id:disclosure && disclosure.id ? disclosure.id :null,name:'',is_required:0}]
        dispatch(updateReducerAction.update(templateConstant.UPDATE_DISCLOSURE_CHOICES, updatedChoices))
    }

    const updateChoiceField=(e,index)=>{
        let choiceTemp=choices
        const {name,value}=e;
        if(name!=="position" || (name==="position" && value<100)){
            for(let i=0;i<choiceTemp.length;i++){
                if(i===index){
                    if(name==="is_required"){
                        choiceTemp[i][name]=value=== true ? 1 : 0
                    }else{
                        choiceTemp[i][name]=value
                    }
                }
            }
            dispatch(updateReducerAction.update(templateConstant.UPDATE_DISCLOSURE_CHOICES, choiceTemp))
        }else{
            dispatch(alertAction.error("Position should be less than 100"))
        }
    }

    //handle delete
    const handleDelete = (index) => {
        let resultData = JSON.parse(JSON.stringify(choices));
        resultData.splice(index, 1);
        dispatch(updateReducerAction.update(templateConstant.UPDATE_DISCLOSURE_CHOICES, resultData, () => {
            setDeleteAlert(null)
        }))
    }

    return (
        <React.Fragment>
             <Grid item xs={12}>
                <Grid item sm={12}>
                    {choices && choices.map((items,indx)=>(
                        <Grid container key={`choices${indx}`} alignItems="center" spacing={2} className={`${"mt-2 relative"} ${classes.choicesContainer}`}>
                        <Grid item xs={12} className="mb-1">
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={4}>
                                    <Typography title={templates_page_constants.TITLE} variant={"body1"}></Typography>
                                    <TextField
                                        id={`name-${indx}`}
                                        name={`name-${indx}`}
                                        margin="none"
                                        placeholder={'Title'}
                                        value={items.name?items.name:''}
                                        onChange={(e)=>{updateChoiceField({name:'name',value:e.target.value},indx)}}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography title={templates_page_constants.DOC_POSITION} variant={"body1"}></Typography>
                                    <TextField
                                        id={`position${indx}`}
                                        name={`position${indx}`}
                                        type={"number"}
                                        margin="none"
                                        placeholder={'Title'}
                                        value={items.position?items.position:''}
                                        onChange={(e)=>{updateChoiceField({name:'position',value:e.target.value},indx)}}
                                        error={items.position?false:true}
                                        helperText={items.position?"":"Position is required"}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant={"body1"} className={"pt-2"}>{" "}</Typography> 
                                        <CheckBox
                                            id={`checked-${indx}`}
                                            label={"Required"}
                                            checked={Number(items.is_required)===1 ? true:false}
                                            color="secondary"
                                            handleChange={(e) => {updateChoiceField({name:'is_required',value:e.target.checked},indx);}}
                                        />
                                </Grid>
                            <IconButton className={classes.choiceDelete} onClick={(e) => { setDeleteAlert({ "delIndex": indx }) }}><DeleteIcon /></IconButton>
                            </Grid>
                        </Grid>
                        <VariationContainer classes={classes} index={indx} choiceData={items} currentVariation={variation.filter(v=>(v['index']===indx))}/>
                    </Grid>
                    ))}
                    <Grid item xs={12} align="left" className="mt-3">
                        <Typography color="primary" variant={"body2"} title={templates_page_constants.ADD_ANOTHER_CHOICE} className={`${"cursorPointer"} ${classes.grow}`} onClick={()=>{addChoices()}}></Typography>
                    </Grid>
                </Grid>
            </Grid>
            {deleteAlert &&
                <AlertDialog
                    fullScreen={false}
                    open={true}
                >
                    <Grid className={'p-4'}>
                        <Typography variant="h6">{`Are you sure do you want to remove this Choice`}</Typography>
                        <Grid className={'pt-2'} align={"center"}>
                            <Button
                                color={"primary"}
                                className={"mr-1"}
                                onClick={() => { handleDelete(deleteAlert["delIndex"]) }}
                                title={"Continue"}
                            />
                            <Button
                                color={"secondary"}
                                onClick={() => { setDeleteAlert(null) }}
                                title={"Cancel"}
                            />
                        </Grid>
                    </Grid>
                </AlertDialog>
            }
        </React.Fragment>
    )
}
// default props
AddChoices.defaultProps = {
    classes: {},
};

// prop types
AddChoices.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(TemplatesStyles)(AddChoices);

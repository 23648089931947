import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from "prop-types";

// Import History
import history from "../../../../config/history";

// Import Components
import { Table, AlertDialog, Typography, Button as Btn } from "../../../../components";

//Import Layouts & Styles
import UsersStyles from '../../UsersStyles'
import { getTableHeight, getTableRowSize } from '../../../../config/layout'

// Import Actions
import { userAction, authAction } from "../../../../redux/actions";

//Constants
import { user_page_constants } from "../../../../config/constants"
import NoRecordsFound from "../NoRecordsFound";

function UserList(props) {
  //define dispatch
  const dispatch = useDispatch();

  //get props
  const { classes, location } = props;

  //set state
  const [deleteAlert, setDeleteAlert] = useState(null)
  const [managerMap, setmanagerMap] = useState(null);

  //get page height
  const height = getTableHeight();
  const tableRowSize = getTableRowSize();

  //get reducer data
  const { search: { page, data }, currentUser: { preference,id:currentUID } } = useSelector(state => state.user);
  const { loading } = useSelector(s=>s.UI)

  //is same page
  const isSamePage = location && location.search === "?page=true" ? true : false;

  //get selected user fields
  const selectHeaders = preference && preference.users && preference.users.select ? preference.users.select : user_page_constants.USER_LIST_HEADER;
  const sortHeaders = preference && preference.users && preference.users.sort ? preference.users.sort : user_page_constants.USER_LIST_HEADER;
  let selectedField = [];
  let selectedHeaders = [];
  if (selectHeaders && sortHeaders) {
    sortHeaders.map(i => {
      const isFound = selectHeaders.filter(h => (i === h))
      if (isFound.length) {
        selectedHeaders.push(i)
        selectedField.push(user_page_constants.USER_LIST_MAP[i])
      }
    })
  }

  useEffect(() => {
      if(!isSamePage){
      dispatch(userAction.search(1, tableRowSize, "create_time", "desc", [], []));
    }  
  }, [dispatch]);

  useEffect(() => {
    let { sFields, sValues } = page;
    if (typeof sFields === 'undefined')
      sFields = []
    if (typeof sValues === 'undefined')
      sValues = []
    if(isSamePage){
      dispatch(userAction.search(1, tableRowSize, "create_time", "desc", sFields, sValues));
      history.replace(location.pathname);
    }  
  }, [isSamePage]);

  //Handle Table pagination Event
  const onChangePageTable = (skip, limit) => {
    const { sFields, sValues, sortBy, sort } = page;
    dispatch(userAction.search(skip, limit, sortBy, sort, sFields, sValues));
  };

  //Handle Table Sort Request Event
  const onSortRequest = (sortBy, sort) => {
    dispatch(userAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues));
  };

  //handle detlete user on yes click in alert
  const deleteAction = () => {
    const { field, itemId, item } = deleteAlert
    dispatch(userAction.remove({ id: item.id, role: item.role, client_id: item.c_id }, () => {
      dispatch(userAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
      setDeleteAlert(null)
    }));
  }

  //Button actions
  const handelActionButtonClick = (field, itemId, item) => {
    switch (field) {
      case "LOGIN":
        localStorage.setItem("id", item.id);
        localStorage.setItem("role", item.role);
        dispatch(userAction.loginUser(item))
        dispatch(authAction.getProfileLogo())
        break;
      case "EDIT":
        dispatch(userAction.getUser({ id: itemId }, () => {
          props.history.push({ pathname: `/users/edit/${itemId}` });
        }))
        break;
      case "DELETE":
        if (item && item["role"] === "Manager") {
            dispatch(userAction.getManagerAppMap({ client_id: item["client_id"], id: item["manager_id"] }, (respData) => {
                if (respData && respData["data"]) {
                    setmanagerMap(true)
                    setDeleteAlert({ field: field, itemId: itemId, item: item })
                  } else {
                    setmanagerMap(null)
                    setDeleteAlert({ field: field, itemId: itemId, item: item })
                  }
            }))
        } else {
          setDeleteAlert({ field: field, itemId: itemId, item: item })
          setmanagerMap(null)
        }
        break;
      default:
        break;
    }
  }

  //Handle Table Search Input Change Event
  const oninputChangeRequest = (searchField, searchKey) => {
    let { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    if(!isSamePage){
      dispatch(userAction.search(1, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
    }
  };

  //update preference on submit click
  const updatePreference = (sort) => {
    let updatedData=[...sort]
    const allHeaders=preference && preference.users && preference.users.sort ? preference.users.sort :[];
    allHeaders.map(i=>{
      if(updatedData.indexOf(i)===-1){
        updatedData.push(i)
      }
    })
    dispatch(userAction.updatePreference({ id: currentUID, preference: { ...preference, "users": { ...preference.users, "sort": updatedData } } }, () => { }));
  }

  return (
    <React.Fragment>
      <Grid item xs={12} className={`${"mt-15"} ${classes.usersTable}`}>
        {page.totalCount>0 &&
          <Paper elevation={3} className={classes.cardSectionList} > 
            <Table
              height={height}
              classes={classes}
              data={data}
              header={selectedHeaders}
              field={selectedField}
              search={selectedField}
              sortBy={page.sortBy}
              sort={page.sort}
              onInputChangeRequest={oninputChangeRequest}
              onChangePagination={onChangePageTable}
              onSortRequest={onSortRequest}
              handelActionButtonClick={handelActionButtonClick}
              updatePreference={updatePreference}
              page={Number(page.skip)}
              count={page.count}
              actions={['LOGIN', 'EDIT', 'DELETE']}
              sFields={page.sFields}
              sValues={page.sValues}
              rowsPerPage={page.limit}
              reset={page.sFields && page.sFields.length>0?false:true}
              screenSizeRowPerPage={tableRowSize}
              loading={loading}
            >
            </Table>
          </Paper>
        }
        {(!page.totalCount && !loading) && <NoRecordsFound/>}
      </Grid>
      {deleteAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            <Typography variant="h6">{managerMap? `Application Mapped, You cannot  delete this ${deleteAlert.item.role}` :`Are you sure you want to delete this ${deleteAlert.item.role}`}</Typography>
            <Grid className={'pt-2'} align={"center"}>
              {!managerMap &&<Btn
                color={"primary"}
                className={"mr-1"}
                onClick={() => { deleteAction() }}
                title={"Yes"}
              />}
              <Btn
                color={"secondary"}
                onClick={() => { setDeleteAlert(null) }}
                title={managerMap ?"Cancel" : "No"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
    </React.Fragment>

  );
}
// default props
UserList.defaultProps = {
  classes: {}
};

// prop types
UserList.propTypes = {
  classes: PropTypes.object
};
export default withStyles(UsersStyles)(UserList);

const style = (theme) => ({
  header: {
    padding: "6px 6px",
    textTransform: "uppercase",
    fontSize: "14px",
    color: "#222",
    fontWeight: 400
  }
});

export default style;

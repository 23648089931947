import React, { useEffect, useState } from "react";
import CKEditor from 'ckeditor4-react';
import { Grid } from "@material-ui/core";
import PropTypes from 'prop-types';

//Styles
import './style.css'

function RichTextEditor(props) {

    const { value, onChange } = props;

    const handleEditorChange = (evt) => {
        onChange(evt.editor.getData())
    }

    const loadData = (e) => {
        setTimeout(() => {
            if (value) {
                e.editor.setData(value)
            }
        }, 1000)
    }

    return (
        <Grid
            container
            alignContent="baseline">
            <Grid item xs={12}>
                <CKEditor
                    data={value}
                    onChange={handleEditorChange}
                    onBeforeLoad={CKEDITOR => {
                        CKEDITOR.addCss('body { background-color: #fafafa; line-height: 1 !important; padding: 5px 15px; margin: 0;  height: 100% }');
                        CKEDITOR.addCss('html::-webkit-scrollbar { width: 6px;height: 6px; }');
                        CKEDITOR.addCss('html::-webkit-scrollbar-thumb { background: #888; border-radius: 4px;');
                        CKEDITOR.addCss('html::-webkit-scrollbar-track { background: #f1f1f1; border-radius: 4px;') }
                    }
                    onLoaded={e => { loadData(e) }}
                    status={"ready"}
                    config={{
                        removePlugins: 'elementspath',
                        // colorButton_colors: 'FontColor1/FF9900,FontColor2/0066CC,FontColor3/F00',
                        extraPlugins: 'colorbutton,colordialog',
                        // uiColor: "#fff",
                        toolbar: [
                            ["Format", 'Blockqoute', 'Bold', 'Italic', 'BulletedList',
                                'NumberedList', 'Table', 'Link', 'Unlink', 'TextColor', 'HorizontalRule']]
                    }}
                />
            </Grid>
        </Grid>
    );
}

RichTextEditor.defaultProps = {
    value: "",
    onChange: () => { }
}
RichTextEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
}

export const RichTextEdit = RichTextEditor;

/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TextField as Core } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// Imports Styles
import style from "./style";
import { VpnIcon, EmailIcon } from '../../assets/svg'
/**
 * TextField Component
 * @param {*} props
 */
function TextFieldComponent({ id, name, placeholder, multiline, autoComplete, margin, rows, variant, fullWidth,withImage,imageType, passShow, handleChange,value,  ...rest }) {
 
  const fieldImage=()=>{
    return withImage ? 
    imageType==='uemail'? <InputAdornment position="start"><EmailIcon /></InputAdornment> :
    imageType==='passwd'? <InputAdornment position="start"><VpnIcon /></InputAdornment> : ""
    :""
  }
 
  return (
    <Core
      id={id}
      placeholder={placeholder}
      name={name}
      multiline={multiline}
      autoComplete={autoComplete}
      margin={margin}
      rows={rows}
      variant={variant}
      fullWidth={fullWidth}
      value={value}
      onChange={handleChange}
      InputProps={{
            startAdornment: fieldImage(),
            endAdornment: passShow ? <InputAdornment position="end"><Visibility className={"opacity0"} /></InputAdornment> : ''
          }}
          {...rest}
    />
  );
}

// default props
TextFieldComponent.defaultProps = {
  id: "",
  name: "",
  type: "text",
  autoComplete: "off",
  rows: "0",
  margin: "normal",
  variant: "outlined",
  fullWidth: true,
  withImage:false,
  passShow: false,
};

// prop types
TextFieldComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  autoComplete: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  withImage: PropTypes.bool,
  passShow: PropTypes.bool
};

// export component
export const TextFieldWithoutRef = withStyles(style)(TextFieldComponent);

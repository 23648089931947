import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles, Paper } from '@material-ui/core';

//Styles
import UsersStyles from "../../UsersStyles"

//images
import CreateTemplateImg from "../../../../assets/images/Create_Template.png"

// Import Components
import { Button, Typography, PageContainer, Table, AlertDialog } from "../../../../components";
import AddServiceDialog from '../../../Services/AddServiceDialog';
import AddEditService from './AddEditUserService';
import UpdateFee from './UpdateFee';

// Import Cofig Constants
import { user_page_constants } from "../../../../config/constants";
import { getTabsTableHeight, getTabsTableRowSize } from "../../../../config/layout"


// Import Actions
import { serviceAction, updateReducerAction } from "../../../../redux/actions";

// Import Constants
import { serviceConstant } from "../../../../redux/constants";

const height = getTabsTableHeight();

const Services = (props) => {
  //define dispatch
  const dispatch = useDispatch();

  const { classes } = props;

  const tableRowSize = getTabsTableRowSize();

  //set state
  const [selectAll, setSelectAll] = useState(false)
  const [drawer, setDrawer] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(null)
  const [serviceId, setServiceId] = useState(null)
  const selectedHeaders = ["Template", "Product", "Fee", "Selections"]
  const selectedField = ["temp_name", "product", "fee", "selectionsStr"]
  const [open, setOpen] = useState(false);

  //get reducer data
  const { usersSearch: { page, data }, applications } = useSelector(state => state.service);
  let { checkBoxSelect } = useSelector(state => state.service);
  const { getUser: currentuser, currentUser } = useSelector(state => state.user);
  let clientId = ""
  if (currentuser.ownership_id === 1) {
    clientId = currentuser.owner_id
  }
  else if (currentuser.ownership_id === 2) {
    clientId = currentuser.client_id
  }

  useEffect(() => {
    if (page.count === Object.keys(checkBoxSelect).length && page.count !== 0) {
      setSelectAll(true)
    }
    let { sFields, sValues } = page;
    if (typeof sFields === 'undefined')
      sFields = []
    if (typeof sValues === 'undefined')
      sValues = []
    dispatch(serviceAction.userServiceSearch(1, tableRowSize, "service_name", "asc", sFields, sValues, clientId));
  }, [dispatch]);

  //uppdate select all checkbox
  useEffect(()=>{
    if(checkBoxSelect && Object.keys(checkBoxSelect).length===0 && selectAll){
      setSelectAll(false)
    }
  },[checkBoxSelect,dispatch])

  const handleAddServiceOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Handle Table pagination Event
  const onChangePageTable = (skip, limit) => {
    const { sFields, sValues, sortBy, sort } = page;
    dispatch(serviceAction.userServiceSearch(skip, limit, sortBy, sort, sFields, sValues, clientId));
  };

  //Handle Table Sort Request Event
  const onSortRequest = (sortBy, sort) => {
    dispatch(serviceAction.userServiceSearch(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues, clientId));
  };

  //handle detlete user on yes click in alert
  const deleteAction = () => {
    const { field, itemId, item } = deleteAlert
    dispatch(serviceAction.remove({ id: item.id }, () => {
      dispatch(serviceAction.userServiceSearch(page.skip, page.limit, "create_time", "desc", page.sFields, page.sValues, clientId));
      setDeleteAlert(null)
    }));
  }

  //Button actions
  const handelActionButtonClick = (field, itemId, item) => {
    switch (field) {
      case "EDIT":
        const selectedservice = data.filter(obj => (obj.id === itemId))
        dispatch(updateReducerAction.update(serviceConstant.ADD_EDIT_SERVICE_REDUCER_UPDATE, selectedservice[0], () => {
          setServiceId(itemId)
          setDrawer(true);
          setAnchorEl(null);
        }));
        break;
      case "DELETE":
        dispatch(serviceAction.getApplicationCount({ id: itemId }, () => {
          setDeleteAlert({ field: field, itemId: itemId, item: item })
        }))
        break;
      default:
        break;
    }
  }

  //Handle Table Search Input Change Event
  const oninputChangeRequest = (searchField, searchKey) => {
    let { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    dispatch(serviceAction.userServiceSearch(1, page.limit, page.sortBy, page.sort, page.sFields, page.sValues, clientId));
  };

  const handleCheckboxChange = (name, value) => {
    if (name === "checkAll" && value === true) {
      page.allServiceIds.map((key) => {
        checkBoxSelect[key] = true
      })
    } else if (name === "checkAll" && value === false) {
      checkBoxSelect = {}
    }
    else if (value === false) {
      delete checkBoxSelect[name]
    }
    else {
      checkBoxSelect[name] = value
    }

    if (page.count === Object.keys(checkBoxSelect).length && page.count !== 0) {
      setSelectAll(true)
    }
    else {
      setSelectAll(false)
    }
    dispatch(updateReducerAction.update(serviceConstant.CHECKBOX_REDUCER_UPDATE, checkBoxSelect, () => { }));
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setServiceId(null)
    setAnchorEl(event.currentTarget);
  };

  const handleCloseService = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawer(open);
    setAnchorEl(null);
  };

  return (
    <Grid>
      {page.totalcount > 0 ?
        <Grid item xs={12} className={classes.contentcontainer} >

          <Grid container justify="flex-end" className={classes.serviceActionBtn}>
            <UpdateFee clientId={clientId} page={page} />
            {(currentUser.role === "admin" && currentuser.ownership_id !== 2) &&
              <Button color="primary" title={user_page_constants.ADD_SERVICE} onClick={handleClick} disabled={(currentUser.role === "admin" && currentuser.ownership_id !== 2) ? false : true}></Button>
            }
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseService}
            >
              <MenuItem onClick={handleAddServiceOpen}>Create a service from scratch</MenuItem>
              <MenuItem onClick={toggleDrawer(true)}>Select from a template</MenuItem>
            </Menu>
            {
              drawer &&
              <AddEditService toggleDrawer={toggleDrawer} drawer={drawer} serviceId={serviceId} />
            }
          </Grid>
          <Grid item xs={12} className={`${"mt-15"} ${classes.usersTable}`}>
            <Paper elevation={3} className={classes.cardSectionList} >
              <Table
                height={height}
                classes={classes}
                data={data}
                header={selectedHeaders}
                field={selectedField}
                search={selectedField}
                sortBy={page.sortBy}
                sort={page.sort}
                onInputChangeRequest={oninputChangeRequest}
                onChangePagination={onChangePageTable}
                onSortRequest={onSortRequest}
                handelActionButtonClick={handelActionButtonClick}
                page={Number(page.skip)}
                count={page.count}
                actions={['EDIT', 'DELETE']}
                sFields={page.sFields}
                sValues={page.sValues}
                rowsPerPage={page.limit}
                checkBox={true}
                checkBoxSelect={checkBoxSelect}
                handleCheckboxChange={handleCheckboxChange}
                selectAll={selectAll}
                screenSizeRowPerPage={tableRowSize}
              >
              </Table>
            </Paper>
          </Grid>
        </Grid> :
        <Grid className={classes.createTemplateContainer}>
          <Grid container alignItems={"center"} alignContent="baseline" justify="center" className={classes.whiteBgContainer}>
            <Grid item xs={12} align="center">
              <img src={CreateTemplateImg} className="pb-2" alt="Create Template" />
              <Typography variant="h4" align="center" className={"pb-1"}>{user_page_constants.OOPS}</Typography>
              {(currentUser.role === "admin") && currentuser.ownership_id !== 2 &&
                <React.Fragment>
                  <Typography variant="h6" align="center" className={"pb-2"}>{user_page_constants.DO_U_CREATE_TEMPLATE}</Typography>
                  <Grid item>
                    <Button size="small" color="primary" title={"Create a Template"} className={"mr-1"} onClick={handleAddServiceOpen}></Button>
                    <Button size="small" color="secondary" title={"Add a Service"} onClick={toggleDrawer(true)}></Button>
                  </Grid>
                </React.Fragment>
              }
            </Grid>
            {
              drawer &&
              <AddEditService toggleDrawer={toggleDrawer} drawer={drawer} serviceId={null} />
            }
          </Grid>
        </Grid>
      }
      <AddServiceDialog open={open} handleClose={handleClose} pageType={"user"} toggleDrawer={handleCloseService} />
      {deleteAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            {applications > 0 ?
              <Typography variant="h6">{`An application is mapped to this service, You cannot delete it`}</Typography> :
              <Typography variant="h6">{`Are you sure you want to delete this service`}</Typography>
            }
            <Grid className={'pt-2'} align={"center"}>
              {applications === 0 &&
                <Button
                  color={"primary"}
                  className={"mr-1"}
                  onClick={() => { deleteAction() }}
                  title={"Yes"}
                />
              }
              <Button
                color={"secondary"}
                onClick={() => { setDeleteAlert(null) }}
                title={applications > 0 ? "Close" : "No"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
    </Grid>

  );
}

// default props
Services.defaultProps = {
  classes: {}
};

// prop types
Services.propTypes = {
  classes: PropTypes.object
};

export default withStyles(UsersStyles)(Services);

import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

/**
 *Tool tip component
 *
 * @class ToolTip
 * @extends {React.Component}
 */
function ToolTipComponent({ title, placement, arrow, children, className ,...rest}) {
  return (
    <Tooltip
      title={title}
      placement={placement}
      className={className}
      {...rest}
      arrow={arrow} >
      {children}
    </Tooltip>
  );
}

// default props
ToolTipComponent.defaultProps = {
  title: "",
  placement: "bottom",
  className: "",
  children: {},
  arrow: true
};

// prop types
ToolTipComponent.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  arrow: PropTypes.bool
};

// export component
export const ToolTip = ToolTipComponent;


import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Table as T, TableHead, TableBody, TableRow, TableCell, TableSortLabel, Tooltip, IconButton } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import StatusInfoIcon from '@material-ui/icons/PriorityHigh';

// Import Component
import { ToolTip, Typography, Select, CheckBox } from "../../components"
import { Search } from "../Search";

// Import SVG Icons
import { DeleteIcon, DownloadIcon, ReloadIcon, RefundIcon, LinkIcon, EditIcon, LoginIcon, LoginIconGrey, RefundIconGrey, ReloadIconGrey, SearchIconTable, InviteIcon, EmailIcon } from '../../assets/svg';

// Import Styles
import style from "./style";

function TableComponent(props) {

  const stickyheight = (document.getElementsByClassName("stickyHeadTop") && document.getElementsByClassName("stickyHeadTop")[0]) ? document.getElementsByClassName("stickyHeadTop")[0].clientHeight : 0;

  // Get Props
  const { screenSizeRowPerPage, data, updatePreference, loading, header, field, search, sortBy, sort, classes, reset, rowsPerPage, page, count, sFields, sValues, onEnterSearch, actions, height, checkBox, checkBoxSelect, selectAll } = props

  let userLength = []
  if (screenSizeRowPerPage && !["10", "50", "100"].includes(screenSizeRowPerPage)) {
    userLength = [{ value: `${screenSizeRowPerPage}`, label: `${screenSizeRowPerPage}` }, { value: "50", label: "50" }, { value: "100", label: "100" }]
  } else {
    userLength = [{ value: "10", label: "10" }, { value: "50", label: "50" }, { value: "100", label: "100" }]
  }

  const { currentUser: { role: currentUserRole } } = useSelector(state => state.user);
  const [cols, setCols] = useState(header);
  const [fields, setFields] = useState(field);
  const [dragOver, setDragOver] = useState("");

  const [dataLength, setDataLength] = useState(`${rowsPerPage}`)

  useEffect(() => {
    setDataLength(`${rowsPerPage}`)
  }, [rowsPerPage])
  const handleChangeDatalength = (event) => {
    setDataLength(event.value);
    props.onChangePagination(1, event.value);
  };

  const handleDragStart = e => {
    const { id } = e.target;
    const idx = cols.indexOf(id);
    e.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = e => e.preventDefault();
  const handleDragEnter = e => {
    const { id } = e.target;
    setDragOver(id);
  };

  const handleOnDrop = e => {
    const { id } = e.currentTarget;
    const droppedColIdx = cols.indexOf(id);
    const draggedColIdx = e.dataTransfer.getData("colIdx");

    let tempCols = [...cols]
    let dragCol = tempCols.splice(draggedColIdx, 1)
    tempCols.splice(droppedColIdx, 0, dragCol[0])

    let tempFields = [...fields]
    let dragField = tempFields.splice(draggedColIdx, 1)
    tempFields.splice(droppedColIdx, 0, dragField[0])

    setCols(tempCols);
    setFields(tempFields);
    updatePreference(tempCols)
    setDragOver("");
  };

  //update header and fields
  useEffect(() => {
    setCols(header)
    setFields(field)
  }, [header, field])


  /**
  * Handle Sorting
  */
  const handleSorting = property => event => {
    const sortBy = property;
    let sorts = "asc";

    if (props.sortBy === sortBy && props.sort === "asc") {
      sorts = "desc";
    }

    props.onSortRequest(sortBy, sorts);
  };

  // Request change on input function
  const onInputChangeRequest = (field, value, userChange) => {
    props.onInputChangeRequest(field, value, userChange);
  };

  // Handle Clear Search Text In Field
  const handleClearSearchByField = (field, value) => {
    props.handleClearSearchByField(field, value);
  };

  // Search Component Enter Event
  const onEnter = (field, value) => {
    if (onEnterSearch) {
      onEnterSearch();
    }
  };

  /** 
   *  Handle Pagination
  */
  const onChangePagination = (event, page) => {
    props.onChangePagination(page, props.rowsPerPage);
  };

  const handelActionButtonClick = (field, itemId, itemName) => {
    props.handelActionButtonClick(field, itemId, itemName);
  }

  const handelDownloadButtonClick = (event, itemId, itemName) => {
    props.handelDownloadButtonClick(event, itemId, itemName);
  }

  //edit menu for application
  const handelEditButtonClick = (event, itemId, itemName) => {
    props.handelEditButtonClick(event, itemId, itemName);
  }
  /**
   * Handle checkbox Change
   * @param {*} event 
   */
  const handleCheckboxChange = (event) => {
    props.handleCheckboxChange(event.target.name, event.target.checked)
  }

  /**
  * Bind Table Header Row
  */
  const bindTableHeader = () => {
    return (
      <TableRow >
        {cols.map((item, index) => {
          const headerColumn = item.value ? item.value : item;
          return <TableCell
            id={item}
            key={item}
            draggable
            style={{ width: headerColumn === "Fee" ? "9%" : headerColumn === "Client Code" ? "14%" : "" }}
            className="stickyHeadTop"
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleOnDrop}
            onDragEnter={handleDragEnter}
            dragOver={item === dragOver}
            sortDirection={sortBy === fields[index] ? sort : false}>
            <div className={`${"resize"} ${classes.resize}`}>
              <Grid container direction="row"
                justify="space-between"
                alignItems="center" >
                <Grid className={classes.headerTextContainer}>
                  {index === 0 && checkBox === true &&
                    <CheckBox
                      color="secondary"
                      id={"All"}
                      name={"checkAll"}
                      checked={selectAll}
                      handleChange={(e) => handleCheckboxChange(e)}
                    />
                  }
                  <Grid item className={classes.ellipsis}>{item}</Grid>
                  <Grid item className="sortIcon">
                    <TableSortLabel
                      active={sortBy === fields[index]}
                      direction={sort}
                      onClick={handleSorting(field[index])}
                    ></TableSortLabel>
                  </Grid>
                </Grid>

              </Grid>
            </div>
          </TableCell>
        })
        }
        {header.length > 0 &&
          actions.length > 0 &&
          <TableCell key={"hrow-action"} className="txtcenter stickyHeadTop actions" align="center"> {"Actions"} </TableCell>
        }
      </TableRow>
    );
  };

  /**
     * Table Search Input Row
     */
  const tableFilterRow = () => {
    const tableRow = search.map((item, index) => {
      const colName = item || "";
      const sValIndex = sFields.indexOf(colName);

      return (
        <TableCell key={`${item ? item : 'search'}` + index} style={{ top: stickyheight + 1 }}>
          {
            item != null &&
            <Search
              ariaLabel={header[index] || "Search"}
              placeholder={"Search"}
              value={sValIndex >= 0 ? sValues[sValIndex] : ""}
              reset={reset}
              field={field[index]}
              showClearIcon={true}
              className={'pageSearchBox'}
              fullWidth={true}
              loading={loading}
              onInputChangeRequest={(field, value, userChange) => onInputChangeRequest(field, value, userChange)}
              handleClearSearch={() => handleClearSearchByField}
              onEnter={onEnter}
            />
          }
        </TableCell>
      );
    });

    return (
      <TableRow>
        {tableRow}
        {header.length > 0 &&
          actions.length > 0 &&
          <TableCell className="actions" style={{ top: stickyheight + 1 }} />
        }
      </TableRow>
    );
  };

  /**
   * Bind Table Body Rows
   */
  const bindTableBody = () => {
    return (
      <React.Fragment>
        {data.map((row, index) =>
          <TableRow key={index}>
            {fields.map((item, index) => {
              return (item === "status" && props.tableId !== "submission" ?
                <TableCell align="center"
                  key={"bRow-" + index}
                // onClick={() => handleNavigateAction(item, field)}
                >
                  <div className={`${getStatusClass(row, item) === "Active" ? classes.statusActive : classes.statusInactive}`}> {bindRowValues(row, item)} </div>
                </TableCell>
                :
                item === "status" && props.tableId === "submission" ?
                  <TableCell align="center"
                    key={"bRow-" + index}
                  // onClick={() => handleNavigateAction(item, field)}
                  >

                    <div className={`${getStatusClass(row, item) === "Complete" ? classes.statusActive : getStatusClass(row, item) === "Failed" ? classes.statusInactiveFailed : ''}`}>
                      {row.status == "Failed" &&
                        <ToolTip title={row.response}><StatusInfoIcon className="StatusInfoIcon" /></ToolTip>
                      }
                      {bindRowValues(row, item)}

                    </div>
                  </TableCell>
                  :
                  <TableCell
                    key={"bRow-" + index}
                  // onClick={() => handleNavigateAction(item, field)}
                  >
                    {index === 0 &&
                      <React.Fragment>
                        <Grid container className={checkBox ? classes.checkBoxClsContainer : 'checkCls'}>
                          {checkBox === true &&
                            <Grid alignItems="center" className={`${"checkBoxContainer"} ${classes.checkBoxContainer}`}>
                              <CheckBox
                                id={index}
                                color="secondary"
                                name={`${row.id}`}
                                checked={checkBoxSelect[`${row.id}`]}
                                handleChange={(e) => handleCheckboxChange(e)}
                              />
                            </Grid>
                          }
                          {bindRowValues(row, item)}
                        </Grid>
                      </React.Fragment>
                    }
                    {index !== 0 &&
                      <React.Fragment>
                        {bindRowValues(row, item)}
                      </React.Fragment>
                    }
                  </TableCell>
              )
            })
            }
            {actions && actions.length > 0 && cols.length > 0 &&
              addAction(row)
            }
          </TableRow>
        )
        }
      </React.Fragment>
    );
  }

  const getStatusClass = (row, item) => {
    const arr = item.split(".");
    const value = arr.reduce((obj, key) => (obj && obj[key] !== undefined)
      ? obj[key]
      : undefined,
      row
    );
    return value
  }

  // Adding action
  const addAction = (item) => {
    const actionItems = actions.slice();
    return (
      <TableCell key={"actions"} align="center">
        <Grid className={`${classes.actions}`}>
          {actionItems.indexOf("REFUND") >= 0 &&
            <Tooltip arrow title="Refund payment">
              {item.card_number != null && item.payment_status != 'Free' ?
                <IconButton className={classes.tableActionBtn} onClick={() => handelActionButtonClick("REFUND", item.id, item)}><RefundIcon /></IconButton>
                :
                <IconButton className={classes.tableActionBtn} onClick={() => { }}><RefundIconGrey /></IconButton>
              }
            </Tooltip>
          }
          {actionItems.indexOf("LOGIN") >= 0 && item.role !== "admin" && item.role !== "Admin" && currentUserRole !== "client" && currentUserRole !== "Client" &&
            <Tooltip arrow title="Sign in">
              {item.email != "" ?
                <IconButton className={classes.tableActionBtn} onClick={() => handelActionButtonClick("LOGIN", item.id, item)}><LoginIcon /></IconButton>
                :
                <IconButton className={classes.tableActionBtn} onClick={() => { }}><LoginIconGrey /></IconButton>
              }
            </Tooltip>
          }
          {actionItems.indexOf("DOWNLOAD") >= 0 &&
            <Tooltip arrow title="Download"><IconButton aria-controls="simple-menu" aria-haspopup="true" className={classes.tableActionBtn} onClick={(event) => handelDownloadButtonClick(event, item.id, item)} ><DownloadIcon /></IconButton></Tooltip>
          }
          {actionItems.indexOf("RETRY") >= 0 &&
            <Tooltip arrow title="Retry submission">
              {item.xml_exist == 1 ?
                <IconButton className={classes.tableActionBtn} onClick={() => handelActionButtonClick("RETRY", item.id, item)} ><ReloadIcon /></IconButton> :
                <IconButton className={classes.tableActionBtn} onClick={() => { }} ><ReloadIconGrey /></IconButton>
              }
            </Tooltip>
          }
          {actionItems.indexOf("EDIT") >= 0 &&
            <Tooltip arrow title="Edit"><IconButton className={classes.tableActionBtn} onClick={() => handelActionButtonClick("EDIT", item.id, item)}><EditIcon /></IconButton></Tooltip>
          }
          {actionItems.indexOf("SEARCH") >= 0 &&
            <Tooltip arrow title="search"><IconButton className={classes.tableActionBtn} onClick={() => handelActionButtonClick("SEARCH", item.id, item)}><SearchIconTable /></IconButton></Tooltip>
          }
          {actionItems.indexOf("SEARCHAUDIT") >= 0 &&
            <Tooltip arrow title="search">
              {/* {item.action && item.action.toLowerCase() !== "delete" ?
                <IconButton className={classes.tableActionBtn} disabled={(item.action && item.action.toLowerCase() !== "delete") ? false : true} onClick={() => handelActionButtonClick("SEARCH", item.id, item)}><SearchIconTable /></IconButton> :
                <IconButton className={classes.tableActionBtn} disabled={(item.action && item.action.toLowerCase() !== "delete") ? false : true} ><SearchIconTableGrey /></IconButton>
              } */}
              <IconButton className={classes.tableActionBtn} onClick={() => handelActionButtonClick("SEARCH", item.id, item)}><SearchIconTable /></IconButton>
            </Tooltip>
          }
          {actionItems.indexOf("GETLINK") >= 0 &&
            <Tooltip arrow title="Get link"><IconButton className={classes.tableActionBtn} onClick={() => handelActionButtonClick("GETLINK", item.id, item)}><LinkIcon /></IconButton></Tooltip>
          }
          {actionItems.indexOf("INVITE") >= 0 &&
            <Tooltip arrow title="invite"><IconButton className={classes.tableActionBtn} onClick={() => handelActionButtonClick("INVITE", item.id, item)}><EmailIcon /></IconButton></Tooltip>
          }
          {actionItems.indexOf("EDITMANY") >= 0 &&
            <Tooltip arrow title="Edit"><IconButton aria-controls="simple-menu" aria-haspopup="true" className={classes.tableActionBtn} onClick={(event) => handelEditButtonClick(event, item.id, item)} ><EditIcon /></IconButton></Tooltip>
          }
          {actionItems.indexOf("DELETE") >= 0 &&
            <Tooltip arrow title="Delete"><IconButton className={classes.tableActionBtn} onClick={() => handelActionButtonClick("DELETE", item.id, item)}><DeleteIcon /></IconButton></Tooltip>
          }
        </Grid>
      </TableCell>
    );
  };

  /**
   * Bind Body Rows Values
   * @param {*} row 
   * @param {*} item 
   */
  const bindRowValues = (row, item) => {
    const arr = item.split(".");
    const value = arr.reduce((obj, key) => (obj && obj[key] !== undefined)
      ? obj[key]
      : undefined,
      row
    );
    return (value ? <ToolTip title={value}><div className="checkBoxWithName">{value}</div></ToolTip> : '')
  }

  const displayPageInfo1 = ((page * rowsPerPage) - rowsPerPage) + 1;
  const displayPageInfo2 = data.length >= rowsPerPage ? page * rowsPerPage : (page * rowsPerPage) - (rowsPerPage - data.length);
  return (
    <Grid>
      <Grid className={classes.TableContainer} style={{ height: height }}>
        <T stickyHeader={true} className={"pr-1"}>
          <TableHead>
            {bindTableHeader()}
            {tableFilterRow()}
          </TableHead>
          <TableBody>
            {bindTableBody()}
          </TableBody>
        </T>

      </Grid>
      <Grid container justify="space-between" alignItems="center" className="mt-1 mb-1">
        <Grid item>
          <Grid container alignItems="center">
            {data.length > 0 &&
              <React.Fragment>
                <Typography className="mr-1" color="textSecondary" variant="body2" > {"Show"}</Typography>
                <Grid className={classes.tablePageSelect}>
                  <Select
                    options={userLength}
                    value={dataLength}
                    handleChange={(e) => handleChangeDatalength(e)}
                    timeOut={9000}
                  />
                </Grid>
              </React.Fragment>
            }
            {data.length > 0
              ? <Typography className="ml-2" variant="body2" color="textSecondary"> Displaying {displayPageInfo1} to  {displayPageInfo2} of {count} records</Typography>
              : <Typography className="ml-2" variant="body2" color="textSecondary" > {"No records Found"}</Typography>
            }
          </Grid>
        </Grid>
        {page &&
          <Grid item>
            <Pagination count={Math.ceil(count / rowsPerPage)} color={"primary"} shape="rounded" onChange={onChangePagination} page={page} showFirstButton showLastButton />
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

// prop types
TableComponent.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  field: PropTypes.array.isRequired,
  search: PropTypes.array.isRequired,
  sortBy: PropTypes.string,
  sort: PropTypes.string,
  height: PropTypes.any,
  checkBox: PropTypes.bool,
  reset: PropTypes.bool,
  updatePreference: PropTypes.func,
  tableId: PropTypes.string,
  handelEditButtonClick: PropTypes.func,
  loading: PropTypes.bool
}

// default types
TableComponent.defaultProps = {
  classes: {},
  data: [],
  header: [],
  field: [],
  search: [],
  sortBy: "",
  sort: "",
  height: "",
  checkBox: false,
  loading: false,
  reset: false,
  tableId: "table",
  updatePreference: () => { },
  handelEditButtonClick: () => { }
}

export const Table = withStyles(style)(TableComponent);
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core';
import moment from "moment"

//Styles
import TenantApplicationStyles from "../TenantApplicationStyles"

// Import Components
import { Signature, Typography } from "../../../components";
import FormFieldsGenerator from "../FieldHelpers/FormFieldsGenerator";

//import actions
import { alertAction, updateReducerAction } from "../../../redux/actions";

//import constants
import { tenantAppConstant } from "../../../redux/constants";

//import helpers
import { dataPopulator } from "../../../utils"

const Disclosure = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes } = props;

    //get reducer data
    const { appData: { disclosure, application, settings, disclosureConditions } } = useSelector(s => (s.tenantApp))

    const critRef = useRef(null);

    useEffect(() => {
        critRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, []);

    //update signature
    const handleSignChange = (sign, bs64, index) => {
        let disclosureData = disclosure;
        let updateData = disclosureData[index];
        if (updateData) {
            updateData["signature"] = bs64;
            updateData["sign"] = sign;
            disclosureData[index] = updateData
            dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_DISCLOSURE, disclosureData))
        } else {
            dispatch(alertAction.error("Error"))
        }
    }

    //change payment form
    const handleChange = (e, activeIndex, choiceIndx, maxLen, checkIndex) => {

        let disclosuretmp = disclosure;
        let activeDisclosure = disclosuretmp[activeIndex];
        //check if the index exists
        if (activeIndex > -1 && activeDisclosure && choiceIndx > -1) {
            let choiceData = activeDisclosure["choiceData"] && activeDisclosure["choiceData"][choiceIndx] ? activeDisclosure["choiceData"][choiceIndx] : {};
            //update choice data falg value
            if (choiceData && typeof choiceData === "object" && Object.keys(choiceData).length) {
                let variation = choiceData["variation"] || [];
                for (let t = 0; t < variation.length; t++) {
                    if (checkIndex === t) {
                        variation[t]["value"] = e["value"];
                    } else {
                        variation[t]["value"] = false;
                    }
                }
                //update variation
                activeDisclosure["choiceData"][choiceIndx] = { ...choiceData, variation: variation }
                disclosuretmp[activeIndex] = activeDisclosure;
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_DISCLOSURE, disclosuretmp))
            }
        }
    }

    //check and change condition
    const handleConditionChange = (e, activeIndex, layoutIndx, maxLen, checkIndex) => {
        //get current disclosure data
        let disclosuretmp = disclosure;
        let activeDisclosure = disclosuretmp[activeIndex];
        //check if the index exists
        if (activeIndex > -1 && activeDisclosure && activeDisclosure["layout"]) {
            //get the active layout data from layout array
            let activeLayout = activeDisclosure["layout"][layoutIndx]
            if (activeLayout) {
                //update value
                activeLayout = { ...activeLayout, elements: { ...activeLayout["elements"], [e["name"]]: { ...activeLayout["elements"][e["name"]], "value": e["value"] } } }
                activeDisclosure["layout"][layoutIndx] = activeLayout;

                //checking condition is satisfied or not
                if (activeDisclosure["conditions"]) {

                    let trueCount = 0;
                    let totalCount = activeDisclosure["conditions"].length;
                    let DOBsatisfied = false
                    //update field values
                    for (let c = 0; c < totalCount; c++) {
                        if (activeDisclosure["conditions"][c] && typeof activeDisclosure["conditions"][c] === "object") {
                            let fieldName = Object.keys(activeDisclosure["conditions"][c]).length ? Object.keys(activeDisclosure["conditions"][c])[0] : ""
                            let layoutName = activeDisclosure["layout"] ? activeDisclosure["layout"].filter(l => (l["elements"][fieldName])) : [];

                            if (layoutName && layoutName.length && layoutName[0]["elements"] && layoutName[0]["elements"][fieldName] && layoutName[0]["elements"][fieldName]["value"] !== "date" && layoutName[0]["elements"][fieldName]["value"] && layoutName[0]["elements"][fieldName]["value"] === activeDisclosure["conditions"][c][fieldName]) {
                                trueCount = trueCount + 1;
                            }

                            if (layoutName && layoutName.length && layoutName[0]["elements"] && layoutName[0]["elements"][fieldName] && layoutName[0]["elements"][fieldName]["type"] === "date" && layoutName[0]["elements"][fieldName]["value"] && new Date(layoutName[0]["elements"][fieldName]["value"]) != "Invalid Date" && Number(activeDisclosure["conditions"][c][fieldName]) && moment().diff(layoutName[0]["elements"][fieldName]["value"], 'years', false) < Number(activeDisclosure["conditions"][c][fieldName])) {
                                trueCount = trueCount + 1;
                                DOBsatisfied = true;
                            }

                            if (disclosureConditions && disclosureConditions.length > 0) {
                                for (let r = 0; r < disclosureConditions.length; r++) {
                                    if (fieldName === "DateOfBirth" && disclosureConditions[r][fieldName] && Number(disclosureConditions[r][fieldName]) && disclosureConditions[r]["value"] && new Date(disclosureConditions[r]["value"]) != "Invalid Date" && moment().diff(disclosureConditions[r]["value"], 'years', false) < Number(disclosureConditions[r][fieldName])) {
                                        trueCount = trueCount + 1;
                                        DOBsatisfied = true;
                                    }

                                    if (disclosureConditions[r][fieldName] && disclosureConditions[r]["value"] && disclosureConditions[r][fieldName] === disclosureConditions[r]["value"]) {
                                        trueCount = trueCount + 1;
                                    }
                                }
                            }
                        }
                    }

                    if (DOBsatisfied) {
                        activeDisclosure["is_signature"] = 1
                    } else {
                        activeDisclosure["is_signature"] = activeDisclosure["is_sign_dup"]
                    }
                    //update whether the conidition is satisfied or not
                    // if (trueCount === totalCount) {
                    if (trueCount >= 1) {
                        activeDisclosure["conditionTrue"] = true
                        activeDisclosure["conditionLayoutTrue"] = true
                    } else {
                        activeDisclosure["conditionTrue"] = false
                        activeDisclosure["conditionLayoutTrue"] = false
                    }
                }
                //check for visible fields and its conditions
                let repeatData = activeDisclosure["layout"] ? activeDisclosure["layout"] : []
                const repeatExists = repeatData.filter(rt => (rt && rt["visible"]))
                if (repeatExists.length) {
                    for (let m = 0; m < repeatData.length; m++) {
                        const repeatKeys = repeatData[m]["visible"] && Object.keys(repeatData[m]["visible"]).length ? Object.keys(repeatData[m]["visible"]) : [];
                        if (repeatKeys && repeatKeys.length) {
                            for (let i = 0; i < repeatData.length; i++) {
                                if (repeatData[i]["elements"]) {
                                    for (let j = 0; j < repeatKeys.length; j++) {
                                        if (e["name"] === repeatKeys[j]) {
                                            if (e["value"] === repeatData[m]["visible"][repeatKeys[j]]) {
                                                repeatData[m]["vis"] = true;
                                            } else {
                                                repeatData[m]["vis"] = false;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    activeDisclosure["layout"] = repeatData;
                }
                disclosuretmp[activeIndex] = activeDisclosure;
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_DISCLOSURE, disclosuretmp))
            }
        }
    }

    const generateContent = (item1, indx) => (
        <Grid className={`${"tenantCardContainer"} `}>

            <Grid item sm={12} className="mb-1">
                <span dangerouslySetInnerHTML={{ __html: dataPopulator(settings, item1["content"]) }}></span>
            </Grid>

            {item1["choiceData"] && item1["choiceData"].length > 0 &&
                item1["choiceData"].map((im, ix) => (
                    <Grid>
                        <Typography variant="body1">{im["name"] || "name"}</Typography>
                        <FormFieldsGenerator
                            item={im["name"] || "name"}
                            indx={ix}
                            indx0={indx}
                            contents={im || {}}
                            handleChange={handleChange}
                            data={{}}
                            settings={settings}
                            validataForm={im["validataForm"]}
                        />
                    </Grid>
                ))
            }

            {item1["is_signature"] > 0 &&
                <Grid item sm={12} className="mb-2" >
                    <Signature
                        name={"sign"}
                        classes={classes}
                        index={indx}
                        sigPad={item1["sign"] ? item1["sign"] : {}}
                        signImg={item1["signature"] ? item1["signature"] : ""}
                        handleSignChange={handleSignChange}
                    />
                </Grid>
            }
        </Grid>
    )

    return (
        <Grid item xs={12} style={{ overflowX: "hidden" }}>
            <form>
                <Grid container className="sm-p-3" ref={critRef}>
                    <Grid item xs={12} className={`${"p-1 sm-p-3"} ${classNames(classes.criteriaContainer, classes.cardContainer)}`}>
                        {disclosure && disclosure.length && disclosure.map((item1, indx) => (
                            <Grid key={"disc" + indx}>
                                {item1["isVisible"] &&
                                    <React.Fragment>
                                        <Grid item sm={12} className="mb-1">
                                            <Typography variant="h5">{item1["title"] || ""}</Typography>
                                        </Grid>
                                        {((item1["conditions"] && typeof item1["conditions"] === "object") || (item1["layout"] && typeof item1["layout"] === "object")) ?
                                            <React.Fragment>
                                                {item1["layout"] && typeof item1["layout"] === "object"
                                                    && item1["layout"].map((im, ix) => (
                                                        <Grid key={"doiscQues" + ix}>
                                                            {im && im["elements"] && !im["visible"] && Object.keys(im["elements"]).length && Object.keys(im["elements"]).map((item1, indx1) => (
                                                                <Grid item sm={6} xs={12} key={`disc${indx1}ccsdf`}>
                                                                    <Grid item sm={12} className="mt-2">
                                                                        <Typography className="transCapital" variant="h5">{im && im["title"] ? im["title"] : ""}</Typography>
                                                                    </Grid>
                                                                    <Grid item sm={12}>
                                                                        <span dangerouslySetInnerHTML={{ __html: dataPopulator(settings, `<p>${im && im["description"] ? im["description"] : ""}</p>`) }}></span>
                                                                    </Grid>
                                                                    <Grid item key={`discinnr${indx1}cc`}>
                                                                        <Typography variant="body1">{im["elements"][item1] && im["elements"][item1]["label"] ? `${im["elements"][item1]["label"]} ${im["elements"][item1] && im["elements"][item1]["required"] ? "*" : ""}` : ""}</Typography>
                                                                        <FormFieldsGenerator
                                                                            item={item1}
                                                                            indx={ix}
                                                                            indx0={indx}
                                                                            contents={im["elements"][item1] || {}}
                                                                            handleChange={handleConditionChange}
                                                                            data={im["elements"] || {}}
                                                                            managerList={application["mangerList"]}
                                                                            regionList={application["regionList"]}
                                                                            validataForm={item1["validataForm"]}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                            {im && im["elements"] && (im["visible"] && im["vis"]) && Object.keys(im["elements"]).length && Object.keys(im["elements"]).map((item1, indx1) => (
                                                                <Grid item sm={6} xs={12} key={`disc${indx1}ccsdf`}>
                                                                    <Grid item sm={12} className="mt-2">
                                                                        <Typography className="transCapital" variant="h5">{im && im["title"] ? im["title"] : ""}</Typography>
                                                                    </Grid>
                                                                    <Grid item sm={12}>
                                                                        <span dangerouslySetInnerHTML={{ __html: `<p>${im && im["description"] ? im["description"] : ""}</p>` }}></span>
                                                                    </Grid>
                                                                    <Grid item key={`discinnr${indx1}cc`}>
                                                                        <Typography variant="body1">{im["elements"][item1] && im["elements"][item1]["label"] ? `${im["elements"][item1]["label"]} ${im["elements"][item1] && im["elements"][item1]["required"] ? "*" : ""}` : ""}</Typography>
                                                                        <FormFieldsGenerator
                                                                            item={item1}
                                                                            indx={ix}
                                                                            indx0={indx}
                                                                            contents={im["elements"][item1] || {}}
                                                                            handleChange={handleConditionChange}
                                                                            data={im["elements"] || {}}
                                                                            managerList={application["mangerList"]}
                                                                            regionList={application["regionList"]}
                                                                            validataForm={item1["validataForm"]}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    ))
                                                }
                                                {item1["conditionTrue"] && generateContent(item1, indx)}
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {generateContent(item1, indx)}
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
}

// default props
Disclosure.defaultProps = {
    classes: {}
};

// prop types
Disclosure.propTypes = {
    classes: PropTypes.object
};

export default withStyles(TenantApplicationStyles)(Disclosure);

import { compose, createStore, applyMiddleware } from "redux";
import { logger } from "redux-logger";
import thunkMiddleware from "redux-thunk";

// Import Reducers and Constants Files
import appReducer from "../redux/reducers";
import { authConstant } from "../redux/constants";

const rootReducer = (state, action) => {
  if (action.type === authConstant.LOGOUT_SUCCESS) {
    state = undefined;
  } else if (action.type ===  "RESET") { // Reset state values

  } else {}

  return appReducer(state, action);
};

const enhancer = compose(
  applyMiddleware(
    thunkMiddleware,
    logger
  )
);

export default function store (initialState) {
  return createStore(
    rootReducer,
    initialState,
    enhancer
  );
}

/* eslint-disable no-constant-condition */
/* eslint-disable no-self-compare */
/* eslint-disable prefer-const */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button as Core } from "@material-ui/core";

// Imports Styles
import style from "./style";

/**
 * Button Component
 * @param {*} props
 */
function ButtonComponent(props) {

  const { className, classes, type, title,children, color, disableElevation, size, variant, component, href, disabled, onClick } = props;
  return (
    <Core
      type={type}
      color={color}
      disableElevation={disableElevation}
      size={size}
      variant={variant}
      component={component}
      disabled={disabled}
      classes={classes}
      className={className}
      href={href}
      onClick={onClick}>{title}{children}</Core>
  );
}

// Default props
ButtonComponent.defaultProps = {
  type: "button",
  title: "",
  color: "",
  classes: "",
  className: "",
  href: "",
  disableElevation: true,
  size: "small",
  variant: "contained",
  component: "button",
  disabled: false,
  onClick: () => { },
};

// Prop types
ButtonComponent.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  href: PropTypes.any,
  component: PropTypes.elementType,
  disabled: PropTypes.bool,
  disableElevation: PropTypes.bool,
  onClick: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string
};

/** Export Component */
export const Button = withStyles(style)(ButtonComponent);

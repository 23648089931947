import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { Backdrop, IconButton } from '@material-ui/core';

//Styles & Images
import TemplatesStyles from "../TemplatesStyles"
import { CloseIcon } from '../../../assets/svg'

// Import Components
import { Button, TextField, Typography, RichTextEdit, TextArea, TextAreaNew } from "../../../components";

// Import Cofig Constants
import { templates_page_constants } from "../../../config/constants";

// Import Actions
import { updateReducerAction, templateAction, alertAction } from "../../../redux/actions";

// Import Helpers
import validations from "../../../utils/validations";
import { richTextTester } from '../../../utils'

//import constant
import { templateConstant } from '../../../redux/constants'

const AddEditEmail = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes } = props;

    //get reduer data
    const { emailDrawer: drawer, getOneEmail, getOneEmailOld, getAllEmail: { page } } = useSelector(s => (s.template))

    //set state for rich text value
    const [richText, setRichText] = useState(getOneEmail && getOneEmail.content ? getOneEmail.content : '')
    const [contentText, setContentText] = useState(getOneEmail && getOneEmail.content ? getOneEmail.content : '')

    //open close toggle redcer update
    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        dispatch(updateReducerAction.update(templateConstant.UPDATE_EMAIL_DRAWER, open))
    };

    // Set Form Values
    const { register, handleSubmit, errors, setValue, clearErrors } = useForm({
        mode: 'all',
        reValidateMode: 'all',
        defaultValues: {
            name: '',
            subject: '',
        }
    });

    //update form data on change
    useEffect(() => {
        if (getOneEmail) {
            setValue("name", getOneEmail && getOneEmail.name ? getOneEmail.name : '')
            setValue("subject", getOneEmail && getOneEmail.subject ? getOneEmail.subject : '')
            clearErrors("name")
            clearErrors('subject')
        }
        setRichText(getOneEmail && getOneEmail.content ? getOneEmail.content : '')
        setContentText(getOneEmail && getOneEmail.content ? getOneEmail.content : '')
    }, [getOneEmail]);

    // Submit Form
    const onSubmit = (data) => {
        if (data) {
            if (richTextTester(richText) === true) {
                dispatch(alertAction.error("Please enter description"));
            } else if (data["name"] && data["name"].length > 255) {
                dispatch(alertAction.error("Name length should be less than 255"));
            } else if (data["subject"] && data["subject"].length > 250) {
                dispatch(alertAction.error("Subject length should be less than 250"));

            } else {
                data['id'] = getOneEmail && getOneEmail.id ? getOneEmail.id : null;
                data['content'] = richText
                dispatch(templateAction.addOrUpdateEmail({ ...data, oldData: getOneEmailOld || {} }, () => {
                    dispatch(templateAction.getAllEmail({ limit: 20, skip: 0, search: page.search, sort: page.sort }))
                }))
            }
        }
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backDrop} onClick={() => { toggleDrawer(false) }} open={drawer} />
            <Button color="primary" title={templates_page_constants.ADD_EMAILTEMPLATE} onClick={toggleDrawer(true)}></Button>
            <Drawer anchor='right' role="presentation" variant="persistent" open={drawer} onClose={toggleDrawer(false)} className={classes.allianceDrawer600}>
                <Grid container alignItems="center" justify="space-between" className={"pl-2 pt-1 pr-2 pb-2"}>
                    <Typography variant="h4" title={`${getOneEmail && getOneEmail.id ? "EDIT" : "ADD"} EMAIL TEMPLATE`}></Typography>
                    <IconButton onClick={toggleDrawer(false)}><CloseIcon /></IconButton>
                </Grid>
                <Grid container className="pl-2 pr-2 h-100">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Grid item xs={12}>
                            <Grid container justify="space-between" className={classes.addEditEMailTemDiv} spacing={3}>
                                <Grid item sm={12}>
                                    <Typography variant="body1">{templates_page_constants.TEMPLATE_NAME}<span className={"reqStar"}>*</span></Typography>
                                    <TextField
                                        id={'name'}
                                        name="name"
                                        margin={"none"}
                                        placeholder={templates_page_constants.TEMPLATE_NAME}
                                        error={errors.name ? true : false}
                                        helperText={errors.name?.message}
                                        inputRef={register({
                                            validate: {
                                                ...validations.isRequired('Template Name cannot be blank')
                                            }
                                        })}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography variant="body1">{templates_page_constants.SUBJECT}<span className={"reqStar"}>*</span></Typography>
                                    <TextField
                                        id={'subject'}
                                        name="subject"
                                        margin={"none"}
                                        placeholder={templates_page_constants.SUBJECT}
                                        error={errors.subject ? true : false}
                                        helperText={errors.subject?.message}
                                        inputRef={register({
                                            validate: {
                                                ...validations.isRequired('Subject cannot be blank')
                                            }
                                        })}
                                    />
                                </Grid>
                                <Grid item sm={12} align="left" className={`${classes.emailTempTextEditor} ${"bgGray"}`}>
                                    <Typography variant="body1">{templates_page_constants.EMAIL_CONTENT}</Typography>
                                    <RichTextEdit
                                        value={contentText ? contentText : ""}
                                        onChange={(v) => { setRichText(v) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="mt-1">
                            <Grid container className="pt-6 pb-1">
                                <Button color="primary" size="small" type='submit' title={getOneEmail && getOneEmail.id ? templates_page_constants.UPDATE : 'Create'} className="mr-1"></Button>
                                <Button color="secondary" size="small" onClick={toggleDrawer(false)} title={templates_page_constants.CANCEL}></Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Drawer>
        </React.Fragment>
    )
}
// default props
AddEditEmail.defaultProps = {
    classes: {},
};

// prop types
AddEditEmail.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(TemplatesStyles)(AddEditEmail);

import React, { useState, useEffect } from 'react';
import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid,withStyles, IconButton } from "@material-ui/core";
import arrayMove from "array-move";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

//Styles and images
import TemplatesStyles from "../TemplatesStyles"
import { DragIcon, DeleteIcon } from '../../../assets/svg'

//Components
import { Typography, AlertDialog, Button } from "../../../components";

//import constant
import { templateConstant } from '../../../redux/constants'

// Import Actions
import { updateReducerAction } from "../../../redux/actions";

//Sortable Item
const SortableItem = SortableElement(props => {
    return (
        props.children
    );
});

//Drag Handle
const DragHandle = SortableHandle((props) => {
    const { classes } = props;

    return (<DragIcon className={"cla"} draggable={false}></DragIcon>);
})

//create sortable list for drag and drop
const SortableList = SortableContainer(props => {

    //get props
    const { data, classes, setDeleteAlert } = props;

    return (
        <React.Fragment>
            <Grid container className="">
                {data && data.map((item, index) => (
                    <SortableItem
                        key={`sort-item-${index}`}
                        index={index}
                    >
                        <Grid container alignItems="center" className={classes.AddSettingsTile}>
                            <DragHandle />
                            <Typography variant={"body1"} title={item.title} className={classNames(classes.SettignsTileText, classes.grow)}></Typography>
                            <IconButton className="p5" onClick={(e)=>{setDeleteAlert({"delIndex":index})}}><DeleteIcon/></IconButton>
                        </Grid>
                    </SortableItem>
                ))}
            </Grid>
        </React.Fragment>
    )
})

function AgreementContainer(props) {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes } = props;

    //get reducer data
    const { agreement:{result} } = useSelector(state => state.template);

    //set state
    const [deleteAlert, setDeleteAlert] = useState(null);

    //check row index on sort
    const onSortEnd = (e) => {
        const { oldIndex, newIndex } = e;

        let resultData = arrayMove(result, oldIndex, newIndex);
        for(let i=1;i<=resultData.length;i++){
            resultData[i-1]["position"]=i;
        }
        dispatch(updateReducerAction.update(templateConstant.UPDATE_AGREEMENT_ARR_DATA, resultData))
    }

    //handle delete
    const handleDelete=(index)=>{
        let resultData = JSON.parse(JSON.stringify(result));
        resultData.splice(index,1);
        
        for(let i=1;i<=resultData.length;i++){
            resultData[i-1]["position"]=i;
        }
        dispatch(updateReducerAction.update(templateConstant.UPDATE_AGREEMENT_ARR_DATA, resultData,()=>{
            setDeleteAlert(null)
        }))
    }

    return (
        <React.Fragment>
            <SortableList
                useDragHandle
                axis="xy"
                enableUserSelectHack={false}
                helperClass="sortable-list-tab"
                data={result}
                onSortEnd={onSortEnd}
                classes={classes}
                setDeleteAlert={setDeleteAlert}
            ></SortableList>
                {deleteAlert &&
                    <AlertDialog
                        fullScreen={false}
                        open={true}
                    >
                        <Grid className={'p-4'}>
                            <Typography variant="h6">{ `Are you sure do you want to remove the document`}</Typography>
                            <Grid className={'pt-2'} align={"center"}>
                                <Button
                                    color={"primary"}
                                    className={"mr-1"}
                                    onClick={() => { handleDelete(deleteAlert["delIndex"]) }}
                                    title={"Continue"}
                                />
                                <Button
                                    color={"secondary"}
                                    onClick={() => { setDeleteAlert(null) }}
                                    title={"Cancel"}
                                />
                            </Grid>
                        </Grid>
                    </AlertDialog>
                }
        </React.Fragment>
    )
}

// default props
AgreementContainer.defaultProps = {
    classes: {},
};

// prop types
AgreementContainer.propTypes = {
    classes: PropTypes.object,
};

// export default UserList;
export default withStyles(TemplatesStyles)(AgreementContainer);
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from '@material-ui/core/Drawer';
import { Backdrop, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

//Styles
import SubmissionsStyles from '../SubmissionsStyles'

//Images
import { CloseIcon } from '../../../assets/svg'

//Components
import { Button, TextField, Typography} from "../../../components";

//Constants
import { submissions_page_constants } from "../../../config/constants"

// Import Actions
import { alertAction, submissionAction } from "../../../redux/actions";
import { Alert } from "@material-ui/lab";

function RetrySubmission(props) {
    const { classes,setAnchorl,anchorl } = props;
        
    //define dispatch
    const dispatch = useDispatch();

    const [drawer, setDrawer] = useState(true);
    const [text, setText] = useState('');

    let retrySubmissionDone = 0;
    
    //get reduer data
    const { getRetrySubmission, retryError } = useSelector(s=>(s.submission))

    const toggleDrawer = (open) => (event) => {
        console.log('drawer close event trigger');
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawer(open);
        setAnchorl(open)
        if (retrySubmissionDone) {
            dispatch(submissionAction.search(1, anchorl.page.limit, "create_time", "desc", anchorl.page.sFields, anchorl.page.sValues,1));
        }
    };

    //hadle search to get data
    const handlechange = (e) => {
      setText(e.target.value);
    }

    // Submit Form
    const onSubmit = () => {
        retrySubmissionDone = 1;
        dispatch(submissionAction.retrySubmission({ submission_XML: text,submission_id:anchorl.item.id,applicant:anchorl.item.applicant }, (responseData) => {
            if (responseData.status == 'success') {
                dispatch(toggleDrawer(false))    
            }
        }));
    }

    //On retry submission data paste - Check the restricted character exist
    const handlePasteEvent = (e) => {
        let clipboardData = e.clipboardData.getData('Text');
        if(/^[a-zA-Z0-9!@#$%^*()_\-\+=., ]*$/.test(clipboardData) == false) {
	    	dispatch(alertAction.error('Please enter only these accepted characters a-z,0-9,!@#$%^*()_-+=.,'));
            e.preventDefault();
            e.stopPropagation();
	    }
    }
    
    //On retry submission data keypress - Check the restricted character exist
    const handleKeyPress = (e) => {
        let code = e.charCode;
        if (!(code == 32) && // space
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123) && // lower alpha (a-z)
        !(code == 33) && // !
        !(code == 64) && // @
        !(code == 35) && // #
        !(code == 36) && // $
        !(code == 37) && // %
        !(code == 94) && // ^
        !(code == 42) && // *
        !(code == 40) && // (
        !(code == 41) && // )
        !(code == 95) && // _
        !(code == 45) && // -
        !(code == 43) && // +
        !(code == 61) && // =
        !(code == 46) && // .
        !(code == 44)  // ,
        ) { 
            dispatch(alertAction.error('Please enter only these accepted characters a-z,0-9,!@#$%^*()_-+=.,'));
            e.preventDefault();
            e.stopPropagation();
        }
    };

    //update form data on change
    useEffect(() => {
        if (getRetrySubmission) {
            setText(getRetrySubmission)
        }
    }, [getRetrySubmission]);

    return (
        <React.Fragment>
            <Backdrop className={classes.backDrop} onClick={toggleDrawer(false)}  open={drawer}/>
            <IconButton onClick={toggleDrawer(true)} className={classes.padding5}></IconButton>
            <Drawer anchor='right' role="presentation" variant="persistent" open={drawer} onClose={toggleDrawer(false)} className={classes.allianceDrawer}>
                
                <Grid container alignItems="center" justify="space-between" className={"pt-1 pb-2 pl-2 pr-2 drawerHeader"}>
                    <Typography variant="h4" title={submissions_page_constants.RETRY_SUBMISSION}></Typography>
                    <IconButton onClick={toggleDrawer(false)} className={classes.padding5}><CloseIcon /></IconButton>
                </Grid>

                {retryError["error"] &&  <Grid container className={`${classes.errorContainer} ${"pl-2 pr-2 pb-2"}`}>
                    <Alert severity="error">{retryError["error"] || ""}</Alert>
                
                </Grid>}


                {/* <form onSubmit={handleSubmit(onSubmit)} noValidate> */}
                    <Grid container className="pl-2 pr-2 pb-2">
                        <TextField
                            className={classes.textArea}
                            multiline
                            rows={25} 
                            margin='none'
                            onKeyPress={handleKeyPress}
                            onChange={handlechange} 
                            onPaste={handlePasteEvent}
                            value={text}
                        />
                    </Grid>
                    <Grid container className="p-2 drawerFooter">
                        <Button color="primary" size="small" title={submissions_page_constants.RETRY_SUBMIT} className="mr-1" onClick={onSubmit}></Button>
                        <Button color="secondary" size="small" title={submissions_page_constants.RETRY_CANCEL} onClick={toggleDrawer(false)}></Button>
                  </Grid>
                {/* </form> */}
            </Drawer>
        </React.Fragment>

    )
}

// default props
RetrySubmission.defaultProps = {
    classes: {},
    drawer: false,
    toggleDrawer: () => { },
};

// prop types
RetrySubmission.propTypes = {
    classes: PropTypes.object,
    drawer: PropTypes.bool,
    toggleDrawer: PropTypes.func
};

// export default UserList;
export default withStyles(SubmissionsStyles)(RetrySubmission);
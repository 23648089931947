import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Tooltip, withStyles, Slide } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//Images
import { SearchIcon, SortIcon, MoreIcon } from '../../../assets/svg'
import TemplatesStyles from "../TemplatesStyles"

// Import Components
import { Button, Typography, AlertDialog, SearchBar, ToolTip, LazyLoader } from "../../../components";
import AddEditAppTemplate from './AddEditAppTemplate';
import NoRecordsFound from "../../Users/Components/NoRecordsFound";

// Import Actions
import { updateReducerAction, templateAction } from "../../../redux/actions";

//import helpers
import { nameLogoGeneration } from '../../../utils'

// Import Config Layout
import { getTemplatesHeight, applicationsCardLimit } from "../../../config/layout";

//import constant
import { templateConstant } from '../../../redux/constants'

const height = getTemplatesHeight();

const ApplicationTemplates = (props) => {
  //define dispatch
  const dispatch = useDispatch();

  // Set Card Limit and Size
  const cardLimit = applicationsCardLimit();
  const cardSize = Math.floor(12 / (cardLimit / 8))

  //set ref for scroll
  const scrollApp = useRef(null)

  //get props
  const { classes } = props;

  //get reducer data
  const { appTemp, appTemplate: { data, page }, applicationDrawer } = useSelector(s => (s.template))
  let { lazyLoading, loading } = useSelector((state) => state.UI);

  //get data oninit
  useEffect(() => {
    dispatch(templateAction.getAllApplication({ limit: cardLimit, skip: 0, search: page.search, sort: page.sort }))
    dispatch(templateAction.getAggrementName())
    dispatch(templateAction.getEmailTemplateName())
  }, [dispatch])

  // More option
  const [anchorEl, setAnchorEl] = useState(null);
  const [templateID, setTemplateID] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(null);
  const [showScrollTopBTN, setShowScrollTopBTN] = useState(false)

  //handle more option change
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setTemplateID(id)
  };

  //close more options
  const handleClose = () => {
    setAnchorEl(null);
    setTemplateID(null)
  };

  //scroll event
  const getScrollData = () => {
    const isBottom = parseInt(scrollApp.current.scrollHeight - scrollApp.current.scrollTop) === (scrollApp.current.clientHeight) || parseInt(scrollApp.current.scrollHeight - scrollApp.current.scrollTop) === (scrollApp.current.clientHeight + 1) || parseInt(scrollApp.current.scrollHeight - scrollApp.current.scrollTop) === (scrollApp.current.clientHeight - 1);
    if (!lazyLoading && isBottom && data.length < page.count) {
      //get next data
      dispatch(templateAction.getAllApplication({ limit: page.limit, skip: page.skip + page.limit, search: page.search, sort: page.sort, loader: true }))
    }

    if (scrollApp.current.scrollTop > 150 && showScrollTopBTN === false) {
      setShowScrollTopBTN(true)
    }
    else if (scrollApp.current.scrollTop < 150 && showScrollTopBTN === true) {
      setShowScrollTopBTN(false)
    }
  }

  //get sort data
  const getSortedData = (sorting) => {
    dispatch(templateAction.getAllApplication({ limit: cardLimit, skip: 0, search: page.search, sort: sorting }))
  }

  //edit template
  const editAppTemplate = () => {
    if (templateID) {
      dispatch(templateAction.getOneAppTemplate({ id: templateID }, () => {
        setAnchorEl(null);
      }))
    }
  }

  //delete template
  const deleteAppTemplate = () => {
    if (templateID) {
      dispatch(templateAction.deleteAppTemplate({ id: templateID }, () => {
        dispatch(templateAction.getAllApplication({ limit: cardLimit, skip: 0, search: page.search, sort: page.sort }))
        handleClose()
        setDeleteAlert(null)
      }))
    }
  }

  //open or close add edit popup
  const openCloseAdd = () => {
    dispatch(updateReducerAction.update(templateConstant.UPDATE_APP_DRAWER, !applicationDrawer))
    if (!applicationDrawer === false) {
      setTemplateID(null)
    }
  }

  //get search data
  const intiSearch = (value) => {
    dispatch(templateAction.getAllApplication({ limit: cardLimit, skip: 0, search: value, sort: page.sort }))
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container justify="space-between" className={classes.serviceActionBtn} alignItems="center">
          <Grid item>
            <Typography variant="h6" color="TextSecondary" component="h3"> {`Count ${page && page.count >= 0 ? `(${page.count})` : ""}`} </Typography>
          </Grid>
          <Grid>
            <Grid container alignItems="center">
              <SearchBar className={"mr-1"} loading={loading} value={page.search} intiSearch={(v) => { intiSearch(v) }} />
              <Grid className={"mr-1"}>
                {page && page.sort && page.sort === "asc" ?
                  <Tooltip arrow title="Sort By Created Date"><IconButton className={classes.searchIcon} onClick={() => { getSortedData('desc') }}><SortIcon /></IconButton></Tooltip>
                  :
                  <Tooltip arrow title="Sort By Created Date"><IconButton className={classes.searchIcon} onClick={() => { getSortedData('asc') }}><SortIcon /></IconButton></Tooltip>
                }
              </Grid>
              <Button color="primary" title={`+ ADD APPLICATION TEMPLATE`} onClick={() => { openCloseAdd() }}></Button>
              {(templateID && appTemp && Object.keys(appTemp).length) > 0 &&
                <AddEditAppTemplate
                  openCloseAdd={openCloseAdd}
                  templateID={templateID}
                />
              }
              {(!templateID) &&
                <AddEditAppTemplate
                  openCloseAdd={openCloseAdd}
                  templateID={null}
                />
              }
            </Grid>
          </Grid>
        </Grid>

        {data && data.length > 0 ?
          <Grid
            container
            spacing={0}
            className={classes.containerScroll}
            style={{ height: height, paddingRight: 12 }}
            alignContent="baseline"
            ref={scrollApp}
            onScroll={() => { getScrollData() }}
          >
            <Grid container spacing={2}>
              {data && data.map((itm, indx) => (
                <Grid className="animate__animated animate__fadeInUp animate__delay-0.8s" item sm={cardSize}>
                  <Grid className={`${"ApplicationlTile"} ${classes.ApplicationlTile}`}>
                    <Grid container alignItems="center">
                      <Grid item className={classes.AppLeftImage}>
                        <Typography color="textSecondary" variant="h3">{nameLogoGeneration(itm.name ? itm.name : "")}</Typography>
                      </Grid>
                      <Grid item className={classes.AppCenterPart}>
                        <Grid container direction="column">
                          <Typography variant="h6" align="left" className={`${"transCapital oneLineEllip"}`}>{itm.name ? itm.name : ""}</Typography>
                          <Typography variant="body1" align="left" color="textSecondary" className={`${classes.templateText} ${"oneLineEllip"}`}>{itm.description ? itm.description : ""}</Typography>
                          <Grid className={classes.customChipContainer}>
                            {/* <Chip label={itm.aggName ? itm.aggName : "-"} />
                          <Chip label={itm.emailName ? itm.emailName : "-"} /> */}
                            <ToolTip title={itm.aggName}><Grid className={classes.customChip}>{itm.aggName ? itm.aggName : "-"}</Grid></ToolTip>
                            <ToolTip title={itm.emailName}><Grid className={classes.customChip}>{itm.emailName ? itm.emailName : "-"}</Grid></ToolTip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.AppRightPart}>
                        <IconButton style={{ padding: 0 }} onClick={(e) => { handleClick(e, itm.id) }}><MoreIcon /></IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <LazyLoader />
            </Grid>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => { editAppTemplate() }}>Edit</MenuItem>
              <MenuItem onClick={() => { setDeleteAlert({ type: true, dataa: data.filter(i => (i.id === templateID)) }) }}>Delete</MenuItem>
            </Menu>
          </Grid>
          : !loading ? <NoRecordsFound /> : null}
        {showScrollTopBTN && <IconButton onClick={() => { scrollApp.current.scrollTo(0, 0) }} className={"toTop"}><KeyboardArrowUpIcon /></IconButton>}
      </Grid>
      {deleteAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            <Typography variant="h6">{deleteAlert.dataa && deleteAlert.dataa[0] && deleteAlert.dataa[0]['template_id'] ? "The application template is mapped to a service You cannot delete it" : `Are you sure you want to delete`}</Typography>
            <Grid className={'pt-2'} align={"center"}>
              {deleteAlert.dataa && deleteAlert.dataa[0] && !(deleteAlert.dataa[0]['template_id']) &&
                <Button
                  color={"primary"}
                  className={"mr-1"}
                  onClick={() => { deleteAppTemplate() }}
                  title={"Continue"}
                />
              }
              <Button
                color={"secondary"}
                onClick={() => { handleClose(); setDeleteAlert(null) }}
                title={"Cancel"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
    </React.Fragment>
  );
}

// default props
ApplicationTemplates.defaultProps = {
  classes: {}
};

// prop types
ApplicationTemplates.propTypes = {
  classes: PropTypes.object
};

export default withStyles(TemplatesStyles)(ApplicationTemplates);

import React from "react";
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core';

// Import Actions
import { authAction } from "../../redux/actions";

//Styles
import AuthenticationStyles from "../../layouts/AuthenticationLayout/AuthenticationLayoutStyles"

// Import Components
import { Button, TextField, Typography, Link } from "../../components";

// Import Helpers
import validations from "../../utils/validations";

// Import Cofig Constants
import { errorsConstants, common_constants } from "../../config/constants";

//import history
import history from "../../config/history";

const RequestAccess = (props) => {

  // Get Props
  const { classes } = props;

  // Set Form Values
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: "",
      password: ""
    }
  });

  // Define Dispatch
  const dispatch = useDispatch();

  // Submit Form
  const onSubmit = (data,e) => {
    const email = data.email;
    // Check for data and send it to redux actions.
    if (data) {
      dispatch(authAction.forgotPassword({email:email},()=>{
        e.target.reset()
        history.push("/success")
      }));
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.paddingBottom5}>
          <Typography variant="h3">{common_constants.REQUEST_ACCESS}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id={'txt_email'}
            name="email"
            withImage={true}
            imageType={'uemail'}
            placeholder={common_constants.EMAIL_ID}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
            inputRef={register({
              validate: {
                ...validations.isRequired(errorsConstants.requiredEmail),
                ...validations.isEmail(errorsConstants.inValidEmail)
              }
            })}
          />
        </Grid>
        <Grid item xs={12} className="mt-3">
          <Grid container spacing={4} alignItems={"center"}>
            <Grid item xs={6}>
              <Button
                title={common_constants.SEND_BTN_TEXT}
                color={"primary"}
                size={"large"}
                className={classes.loginBtn}
                type="submit"
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container alignItems="flex-end">
                <Grid item xs={12} align="right">
                  <Link
                    href="#/login"
                    variant="subtitle1"
                    color="textSecondary">
                    {common_constants.BACK_TO_SIGNIN}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

// default props
RequestAccess.defaultProps = {
  classes: {}
};

// prop types
RequestAccess.propTypes = {
  classes: PropTypes.object
};

export default withStyles(AuthenticationStyles)(RequestAccess);

import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames"
import Grid from "@material-ui/core/Grid";
import { IconButton, withStyles } from '@material-ui/core';
import moment from "moment"
import AddIcon from '@material-ui/icons/Add';

//Styles
import TenantApplicationStyles from "../TenantApplicationStyles"

// Import Components
import { Typography, Signature, CheckBox } from "../../../components";
import FormFieldsGenerator from "../FieldHelpers/FormFieldsGenerator"

//import actions
import { updateReducerAction, alertAction } from "../../../redux/actions";

//import constants
import { tenantAppConstant } from "../../../redux/constants";
import { CloseIcon } from "../../../assets/svg";

const Application = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    const appRef = useRef(null);

    //get props
    const { classes, urlPath } = props;

    //get reducer data
    const { appData: { allSteps, application, disclosureConditions, settings, appType, DOB, logoData: { common } } } = useSelector(s => (s.tenantApp))

    useEffect(() => {
        appRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, []);

    //change payment form
    const handleChange = (e, indx0, indx, maxLen, checkIndex,fieldType="") => {
        const regX = /[^a-zA-Z0-9! @ # $ % ^ * ( ) _ \n + = . ,-]/
        if (fieldType!=="DDN" && e["value"] && typeof e["value"] === "string" && !e["val"] && regX.test(e["value"]) === true) {
            dispatch(alertAction.error("Please enter only these accepted characters A-Z 0-9 ! @ # $ % ^ * ( ) _ - + = . ,"))
        } else if (appType === "resident" && e["name"] !== "DateOfBirth" && (DOB && new Date(DOB) != "Invalid Date" && moment().diff(DOB, 'years', false) < 18)) {
            dispatch(alertAction.error("Please Enter a valid Date Of Birth"))
        } else {
            if (!maxLen || (maxLen && typeof e["value"] === "string" && e["value"].length <= Number(maxLen)) || (typeof e["value"] !== "string")) {

                let applicationData = application
                let updateData = applicationData["applicationForm"];
                //data at particular index
                let indexData = updateData[indx0];
                if (indexData && indexData["elements"]) {
                    if (checkIndex !== null && checkIndex >= 0) {
                        indexData = { ...indexData, "elements": { ...indexData["elements"], [e["name"]]: { ...indexData["elements"][e["name"]], ["value" + checkIndex]: e["value"], "datee" : moment().format("MMMM Do YYYY, h:mm:ss a") } } }
                    } else if (e["val"]) {
                        indexData = { ...indexData, "elements": { ...indexData["elements"], [e["name"]]: { ...indexData["elements"][e["name"]], "value": e["value"], "val": e["val"], "datee" : moment().format("MMMM Do YYYY, h:mm:ss a") } } }
                    } else {
                        indexData = { ...indexData, "elements": { ...indexData["elements"], [e["name"]]: { ...indexData["elements"][e["name"]], "value": e["value"], "datee" : moment().format("MMMM Do YYYY, h:mm:ss a") } } }
                    }
                    updateData[indx0] = indexData;
                    applicationData = { ...applicationData, "applicationForm": updateData };

                    //check is repeat data
                    let applicationData1 = applicationData
                    let repeatData = applicationData1["applicationForm"] ? applicationData1["applicationForm"] : [];
                    const repeatExists = repeatData.filter(rt => (rt && rt["visible"]))
                    if (repeatExists.length) {
                        for (let m = 0; m < repeatData.length; m++) {
                            const repeatKeys = repeatData[m]["visible"] && Object.keys(repeatData[m]["visible"]).length ? Object.keys(repeatData[m]["visible"]) : [];
                            if (repeatKeys && repeatKeys.length) {
                                for (let i = 0; i < repeatData.length; i++) {
                                    if (repeatData[i]["elements"]) {
                                        for (let j = 0; j < repeatKeys.length; j++) {
                                            if (e["name"] === repeatKeys[j]) {
                                                if (e["value"] === repeatData[m]["visible"][repeatKeys[j]]) {
                                                    repeatData[m]["vis"] = true;
                                                } else {
                                                    repeatData[m]["vis"] = false;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        applicationData1["applicationForm"] = repeatData;
                    }

                    dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_APPLICATION, applicationData1))
                }
                //update settings
                if (settings && settings["valueData"] && settings["valueData"].hasOwnProperty(e["name"])) {
                    let updateSettings = settings;
                    updateSettings["valueData"][e["name"]] = e["value"];
                    dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_SETTINGS, updateSettings))
                }

                //DOB UPDATE
                if (e["name"] == "DateOfBirth") {
                    dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_DOB, e["value"]))
                }

                //update disclosureConditions
                if (disclosureConditions && disclosureConditions.length > 0) {
                    let disclosureConditionsTemp = disclosureConditions;
                    let updatedDisc = false;
                    for (let y = 0; y < disclosureConditionsTemp.length; y++) {
                        if (disclosureConditionsTemp[y] && disclosureConditionsTemp[y].hasOwnProperty(e["name"])) {
                            disclosureConditionsTemp[y]["value"] = e["value"];
                            updatedDisc = true;
                        }
                    }
                    if (updatedDisc) {
                        dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_DISC_COND, disclosureConditionsTemp))
                    }
                }
            } else {
                dispatch(alertAction.error("Maximum length reached"))
            }
        }
    }

    //update signature
    const handleSignChange = (sign, bs64) => {
        let updateData = application;
        updateData["applicationSignature"] = bs64;
        updateData["sign"] = sign;
        dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_APPLICATION, updateData))
    }

    //add more boxes
    const updateAddMore = (index) => {
        let applicationData = application
        let updateData = applicationData["applicationForm"];
        //get data at particular index
        let indexData = updateData[index];
        if (indexData && indexData["elements"]) {
            //adding new items
            let moreOptions = {};
            let repeatIndex = null;
            //calculating and setting repeat index based on the repeat in Fields
            if(!indexData["repeatLength"]){
                indexData["repeatLength"] = indexData["repeat"];
            }
            if(Number(indexData["repeatLength"]) === Number(indexData["repeat"])){
                repeatIndex= 1;
            }else if(indexData["repeatIndex"] && Number(indexData["repeatIndex"])<Number(indexData["repeatLength"]) && Number(indexData["repeat"])>0){
               repeatIndex= Number(indexData["repeatIndex"])+1;
            }
            indexData["repeatIndex"] = repeatIndex;

            if (repeatIndex) {
                //clone only the original items
                let keyData = Object.keys(indexData["elements"])
                keyData = keyData.filter(d => (d && d[(d.length) - 1] !== "1" && d[(d.length) - 1] !== "2" && d[(d.length) - 1] !== "3" && d[(d.length) - 1] !== "4" && d[(d.length) - 1] !== "5" && d[(d.length) - 1] !== "6"))

                keyData.map(i => {
                    moreOptions[`${i}${repeatIndex}`] = { ...indexData["elements"][i], value: "",isNew:true };
                })
                //add duplicate fields to the form
                indexData = { ...indexData, "repeat": (indexData["repeat"] - 1), "elements": { ...indexData["elements"], ["closeButton" + repeatIndex]: { label: "close", value: "", "repeatIndex": repeatIndex }, ...moreOptions } }
                updateData[index] = indexData;
                applicationData = { ...applicationData, "applicationForm": updateData };
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_APPLICATION, applicationData))
            } else {
                dispatch(alertAction.error("Maximum number of fields reached"))
            }
        }
    }

    //remove extea options
    const removeExtraAdd = (contents, index) => {
        let applicationData = application
        let updateData = applicationData["applicationForm"];
        //get data at particular index
        let indexData = updateData[index];
        if (indexData && indexData["elements"]) {
            //adding new items
            let repeatIndex = indexData["repeatIndex"] ? indexData["repeatIndex"] : null;

            if (repeatIndex) {
                //clone only the original items
                let keyData = Object.keys(indexData["elements"])
                keyData = keyData.filter(d => (d && d[(d.length) - 1] !== "1" && d[(d.length) - 1] !== "2" && d[(d.length) - 1] !== "3" && d[(d.length) - 1] !== "4" && d[(d.length) - 1] !== "5" && d[(d.length) - 1] !== "6"))

                keyData.map(i => {
                    if (indexData["elements"][`${i}${repeatIndex}`]) {
                        delete indexData["elements"][`${i}${repeatIndex}`];
                    }
                })
                delete indexData["elements"]["closeButton" + repeatIndex];

                //add duplicate fields to the form
                indexData = { ...indexData, "repeat": (indexData["repeat"] + 1),"repeatIndex": repeatIndex-1, "elements": { ...indexData["elements"] } }
                updateData[index] = indexData;
                applicationData = { ...applicationData, "applicationForm": updateData };
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_APPLICATION, applicationData))
            } else {
                dispatch(alertAction.error("Something went wrong"))
            }
        }
    }

    const repeatableFormFields = (item0, indx) => {
        return (
            <Grid className={`${"tenantCardContainer"} ${classNames(classes.cardContainer)}`} key={`appContain${indx}cc`}>
                <Grid item sm={12}>
                    <Typography className="pb-1 transCapital" variant="h4">{item0 && item0["title"] ? item0["title"] : ""}</Typography>
                </Grid>
                <Grid item sm={12}>
                    <span dangerouslySetInnerHTML={{ __html: `<p>${item0 && item0["description"] ? item0["description"] : ""}</p>` }}></span>
                </Grid>
                <Grid item sm={12}>
                    <Grid container className={"sm-spacing15"}>
                        {item0 && item0["elements"] && Object.keys(item0["elements"]).length && Object.keys(item0["elements"]).map((item1, indx1) => (

                            <Grid item sm={(item1 === "closeButton1" || item1 === "closeButton2" || item1 === "closeButton3" || item1 === "closeButton4" || item1 === "closeButton5" || item1 === "closeButton6" ) ? 12 : 6} xs={12} key={`appinnr${indx1}ccsdf`}>
                                {(item1 === "closeButton1" || item1 === "closeButton2" || item1 === "closeButton3" || item1 === "closeButton4" || item1 === "closeButton5" || item1 === "closeButton6") ?
                                    <Grid className={classes.closeContainer} key={`inner${indx1}cdf`}>
                                        <hr />
                                        {item0 && item0["elements"] && item0["elements"][item1] && item0["repeatIndex"]===item0["elements"][item1]["repeatIndex"] && <IconButton className={classes.closeIconBtn} onClick={() => { removeExtraAdd(item0["elements"][item1], indx) }}><CloseIcon /></IconButton>}
                                    </Grid>
                                    :
                                    <Grid item key={`appinnr${indx1}cc`}>
                                        <Typography variant="body1">{item0["elements"][item1] && item0["elements"][item1]["label"] ? `${item0["elements"][item1]["label"]} ${item0["elements"][item1] && (item0["elements"][item1]["required"] || (item0["elements"][item1]["onrepeat"] && item0["elements"][item1]["onrepeat"]["required"] && item0["elements"][item1]["isNew"]))  ? "*" : ""}` : "label"}</Typography>
                                        <FormFieldsGenerator
                                            item={item1}
                                            indx={indx1}
                                            indx0={indx}
                                            contents={item0["elements"][item1] || {}}
                                            handleChange={handleChange}
                                            data={item0["elements"] || {}}
                                            managerList={application["mangerList"]}
                                            managerListOrder={application["mangerListOrder"]}
                                            regionList={application["regionList"]}
                                            validataForm={application["validataForm"]}
                                            urlPath={urlPath}
                                            common={common}
                                            managerDefault={application["managerDefault"] ===false ? false : true}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container className={classes.addMoreCheckBox}>
                        
                            {(item0["repeat"] > 0) &&
                                <CheckBox
                                    id={`checked-${indx}${"kk"}`}
                                    label={<div className="addMoreCheckBoxDiv link"><AddIcon />{"Add more"}</div>}
                                    checked={false}
                                    color="secondary"
                                    handleChange={(e) => { updateAddMore(indx); }}
                                />
                            }
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid item xs={12} style={{ overflowX: "hidden" }}>
            <form>
                <Grid container ref={appRef}>
                    <Grid item xs={12} className={"p-1 sm-p-3"}>

                        {application && application['applicationForm'] && typeof application['applicationForm'] !== "string" && application['applicationForm'].map((item0, indx) => (
                            <React.Fragment>
                                {application['applicationForm'][indx]["visible"] ? application['applicationForm'][indx]["vis"] ? repeatableFormFields(item0, indx) : null : repeatableFormFields(item0, indx)}
                            </React.Fragment>
                        ))}

                        {allSteps.indexOf("applicationSign") > -1 &&
                            <Grid className={`${"tenantCardContainer"} ${classNames(classes.cardContainer)}`}>
                                <Grid item sm={12} className="mb-2" >
                                    <Signature
                                        name={"sign"}
                                        classes={classes}
                                        sigPad={application["sign"] ? application["sign"] : {}}
                                        signImg={application["applicationSignature"] ? application["applicationSignature"] : ""}
                                        handleSignChange={handleSignChange}
                                    />
                                </Grid>
                            </Grid>
                        }


                    </Grid>

                </Grid>
            </form>
        </Grid>
    );
}

// default props
Application.defaultProps = {
    classes: {}
};

// prop types
Application.propTypes = {
    classes: PropTypes.object
};

export default withStyles(TenantApplicationStyles)(Application);

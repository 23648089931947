import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import propTypes from 'prop-types';
import { IconButton, Slide, withStyles, Grid, Menu, MenuItem } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

//Styles
import DirectoryStyles from "../DirectoriesStyles"
import { MoreIcon, CopIcon } from '../../../assets/svg'

// Import Components
import { Button, Typography, AlertDialog, LazyLoader} from "../../../components";

// Import Actions
import { directoryAction } from "../../../redux/actions";

// Import Config Layout
import { directorysCardLimit } from "../../../config/layout";
import history from "../../../config/history";

function DirectoryChord(props) {

    //get props
    const { classes } = props;

    let cardLimit = directorysCardLimit();
    const cardSize = Math.floor(12 / (cardLimit / 5))

    //define dispatch
    const dispatch = useDispatch();

    //set ref for scroll
    const scrollContent = useRef(null)

    //get reducer data
    let { getAll: { data, page } } = useSelector((state) => state.directory);
    let { lazyLoading } = useSelector((state) => state.UI);

    //open popup inside each chord
    const [anchorEl, setAnchorEl] = useState(null);
    const [editId, setEditId] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(null);
    const [showScrollTopBTN, setShowScrollTopBTN] = useState(false)

    //open popup
    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditId(id)
    };

    //close popup
    const handleClose = () => {
        setAnchorEl(null);
        setEditId(null)
    };

    //delete directory
    const handleDelete = () => {
        if (editId) {
            let dataObj = data.filter(i => (i.id === editId))
            if (dataObj.length === 1) {
                dispatch(directoryAction.remove({ id: dataObj[0].id }, () => {
                    dispatch(directoryAction.getAll({ limit: page.limit, skip: page.skip, search: page.search, sort: page.sort }));
                    setAnchorEl(null);
                    setDeleteAlert(null)
                }));
            }
        }
    }

    //edit directory
    const handleEditPopup = () => {
        if (editId) {
            let dataObj = data.filter(i => (i.id === editId))
            if (dataObj.length === 1) {
                dispatch(directoryAction.getOne({ id: dataObj[0].id }, () => {
                    history.push({ pathname: `/directory/edit/${dataObj[0].id}` });
                }));
            }
        }
    }

    //handle delete popup function
    const handleDeletePopup = (item) => {
        const selecteddirectory = data.filter(obj => (obj.id === editId))
        setDeleteAlert({ item: selecteddirectory[0] })
    }

    //scroll event
    const getScrollData = () => {
        const isBottom = parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight) || parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight-1) || parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight+1);
        // const currentVal=scrollContent.current.scrollTop;
        if (!lazyLoading && isBottom && page.count > data.length) {
            //get neext data
            dispatch(directoryAction.getAll({ limit: page.limit, skip: page.skip + 1, search: page.search, sort: page.sort, loader: true }));
        }

        if (scrollContent.current.scrollTop > 150 && showScrollTopBTN===false){
            setShowScrollTopBTN(true)
        }
        else if(scrollContent.current.scrollTop < 150 && showScrollTopBTN===true){
            setShowScrollTopBTN(false)
        }
    }

    return (
        <React.Fragment>
            <Grid item className={classes.usersRightTiles} ref={scrollContent} onScroll={() => { getScrollData() }}>
                <Grid container>
                    <Grid item sm={12} >
                        <Grid container spacing={2} >
                            {data && data.map((itm, ind) => (
                                    <Grid className="animate__animated animate__fadeInUp animate__delay-0.8s" xs={cardSize} item key={ind}>
                                        <Grid className={classes.directoryTile}>
                                            <Grid container justify="flex-end" className={"pr-1 pt-1"}>
                                                <IconButton className={classes.tileMoreIcon} onClick={(e) => { handleClick(e, itm.id) }}><MoreIcon /></IconButton>
                                            </Grid>
                                            <Grid container className={classes.tileTop} alignItems="center">
                                                <Grid className={classes.DirectoryUser}>
                                                    {itm.image !== '' ? <img style={{maxWidth:"100%" ,borderRadius: "30px"}} src={itm.image} alt={"img"} /> : <CopIcon />}
                                                    <Grid className={itm.status === "Active" ? classes.userActiveGreen : classes.userActiveGrey}></Grid>
                                                </Grid>
                                                <Grid className={`${classes.topContainerDiv} ${"ml-1"}`}>
                                                    <Typography variant="h6" align="left" className="oneLineEllip">{`${itm.first_name} ${itm.last_name}`}</Typography>
                                                    <Typography variant="body1" align="left" className="oneLineEllip">{itm.company}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.tileBottom} >
                                                <Grid item xs={6} className={classes.tileBottomLt}>
                                                    <Typography variant="subtitle1" align="center" className="oneLineEllip">{itm.hologram}</Typography>
                                                    <Typography variant="body1" align="center" color="textSecondary" className="oneLineEllip">{"Hologram"}</Typography>
                                                </Grid>
                                                <Grid item xs={6} className={classes.tileBottomRt}>
                                                    <Typography variant="subtitle1" align="center" className="oneLineEllip">{itm.card_id}</Typography>
                                                    <Typography variant="body1" align="center" color="textSecondary" className="oneLineEllip">{"Card ID"}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            ))}
                            <LazyLoader />
                        </Grid>
                        <Menu
                            id={`directory-menu`}
                            key={`jdfn-n sdj`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            elevation={2}
                        >
                            <MenuItem onClick={() => { handleEditPopup() }}>Edit Listing</MenuItem>
                            <MenuItem onClick={() => { handleDeletePopup() }}>Delete Listing</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                {showScrollTopBTN && <IconButton  onClick={()=>{scrollContent.current.scrollTo(0, 0)}} className={"toTop"}><KeyboardArrowUpIcon /></IconButton>}
            </Grid>
            {deleteAlert &&
                <AlertDialog
                    fullScreen={false}
                    open={true}
                >
                    <Grid className={'p-4'}>
                        <Typography variant="h6">{`Are you sure you want to delete this listing`}</Typography>
                        <Grid className={'pt-2'} align={"center"}>
                            <Button
                                color={"primary"}
                                className={"mr-1"}
                                onClick={() => { handleDelete() }}
                                title={"Yes"}
                            />
                            <Button
                                color={"secondary"}
                                onClick={() => { setAnchorEl(null); setDeleteAlert(null) }}
                                title={"No"}
                            />
                        </Grid>
                    </Grid>
                </AlertDialog>
            }
        </React.Fragment>
    )
}

// default props
DirectoryChord.defaultProps = {
    classes: {}
};

// prop types
DirectoryChord.propTypes = {
    classes: propTypes.object
};

// export default UserList;
export default withStyles(DirectoryStyles)(DirectoryChord);
import '../../assets/theme/palette';
import palette from '../../assets/theme/palette';
const SettingsStyles = () => ({

    searchIcon: { padding: 6, marginRight: 10 },
    marginBottom15: { marginBottom: 15 },
    btnBlue: {
        backgroundColor: palette.primary.dary,
        color: "#fff",
        '&:hover': {
            color: "#fff",
            backgroundColor: '#1b88be',
        }
    },
    titlerow: {
        height: '34px',
        padding: '0 16px',

        // backgroundColor: 'blue',
        // padding: '10px 0',
        '& .MuiToggleButton-root': {
            padding: 7
        }
    },
    uploadImgPopup: {
        height: 175,
        width: 175,
        objectFit: 'contain'
    },
    uploadImgOpacity: {
        opacity: 0,
        height: 175,
        width: 175,
    },
    profileImgContainer: {
        height: 176,
        width: 176,
        cursor: "pointer",
        background: "#F7F7F7",
        '& svg': {
            width: '100%',
            cursor: "pointer",
            height: 'auto'
        },
        '& .deleteIcon': {
            height: 12,
            width: 12
        }
    },
    cardSectionProfileImg: {
        height: '100%',
        width: "100%",
        borderRadius: '4px',
        boxShadow: '0px 0px 8px #d4d1d1',
        padding: '8px',
        '& .closeIconBtn': {
            position: 'absolute',
            background: '#fff',
            borderRadius: '30px',
            top: '12px',
            right: '12px',
            width: '18px',
            height: '18px',
            padding: 2,
            '&:hover': {
                background: 'red',
                '& path': {
                    fill: '#fff'
                }
            }
        }
    },
    addUSerAction: {
        paddingLeft: '2.6%'
    },
    selectBox: {
        width: "100%",
        '& .MuiSelect-outlined': {
            padding: 10,
            textAlign: 'left',
            borderRadius: 30
        },
        '& .MuiOutlinedInput-notchedOutline legend': {
            display: "none !important"
        },
        '& .MuiOutlinedInput-notchedOutline': {
            marginTop: "5px !important"
        },
    },
    serviceActionBtn: {
        padding: '16px 16px 0 16px',
    },
    cardSection: {
        height: '100%',
        width: "100%",
        borderRadius: '4px',
        boxShadow: '0px 0px 16px rgba(0,0,0,0.2)',
        padding: '8px 16px',
    },
    //Application Template
    //Add Application Template
    dialogHeader: {
        background: '#fff'
    },
    // ........
    accordionContainer: {
        maxWidth: 1000,
        margin: 'auto',
        paddingBottom: 20,
        '& .MuiAccordionSummary-root': {
            minHeight: 41,
            '& .MuiIconButton-root': {
                padding: 5
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: '5px 0'
        },
        '& .MuiPaper-elevation1': {
            boxShadow: '0px 0px 8px #d4d1d1',
            // boxShadow: 'none',
            // borderBottom: `1px solid ${palette.greyshades.light2}`,
            borderRadius: 0,
            marginBottom: 20,
            '&:before': {
                top: '0 !important'
            }
        },
        '& .MuiAccordion-root:before': {
            // backgroundColor: palette.primary.main
        },
        '& .MuiAccordion-root.Mui-expanded': {
            marginBottom: 20,
            '& .MuiAccordionSummary-root': {
                '& .MuiTypography-root': {
                    color: '#fff'
                },
            },
            '& .expandIcon': {
                fill: '#fff'
            }
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            borderBottom: `1px solid ${palette.greyshades.light2}`,
            backgroundColor: palette.primary.main
        },
        '& .MuiAccordionDetails-root': {
            padding: '25px 35px',
            '& .searchIcon': {
                width: 15,
                '& path': {
                    fill: "#D2D3D4"
                }
            }
        },
    },
    // .........
    allianceTextarea: {
        borderRadius: 4,
        borderColor: '#D2D3D4',
        width: '100%',
        '&:hover': {
            borderColor: 'rgb(95 95 95 / 87%)',
        },
        '&:focus': {
            border: `2px solid ${palette.primary.main}`,
            outline: 'transparent !important'
        }
    },
    //Card
    ApplicationlTile: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 8px #d4d1d1',
        borderRadius: 4,
        padding: '20px 10px 20px 20px',
        '& .MuiCheckbox-root': {
            padding: 0
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0
        }
    },
    AppLeftImage: {
        width: 68,
        height: 78,
        background: palette.greyshades.light3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiTypography-root': {
            color: `${palette.greyshades.light1} !important`
        }
    },
    AppCenterPart: {
        flexBasis: 'calc( 100% - 90px)',
        maxWidth: 'calc( 100% - 90px)',
        padding: '0 15px',
    },
    templateText: {
        marginBottom: 5
    },
    AppRightPart: {

    },
    appTempChip: {
        '& .MuiChip-root': {
            background: palette.greyshades.light3,
            border: `1px solid ${palette.greyshades.light2}`,
            borderRadius: 20,
            padding: '0 8px',
            marginRight: 5,
            height: 26
        }
    },
    appTemplateDialog: {
        background: palette.greyshades.light3,
    },


    //Email Template
    //Card
    EmailTile: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 8px #d4d1d1',
        borderRadius: 4,
        // padding: 20,
        '& .MuiCheckbox-root': {
            padding: 0
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0
        }
    },
    tileTop: {
        padding: '0px 20px 0px 20px',
        marginTop: -18
    },
    tileBottom: {
        padding: '20px'
    },
    emailTemplateImg: {
        margin: "auto"
    },
    emailTemplateBodyTxt: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: 'webkit-box',
        webkitLineClamp: 3,
        webkitBoxOrient: 'vertical'
    },

    //Agreement
    AgreementTile: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 8px #d4d1d1',
        borderRadius: 4,
        padding: '20px 10px 20px 20px',
        '& .MuiCheckbox-root': {
            padding: 0
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0
        }
    },
    AgreementLeftImage: {
        width: 68,
        height: 57,
        background: palette.greyshades.light3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiTypography-root': {
            color: palette.greyshades.light1
        }
    },
    AgreementCenterPart: {
        flexBasis: 'calc( 100% - 90px)',
        maxWidth: 'calc( 100% - 90px)',
        padding: '0 15px',
    },
    AgreementTempChip: {
        '& .MuiChip-root': {
            background: palette.greyshades.light3,
            border: `1px solid ${palette.greyshades.light2}`,
            borderRadius: 20,
            padding: '0 8px',
            marginRight: 20,
            height: 26,
            '& .MuiChip-label': {
                paddingLeft: '5px !important'
            }
        }
    },

    //Document
    choicesContainer: {
        padding: 20,
        background: palette.greyshades.light3,
        border: `1px solid ${palette.greyshades.light2}`,
    },
    settingsTileBgnoneAddDoc: {
        padding: '10px',
        border: `1px solid ${palette.greyshades.light2}`,
        borderRadius: 4,
        backgroundColor: '#fff',
        marginBottom: 12,
        '& .MuiTypography-root': {
            width: '70%',
        },
        '& .MuiSvgIcon-root': {
            width: '10%',
        },
        '& .dragIcon': {
            cursor: "move",
            marginRight: 5
        }
    },
    notifyIcon: {
        width: 28,
        height: 28,
        background: palette.greyshades.light2
    },

    //Criteria Tile
    CriteriaTile: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 8px #d4d1d1',
        borderRadius: 4,
        padding: '20px 7px 20px 20px',
        '& .MuiCheckbox-root': {
            padding: 0
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0
        }
    },
    CriteriaLeftImage: {
        width: 45,
        height: 45,
        background: palette.greyshades.light3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiTypography-root': {
            color: `${palette.greyshades.light1} !important`
        }
    },
    CriteriaCenterPart: {
        flexBasis: 'calc( 100% - 70px)',
        maxWidth: 'calc( 100% - 70px)',
        padding: '0 15px',
    },

    //Drag Container
    grow: {
        flexGrow: 1
    },
    groupTitle: {
        background: palette.primary.main,
        color: '#fff',
        borderLeft: '5px solid #551127',
        padding: '7px 10px'
    },
    titleContainer: {
        paddingLeft: '3.5% !important',
        paddingRight: '3.5% !important'
    },
    settingsCardSection: {
        height: '100%',
        width: "100%",
        borderRadius: '4px',
        boxShadow: '0px 0px 8px #d4d1d1',
        padding: '8px 16px',
        height: 'calc(100vh - 172px)',
        overflowX: 'hidden',
        overflowY: 'auto',

    },
    AddSettingsTile: {
        padding: '8px 13px 8px 7px',
        border: `1px solid ${palette.greyshades.light2}`,
        borderRadius: 4,
        backgroundColor: palette.greyshades.light3,
        marginBottom: 15,
        height: 42,
        '& .dragIcon': {
            cursor: "move",
            marginRight: 5,
        }
    },


    //Drawer
    backDrop: {
        zIndex: 1
    },
    allianceDrawer: {
        '& .MuiDrawer-paper': {
            width: 400
        }
    },
    //
    appBarCls: {
        padding: '0 16px 0px',
        backgroundColor: 'transparent'
    },

    error: {
        '& .editor-class': {
            border: '1px solid red !important'
        }
    },
    uploadText: {
        position: 'absolute',
        bottom: '7px',
        padding: '7px',
        width: '176px',
        background: '#e8e8e88c',
        textAlign: 'center',
        color: '#b0b0b0',
        cursor: 'pointer'
    },
    tempContainer: {
        height: 'calc(100vh - 145px)',
        overflowY: 'auto',
        alignContent: 'baseline'
    },
    userProfileDoNot: {
        textAlign: 'center',
        marginTop: '15px'
      },
})

export default SettingsStyles;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

//Styles
import ServicesStyles from "../ServicesStyles"

//Images
import { DragIcon, CheckSelectedIcon, DeleteIcon, BellIcon } from '../../../assets/svg'

// Import Components
import { Button, TextFieldWithoutRef, Typography, CheckBox, Info, RichTextEdit, TextAreaWithLines } from "../../../components";

// Import Helpers
import validations from "../../../utils/validations";

// Import Constants
import { serviceConstant } from "../../../redux/constants";
import { user_page_constants, templates_page_constants } from "../../../config/constants";

// Import Actions
import { serviceAction, updateReducerAction } from "../../../redux/actions";

const AddTemplate = (props) => {

    //define dispatch
    const dispatch = useDispatch();

    const { classes, handleTempRichTextChange } = props;

    const { createFromScratch, createFromScratchDocuments, createFromScratchErrorFlags: errorFlags } = useSelector((state) => state.service);

    // Set Form Values
    const { register, handleSubmit, errors } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            email: "",
            password: ""
        }
    });

    // Submit Form
    const onSubmit = (data) => {
        console.log(data)
    }

    const handleChange = (e) => {
        if (e.target.name === "is_template_checkbox") {
            createFromScratch[e.target.name] = e.target.checked
        } else {
            createFromScratch[e.target.name] = e.target.value
        }
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
    }

    return (

        <Grid item xs={12} className={classes.contentcontainer} >
            <Typography variant="h4" className="mt-3 mb-3" align="center">{user_page_constants.ADD_A_TEMPLATE}</Typography>
            <Paper elevation={3} className={`${"overflowY"} ${classes.cardSectionDialogAddTemplate}`} >
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid item sm={12}>
                        <Grid container spacing={3}>
                            <Grid item sm={7} align="left">
                                <Grid container spacing={2}>
                                    <Grid item sm={12} align="left">
                                        <Grid container spacing={3}>
                                            <Grid item sm={6}>
                                                <Typography title={user_page_constants.TEMPLATE_NAME} variant={"body1"} className="textFieldLabel"><span className={"reqStar"}>*</span></Typography>
                                                <TextFieldWithoutRef
                                                    name={"template_name"}
                                                    margin="none"
                                                    size={"small"}
                                                    value={createFromScratch["template_name"] ? createFromScratch["template_name"] : ""}
                                                    handleChange={(e) => handleChange(e)}
                                                    error={errorFlags.template_name ? true : false}
                                                    helperText={errorFlags.template_name ? "Template name cannot be blank" : ""}
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <Typography title={user_page_constants.DESCRIPTION} variant={"body1"} className="textFieldLabel"></Typography>
                                                <TextFieldWithoutRef
                                                    name={"template_description"}
                                                    margin="none"
                                                    size={"small"}
                                                    value={createFromScratch["template_description"] ? createFromScratch["template_description"] : ""}
                                                    handleChange={(e) => handleChange(e)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={"body1"}>{templates_page_constants.CONFIRM_MESSAGE}</Typography>
                                        <TextareaAutosize
                                            name={"template_confirmation"}
                                            value={createFromScratch["template_confirmation"] ? createFromScratch["template_confirmation"] : ""}
                                            onChange={(e) => handleChange(e)}
                                            className={classes.allianceTextarea}
                                            style={{width:"100%"}}
                                            aria-label="minimum height"
                                            rowsMin={3}
                                            placeholder={"Read, acknowledged, and authorized."}
                                        />
                                    </Grid>
                                    <Grid item sm={12} align="left">
                                        <CheckBox
                                            name={"is_template_checkbox"}
                                            label={<Typography variant="body2" title={user_page_constants.DEFAULT_EMAIL_TEMPLATE}></Typography>}
                                            checked={createFromScratch.is_template_checkbox}
                                            color="secondary"
                                            handleChange={handleChange}
                                        />
                                    </Grid>
                                    {!createFromScratch.is_template_checkbox && <Grid item sm={12} align="left">
                                        <Grid container spacing={3}>
                                            <Grid item sm={6}>
                                                <Typography className="textFieldLabel" title={user_page_constants.EMAIL_TEMPLATE_NAME} variant={"body1"}></Typography>
                                                <TextFieldWithoutRef
                                                    name={"emailTempName"}
                                                    margin="none"
                                                    size={"small"}
                                                    value={createFromScratch["emailTempName"] ? createFromScratch["emailTempName"] : ""}
                                                    handleChange={(e) => handleChange(e)}
                                                    error={(!createFromScratch.is_template_checkbox && errorFlags.emailTempName) ? true : false}
                                                    helperText={(!createFromScratch.is_template_checkbox && errorFlags.emailTempName) ? "This is required" : ""}
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <Typography className="textFieldLabel" title={user_page_constants.SUBJECT} variant={"body1"}></Typography>
                                                <TextFieldWithoutRef
                                                    name={"emailTempSubject"}
                                                    margin="none"
                                                    size={"small"}
                                                    value={createFromScratch["emailTempSubject"] ? createFromScratch["emailTempSubject"] : ""}
                                                    handleChange={(e) => handleChange(e)}
                                                    error={(!createFromScratch.is_template_checkbox && errorFlags.emailTempSubject) ? true : false}
                                                    helperText={(!createFromScratch.is_template_checkbox && errorFlags.emailTempSubject) ? "This is required" : ""}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>}
                                    {!createFromScratch.is_template_checkbox && <Grid item sm={12}>
                                        <Typography title={user_page_constants.EMAIL_CONTENT} variant={"body1"}></Typography>
                                        <Grid className={(!createFromScratch.is_template_checkbox && errorFlags.emailTempContent) ? classes.error : ""}>
                                            <RichTextEdit
                                                value={createFromScratch.emailTempContent ? createFromScratch.emailTempContent : ""}
                                                onChange={(v) => { handleTempRichTextChange(v) }}
                                            />
                                        </Grid>
                                        {(!createFromScratch.is_template_checkbox && errorFlags.emailTempContent) &&
                                            <span style={{ color: 'red' }}>{"Content cannot be blank"}</span>
                                        }
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item sm={5} align="right">
                                <Grid item sm={12}>
                                    <Grid className={classes.selectTemplate}>
                                        <Typography variant={"h6"} title={user_page_constants.SELECTED_AGREEMENT} className="mb-2 textFieldLabel" align="center"></Typography>
                                        <Grid container alignItems="center" className={`${"mb-1"} ${classes.settingsTileBgnone}`}>
                                            <Typography variant={"body2"} title={createFromScratch.agreement_name} className={`${"oneLineEllip"} ${classes.grow}`}></Typography>
                                            <CheckSelectedIcon className={classes.checkIcon} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={"multiLineText pb-1"}>
                                <Typography variant="body1" className="textFieldLabel" title={templates_page_constants.EDIT_XML}></Typography>
                                {/* <TextFieldWithoutRef
                                    className={classes.borderNone}
                                    name={"xml"}
                                    size={"small"}
                                    margin={"none"}
                                    rows={6}
                                    multiline={true}
                                    value={createFromScratch.xml ? createFromScratch.xml : ""}
                                    handleChange={(e) => handleChange(e)}
                                /> */}
                                <TextAreaWithLines
                                    name={"xml"}
                                    minRow="6"
                                    onChange={(e) => handleChange(e)}
                                    value={createFromScratch.xml ? createFromScratch.xml : ""}
                                />
                            </Grid>
                            <Grid item xs={12} className={"multiLineText pb-1"}>
                                <Typography className="textFieldLabel flex alignCenter" variant="body1" title={templates_page_constants.EDIT_LAYOUT}><Info>
                                    <p dangerouslySetInnerHTML={{ __html: `${templates_page_constants.LAYOUT_TEXT}` }} className={`${classes.borderline} pb-1`}></p>
                                </Info></Typography>

                                {/* <TextFieldWithoutRef
                                    className={classes.borderNone}
                                    name={"layout"}
                                    size={"small"}
                                    margin={"none"}
                                    rows={6}
                                    multiline={true}
                                    value={createFromScratch.layout ? createFromScratch.layout : ""}
                                    handleChange={(e) => handleChange(e)}
                                /> */}
                                 <TextAreaWithLines
                                  minRow="6"
                                    name={"layout"}
                                    onChange={(e) => handleChange(e)}
                                    value={createFromScratch.layout ? createFromScratch.layout : ""}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </form>
            </Paper>
        </Grid>

    );
}

// default props
AddTemplate.defaultProps = {
    classes: {}
};

// prop types
AddTemplate.propTypes = {
    classes: PropTypes.object
};

export default withStyles(ServicesStyles)(AddTemplate);

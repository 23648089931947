import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

//import components
import { TextField } from "../../components";
import { SearchIcon, CloseIcon, SearchCloseIcon } from "../../assets/svg";
import styles from "./styles";
import { InputAdornment } from "@material-ui/core";

const SearchBarComponent = (props) => {
  const { id, fullWidth, classes, placeholder, intiSearch, autoFocus, loading } = props;

  //Toggle button for list and chord view
  const [toggleSearch, setToggleSearch] = useState(false);
  const [value, setValue] = useState(toggleSearch === false ? '' : props.value);

  //show hide search bar
  const showHideSearch = () => {
    setToggleSearch(!toggleSearch);
  };

  const hideSearchandClear = () => {
    setToggleSearch(!toggleSearch);
    setValue("");
  }

  //update search and timeout
  useEffect(() => {
    const delaySet = setTimeout(() => {
      if (value !== props.value) {
        intiSearch(value)
      }
    }, 600)
    return () => clearTimeout(delaySet)
  }, [value]);

  //update search value
  useEffect(() => {
    setValue(props.value)
  }, [props.value]);

  return (
    <React.Fragment>
    <Grid>
      <Grid container alignItems="center">
        <Grid className={`${classes.searchBoxGrid}`}>
          {toggleSearch &&
          <TextField
            id={id}
            variant="outlined"
            size="small"
            margin="none"
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            placeholder={placeholder}
            className={"searchBox"}
            value={value}
            onChange={(e) => { 
              if(!loading){
                setValue(e.target.value);
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{value == "" ? <IconButton onClick={() => {showHideSearch()}} style={{ padding: '0px' }}><SearchIcon className={classes.seachIcon} /></IconButton> : <IconButton onClick={hideSearchandClear} style={{ padding: '0px' }}><SearchCloseIcon /></IconButton>}</InputAdornment>,
            }}
          /> }
        </Grid>
        <Grid>
          {!toggleSearch && <IconButton
            className={`${"mr-1"} ${classes.padding6}`}
            onClick={() => {
              showHideSearch();
            }}
          >
            <SearchIcon />
          </IconButton>}
          {/* } */}
        </Grid>
      </Grid>
      </Grid>
    </React.Fragment>
  );
};

// default props
SearchBarComponent.defaultProps = {
  id: "dxzc",
  fullWidth: true,
  autoFocus: true,
  classes: {},
  placeholder: "search",
  intiSearch: () => { },
  loading:false
};

// prop types
SearchBarComponent.propTypes = {
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  classes: PropTypes.object,
  placeholder: PropTypes.string,
  intiSearch: PropTypes.func,
  loading:PropTypes.bool
};

export const SearchBar = withStyles(styles)(SearchBarComponent);

import React, { useState } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { TextField } from "@material-ui/core";
import { TextField, FormHelperText } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

// Import Config Constants
import { errorsConstants } from "../../config/constants";
import { CheckBox } from "../CheckBox";

/**
 * Autocomplete Component
 */
function MultiSelectComponent (props) {
  const [open, setOpen] = useState(false);

  /**
   * render html
   */
  const { suggestions, disabled, value, placeholder, className, validators, showErrorMessages, name, error,errorText } = props;

  return (
    <div>
      {suggestions &&
        <React.Fragment>
          <Autocomplete
            classes={{listbox: "multi-select-option-list"}}
            name={name}
            variant={"outlined"}
            multiple
            disableClearable
            autoHighlight
            blurOnSelect={false}
            value={Array.isArray(value) ? value : []}
            disabled={disabled}
            className={className}
            options={suggestions}
            getOptionSelected={(option, value) => option === value}
            onChange={(event, newValue) => {
              props.handleAutoCompolete(name, newValue, event);
            }}
            getOptionLabel={(option) => option}
            renderTags={(value, getTagProps) => {return null;}}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={"outlined"}
                value={Array.isArray(value) ? value : null}
                placeholder={value.length > 0 ? value.filter(v => (v && v!=="Select All")).join(", ") : placeholder}
                name={name}
              />
            )}
            renderOption={(option, { selected }) => {
              return (
                <React.Fragment>
                  <CheckBox
                    color="secondary"
                    className="autocomplete-checkbox p-0 mr5"
                    checked={selected}
                    style={{ margin: 0, paddingRight: "4px" }}
                  />
                  {option}
                </React.Fragment>
              );
            }}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={(e, reason) => {
              if (reason === "blur" || reason === "toggleInput") {
                setOpen(false);
              } else {
                setOpen(true);
              }
            }}
            open={open || false}
          />
          {error && <FormHelperText style={{color:"red"}}>{errorText}</FormHelperText>}
        </React.Fragment>
      }
    </div>
  );
}

// default props
MultiSelectComponent.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  name: "autocomplete",
  placeholder: "",
  validators: [],
  errorMessages: [],
  showErrorMessages: true,
  fieldErrors: [],
  warningFieldName: "",
  margin: "dense",
  className: "",
  suggestionType: "arrayObject",
  defaultValue: null,
  handleAutoCompolete: () => { },
  multiple: true,
  errorText:"",
  error:false
};

// prop types
MultiSelectComponent.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  suggestionType: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  errorMessages: PropTypes.array,
  showErrorMessages: PropTypes.bool,
  fieldErrors: PropTypes.array,
  margin: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  handleAutoCompolete: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  errorText:PropTypes.string,
  error:PropTypes.bool,
};

/** Export Component */
export const MultiSelect = MultiSelectComponent;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Tooltip, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputAdornment from '@material-ui/core/InputAdornment';

//Styles
import DirectoryStyles from "./DirectoriesStyles"

// Import Config Layout
import { directorysCardLimit } from "../../config/layout";

//Images
import { TileIcon, ListIcon, SearchIcon, SortIcon, CloseIcon, SearchCloseIcon } from '../../assets/svg'

// Import Components
import { Button, TextField, Typography, AlertDialog } from "../../components";
import DirectoryTile from './Components/DirectoryTiles';
import DirectoryList from './Components/DirectoryList';
import ColumnSettings from './Components/ColumnSettings'

// Import Cofig Constants
import { services_page_constants } from "../../config/constants";
import { directoryConstant } from "../../redux/constants";

// Import Actions
import { directoryAction, alertAction, updateReducerAction } from "../../redux/actions";
import history from "../../config/history";

const Directories = (props) => {

  //define dispatch
  const dispatch = useDispatch();

  let cardLimit = directorysCardLimit();

  const { classes, match } = props;

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    dispatch(updateReducerAction.update(directoryConstant.UPDATE_DIRECTORY_ALIGNMENT, newAlignment))
  };

  //get reducer data
  let { getAll: { data, page }, search: { page: tablePage }, pageViewType } = useSelector((state) => state.directory);
  const { currentUser } = useSelector(state => state.user);
  const { loading } = useSelector(s=>s.UI)

  //Toggle button for list and chord view
  const [alignment, setAlignment] = useState(pageViewType);
  const [search, setSearchValue] = useState(false);
  const [searchData, setSearchData] = useState(page.search ? page.search : "");

  //show hide search box
  const enableSearch = () => {
    setSearchValue(!search)
  }
  const hideSearchandClear = () => {
    setSearchValue(!search)
    setSearchData("");
    dispatch(directoryAction.getAll({ limit: cardLimit, skip: 1, search: "", sort: page.sort }));
  }


  //hadle search to get data
  const handleSearch = (e) => {
    if(!loading){
      setSearchData(e.target.value)
      setTimeout(() => {
        dispatch(directoryAction.getAll({ limit: cardLimit, skip: 1, search: e.target.value, sort: page.sort }));
      }, 1000)
    }
  }

  //get sort data
  const getSortedData = (sorting) => {
    dispatch(directoryAction.getAll({ limit: cardLimit, skip: 1, search: page.search, sort: sorting }));
  }

  //clearing state
  const openAddDirectory = ()=>{
    dispatch(updateReducerAction.update(directoryConstant.DIRECTORY_GET_REQUEST,{},()=>{
      history.push("directory/add")
    }))
  }

  return (
    <React.Fragment >
      <Grid item xs={12} >
        <Grid container direction="row" justify="space-between" alignItems="center" className={` ${classes.titlerow}`}>
          <Grid item>
            <Typography variant="h3" component="h3"> {`${services_page_constants.DIRECTORIES} ${alignment !== "right" &&  page && page.count>=0 ? `(${page.count})` : ""}`} </Typography>
          </Grid>
          <Grid item >
            <Grid container alignItems="center">
              {alignment !== "right" && search &&
                <Grid className={`${"searchBoxGridHide"} ${classes.searchBoxGrid} ${alignment !== "right" && search && "searchBoxGridShow"}`}>
                  <TextField
                    id="standard-start-adornment"
                    variant="outlined"
                    size="small"
                    margin="none"
                    fullWidth={true}
                    autoFocus={true}
                    placeholder="Search"
                    className={"searchBox"}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{ searchData =="" ? <IconButton onClick={enableSearch} style={{ padding: '0px' }}><SearchIcon className={classes.seachIcon} /></IconButton> : <IconButton onClick={hideSearchandClear} style={{padding: '0px'}}><SearchCloseIcon /></IconButton>}</InputAdornment>,
                    }}
                    value={searchData}
                    onChange={(e) => { if(!loading){handleSearch(e)} }}
                  />
                </Grid>
              }
              {alignment === "right" ? <ColumnSettings /> : 
                <IconButton className={` ${classes.padding6}`} onClick={enableSearch}>{!search && <SearchIcon />}</IconButton>
              }
              {alignment !== "right" && <Grid>
                {page && page.sort && page.sort === "asc" ?
                  <Tooltip arrow title="Sort By Created Date"><IconButton className={classes.searchIcon} onClick={() => { getSortedData('desc') }}><SortIcon /></IconButton></Tooltip>
                  :
                  <Tooltip arrow title="Sort By Created Date"><IconButton className={classes.searchIcon} onClick={() => { getSortedData('asc') }}><SortIcon /></IconButton></Tooltip>
                }
              </Grid>
              }
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                className={`${"mr-1"} ${classes.toggleButton}`}
              >
                <ToggleButton value="left" aria-label="left aligned">
                  <TileIcon />
                </ToggleButton>
                <ToggleButton value="right" aria-label="centered">
                  <ListIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            <Button variant="contained" size="small" color="primary" onClick={()=>{openAddDirectory()}} title={"+ ADD LISTING"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      { alignment === "right" ? <DirectoryList {...props}/> : <DirectoryTile />}
    </React.Fragment>
  );
}

// default props
Directories.defaultProps = {
  classes: {}
};

// prop types
Directories.propTypes = {
  classes: PropTypes.object
};

export default withStyles(DirectoryStyles)(Directories);

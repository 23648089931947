import React from "react";
import PropTypes from "prop-types";
import { Grid } from '@material-ui/core';

//Images
import { NoRecordIcon } from "../../../assets/svg"

// Import Components
import { Typography } from "../../../components";

const NoRecordsFound = (props) => {

    //css
    const whiteBgContainer = {
        height: "calc(100vh - 135px)",
        background: '#fff',
        borderRadius: 4,
        marginRight: 10,
        marginLeft: 10,
        alignContent: 'center',
        boxShadow: '0px 0px 16px #50576614'
    };

    return (
        <Grid container alignItems={"center"} alignContent="baseline" justify="center" style={whiteBgContainer}>
            <Grid item xs={12} align="center">
                <NoRecordIcon />
                <Typography variant="h4" align="center" className={"pt-2 pb-1"}>{"NO RECORDS FOUND"}</Typography>
            </Grid>
        </Grid>
    );
}

// default props
NoRecordsFound.defaultProps = {
    classes: {}
};

// prop types
NoRecordsFound.propTypes = {
    classes: PropTypes.object
};

export default NoRecordsFound

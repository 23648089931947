import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import PropTypes from "prop-types";
import classNames from "classnames"
import { IconButton, withStyles, Grid, FormControl, Select, Dialog, Accordion, AccordionSummary, AccordionDetails, TextareaAutosize } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

//Styles and Images
import ServicesStyles from "../../ServicesStyles"
import { EditTableIcon, DragIcon, CheckSelectedIcon, CloseIcon, BellIcon, DeleteIcon } from '../../../../assets/svg'

// Import Components
import { Button as Btn, AlertDialog, TextField, Typography, CheckBox, Link, RichTextEdit } from "../../../../components";

// Import Helpers
import validations from "../../../../utils/validations";

// Import Cofig Constants
import { templates_page_constants, user_page_constants } from "../../../../config/constants";

// Import Actions
import { updateReducerAction, templateAction, alertAction } from "../../../../redux/actions";

//import constant
import { serviceConstant } from '../../../../redux/constants'
import VariationContainer from "./VariationContainer";

const Choices = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, createFromScratch, page, errorFlags } = props;
    const [deleteAlert, setDeleteAlert] = useState(null)

    //add new choices
    const addChoices = () => {
        if (!createFromScratch.documentData[page].choices) {
            createFromScratch.documentData[page].choices = []
            errorFlags.documentData[page].choices = []
        }
        createFromScratch.documentData[page].choices = [...createFromScratch.documentData[page].choices, { title: '', position: '', is_required: false, variation: [{ text: "", is_notification: 0, position: 1 }] }]
        errorFlags.documentData[page].choices = [...errorFlags.documentData[page].choices, { title: false, position: false, variation: [{ text: false }] }]
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
        dispatch(updateReducerAction.update(serviceConstant.UPDATE_ERROR_FLAG_DOCUMENT, errorFlags, () => { }));
    }

    const updateChoiceField = (e, index) => {
        const { name, value } = e;
        if(name!=="position" || (name==="position" && value<100)){
            for (let i = 0; i < createFromScratch.documentData[page].choices.length; i++) {
                if (i === index) {
                    if (name === "is_required") {
                        createFromScratch.documentData[page].choices[i][name] = value === true ? 1 : 0
                    } else {
                        createFromScratch.documentData[page].choices[i][name] = value
                    }
                }
            }
            dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
        }else{
            dispatch(alertAction.error("Position should be less than 100"))
        }
    }

    //handle detlete choices on yes click in alert
    const deleteAction = () => {
        createFromScratch.documentData[page].choices.splice(deleteAlert.index, 1)
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { setDeleteAlert(null) }));
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Grid item sm={12}>
                    {createFromScratch.documentData[page] && createFromScratch.documentData[page].choices && createFromScratch.documentData[page].choices.map((items, indx) => (
                        <Grid container key={`choices${indx}`} alignItems="center" spacing={2} className={`${"createTemChoice mb15"} ${classes.choicesContainer}`}>
                            <Grid item xs={12} className="mb-2">
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography className="textFieldLabel" title={templates_page_constants.TITLE} variant={"body1"}></Typography>
                                        <TextField
                                            id={`name-${indx}`}
                                            name={`name-${indx}`}
                                            margin="none"
                                            placeholder={'Title'}
                                            value={items.title ? items.title : ''}
                                            onChange={(e) => { updateChoiceField({ name: 'title', value: e.target.value }, indx) }}
                                            error={errorFlags.documentData[page].choices[indx].title ? true : false}
                                            helperText={errorFlags.documentData[page].choices[indx].title ? "Title cannot be blank" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography className="textFieldLabel" title={templates_page_constants.DOC_POSITION} variant={"body1"}></Typography>
                                        <TextField
                                            id={`position${indx}`}
                                            name={`position${indx}`}
                                            margin="none"
                                            type={"number"}
                                            placeholder={'Position'}
                                            value={items.position ? items.position : ''}
                                            onChange={(e) => { updateChoiceField({ name: 'position', value: e.target.value }, indx) }}
                                            error={errorFlags.documentData[page].choices[indx].position ? true : false}
                                            helperText={errorFlags.documentData[page].choices[indx].position ? "Position cannot be blank" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant={"body1"} className={"pt-2"}>{" "}</Typography>
                                        <CheckBox
                                            id={`checked-${indx}`}
                                            label={"Required"}
                                            checked={items.is_required || false}
                                            color="secondary"
                                            handleChange={(e) => { updateChoiceField({ name: 'is_required', value: e.target.checked }, indx); }}
                                        />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <IconButton className={classes.choicesDelete}  onClick={(e) => { setDeleteAlert({index:indx}) }}><DeleteIcon /></IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <VariationContainer
                                classes={classes}
                                parentIndex={indx}
                                createFromScratch={createFromScratch}
                                page={page}
                                choiceVariation={items.variation}
                                errorFlagsvar={errorFlags.documentData[page].choices[indx].variation}
                                errorFlagsAll={JSON.parse(JSON.stringify(errorFlags))}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={12} align="left" className="mt-3 pl-1 pb-2 cursorPointer">
                        <Typography color="primary" variant={"body2"} title={"+ Add Another Choice"} className={`${"link inlineBlock"} ${classes.grow}`} onClick={() => { addChoices() }}></Typography>
                    </Grid>
                    {deleteAlert &&
                        <AlertDialog
                            fullScreen={false}
                            open={true}
                        >
                            <Grid className={'p-4'}>
                                <Typography variant="h6">{`Are you sure you want to delete this choice`}</Typography>
                                <Grid className={'pt-2'} align={"center"}>
                                    <Btn
                                        color={"primary"}
                                        className={"mr-1"}
                                        onClick={() => { deleteAction() }}
                                        title={"Yes"}
                                    />
                                    <Btn
                                        color={"secondary"}
                                        onClick={() => { setDeleteAlert(null) }}
                                        title={"No"}
                                    />
                                </Grid>
                            </Grid>
                        </AlertDialog>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
// default props
Choices.defaultProps = {
    classes: {},
};

// prop types
Choices.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(ServicesStyles)(Choices);

import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core';

//Styles
import SettingsStyles from "../SettingsStyles"

// Import History
import history from "../../../config/history";

// Import Components
import { Button, TextFieldWithoutRef, Typography} from "../../../components";

// Import Cofig Constants
import { settings_page_constants, user_page_constants } from "../../../config/constants";
import { settingsConstant } from "../../../redux/constants";

// Import Actions
import { settingsAction, updateReducerAction } from "../../../redux/actions";

const EmailContacts = (props) => {
    const dispatch = useDispatch()

    const { configuration: { email } } = useSelector(state => state.settings);
    const [errors, setErrors] = useState({});
    const [updateButton, setUpdateButton] = useState(false)

    /**
  *Handle Cancel Navigage to Tab based on Roles
  */
    const handleCancel = () => {
        history.go(-1)
    };

    const handleChange = (e) => {
        setUpdateButton(true)
        email[e.target.name] = e.target.value
        dispatch(updateReducerAction.update(settingsConstant.UPDATE_EMAIL_REQUEST, email, () => { }));
    }

    const handleSubmit = () => {
        let errors = {}
        if (!(email["compliance_email"].trim())) {
            errors["compliance_email"] = true
        }
        if (!(email["compliance_name"].trim())) {
            errors["compliance_name"] = true
        }
        if (!(email["support_email"].trim())) {
            errors["support_email"] = true
        }
        if (!(email["support_name"].trim())) {
            errors["support_name"] = true
        }
        setErrors(errors)
        if (Object.keys(errors).length === 0) {
            dispatch(settingsAction.saveConfiguration({ key: "email", value: email }, () => {
                setUpdateButton(false)
             }));
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"}>{settings_page_constants.SUPPORT_NAME}</Typography>
                            <TextFieldWithoutRef
                                name={"support_name"}
                                size={"small"}
                                margin={"none"}
                                value={email.support_name ? email.support_name : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.support_name ? true : false}
                                helperText={errors.support_name ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Grid item xs={11}>
                            <Typography variant={"body1"}>{settings_page_constants.SUPPORT_EMAIL}</Typography>
                            <TextFieldWithoutRef
                                name={"support_email"}
                                size={"small"}
                                margin={"none"}
                                value={email.support_email ? email.support_email : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.support_email ? true : false}
                                helperText={errors.support_email ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"}>{settings_page_constants.COMPLIANCE_NAME}</Typography>
                            <TextFieldWithoutRef
                                name={"compliance_name"}
                                size={"small"}
                                margin={"none"}
                                value={email.compliance_name ? email.compliance_name : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.compliance_name ? true : false}
                                helperText={errors.compliance_name ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Grid item xs={11}>
                            <Typography variant={"body1"}>{settings_page_constants.COMPLIANCE_EMAIL}</Typography>
                            <TextFieldWithoutRef
                                name={"compliance_email"}
                                size={"small"}
                                margin={"none"}
                                value={email.compliance_email ? email.compliance_email : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.compliance_email ? true : false}
                                helperText={errors.compliance_email ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <Grid item xs={4} align="left">
                            <Button color="primary" size="small" title={"UPDATE"} className="mr-1" onClick={handleSubmit} disabled={updateButton?false:true}></Button>
                            <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={handleCancel}></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

// default props
EmailContacts.defaultProps = {
    classes: {},
};

// prop types
EmailContacts.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(SettingsStyles)(EmailContacts);
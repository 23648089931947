import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';


import Img404 from "../../assets/images/svg/404.svg";
import { Button } from "../../components";
import { AppBar, Toolbar } from "@material-ui/core";

// Import Images
import AllianceLogoIcon from '../../assets/images/logo_icon.png';

// Import History
import history from "../../config/history";


const useStyles = makeStyles({
    root: {
        position: "relative",
        textAlign: "center",
        height: "100%",
    },
    logo: {
        cursor: 'pointer'
    },
    appBar: {
        position: 'absolute',
        top: 0
    }
});



function Page404({ history }) {
    const classes = useStyles();


     //get reducer data
  const { currentUser, logInUser } = useSelector(state => state.user);

    //when the user click the logo
    const logoClick = () => {
        // history.push(`/client`);
        if ((currentUser.role === "admin" || currentUser.role === "client")) {
          history.push(`/users?reset=true`);
        } else {
          history.push(`/submissions`);
        }
    }


    return (
        <Grid container alignItems="center" className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Grid>
                        {/* <Button className={classes.headerLogo} color="inherit" onClick={() => { logoClick() }}> */}
                        <img src={AllianceLogoIcon} className={classes.logo} onClick={() => { logoClick() }} alt="Alliance 2020" />
                        {/* </Button> */}
                    </Grid>
                </Toolbar>
            </AppBar>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className="height100"
            >
                <Grid item xs>

                    <img className="mb-3" src={Img404} alt="page 404"></img>
                    <Typography variant="h2" className="mb-2 txtBold">
                        OOPS! PAGE NOT FOUND
 </Typography>

                    <Typography variant="h6" className="mb-2">
                        Maybe the page you are looking for has been removed, or you typed in the wrong URL
 </Typography>
                    <Button color="primary" onClick={() => history.goBack()} title="GO TO HOME"></Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Page404.defaultProps = {
    classes: {},
    match: {},
    user: {},
  };
  
  // prop types
  Page404.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
  };
  

export default Page404;
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';

//Styles
import ServicesStyles from "./ServicesStyles"

//Import Images
import { StepperCircle } from '../../assets/svg'

// Import Components
import { Button, TextField, Typography, CheckBox, Link } from "../../components";
import AddDocuments from "./createServiceFromScratch/AddDocuments"
import AddAgreement from "./createServiceFromScratch/AddAgreement"
import AddTemplate from "./createServiceFromScratch/AddTemplate"
import AddService from "./createServiceFromScratch/AddService"
import AddClients from "./createServiceFromScratch/AddClient"

// Import Cofig Constants
import { user_page_constants } from "../../config/constants";
import { serviceConstant } from "../../redux/constants";

// Import Config Layout
import { servicesCardLimit } from "../../config/layout";

// Import Actions
import { alertAction, serviceAction, updateReducerAction } from "../../redux/actions";

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 8,
        left: 'calc(-50% + 0px)',
        right: 'calc(50% + 0px)',
    },
    active: {
        '& $line': {
            borderColor: '#3abeff',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#3abeff',
        },
        '& .StepperCircle g': {
            stroke: '#3abeff !important'
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        cursor: 'pointer',
        '& .StepperCircle': {
            zIndex: 1
        }
    },
    active: {
        '& .StepperCircle g': {
            stroke: '#3abeff'
        },
        color: '#3abeff',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#3abeff',
        zIndex: 1,
        fontSize: 18,
        '& .StepperCircle g': {
            stroke: '#3abeff !important'
        },
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <StepperCircle className={classes.completed} /> : <StepperCircle />}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

function getSteps(pageType) {
    if (pageType === "user") {
        return ['Documents', 'Agreement', 'Template', 'Service'];
    } else {
        return ['Client', 'Documents', 'Agreement', 'Template', 'Service'];
    }
}

function getStepContent(step, pageType, handleRichTextChange, handleTempRichTextChange, handleGoToService, addedSuccess) {
    if (pageType === "user") {
        switch (step) {
            case 0:
                return <AddDocuments handleRichTextChange={handleRichTextChange} />
            case 1:
                return <AddAgreement />
            case 2:
                return <AddTemplate handleTempRichTextChange={handleTempRichTextChange} />
            case 3:
                return <AddService handleGoToService={handleGoToService} addedSuccess={addedSuccess} />
            default:
                return <AddDocuments />
        }
    } else {
        switch (step) {
            case 0:
                return <AddClients clientId={false} />
            case 1:
                return <AddDocuments handleRichTextChange={handleRichTextChange} />
            case 2:
                return <AddAgreement />
            case 3:
                return <AddTemplate handleTempRichTextChange={handleTempRichTextChange} />
            case 4:
                return <AddService handleGoToService={handleGoToService} addedSuccess={addedSuccess} />
            default:
                return <AddClients clientId={false} />
        }
    }

}

const AddServiceDialog = (props) => {

    let cardLimit = servicesCardLimit();

    //define dispatch
    const dispatch = useDispatch();

    const { classes, open, handleClose, pageType, alignment, toggleDrawer } = props;

    let { createFromScratch, createFromScratchErrorFlags: errorFlags, createFromScratchDocuments, usersSearch: { page }, getAll: { page: getAllPage } } = useSelector((state) => state.service);

    //Stepper
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const steps = getSteps(pageType);
    const [addedSuccess, setAddedSuccess] = useState(false)

    const { getUser: currentuser } = useSelector(state => state.user);
    if (pageType === "user") {
        if (Number(currentuser.ownership_id === 1) || Number(currentuser.role) === 1) {
            createFromScratch.client_id = currentuser.owner_id
        }
        else if (Number(currentuser.ownership_id === 2) || Number(currentuser.role) === 2) {
            createFromScratch.client_id = currentuser.client_id
        }
    }

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    //handle rich text change
    const handleRichTextChange = (v, page) => {
        //setRichText
        if (createFromScratch.documentData[page])
            createFromScratch.documentData[page].richText = v
    }

    //handle rich text change
    const handleTempRichTextChange = (v) => {
        createFromScratch.emailTempContent = v
    }

    //handle next button
    const handleNext = () => {
        dispatch(handleStep(activeStep + 1))
    };

    // handle stepper
    const handleStepperClick = (newStep) => {
        if ((pageType === "user" && newStep === 0) || (pageType !== "user" && newStep === 1)) {
            dispatch(updateReducerAction.update(serviceConstant.UPDATE_DOC_STEP_INIT, null, () => {
                if (newStep < activeStep) {
                    setActiveStep(newStep);
                } else {
                    dispatch(handleStep(activeStep + 1))
                }
            }))
        } else {
            if (newStep < activeStep) {
                setActiveStep(newStep);
            } else {
                dispatch(handleStep(activeStep + 1))
            }
        }
    };

    //handle step change- required fields check
    const handleStep = (newActiveStep) => () => {
        let updateFlag = true
        errorFlags.errorTab0 = false
        errorFlags.errorTab1 = false
        errorFlags.errorTab3 = false

        if (pageType !== "user" && activeStep === 0) {
            if (!createFromScratch.client_id) {
                errorFlags.client_id = true
                updateFlag = false
            }
            else {
                errorFlags.client_id = false
            }
        } else if ((pageType !== "user" && activeStep === 1) || (pageType === "user" && activeStep === 0)) {
            Object.keys(createFromScratch.documentData).map((page) => {
                createFromScratch.documentData[page]["title"] = createFromScratch.documentData[page]["title"] ? createFromScratch.documentData[page]["title"].trim() : ""
                if (!(createFromScratch.documentData[page]["title"].trim())) {
                    updateFlag = false
                    errorFlags.documentData[page].title = true
                    errorFlags.errorTab0 = true
                }
                else {
                    errorFlags.documentData[page].title = false
                }
                if (!createFromScratch.documentData[page]["richText"]) {
                    updateFlag = false
                    errorFlags.documentData[page].richText = true
                    errorFlags.errorTab1 = true
                }
                else {
                    let str = createFromScratch.documentData[page]["richText"]
                    str = str.replace(/(<([^>]+)>)/ig, "")
                    str = str.trim()
                    if (str) {
                        errorFlags.documentData[page].richText = false
                    } else {
                        updateFlag = false
                        errorFlags.documentData[page].richText = true
                        errorFlags.errorTab1 = true
                    }
                }
                if ((createFromScratch.documentData[page]).hasOwnProperty("choices") && createFromScratch.documentData[page].choices.length > 0) {
                    createFromScratch.documentData[page].choices.map((items, indx) => {
                        items.title = items.title ? items.title.trim() : ""
                        items.position = items.position ? items.position.trim() : ""
                        if (!(items.title.trim())) {
                            errorFlags.documentData[page].choices[indx].title = true
                            errorFlags.errorTab3 = true
                            updateFlag = false
                        } else {
                            errorFlags.documentData[page].choices[indx].title = false
                        }
                        if (!(items.position.trim())) {
                            errorFlags.documentData[page].choices[indx].position = true
                            errorFlags.errorTab3 = true
                            updateFlag = false
                        } else {
                            errorFlags.documentData[page].choices[indx].position = false
                        }
                        createFromScratch.documentData[page].choices[indx].variation.map((item1, indx1) => {
                            item1.text = item1.text ? item1.text.trim() : ""
                            if (!(item1.text.trim())) {
                                errorFlags.documentData[page].choices[indx].variation[indx1].text = true
                                errorFlags.errorTab3 = true
                                updateFlag = false
                            } else {
                                errorFlags.documentData[page].choices[indx].variation[indx1].text = false
                            }
                        })
                    })
                }
            })
        } else if ((pageType !== "user" && activeStep === 2) || (pageType === "user" && activeStep === 1)) {
            createFromScratch.agreement_name = createFromScratch.agreement_name ? createFromScratch.agreement_name.trim() : ""
            createFromScratch.checkedData = createFromScratch.checkedData ? createFromScratch.checkedData : []
            if (!(createFromScratch.agreement_name.trim())) {
                errorFlags.agreement_name = true
                updateFlag = false
            } else {
                errorFlags.agreement_name = false
            }
            if (createFromScratchDocuments.checkedData.length === 0) {
                errorFlags.checkedDocuments = true
                updateFlag = false
            } else {
                errorFlags.checkedDocuments = false
            }
        } else if ((pageType !== "user" && activeStep === 3) || (pageType === "user" && activeStep === 2)) {
            createFromScratch.template_name = createFromScratch.template_name ? createFromScratch.template_name.trim() : ""
            if (!(createFromScratch.template_name.trim())) {
                errorFlags.template_name = true
                updateFlag = false
            } else {
                errorFlags.template_name = false
            }

            if (!createFromScratch.is_template_checkbox) {
                createFromScratch.emailTempName = createFromScratch.emailTempName ? createFromScratch.emailTempName.trim() : ""
                createFromScratch.emailTempSubject = createFromScratch.emailTempSubject ? createFromScratch.emailTempSubject.trim() : ""
                if (!(createFromScratch.emailTempName.trim())) {
                    errorFlags.emailTempName = true
                    if (!createFromScratch.is_template_checkbox)
                        updateFlag = false
                } else {
                    errorFlags.emailTempName = false
                }
                if (!(createFromScratch.emailTempSubject.trim())) {
                    errorFlags.emailTempSubject = true
                    if (!createFromScratch.is_template_checkbox)
                        updateFlag = false
                } else {
                    errorFlags.emailTempSubject = false
                }
                if (!createFromScratch.emailTempContent) {
                    errorFlags.emailTempContent = true
                    if (!createFromScratch.is_template_checkbox)
                        updateFlag = false
                } else {
                    let str = createFromScratch.emailTempContent
                    str = str.replace(/(<([^>]+)>)/ig, "")
                    str = str.trim()
                    if (str) {
                        errorFlags.emailTempContent = false
                    } else {
                        errorFlags.emailTempContent = true
                        if (!createFromScratch.is_template_checkbox)
                            updateFlag = false
                    }
                }
            }
        } else if ((pageType !== "user" && activeStep === 4) || (pageType === "user" && activeStep === 3)) {
            createFromScratch.product = createFromScratch.product ? createFromScratch.product.trim() : ""
            if (!(createFromScratch.product.trim())) {
                errorFlags.product = true
                updateFlag = false
            } else {
                errorFlags.product = false
            }
            if (!createFromScratch.fees) {
                createFromScratch.fees = 0
            } else {
                if (Number(createFromScratch.fees) && Number(createFromScratch.fees) < 0) {
                    createFromScratch.fees = 0
                } else if (!Number(createFromScratch.fees)) {
                    createFromScratch.fees = 0
                }
                errorFlags.fees = false
            }
        }

        dispatch(updateReducerAction.update(serviceConstant.UPDATE_ERROR_FLAG_DOCUMENT, errorFlags, () => { }));
        if (updateFlag) {
            if (pageType !== "user" && activeStep === 0) {
                setActiveStep(newActiveStep);
            } else if ((pageType !== "user" && activeStep === 1) || (pageType === "user" && activeStep === 0)) {
                if(createFromScratch.documentData){
                    let update  = true;
                    let title = true;
                    
                    let keyData = Object.keys(createFromScratch.documentData);
                    for(let k=0;k<keyData.length;k++){
                        if(createFromScratch.documentData[keyData[k]] && createFromScratch.documentData[keyData[k]]["title"] && createFromScratch.documentData[keyData[k]]["title"].length>64){
                            update = false;
                            title = false;
                        }
                        if(createFromScratch.documentData[keyData[k]] && createFromScratch.documentData[keyData[k]]["description"] && createFromScratch.documentData[keyData[k]]["description"].length>250){
                            update = false;
                        }
                    }
                    if(update===true){
                        dispatch(serviceAction.addDocumentsFromSearch(createFromScratch.documentData, () => {
                            setActiveStep(newActiveStep);
                        }));
                    }else if(title === false){
                        dispatch(alertAction.error("Title length should be less than 64"));
                    }else{
                        dispatch(alertAction.error("Description length should be less than 250"));
                    }
                }
            } else if ((pageType !== "user" && activeStep === 2) || (pageType === "user" && activeStep === 1)) {
                if(createFromScratch && createFromScratch["agreement_name"] && createFromScratch["agreement_name"].length>250){
                    dispatch(alertAction.error("Agreement name length should be less than 250"));
                }else{
                    dispatch(serviceAction.addAgreementFromScratch({ createFromScratch, checkedData: createFromScratchDocuments.checkedData, dataOrder: createFromScratchDocuments.data }, () => {
                        setActiveStep(newActiveStep);
                    }));
                }
            } else if ((pageType !== "user" && activeStep === 3) || (pageType === "user" && activeStep === 2)) {
                
                if(createFromScratch && (createFromScratch["template_name"] && createFromScratch["template_name"].length>100)){
                    dispatch(alertAction.error("Template name length should be less than 100"));
                }else if(createFromScratch && (createFromScratch["template_description"] && createFromScratch["template_description"].length>255)){
                    dispatch(alertAction.error("Template description length should be less than 255"));
                }else if(createFromScratch && createFromScratch["is_template_checkbox"]===false && (createFromScratch["emailTempName"] && createFromScratch["emailTempName"].length>255)){
                    dispatch(alertAction.error("Email Template name length should be less than 255"));
                }else if(createFromScratch && createFromScratch["is_template_checkbox"]===false && (createFromScratch["emailTempSubject"] && createFromScratch["emailTempSubject"].length>250)){
                    dispatch(alertAction.error("Email Template description length should be less than 250"));
                }else{
                    dispatch(serviceAction.addTemplateFromScratch({ createFromScratch }, () => {
                        setActiveStep(newActiveStep);
                    }));
                }
            } else if (pageType !== "user" && activeStep === 4) {
                if(createFromScratch && createFromScratch.product && createFromScratch.product.length>250){
                    dispatch(alertAction.error("Product length should be less than 250"));
                }else{   
                    dispatch(serviceAction.createService({
                        client_id: createFromScratch.client_id,
                        template_id: createFromScratch.template_id,
                        selectedItem: createFromScratch.serviceCheckedData,
                        selectOrder: createFromScratch.serviceData,
                        product: createFromScratch.product,
                        fees: createFromScratch.fees
                    }, () => {
                        setAddedSuccess(true)
                    }))
                }
            } else if (pageType === "user" && activeStep === 3) {
                if(createFromScratch && createFromScratch.product && createFromScratch.product.length>250){
                    dispatch(alertAction.error("Product length should be less than 250"));
                }else{
                    dispatch(serviceAction.createService({
                        client_id: createFromScratch.client_id,
                        template_id: createFromScratch.template_id,
                        selectedItem: createFromScratch.serviceCheckedData,
                        selectOrder: createFromScratch.serviceData,
                        product: createFromScratch.product,
                        fees: createFromScratch.fees
                    }, () => {
                        setAddedSuccess(true)
                    }))
                }
            }
        }
    };

    // handle go to service
    const handleGoToService = () => {
        setAddedSuccess(false)
        if (pageType !== "user" && activeStep === 4) {
            let cfsReload = {
                client_id: null,
                page: 0,
                totalPage: [0],
                tabValue: 0,
                documentData: {
                    0: {},
                },
                serviceData: ["Introduction", "Criteria", "Application Signature", "Document", "Application timestamp", "Queue Submission", "Payment PDF"],
                serviceCheckedData: ["Application Review"]
            }
            dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, cfsReload, () => {
                setActiveStep(0)
                dispatch(handleClose)
                dispatch(toggleDrawer(false))
                if (alignment === "right") {
                    dispatch(serviceAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
                }
                else {
                    dispatch(serviceAction.getAll({ limit: cardLimit, skip: 1, search: getAllPage.search, sort: getAllPage.sort }));
                }
            }));
        } else if (pageType === "user" && activeStep === 3) {
            let cfsReload = {
                client_id: null,
                page: 0,
                totalPage: [0],
                tabValue: 0,
                documentData: {
                    0: {},
                },
                serviceData: ["Introduction", "Criteria", "Application Signature", "Document", "Application timestamp", "Queue Submission", "Payment PDF"],
                serviceCheckedData: ["Application Review"]
            }
            dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, cfsReload, () => {
                setActiveStep(0)
                dispatch(handleClose)
                dispatch(toggleDrawer)
                dispatch(serviceAction.userServiceSearch(1, page.limit, "service_name", "asc", page.sFields, page.sValues, createFromScratch.client_id));
            }));
        }
    }

    //handle cancel
    const handleCloseService = () => {
        let cfsReload = {
            client_id: null,
            page: 0,
            totalPage: [0],
            tabValue: 0,
            documentData: {
                0: {},
            },
            serviceData: ["Introduction", "Criteria", "Application Signature", "Document", "Application timestamp", "Queue Submission", "Payment PDF"],
            serviceCheckedData: ["Application Review"],
            is_template_checkbox: true
        }
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, cfsReload, () => {
            if (pageType !== "user") {
                setActiveStep(0)
                dispatch(handleClose)
                dispatch(toggleDrawer(false))
            } else {
                setActiveStep(0)
                dispatch(handleClose)
                dispatch(toggleDrawer)
            }
        }));
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    return (
        <React.Fragment>
            <Dialog fullScreen open={open} onClose={handleClose} className={classes.addServiceDialogRoot} disableEnforceFocus={true}>
                <Grid container className={classes.addServiceDialog} alignContent="flex-start">

                    <Grid item xs={12} className={classes.addAServiceTop}>
                        <Paper elevation={3} className={classes.cardSection} >
                            <Grid container alignItems="center" justify="space-between">
                                <Typography variant="h3" className={addedSuccess && "mt-15"}>{user_page_constants.ADD_A_SERVICE}</Typography>
                                {!addedSuccess &&
                                    <React.Fragment>
                                        <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                                            {steps.map((label, index) => (
                                                <Step key={label} >
                                                    <StepLabel
                                                        onClick={() => handleStepperClick(index)}
                                                        className={"cursorPointer"}
                                                        completed={completed[index]}
                                                        StepIconComponent={QontoStepIcon}
                                                    >{label}
                                                    </StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        <Grid>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                className="mr-1"
                                                title={steps.length === (activeStep + 1) ? "Create" : "Next"}
                                            >
                                            </Button>
                                            <Button title={user_page_constants.CANCEL} size="small" onClick={handleCloseService} color="secondary"></Button>
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid ite xs={12} className={classes.addAServiceCenter} align="center">
                        <React.Fragment>
                            {allStepsCompleted() ? (
                                <React.Fragment>
                                    <Typography className={classes.instructions}>
                                        All steps completed - you&apos;re finished
                                    </Typography>

                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid className={classes.instructions}>{getStepContent(activeStep, pageType, handleRichTextChange, handleTempRichTextChange, handleGoToService, addedSuccess)}</Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Grid>
                </Grid>

            </Dialog>
        </React.Fragment>
    );
}

// default props
AddServiceDialog.defaultProps = {
    classes: {}
};

// prop types
AddServiceDialog.propTypes = {
    classes: PropTypes.object
};

export default withStyles(ServicesStyles)(AddServiceDialog);

import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import classsNames from 'classnames';
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

//Styles
import SettingsStyles from "../SettingsStyles"

// Import Components
import { Typography } from "../../../components";
import CompanyInformation from './CompanyInformation'
import EmailContacts from './EmailContacts'
import TazWorks from './TazWorks'
import BlueFin from './BlueFin'
import Square from './Square'

// Import Helpers
// import validations from "../../../utils/validations";

// Import Cofig Constants
import { settings_page_constants } from "../../../config/constants";

const SystemConfigurationTab = (props) => {
    const { classes } = props;

    //Accordion
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    return (
        <React.Fragment>
            <Grid container className={`${"pt-2"} ${classsNames(classes.appTemplateDialog, classes.tempContainer)}`}>
                <Grid container className={classes.accordionContainer}>
                    <Grid >
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                aria-label="Expand"
                                aria-controls="additional-actions1-content"
                                id="additional-actions1-header"
                            >
                                <Typography variant="h6">{settings_page_constants.COMPANY_INFORMATION}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{padding: '0px !important'}}>
                                <CompanyInformation  {...props} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                aria-label="Expand"
                                aria-controls="additional-actions2-content"
                                id="additional-actions2-header"
                            >
                                <Typography variant="h6" title={settings_page_constants.EMAIL_CONTACTS}></Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                <EmailContacts  {...props} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                            >
                                <Typography variant="h6" title={settings_page_constants.TAZWORKS}></Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                <TazWorks  {...props} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                            >
                                <Typography variant="h6" title={settings_page_constants.BLUEFIN}></Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                <BlueFin  {...props} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                            >
                                <Typography variant="h6" title={"Square"}></Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                <Square  {...props} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
// default props
SystemConfigurationTab.defaultProps = {
    classes: {},
};

// prop types
SystemConfigurationTab.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(SettingsStyles)(SystemConfigurationTab);

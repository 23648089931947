import React from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

// Import css
const loaderWrapperStyle = {
  position: "absolute",
  top: "0px",
  left: "0px",
  bottom: "0px",
  width: "100%",
  height: "100%",
  zIndex: 2000,
  backgroundColor: "rgba(255, 255, 255, 0.85)",
  color: "#222",
};

const loaderTextStyle = {
  color: "rgb(255, 255, 255)",
  height: "100px",
  position: "absolute",
  width: "100px",
  margin: "auto",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const PageLoaderComponent = () => {

  //get reducer data
  const UI = useSelector((state) => state.UI);

  if (!UI.loading) {
    return <div></div>;
  } else {
    return (
      <div style={loaderWrapperStyle}>
        <div style={loaderTextStyle}>
          {/* <CircularProgress /> */}
          <svg height="60" width="60">
          <circle className="circle1" cx="30" cy="30" r="25" stroke="#12a8de" strokeWidth="4" fill="transparent" />
          <circle className="circle2" cx="30" cy="30" r="15" stroke="#12a8de" strokeWidth="4" fill="transparent" />
          <circle className="circle3" cx="30" cy="30" r="5" stroke="#12a8de" strokeWidth="4" fill="transparent" />
          Sorry, your browser does not support inline SVG.
        </svg>
        </div>
      </div>
    );
  }
};

export const PageLoader = PageLoaderComponent;

import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Import Icon
import { Information } from "../../assets/images/svg";

/**
 *Tool tip Information component
 */
function InformationComponent (props) {
  /**
   * Bind
   * HTML to DOM
   */
  const { children, color } = props;

  return (
    <Tooltip title={children} arrow className= "infoToolTip">
      <IconButton color="inherit" className={"p-0 ml-1"}>
        <Information color={color}/>
      </IconButton>
    </Tooltip>
  );
}

// default props
InformationComponent.defaultProps = {
  title: "",
  placement: "bottom",
  className: "",
  children: "",
  color:""
};

// prop types
InformationComponent.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.string,
};

// export component
export const Info = InformationComponent;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import classNames from "classnames"
import { FormControl, Grid, withStyles, Paper, IconButton } from '@material-ui/core';
import { Link as MUIlink } from "@material-ui/core";

//Import styles and Images
import { AllianceAMaskIcon, DeleteIcon } from "../../../assets/svg"
import SettingsStyles from "../SettingsStyles"

// Import Components
import { Button, TextFieldWithoutRef, Typography, AutoComplete, CheckBox, TextField } from "../../../components";

// Import Actions
import { settingsAction, authAction, updateReducerAction, alertAction } from "../../../redux/actions";

// Import History
import history from "../../../config/history";

// Import Helpers
import validations from "../../../utils/validations";

// Import Cofig Constants
import { user_page_constants, errorsConstants, common_constants } from "../../../config/constants";
import { settingsConstant } from "../../../redux/constants";

const ProfileTab = (props) => {
    const dispatch = useDispatch()
    const { classes, match } = props;

    //fetch data from reducer
    const { profile } = useSelector(state => state.settings);
    const { region: stateOptions } = useSelector(state => state.user);

    // set state
    const [passDis, setPassDis] = useState(false);
    const [passDis1, setPassDis1] = useState(false);
    const [userImg, setUserImg] = useState("");
    const [role, setRole] = useState("");
    const [activeStatus, setStatus] = useState("");
    const [errorss, setErrors] = useState({});
    const [activeStateField, setActiveState] = useState("");
    const [doNotUse, setDoNotUse] = useState(null);
    const [isResetPassword, setIsResetPassword] = useState(false)
    const [updateButton, setUpdateButton] = useState(false)

    useEffect(() => {
        setUserImg(profile.image ? profile.image : "")
        setRole(profile.role ? profile.role : "0")
        setStatus(profile.status >= 0 ? profile.status.toString() : "1")
        setActiveState(profile.state ? profile.state.toString() : "0")
        setDoNotUse(Number(profile.useLogo) > 0 ? true : false)
    }, [profile])

    let roleOptions = [
        { "value": "3", "label": "Admin" },
        { "value": "1", "label": "Client" },
        { "value": "2", "label": "Manager" }
    ];

    let statusOptions = [
        { "value": "1", "label": "Active" },
        { "value": "0", "label": "In-Active" }
    ];

    // Set Form Values
    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'all',
        reValidateMode: 'all',
        defaultValues: {
            password: "",
            cpassword: ""
        }
    });

    let trackEdit = watch()
    if (isResetPassword && !updateButton && (trackEdit.password || trackEdit.cpassword)) {
        setUpdateButton(true)
        console.log("sdf", trackEdit.password, trackEdit.cpassword)
    }

    /**
   *Handle Cancel Navigage to Tab based on Roles
   */
    const handleCancel = () => {
        history.go(-1)
    };

    // Check / Uncheck use profile
    const handleChangeDoNotUse = (event) => {
        setUpdateButton(true)
        setDoNotUse(event.target.checked);
    };

    //handle image upload
    const imageHandler = (e) => {
        setUpdateButton(true)
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setUserImg(reader.result)
                    profile.image = reader.result
                }
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    //handle change
    const handleChange = (e) => {
        setUpdateButton(true)
        if (e.target.name === "status") {
            setStatus(e.target.value)
        } else if (e.target.name === "state") {
            setActiveState(e.target.value)
        }
        profile[e.target.name] = e.target.value
        dispatch(updateReducerAction.update(settingsConstant.UPDATE_PROFILE_REQUEST, profile, () => { }));
    }

    //get role
    const getSelectedRoleValue = (v) => {
        const valuee = roleOptions.filter(i => (Number(i.value) === Number(v)))
        return (valuee[0] ? valuee[0] : { value: "", label: "" })
    }

    //get status
    const getSelectedStatusValue = (v) => {
        const valuee = statusOptions.filter(i => (Number(i.value) === Number(v)))
        return (valuee[0] ? valuee[0] : { value: "", label: "" })
    }

    //handle profile submit
    const handleProfileSubmit = (passworddata, ev) => {
        let errorss = {}
        if (isResetPassword && passworddata.password.trim() !== passworddata.cpassword.trim()) {
            errorss["passwordNotMatch"] = true
            dispatch(alertAction.error("Confirm password doesn't match"))
        }
        if (role === "1") {
            if (!(profile["company"].trim())) {
                errorss["company"] = true
            }
        }
        if (role !== "2" && !(profile["full_name"].trim())) {
            errorss["full_name"] = true
        }
        if (role === "2" && !(profile["first_name"].trim())) {
            errorss["first_name"] = true
        }
        if (!(profile["email"].trim())) {
            errorss["email"] = true
        }

        profile['useLogo'] = doNotUse ? 1 : 0;
        profile['password'] = passworddata.password
        profile['isResetPassword'] = isResetPassword
        setErrors(errorss)
        if (Object.keys(errorss).length === 0) {
            if(profile && profile["email"] && profile["email"].length>255){
                dispatch(alertAction.error("Email length should be less than 255"));
            } else if(profile && profile["phone"] && profile["phone"].length>25){
                dispatch(alertAction.error("Phone length should be less than 25"));
            } else if(profile && profile["full_name"] && profile["full_name"].length>150){
                dispatch(alertAction.error("Full Name length should be less than 150"));
            } else if(profile && profile["first_name"] && profile["first_name"].length>50){
                dispatch(alertAction.error("First name length should be less than 50"));
            } else if(profile && profile["last_name"] && profile["last_name"].length>50){
                dispatch(alertAction.error("Last Name length should be less than 50"));
            } else if (profile["zipcode"] && profile["zipcode"].length > 15) {
                dispatch(alertAction.error("Postal Code length should be less than 15"));
            } else if (profile["company"] && profile["company"].length > 150) {
                dispatch(alertAction.error("Company length should be less than 150"));
            } else if (profile["address"] && profile["address"].length > 50) {
                dispatch(alertAction.error("Street length should be less than 50"));
            } else if (profile["city"] && profile["city"].length > 50) {
                dispatch(alertAction.error("City length should be less than 50"));
            }else{
                dispatch(settingsAction.saveProfile(profile, () => {
                    setTimeout(() => {
                        setUpdateButton(false)
                    }, 100);
                    setIsResetPassword(false)
                    ev.target.reset();
                    dispatch(authAction.getProfileLogo())
                }));
            }
        }
    }

    //set company selected Dropown
    const getSelectedValue = (arr, val) => {
        const valuee = arr.filter(i => (i.value === val))
        return (valuee[0] ? valuee[0] : { value: "", label: "" })
    }

    // delete image
    const deleteImage = () => {
        setUpdateButton(true)
        setUserImg("")
        profile['image'] = ""
    }

    return (
        <Grid container alignItems="flex-start" spacing={3} wrap="nowrap">
            <Grid item>
                <Paper elevation={3} className={`${"relative"} ${classes.cardSectionProfileImg}`} >
                    <Grid className={classes.profileImgContainer}>
                        {userImg ? <IconButton className={'closeIconBtn'} onClick={() => { deleteImage() }}><DeleteIcon /></IconButton> : ''}
                        <label htmlFor="file-input">
                            {userImg ? <img src={userImg} className={classes.uploadImgPopup} /> : <AllianceAMaskIcon />}
                            <input className={`${classes.uploadImgPopup} ${classes.uploadImgOpacity}`} id="file-input" onChange={(e) => { imageHandler(e) }} type="file" accept=".png,.jpeg,.jpg" style={{ display: "none" }} value='' />
                            {!userImg ? <Grid className={classes.uploadText} >Upload image</Grid> : ''}
                        </label>
                    </Grid>
                </Paper>
                {userImg ? <Grid className={classes.userProfileDoNot}>
                    <CheckBox
                        label="Use current"
                        checked={doNotUse}
                        color="secondary"
                        handleChange={(w) => { handleChangeDoNotUse(w) }} />
                </Grid> : ''}
            </Grid>
            <Grid item className={classes.grow}>
                <Paper elevation={3} className={`${"pt-3 pb-3"} ${classes.settingsCardSection}`} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} className={classes.titleContainer}>
                            <Typography variant="h6" className={classes.groupTitle}>{"Generic"}</Typography>
                        </Grid>
                        <Grid item xs={4} align="center">
                            <Grid item xs={10}>
                                <Typography variant={"body1"} className={"textFieldLabel "}>{user_page_constants.ROLE}</Typography>
                                <FormControl variant="outlined" className={classNames(classes.formControl, classes.selectBox)}>
                                    <AutoComplete
                                        name={'role'}
                                        suggestions={roleOptions}
                                        handleAutoComplete={(e) => handleChange(e)}
                                        value={getSelectedRoleValue(role)}
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {Number(role) !== 2 &&
                            <Grid item xs={4} align="center">
                                <Grid item xs={10}>
                                    <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.FULLNAME}</Typography>
                                    <TextFieldWithoutRef
                                        name={"full_name"}
                                        size={"small"}
                                        margin={"none"}
                                        value={profile.full_name ? profile.full_name : ""}
                                        handleChange={(e) => handleChange(e)}
                                        error={errorss.full_name ? true : false}
                                        helperText={errorss.full_name ? "This is required" : ""}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {Number(role) === 2 &&
                            <Grid item xs={4} align="center">
                                <Grid item xs={10}>
                                    <Typography variant={"body1"} className={"textFieldLabel"}>{'First Name'}</Typography>
                                    <TextFieldWithoutRef
                                        name={"first_name"}
                                        size={"small"}
                                        margin={"none"}
                                        value={profile.first_name ? profile.first_name : ""}
                                        handleChange={(e) => handleChange(e)}
                                        error={errorss.first_name ? true : false}
                                        helperText={errorss.first_name ? "This is required" : ""}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {Number(role) === 2 &&
                            <Grid item xs={4} align="center">
                                <Grid item xs={10}>
                                    <Typography variant={"body1"} className={"textFieldLabel"}>{'Last Name'}</Typography>
                                    <TextFieldWithoutRef
                                        name={"last_name"}
                                        size={"small"}
                                        margin={"none"}
                                        value={profile.last_name ? profile.last_name : ""}
                                        handleChange={(e) => handleChange(e)}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {Number(role) === 1 &&
                            <React.Fragment>
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.COMPANY_NAME}</Typography>
                                        <TextFieldWithoutRef
                                            name={"company"}
                                            size={"small"}
                                            margin={"none"}
                                            value={profile.company ? profile.company : ""}
                                            handleChange={(e) => handleChange(e)}
                                            error={errorss.company ? true : false}
                                            helperText={errorss.company ? "This is required" : ""}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} align="center">
                                    <Grid item xs={10}>
                                        <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.SALESRATE}</Typography>
                                        <TextFieldWithoutRef
                                            name={"salesrate"}
                                            size={"small"}
                                            margin={"none"}
                                            value={profile.salesrate ? profile.salesrate : ""}
                                            handleChange={(e) => handleChange(e)}
                                            error={errorss.salesrate ? true : false}
                                            helperText={errorss.salesrate ? "This is required" : ""}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        {Number(role) !== 0 &&
                            <Grid item xs={4} align="center">
                                <Grid item xs={10}>
                                    <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.PHONE}</Typography>
                                    <TextFieldWithoutRef
                                        name={"phone"}
                                        size={"small"}
                                        margin={"none"}
                                        value={profile.phone ? profile.phone : ""}
                                        handleChange={(e) => handleChange(e)}
                                        error={errorss.phone ? true : false}
                                        helperText={errorss.phone ? "This is required" : ""}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {Number(role) !== 2 &&
                            <Grid item xs={4} align="center">
                                <Grid item xs={10}>
                                    <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.EMAIL}</Typography>
                                    <TextFieldWithoutRef
                                        name={"email"}
                                        size={"small"}
                                        margin={"none"}
                                        value={profile.email ? profile.email : ""}
                                        handleChange={(e) => handleChange(e)}
                                        error={errorss.email ? true : false}
                                        helperText={errorss.email ? "This is required" : ""}
                                    />
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={4} align="center">
                            <Grid item xs={10}>
                                <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.STATUS}</Typography>
                                <FormControl variant="outlined" className={classNames(classes.formControl, classes.selectBox)}>
                                    <AutoComplete
                                        name={'status'}
                                        suggestions={statusOptions}
                                        handleAutoComplete={(e) => handleChange({ target: { name: "status", value: e.value } })}
                                        value={getSelectedStatusValue(activeStatus)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    {Number(role) === 1 &&
                        <Grid container spacing={3}>
                            <Grid item xs={12} className={classes.titleContainer}>
                                <Typography variant="h6" className={classes.groupTitle}>{"Address"}</Typography>
                            </Grid>
                            <Grid item xs={4} align="center">
                                <Grid item xs={10}>
                                    <Typography variant={"body1"} className={"textFieldLabel "}>{user_page_constants.STREET}</Typography>
                                    <TextFieldWithoutRef
                                        name={"address"}
                                        size={"small"}
                                        margin={"none"}
                                        value={profile.address ? profile.address : ""}
                                        handleChange={(e) => handleChange(e)}
                                        error={errorss.address ? true : false}
                                        helperText={errorss.address ? "This is required" : ""}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4} align="center">
                                <Grid item xs={10}>
                                    <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.CITY}</Typography>
                                    <TextFieldWithoutRef
                                        name={"city"}
                                        size={"small"}
                                        margin={"none"}
                                        value={profile.city ? profile.city : ""}
                                        handleChange={(e) => handleChange(e)}
                                        error={errorss.city ? true : false}
                                        helperText={errorss.city ? "This is required" : ""}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4} align="center">
                                <Grid item xs={10}>
                                    <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.STATE}</Typography>
                                    <FormControl variant="outlined" className={classNames(classes.formControl, classes.selectBox)}>
                                        <AutoComplete
                                            name={'selState'}
                                            suggestions={stateOptions}
                                            handleAutoComplete={(e) => handleChange({ target: { name: "state", value: e.value } })}
                                            value={getSelectedValue(stateOptions, activeStateField)}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} align="center">
                                <Grid item xs={10}>
                                    <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.ZIPCODE}</Typography>
                                    <TextFieldWithoutRef
                                        name={"zipcode"}
                                        size={"small"}
                                        type={"number"}
                                        margin={"none"}
                                        value={profile.zipcode ? profile.zipcode : ""}
                                        handleChange={(e) => handleChange(e)}
                                        error={errorss.zipcode ? true : false}
                                        helperText={errorss.zipcode ? "This is required" : ""}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid container className={` ${"mt-3"}`}>
                        <form onSubmit={handleSubmit(handleProfileSubmit)} noValidate className={"w-100"}>
                            <Grid item xs={12} className={`${classes.addUSerAction} ${"pb-2"}`}>
                                {/* <CheckBox
                                    label={user_page_constants.RESETPASSWORD}
                                    checked={isResetPassword}
                                    color="secondary"
                                    handleChange={(w) => { handleChangeResetPassword(w) }}
                                /> */}
                                <MUIlink
                                    variant="body1"
                                    color="primary"
                                    className="cursorPointer mb-1"
                                    onClick={() => { setIsResetPassword(!isResetPassword) }}
                                >
                                    {user_page_constants.RESETPASSWORD}
                                </MUIlink>
                            </Grid>
                            {isResetPassword &&
                                <React.Fragment>
                                    <Grid container item xs={12} className={"mb-4"}>
                                        <Grid item xs={4} align="center">
                                            <Grid item xs={10}>
                                                <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.PASSWORD}<span className={"reqStar"}>*</span></Typography>
                                                <TextField
                                                    id={'txt_password'}
                                                    name={"password"}
                                                    withImage={true}
                                                    passShow={true}
                                                    margin="none"
                                                    imageType={'passwd'}
                                                    type={passDis ? "text" : "password"}
                                                    placeholder={common_constants.PASSWORD}
                                                    error={errors.password ? true : false}
                                                    helperText={errors.password?.message}
                                                    inputRef={!isResetPassword ? register() :
                                                        register({
                                                            validate: {
                                                                ...validations.isRequired(errorsConstants.requiredPassword),
                                                                ...validations.isPasswordCriteriaMet(errorsConstants.passwordCriteria)
                                                            }
                                                        })}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} align="center">
                                            <Grid item xs={10}>
                                                <Typography variant={"body1"} className={"textFieldLabel"}>{user_page_constants.CONFIRM_PASSWORD}<span className={"reqStar"}>*</span></Typography>
                                                <TextField
                                                    id={'txt_cpassword'}
                                                    name={"cpassword"}
                                                    withImage={true}
                                                    passShow={true}
                                                    margin="none"
                                                    imageType={'passwd'}
                                                    type={passDis1 ? "text" : "password"}
                                                    placeholder={common_constants.CONFIRM_PASSWORD}
                                                    error={errors.cpassword ? true : false}
                                                    helperText={errors.cpassword?.message}
                                                    inputRef={!isResetPassword ? register() :
                                                        register({
                                                            validate: {
                                                                ...validations.isRequired(errorsConstants.requiredPassword),
                                                                ...validations.isPasswordCriteriaMet(errorsConstants.passwordCriteria)
                                                            }
                                                        })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                            <Grid item xs={12} className={`${"mt-1"} ${classes.addUSerAction}`}>
                                <Grid item xs={4} align="left">
                                    <Button color="primary" size="small" title={"save"} className="mr-1" type="submit" disabled={updateButton ? false : true}></Button>
                                    <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={handleCancel} ></Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

// default props
ProfileTab.defaultProps = {
    classes: {}
};

// prop types
ProfileTab.propTypes = {
    classes: PropTypes.object
};

export default withStyles(SettingsStyles)(ProfileTab);
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Slide, Tooltip, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputAdornment from '@material-ui/core/InputAdornment';

//Styles
import ServicesStyles from "./ServicesStyles"

//Images
import { TileIcon, ListIcon, SearchIcon, SortIcon, CloseIcon, SearchCloseIcon } from '../../assets/svg'

// Import Components
import { Button, TextField, Typography, AlertDialog } from "../../components";
import ServicesTile from './Components/ServicesTile';
import ServicesList from './Components/ServicesList';
import AddServiceDialog from './AddServiceDialog';
import UpdateFee from './Components/UpdateFee';
import AddEditService from './AddEditService'

// Import Cofig Constants
import { services_page_constants } from "../../config/constants";
import { serviceConstant } from "../../redux/constants";

// Import Actions
import { serviceAction, alertAction, updateReducerAction } from "../../redux/actions";

const Services = (props) => {

  //define dispatch
  const dispatch = useDispatch();

  const { classes, match } = props;

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    dispatch(updateReducerAction.update(serviceConstant.UPDATE_SERVICE_ALIGNMENT, newAlignment))
  };

  //get reducer data
  let { getAll: { data, page }, search: { page: tablePage }, pageViewType } = useSelector((state) => state.service);
  const { currentUser } = useSelector(state => state.user);
  const { loading } = useSelector(s => s.UI)

  //Toggle button for list and chord view
  const [drawer, setDrawer] = useState(false);
  const [alignment, setAlignment] = useState(pageViewType);
  const [serviceId, setServiceId] = useState(null)
  const [search, setSearchValue] = useState(false);
  const [searchData, setSearchData] = useState(page.search ? page.search : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [tempOpen, setTempOpen] = useState(false);
  const [uploadAlert, setUploadAlert] = useState(false)
  const [fileName, setFileName] = useState(null)

  //show hide search box
  const enableSearch = () => {
    setSearchValue(!search)
  }
  const hideSearchandClear = () => {
    setSearchValue(!search)
    setSearchData("");
    dispatch(serviceAction.getAll({ limit: 20, skip: 1, search: "", sort: page.sort }));
  }


  //hadle search to get data
  const handleSearch = (e) => {
    if(!loading){
      setSearchData(e.target.value)
      setTimeout(() => {
        dispatch(serviceAction.getAll({ limit: 20, skip: 1, search: e.target.value, sort: page.sort }));
      }, 1000)
    }
  }

  //close add service menu item
  const handleCloseService = () => {
    setAnchorEl(null);
  };

  //open menu item on add service
  const handleClick = (event) => {
    setServiceId(null)
    setAnchorEl(event.currentTarget);
  };

  // open/close drawer
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawer(open);
    setAnchorEl(null);
  };

  // open service drawer from edit button
  const editOpenDrawer = (id) => {
    setServiceId(id)
    setDrawer(true);
  }

  /**
   * download update fee excel
   */
  const downloadExcel = () => {
    let obj = {}
    if (alignment === "right") {
      obj["sortBy"] = tablePage.sortBy ? tablePage.sortBy : ""
      obj["sort"] = tablePage.sort ? tablePage.sort : ""
      obj["sFields"] = tablePage.sFields ? tablePage.sFields : []
      obj["sValues"] = tablePage.sValues ? tablePage.sValues : []
      obj["isTable"] = true
    } else {
      obj["search"] = page.search ? page.search : ""
      obj["isTable"] = false
    }
    dispatch(serviceAction.downloadExcel(obj));
  }

  // Create a reference to the hidden file input element
  let hiddenFileInput = React.useRef(null);

  /**
   * Select excel popup
   */
  const excelImport = () => {
    hiddenFileInput.current.click();
  };

  /**
   * open upload popup
   * @param {*} event 
   */
  const excelUploadPopup = (event) => {
    const fileUploaded = event.target.files[0];
    setFileName(fileUploaded)
    setUploadAlert(true)
  }

  /**
   * upload excel
   */
  const uploadAction = () => {
    var formData = new FormData();
    formData.append("file", fileName);
    let fileNameArr = fileName.name.split(".")
    const fileTypes = ["xlsx", "xls, csv"]
    if (fileTypes.includes(fileNameArr[fileNameArr.length - 1])) {
      setUploadAlert(false)
      setFileName(null)
      dispatch(serviceAction.uploadExcel(formData));
    } else {
      dispatch(alertAction.error("File format not supported"))
    }
  }

  const handleAddServiceOpen = () => {
    setTempOpen(true);
  };

  const handleClose = () => {
    setTempOpen(false);
  };

  //get sort data
  const getSortedData = (sorting) => {
    dispatch(serviceAction.getAll({ limit: 20, skip: 1, search: page.search, sort: sorting }));
  }

  return (
    <React.Fragment >
      <Grid item xs={12} >
        <Grid container direction="row" justify="space-between" alignItems="center" className={` ${classes.titlerow}`}>
          <Grid item>
            <Typography variant="h3" component="h3"> {`${services_page_constants.SERVICES} ${alignment !== "right" &&  page && page.count>=0 ? `(${page.count})` : ""}`} </Typography>
          </Grid>
          <Grid item >
            {/* <img src={ColumnsettingIcon} alt="ColumnsettingIcon" /> */}
            <Grid container alignItems="center">
              {alignment !== "right" && search &&
                <Grid className={`${classes.searchBoxGrid} ${"searchBoxGridHide"} ${alignment !== "right" && search && "searchBoxGridShow"}`}>
                  <TextField
                    id="standard-start-adornment"
                    variant="outlined"
                    size="small"
                    margin="none"
                    fullWidth={true}
                    autoFocus={true}
                    placeholder="Search"
                    className={"searchBox"}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{searchData == "" ? <IconButton onClick={enableSearch} style={{ padding: '0px' }}><SearchIcon className={classes.seachIcon} /></IconButton> : <IconButton onClick={hideSearchandClear} style={{ padding: '0px' }}><SearchCloseIcon /></IconButton>}</InputAdornment>,
                    }}
                    value={searchData}
                    onChange={(e) => { handleSearch(e) }}
                  />
                </Grid>
              }
              {alignment !== "right" &&
                <IconButton className={`${"mr-1"} ${classes.padding6}`} onClick={enableSearch}>{!search && <SearchIcon />}</IconButton>
              }
              {alignment !== "right" && <Grid>
                {page && page.sort && page.sort === "asc" ?
                  <Tooltip arrow title="Sort By Created Date"><IconButton className={classes.searchIcon} onClick={() => { getSortedData('desc') }}><SortIcon /></IconButton></Tooltip>
                  :
                  <Tooltip arrow title="Sort By Created Date"><IconButton className={classes.searchIcon} onClick={() => { getSortedData('asc') }}><SortIcon /></IconButton></Tooltip>
                }
              </Grid>
              }
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                className={`${"mr-1"} ${classes.toggleButton}`}
              >
                <ToggleButton value="left" aria-label="left aligned">
                  <TileIcon />
                  {/* <img src={CardIcon} alt="CardIcon" /> */}
                </ToggleButton>
                <ToggleButton value="right" aria-label="centered">
                  <ListIcon />
                  {/* <img src={ListIcon} alt="ListIcon" /> */}
                </ToggleButton>
              </ToggleButtonGroup>
              <Button color="secondary" title={services_page_constants.DOWNLOAD} className="mr-1" onClick={downloadExcel}></Button>
              <input color={"secondary"} ref={hiddenFileInput} onChange={excelUploadPopup} type="file" accept=".xls,.xlsx,.csv" style={{ display: "none" }} value='' />
              <Button color="secondary" title={services_page_constants.UPLOAD} className="mr-1" onClick={excelImport}></Button>
              <UpdateFee alignment={alignment} />
              <AddServiceDialog open={tempOpen} handleClose={handleClose} pageType={"service"} alignment={alignment} toggleDrawer={toggleDrawer} />
              {currentUser.role === "admin" &&
                <Button color="primary" title={services_page_constants.ADD_SERVICE} onClick={handleClick} disabled={currentUser.role === "admin" ? false : true}></Button>
              }
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseService}
              >
                <MenuItem onClick={handleAddServiceOpen}>Create a service from scratch</MenuItem>
                <MenuItem onClick={toggleDrawer(true)}>Select from a template</MenuItem>
              </Menu>
                {drawer && 
                  <AddEditService toggleDrawer={toggleDrawer} drawer={drawer || false} serviceId={serviceId} alignment={alignment} />
                }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      { alignment === "right" ? <ServicesList {...props} editOpenDrawer={editOpenDrawer} /> : <ServicesTile editOpenDrawer={(e) => editOpenDrawer(e)} />}
      {uploadAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            <Typography variant="h6">{`Are you sure you want to upload this file`}</Typography>
            <Typography variant="h6">{`${fileName.name}`}</Typography>
            <Grid className={'pt-2'} align={"center"}>
              <Button
                color={"primary"}
                className={"mr-1"}
                onClick={() => { uploadAction() }}
                title={"Upload"}
              />
              <Button
                color={"secondary"}
                onClick={() => { setUploadAlert(false); setFileName(null); }}
                title={"Cancel"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
    </React.Fragment>
  );
}

// default props
Services.defaultProps = {
  classes: {}
};

// prop types
Services.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ServicesStyles)(Services);

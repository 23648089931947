import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, Grid, IconButton, Menu, MenuItem, Tooltip, Slide } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

//Styles
import TemplatesStyles from "../TemplatesStyles"
import { SortIcon, MoreIcon } from '../../../assets/svg'

// Import Components
import { Typography, SearchBar, AlertDialog, Button, LazyLoader } from "../../../components";
import AddEditDocuments from "./AddEditDocument";
import NoRecordsFound from "../../Users/Components/NoRecordsFound";

// Import Actions
import { templateAction, updateReducerAction } from "../../../redux/actions";

//import helpers
import { nameLogoGeneration } from '../../../utils'

//import constant
import { templateConstant } from '../../../redux/constants'

// Import Config Layout
import { getChildContainerUnderBtnTabsHeight, documentsCardLimit } from "../../../config/layout";

const Documents = (props) => {
  //define dispatch
  const dispatch = useDispatch();

  // Set Card Limit and Size
  const cardLimit = documentsCardLimit();
  const cardSize = Math.floor(12 / (cardLimit / 8))

  //set ref for scroll
  const scrollDocument = useRef(null);

  //get inner conteainer height
  const height = getChildContainerUnderBtnTabsHeight();

  //get props
  const { classes } = props;

  //get reducer data
  const { documentTemplate: { data, page }, document: { disclosure } } = useSelector(s => (s.template))
  let { lazyLoading, loading } = useSelector((state) => state.UI);

  //get data oninit
  useEffect(() => {
    dispatch(templateAction.getAllDocument({ limit: cardLimit, skip: 0, search: page.search, sort: page.sort }))
  }, [dispatch])

  // set state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [templateID, setTemplateID] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(null);
  const [showScrollTopBTN, setShowScrollTopBTN] = useState(false)

  //handle more option change
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setTemplateID(id)
  };

  //close more options
  const handleClose = () => {
    setAnchorEl(null);
    setTemplateID(null)
  };

  //scroll event
  const getScrollData = () => {
    const isBottom = parseInt(scrollDocument.current.scrollHeight - scrollDocument.current.scrollTop) === (scrollDocument.current.clientHeight) || parseInt(scrollDocument.current.scrollHeight - scrollDocument.current.scrollTop) === (scrollDocument.current.clientHeight - 1) || parseInt(scrollDocument.current.scrollHeight - scrollDocument.current.scrollTop) === (scrollDocument.current.clientHeight + 1);
    if (!lazyLoading && isBottom && data.length < page.count) {
      //get next data
      dispatch(templateAction.getAllDocument({ limit: page.limit, skip: page.skip + page.limit, search: page.search, sort: page.sort, loader: true }))
    }

    if (scrollDocument.current.scrollTop > 150 && showScrollTopBTN === false) {
      setShowScrollTopBTN(true)
    }
    else if (scrollDocument.current.scrollTop < 150 && showScrollTopBTN === true) {
      setShowScrollTopBTN(false)
    }
  }
  //get sort data
  const getSortedData = (sorting) => {
    dispatch(templateAction.getAllDocument({ limit: cardLimit, skip: 0, search: page.search, sort: sorting }))
  }

  //edit template
  const editDocument = () => {
    if (templateID) {
      dispatch(templateAction.getOneDocument({ id: templateID }, () => {
        setAnchorEl(null);
      }))
    }
  }

  //delete Documenttemplate
  const deleteDocument = () => {
    if (templateID) {
      dispatch(templateAction.deleteDocument({ id: templateID }, () => {
        dispatch(templateAction.getAllDocument({ limit: cardLimit, skip: 0, search: page.search, sort: page.sort }))
        handleClose()
        setDeleteAlert(null)
      }))
    }
  }

  //get search data
  const intiSearch = (value) => {
    dispatch(templateAction.getAllDocument({ limit: cardLimit, skip: 0, search: value, sort: page.sort }))
  }

  //open close popup
  const handleClickOpen = () => {
    dispatch(updateReducerAction.update(templateConstant.UPDATE_DOCUMENT_DRAWER, true))
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container justify="space-between" className={classes.serviceActionBtn} alignItems="center">
          <Grid item>
            <Typography variant="h6" color="TextSecondary" component="h3"> {`Count ${page && page.count >= 0 ? `(${page.count})` : ""}`} </Typography>
          </Grid>
          <Grid>
            <Grid container alignItems="center">

              <SearchBar className={"mr-1"} loading={loading} value={page.search} intiSearch={(v) => { intiSearch(v) }} />
              <Grid className={"mr-1"}>
                {page && page.sort && page.sort === "asc" ?
                  <Tooltip arrow title="Sort By Created Date"><IconButton className={classes.searchIcon} onClick={() => { getSortedData('desc') }}><SortIcon /></IconButton></Tooltip>
                  :
                  <Tooltip arrow title="Sort By Created Date"><IconButton className={classes.searchIcon} onClick={() => { getSortedData('asc') }}><SortIcon /></IconButton></Tooltip>
                }
              </Grid>
              <Button color="primary" title={`+ ADD DOCUMENT`} onClick={() => { handleClickOpen() }}></Button>
              {(templateID && disclosure && Object.keys(disclosure).length) > 0 &&
                <AddEditDocuments
                  templateID={templateID}
                  setTemplateID={setTemplateID}
                />
              }
              {(!templateID) &&
                <AddEditDocuments
                  setTemplateID={setTemplateID}
                  templateID={null}
                />
              }
            </Grid>
          </Grid>
        </Grid>
        {data && data.length > 0 ?
          <Grid
            container
            spacing={0}
            className={classes.containerScroll}
            style={{ height: height, paddingRight: 12 }}
            alignContent="baseline"
            ref={scrollDocument}
            onScroll={() => { getScrollData() }}
          >
            <Grid container spacing={2}>

              {data && data.map((itm, indx) => (
                <Grid className="animate__animated animate__fadeInUp animate__delay-0.8s" item sm={cardSize} key={indx}>
                  <Grid className={classes.ApplicationlTile}>
                    <Grid container alignItems="center">
                      <Grid item className={classes.AppLeftImage}>
                        <Typography color="textSecondary" variant="h3">{nameLogoGeneration(itm.title ? itm.title : "")}</Typography>
                      </Grid>
                      <Grid item className={classes.AppCenterPart}>
                        <Grid container direction="column">
                          <Typography variant="h6" align="left" className={`${"oneLineEllip transCapital"}`}>{itm.title ? itm.title : ""}</Typography>
                          <Typography variant="body1" align="left" className={`${classes.templateText} ${"oneLineEllip"}`}>{itm.description ? itm.description : ""}</Typography>
                          <Typography variant="body1" align="left" className={`${classes.templateText} ${"oneLineEllip"}`}>{`Signature : ${itm.is_signature ? itm.is_signature : ""}`}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.AppRightPart}>
                        <IconButton style={{ padding: 0 }} onClick={(e) => { handleClick(e, itm.id) }}><MoreIcon /></IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <LazyLoader />
            </Grid>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => { editDocument() }}>Edit</MenuItem>
              <MenuItem onClick={() => { setDeleteAlert({ dataa: data.filter(i => (i.id === templateID)) }) }}>Delete</MenuItem>
            </Menu>
          </Grid>
          : !loading ? <NoRecordsFound /> : null}
        {showScrollTopBTN && <IconButton onClick={() => { scrollDocument.current.scrollTo(0, 0) }} className={"toTop"}><KeyboardArrowUpIcon /></IconButton>}
      </Grid>
      {deleteAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            <Typography variant="h6">{deleteAlert.dataa && deleteAlert.dataa[0] && deleteAlert.dataa[0]['docMap'] > 0 ? `The document is mapped to agreement, You cannot delete it` : `Are you sure you want to delete`}</Typography>
            <Grid className={'pt-2'} align={"center"}>
              {deleteAlert.dataa && deleteAlert.dataa[0] && !(deleteAlert.dataa[0]['docMap']) &&
                <Button
                  color={"primary"}
                  className={"mr-1"}
                  onClick={() => { deleteDocument() }}
                  title={"Continue"}
                />
              }
              <Button
                color={"secondary"}
                onClick={() => { handleClose(); setDeleteAlert(null) }}
                title={"Cancel"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
    </React.Fragment>
  );
}

// default props
Documents.defaultProps = {
  classes: {}
};

// prop types
Documents.propTypes = {
  classes: PropTypes.object
};

export default withStyles(TemplatesStyles)(Documents);

import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from '@material-ui/core';

//Styles
import TenantApplicationStyles from "../TenantApplicationStyles"

// Import Components
import { Signature, CheckBox, Typography } from "../../../components";

//import actions
import { updateReducerAction } from "../../../redux/actions";

//import constants
import { tenantAppConstant } from "../../../redux/constants";

//import helpers
import { dataPopulator } from "../../../utils"

const Criteria = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes } = props;

    //get reducer data
    const { appData: { criteria, allSteps , settings} } = useSelector(s => (s.tenantApp))

    const critRef = useRef(null);

    useEffect(() => {
        critRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, []);

    //update checkbox
    const handleChangeCheckbox = (e) => {
        let updateData = criteria;
        updateData[e["name"]] = e["value"]
        dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_CRITERIA, updateData))
    }

    //update signature
    const handleSignChange = (sign, bs64) => {
        let updateData = criteria;
        updateData["criteriaSignature"] = bs64;
        updateData["sign"] = sign;
        dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_CRITERIA, updateData))
    }

    return (
        <Grid item xs={12} style={{ overflowX: "hidden" }}>
            <form>
                <Grid container ref={critRef}>
                    <Grid item xs={12} className={`${"p-1 sm-p-3"} ${classes.criteriaContainer}`}>
                        <Grid className={`${"tenantCardContainer"} ${classes.cardContainer}`}>

                            <Grid item sm={12} className="mb-1">
                                <span dangerouslySetInnerHTML={{ __html: dataPopulator(settings ,criteria["content"]) }}></span>
                            </Grid>

                            {allSteps.indexOf("criteriaSign") > -1 ?
                                <Grid item sm={12} className="mb-2" >
                                    <Typography variant="h5">{"Sign below to confirm that you have read and understand the above, before continuing with the application"}</Typography>
                                    <Signature
                                        name={"sign"}
                                        classes={classes}
                                        sigPad={criteria["sign"] ? criteria["sign"] : {}}
                                        signImg={criteria["criteriaSignature"] ? criteria["criteriaSignature"] : ""}
                                        handleSignChange={handleSignChange}
                                    />
                                </Grid>

                                :

                                <Grid item sm={12} className="mb-2" >
                                    <Typography variant="h5">{"Sign below to confirm that you have read and understand the above, before continuing with the application"}</Typography>
                                    <CheckBox
                                        id={'criteriaCheckbox'}
                                        name={"criteriaCheckbox"}
                                        color="secondary"
                                        label={criteria["criteriaConfirmMsg"]}
                                        checked={criteria["criteriaCheckbox"]}
                                        handleChange={(e) => handleChangeCheckbox({ name: "criteriaCheckbox", value: e.target.checked })}
                                    />
                                </Grid>
                            }
                        </Grid>

                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
}

// default props
Criteria.defaultProps = {
    classes: {}
};

// prop types
Criteria.propTypes = {
    classes: PropTypes.object
};

export default withStyles(TenantApplicationStyles)(Criteria);

import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core';

//Styles
import SettingsStyles from "../SettingsStyles"

// Import Components
import { Button, TextFieldWithoutRef, Typography, CheckBox } from "../../../components";

// Import History
import history from "../../../config/history";

// Import Cofig Constants
import { settings_page_constants, user_page_constants } from "../../../config/constants";
import { settingsConstant } from "../../../redux/constants";

// Import Actions
import { settingsAction, updateReducerAction } from "../../../redux/actions";

const BlueFin = (props) => {
    const dispatch = useDispatch()

    const { configuration: { bluefin } } = useSelector(state => state.settings);
    const [errors, setErrors] = useState({});
    const [checkedCert, setCheckedCert] = useState(bluefin["is_cert"] ? bluefin["is_cert"] : false)
    const [updateButton, setUpdateButton] = useState(false)

    /**
  *Handle Cancel Navigage to Tab based on Roles
  */
    const handleCancel = () => {
        history.go(-1)
    };

    const handleChange = (e) => {
        setUpdateButton(true)
        if (e.target.name === "is_cert") {
            bluefin["is_cert"] = e.target.checked
            setCheckedCert(e.target.checked)
        }
        else {
            bluefin[e.target.name] = e.target.value
        }
        dispatch(updateReducerAction.update(settingsConstant.UPDATE_BLUEFIN_REQUEST, bluefin, () => { }));
    }

    const handleSubmit = () => {
        let errors = {}
        if (!(bluefin["account"].trim())) {
            errors["account"] = true
        }
        if (!(bluefin["api_key"].trim())) {
            errors["api_key"] = true
        }
        if (!(bluefin["group"].trim())) {
            errors["group"] = true
        }
        setErrors(errors)
        if (Object.keys(errors).length === 0) {
            dispatch(settingsAction.saveConfiguration({ key: "bluefin", value: { ...bluefin, is_cert: bluefin.is_cert ? 1 : 0 } }, () => { 
                setUpdateButton(false)
            }));
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.ACCOUNT}</Typography>
                            <TextFieldWithoutRef
                                name={"account"}
                                size={"small"}
                                margin={"none"}
                                value={bluefin.account ? bluefin.account : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.account ? true : false}
                                helperText={errors.account ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.APIKEY}</Typography>
                            <TextFieldWithoutRef
                                name={"api_key"}
                                size={"small"}
                                margin={"none"}
                                value={bluefin.api_key ? bluefin.api_key : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.api_key ? true : false}
                                helperText={errors.api_key ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.GROUP}</Typography>
                            <TextFieldWithoutRef
                                name={"group"}
                                size={"small"}
                                margin={"none"}
                                value={bluefin.group ? bluefin.group : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.group ? true : false}
                                helperText={errors.group ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={4}>
                            <CheckBox
                                name={"is_cert"}
                                label={settings_page_constants.CERT}
                                checked={checkedCert}
                                color="secondary"
                                handleChange={(e) => handleChange(e)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mt-1">
                        <Grid item xs={4} align="left">
                            <Button color="primary" size="small" title={"UPDATE"} className="mr-1" onClick={handleSubmit} disabled={updateButton?false:true}></Button>
                            <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={handleCancel}></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

// default props
BlueFin.defaultProps = {
    classes: {},
};

// prop types
BlueFin.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(SettingsStyles)(BlueFin);
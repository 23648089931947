
// import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import '../../assets/theme/palette';
import palette from '../../assets/theme/palette';

const style = theme => ({
  ALERT_SUCCESS: {
    backgroundColor: palette.primary.dary,
    fontWeight: "500"
  },
  ALERT_ERROR: {
    backgroundColor: palette.primary.light,
    fontWeight: "500"
  },
  ALERT_INFO: {
    backgroundColor: palette.primary.light,
    fontWeight: "500"
  },
  ALERT_WARNING: {
    backgroundColor: palette.greyshades.main,
    fontWeight: "500"
  },
  ALERT_CLOSE: {
    padding: theme.spacing(0.5)
  }
});

export default style;

import React from "react";
import { useSelector } from "react-redux";
import { Grid, CircularProgress } from "@material-ui/core";
import './styles.css'

// Import css
const loaderTextStyle = {
  // position: "absolute",
  // bottom: "5px",
  width: "30px",
  height: "30px",
  // left: "634px",
  // background: "#ddd"
};

const LazyLoaderComponent = () => {

  //get reducer data
  const UI = useSelector((state) => state.UI);

  if (!UI.lazyLoading) {
    return <div></div>;
  } else {
    return (
      <Grid container justify="center" alignItems={"center"} style={{ height: "100px" }}>
        {/* <CircularProgress style={loaderTextStyle} /> */}
        <svg className="circleLoaderSvg" height="60" width="60">
          <circle className="circle1" cx="30" cy="30" r="25" stroke="#12a8de" strokeWidth="4" fill="transparent" />
          <circle className="circle2" cx="30" cy="30" r="15" stroke="#12a8de" strokeWidth="4" fill="transparent" />
          <circle className="circle3" cx="30" cy="30" r="5" stroke="#12a8de" strokeWidth="4" fill="transparent" />
          Sorry, your browser does not support inline SVG.
        </svg>
      </Grid>
    );
  }
};

export const LazyLoader = LazyLoaderComponent;
